import React from 'react';
import { tableNameWdList } from './dataList';
import useWithdrawal from 'hooks/useWithdrawal';
import { WithdrawalContext } from 'context/withdrawalContext';
import Filter from './filter';

import BasicTable from 'components/table';
import Pagination from 'components/pagination';

const TableWd = () => {
  const { withdrawal } = useWithdrawal();
  let { data } = withdrawal.withdrawal_list;
  let { isLoading } = withdrawal;

  let {
    page,
    countPage,
    handleAction,
    header,
    ChangePaginationWdList,
    ChangePageWdList,
    handleSubmit,
    handleReset,
  } = React.useContext(WithdrawalContext);

  const handleOpenDetail = (id) => {
    handleAction('wdDetail', id);
  };

  return (
    <div>
      <BasicTable
        FilterComponent={Filter}
        headerList={header.val}
        dataList={data}
        handleAction={handleOpenDetail}
        tableNameList={tableNameWdList}
        handleSubmitFilter={handleSubmit}
        handleResetFilter={handleReset}
        {...{ countPage, page, isLoading }}
      />

      <Pagination
        value={countPage}
        data={withdrawal.withdrawal_list.pagination}
        page={page}
        handleChange={ChangePaginationWdList}
        handlePage={ChangePageWdList}
      />
    </div>
  );
};

export default TableWd;
