import React from 'react';
import Card from 'components/cardComponents';
import { btnTheme2 } from 'components/muiTheme';
import { Button, ThemeProvider } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { NumericFormat } from 'react-number-format';
import { FiArrowLeft } from 'react-icons/fi';

import TableWdSettled from '../table/tableWdSettled';
import useTransaction from 'hooks/useWithdrawal';
import { WithdrawalContext } from 'context/withdrawalContext';
import ModalWdReq from './Modal';

const WidrawalReq = () => {
  let { handleChangeUi, open, show, setShow, setOpen, handlWdReqAction } =
    React.useContext(WithdrawalContext);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleShow = () => setShow(!show);

  const { withdrawal } = useTransaction();
  const { summary } = withdrawal.wd_settled_transactions;
  let { data } = withdrawal.wd_settled_transactions;

  return (
    <div>
      <div
        className="text-2xl sm:text-2xl flex items-center font-bold mb-5 mt-[5rem] lg:mt-[1rem] cursor-pointer"
        onClick={() => handleChangeUi('')}
      >
        <FiArrowLeft className="mr-3" style={{ fontSize: 25 }} />
        Withdrawal Request
      </div>

      <Card>
        <div className="py-5 px-8">
          <div className="flex items-center py-1 pb-2 border-b-[1.5px] border-[#ECECEC]">
            <div className="text-md font-bold mr-2">
              Settled Transaction List
            </div>
            <div className="text-[#21AF7D] font-bold text-sm">
              ({summary.total_records})
            </div>
          </div>

          <div className={`${show ? 'h-[400px]' : 'auto'} mt-3`}>
            <TableWdSettled show={show} />
          </div>
          {data.length > 5 && (
            <div
              className="border-dashed border-[1px] border-[#21AF7D] flex py-1 justify-center text-[#21AF7D]"
              onClick={handleShow}
            >
              <div className="mr-3">{!show ? 'Show More' : 'Show Less'}</div>
              {!show ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
            </div>
          )}
        </div>
      </Card>

      <div className="mt-3"></div>

      <Card>
        <div className="py-5 px-8">
          <div className="text-md font-bold pb-3 py-1 border-b-[1.5px] border-[#ECECEC]">
            Amount Summary
          </div>
          <div className="flex w-full ">
            <div className="w-full lg:w-[40%]">
              <div className="font-bold text-[14px] py-4">
                Transaction Amount Summary
              </div>
              <div className="flex justify-between  text-[14px] text-[#f6C6D71]">
                <div>Total Record Transaction</div>
                <div>{summary.total_records}</div>
              </div>
              <div className="flex py-2 border-dotted text-[13px] border-b-2 justify-between font-bold text-md">
                <div>Total Transaction Amount</div>
                <div>
                  Rp.
                  <NumericFormat
                    value={summary.total_trx_amount}
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    prefix={'  '}
                  />
                </div>
              </div>

              <div className="font-bold text-[14px] py-4">Fee Summary</div>
              <div className="flex justify-between  text-[14px] text-[#f6C6D71]">
                <div>Total 3rd Party Fee</div>
                <div>
                  Rp.
                  <NumericFormat
                    value={summary.total_fee_third_party}
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    prefix={'  '}
                  />
                </div>
              </div>
              <div className="flex justify-between text-[14px] text-[#f6C6D71]">
                <div>Total Settled Fee</div>
                <div>
                  Rp.
                  <NumericFormat
                    value={summary.total_fee_settled}
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    prefix={'  '}
                  />
                </div>
              </div>
              <div className="flex py-2 border-dotted text-[13px]  justify-between font-bold text-md">
                <div>Grand Total Fee</div>
                <div>
                  Rp.
                  <NumericFormat
                    value={summary.total_fee}
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    prefix={'  '}
                  />
                </div>
              </div>

              <div className="bg-[#F8F8F8] font-bold text-[14px] flex w-full px-3 py-5 rounded-lg justify-between">
                <div>Payable Amount</div>
                <div className="text-[#21AF7D]">
                  Rp.
                  <NumericFormat
                    value={summary.total_payable_amount}
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    prefix={'  '}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>

      <ModalWdReq
        {...{ open, handleClose, handlWdReqAction }}
        isLoading={withdrawal.isLoading}
      />

      <div className="float-right my-5 m-3 mb-16">
        <ThemeProvider theme={btnTheme2}>
          <Button
            variant="contained"
            color="primary"
            sx={{ width: '200px', marginRight: '-10px' }}
            onClick={handleOpen}
          >
            Withdraw
          </Button>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default WidrawalReq;
