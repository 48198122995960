import useReport from 'hooks/useTransaction';
import { NumericFormat } from 'react-number-format';
import Amounts from './amounts';
import LoadingWrapper from 'components/loadingWrapper';

const HeaderTransactionList = () => {
  const { TransactionList } = useReport();

  const {
    total_submerchant,
    total_freq_transaction,
    total_mdr,
    total_transaction_amount,
  } = TransactionList.summary;

  let { isLoading } = TransactionList;
  return (
    <div className='p-5'>
      <div className='text-xl font-bold mt-2'>Transaction </div>
      <div className='mt-5 p-5 border rounded-md'>
        <div className='flex items-center mb-2'>
          <div className='text-[16px] lg:text-[16px] font-medium text-black'>
            Total Transaction Amount
          </div>
        </div>
        <div className='text-[36px] font-bold w-full'>
          <LoadingWrapper isLoading={isLoading}>
            Rp.
            <NumericFormat
              value={total_transaction_amount}
              displayType={'text'}
              thousandSeparator={'.'}
              decimalSeparator={','}
              prefix={'  '}
            />
          </LoadingWrapper>
        </div>
        <div className='mt-2'>
          <Amounts
            {...{
              total_freq_transaction,
              total_mdr,
              total_submerchant,
              isLoading,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default HeaderTransactionList;
