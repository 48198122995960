import * as Sentry from '@sentry/react';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import storage from 'redux-persist/lib/storage';
import { rootReducer } from './reducers';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import { IAuthState } from './types/authTypes';
import { IExampleState } from './types/exampleType';
import { IUtilsState } from './types/utilsTypes';
import { IAppState } from './types/appStateTypes';

import { IRegisterState } from './types/registerTypes';
import { IOnboardingState } from './types/onboardingTypes';
import { IBalanceState } from './types/balanceTypes';
import { IRefundState } from './types/refundTypes';
import { IWithdrawalState } from './types/withdrawalTypes';
import { ITransactionListState } from './types/transactionTypes';
import { INotificationState } from './types/notificationTypes';
import { ISettingState } from './types/settingTypes';
import { ITicketingState } from './types/ticketingTypes';
import { IDashboardState } from './types/dashboardType';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

let middleware: any[] = [thunk];
if (process.env.NODE_ENV === 'development') {
  middleware = [...middleware, thunk, logger];
} else {
  middleware = [...middleware, thunk];
}

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [''], // only navigation will be persisted
};

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middleware), sentryReduxEnhancer)
);
const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export interface RootState {
  example: IExampleState;
  auth: IAuthState;
  utils: IUtilsState;
  appState: IAppState;
  register: IRegisterState;
  onboarding: IOnboardingState;
  refund: IRefundState;
  balance: IBalanceState;
  withdrawal: IWithdrawalState;
  transactionList: ITransactionListState;
  notificationState: INotificationState;
  setting: ISettingState;
  ticketing: ITicketingState;
  dasboard: IDashboardState;
}

export { store, persistor };
