import React from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
} from '@mui/material';
import { Option } from '../interface';

type Props = SelectProps & {
  options: Option[];
  selection: { val: string; set: (val: string) => void };
};
const Component: React.FC<Props> = (props) => {
  const { options, disabled, selection } = props;
  const singleChange = (event: SelectChangeEvent) => {
    selection?.set(event.target.value);
  };
  return (
    <FormControl fullWidth>
      {props.placeholder ? (
        <InputLabel
          size="small"
          id={props.placeholder}
          sx={{ bgcolor: disabled ? 'unset' : 'white' }}
          className="opacity-70"
        >
          {props?.placeholder}
        </InputLabel>
      ) : null}
      <Select
        disabled={disabled}
        size="small"
        onChange={singleChange}
        required
        value={selection?.val}
        sx={{ bgcolor: disabled && '#F0F0F0', fontSize: '14px' }}
        labelId={props.placeholder}
        {...props}
      >
        {options.map((list) => (
          <MenuItem key={list.value} value={list.value} id={list.label}>
            <div className="">{list.label}</div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Component;
