enum variant {
  success = 'success',
  info = 'info',
  warning = 'warning',
  danger = 'danger',
  other = 'other',
  primary = 'primary',
  disabled = 'disabled',
}

export type colorVariant = keyof typeof variant;
type colorTypes = Partial<Record<colorVariant, string>>;

export const txColor: colorTypes = {
  primary: '#21AF7D',
  danger: '#EE4E2B',
  warning: '#FDBE2C',
  info: '#3967E0',
  disabled: '#6C696A',
};

export const bgColor: colorTypes = {
  primary: '#E9F7F2',
  disabled: '#CACACA',
  warning: '#FFF9EA',
};

export const bdColor: colorTypes = {
  primary: '#21AF7D',
  warning: '#FDBE2C',
  disabled: '#CACACA',
  success: '#BAE6D7',
};
