import DateTime from 'components/filter/dateTime';
import Search from 'components/filter/search';
import { WithdrawalContext } from 'context/withdrawalContext';
import React from 'react';

const Components = ({ id }) => {
  let {
    WithdrawalNo,
    setWithdrawalNo,
    setReceiptNo,
    ReceiptNo,
    setDateRange,
    startDate,
    endDate,
  } = React.useContext(WithdrawalContext);
  return (
    <div className="p-2">
      {id.includes('date') && (
        <DateTime {...{ setDateRange, startDate, endDate }} />
      )}
      {id === 'trx_no' && (
        <Search
          label="Search Withdrawal No"
          handleChange={setWithdrawalNo}
          value={WithdrawalNo}
        />
      )}
      {id === 'receipt_no' && (
        <Search
          label="Search Receipt No"
          handleChange={setReceiptNo}
          value={ReceiptNo}
        />
      )}
    </div>
  );
};

export default Components;
