import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'stores';
import { TextField } from '@mui/material';
import { SingleOption } from 'components/select';
import { onlyNumbers } from 'utils/caseFormatter';
import { useFormik } from 'formik';
import { QRoptions, qris_fields } from '..';
import SectionQRIS, { GridDetailQRIS } from '../template';
import { phoneRegex } from 'components/configs/regex';

type Props = {
  identity: { val: string; set: (val: string) => void };
  criteria: { val: string; set: (val: string) => void };
  category: { val: string; set: (val: string) => void };
  formik: ReturnType<typeof useFormik>;
  disabled?: boolean;
};
type field_data = {
  NamaPemilik: string;
  NomorRekening: string;
  NoIdentitas: string;
  NoNPWP: string;
  NoTelp: string;
  NamaMerchant: string;
};
const InfoMerchant: React.FC<Props> = (props) => {
  const url = window.location.href.split('/').pop();
  const { category, criteria, identity, formik } = props;
  const { userData } = useSelector((state: RootState) => state.auth);
  const {
    informasiBisnis,
    detailBank,
    QRIS,
    detailQRIS: dq,
  } = useSelector((o: RootState) => o.onboarding);
  const norek = detailBank?.BankAccountNumber;
  const listField: qris_fields[] = [
    {
      title: 'Nama Pemilik Usaha',
      id: 'NamaPemilik',
      disabled: true,
      defaultValue: userData?.Username,
    },
    {
      title: 'No. Rekening Merchant',
      id: 'NomorRekening',
      isNumber: true,
      disabled: props?.disabled,
      defaultValue: norek,
      max: 20,
    },
    {
      title: 'Jenis Identitas',
      id: 'JenisIdentitas',
      isOption: true,
      options: QRoptions.listID,
      states: identity,
      disabled: props?.disabled,
    },
    {
      title: 'No. Identitas',
      id: 'NoIdentitas',
      isNumber: true,
      disabled: props?.disabled,
      max: 30,
    },
    {
      title: 'No. NPWP',
      id: 'NoNPWP',
      isNumber: true,
      disabled: props?.disabled,
      defaultValue: informasiBisnis?.NpwpNumber,
      max: 20,
    },
    {
      title: 'No. Telp',
      id: 'NoTelp',
      isNumber: true,
      disabled: props?.disabled,
      max: 20,
    },
    {
      title: 'Kriteria Merchant',
      id: 'KriteriaMerchant',
      isOption: true,
      options: QRoptions.listKriteria,
      states: criteria,
      disabled: props?.disabled,
    },
    {
      title: 'Nama Merchant',
      id: 'NamaMerchant',
      disabled: true,
      defaultValue: userData?.Fullname,
    },
    {
      title: 'Kategori Merchant',
      id: 'KategoriMerchant',
      isOption: true,
      options: QRoptions.listKategori,
      states: category,
      disabled: props?.disabled,
    },
  ];

  const handleDefValue = (
    regQr: keyof field_data, // register QR value
    detQr: keyof typeof dq, // detail QR value
    etc?: string // other source value
  ) => {
    if (QRIS !== null) return QRIS[regQr];
    if (dq !== null) return String(dq[detQr]);
    if (etc) return etc;
    return '';
  };

  // list of other values except the option dropdown
  const [other, setOther] = React.useState<field_data>({
    NoIdentitas: handleDefValue('NoIdentitas', 'mq_identity_no'),
    NomorRekening: handleDefValue('NomorRekening', 'mq_account_no', norek),
    NoNPWP: handleDefValue('NoNPWP', 'mq_npwp', informasiBisnis?.NpwpNumber),
    NoTelp: handleDefValue('NoTelp', 'mq_phone_number'),
    NamaMerchant: userData?.Fullname,
    NamaPemilik: userData?.Username,
  });
  const [errors, setErrors] = React.useState<field_data>({
    NamaMerchant: '',
    NamaPemilik: '',
    NoIdentitas: '',
    NomorRekening: '',
    NoNPWP: '',
    NoTelp: '',
  });

  const validationField = (id: string, val: string, max?: number) => {
    const listName = listField.flatMap((x) => ({ id: x.id, title: x.title }));
    const ids = listName.find((i) => i.id === id).title;
    if (val.length === 0) return `Data tidak boleh kosong`;
    if (max && val.length > max) return `${ids} terlalu panjang`;
    if (id === 'NoTelp' && !phoneRegex.test(val)) {
      return `Format nomor tidak sesuai`;
    }
  };
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    max?: number
  ) => {
    const { value, id } = e.target;
    setOther((prev) => ({ ...prev, [id]: value }));
    formik.setValues((prev) => ({ ...prev, [id]: value })); // to update the formik
    const error = validationField(id, value, max);
    setErrors((prev) => ({ ...prev, [id]: error }));
  };
  return (
    <SectionQRIS
      noTitle={url !== 'onboarding' && url !== 'register-qris'}
      title="Informasi Merchant"
    >
      {listField?.map((info) => (
        <GridDetailQRIS key={info.title} title={info.title}>
          {info.isOption ? (
            <SingleOption
              options={info.options}
              selection={{
                val: info.states.val,
                set: info.states.set,
              }}
              fullWidth
              disabled={info?.disabled}
              placeholder={`Pilih ${info.title}`}
            />
          ) : (
            <>
              <TextField
                id={info.id}
                value={other[info.id]}
                onChange={(e) => handleChange(e, info.max)}
                size="small"
                fullWidth
                onInput={info?.isNumber && onlyNumbers}
                placeholder={`Masukkan ${info.title}`}
                disabled={info?.disabled}
                className={`${info.disabled ? 'bg-[#F0F0F0]' : ''}`}
              />
              {errors[info?.id] ? (
                <b className="text-red-500">{errors[info?.id]}</b>
              ) : null}
            </>
          )}
        </GridDetailQRIS>
      ))}
    </SectionQRIS>
  );
};

export default InfoMerchant;
