import { ThemeProvider } from '@emotion/react';
import { Checkbox, Collapse } from '@mui/material';
import { PaginationTheme } from 'components/muiTheme';
import React, { useEffect, useMemo, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { ItemTypes } from './ItemTypes';
import CardPaymentChannel from './cardPaymentChannel';

interface IPaymentMethodCard {
  method?;
  index?;
  movePaymentMethod?;
  moveChannel?;
  findMethod?: any;
  findChannel?: any;
  priview?: boolean;
  editView?: any;
  pcDrag?: any;
  handleSelectChannel?;
  handleSelectAllChannel?;
  handleChangeFee?;
  handleChangeType?;
}
const PaymentMethodCard: React.FC<IPaymentMethodCard> = ({
  method,
  index,
  movePaymentMethod,
  moveChannel,
  findMethod,
  findChannel,
  editView,
  pcDrag,
  handleSelectChannel,
  handleSelectAllChannel,
  handleChangeFee,
  handleChangeType,
}) => {
  const [showMore, setshowMore] = useState(false);

  const [isDragindId, setisDragindId] = useState(null);

  const hoverCallback = useMemo(() => {
    return (draggedItem) => {
      if (draggedItem.index !== index) {
        movePaymentMethod(draggedItem.index, index);
        draggedItem.index = index;
      } else {
        findMethod(draggedItem.index, index);
      }
    }; // eslint-disable-next-line
  }, [index, movePaymentMethod]);

  const [, drop] = useDrop({
    accept: ItemTypes.PAYMENTMETHOD,
    hover: hoverCallback,
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
    // eslint-disable-next-line
  }, []);

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.PAYMENTMETHOD,
    item: { pmId: method?.id, index, type: ItemTypes.PAYMENTMETHOD },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const renderedChannels = React.useMemo(() => {
    return method?.chanels?.map((chanel, index) => (
      <CardPaymentChannel
        {...{
          chanel,
          pcDrag,
          handleSelectChannel,
          handleChangeFee,
          handleChangeType,
        }}
        index={index}
        pmId={method.id}
        moveChannel={moveChannel}
        findChannel={findChannel}
        dragindId={{
          val: isDragindId,
          set: setisDragindId,
        }}
        editView={editView}
      />
    )); // eslint-disable-next-line
  }, [method, movePaymentMethod, moveChannel]);

  return (
    <div
      ref={(node) => editView && drag(drop(node))}
      key={index}
      className={`${
        isDragging
          ? 'bg-[#F0F0F0] relative h-[125px] border-none'
          : 'bg-[#FFFFFF] '
      } w-full border-2 pb-3 pt-3 pl-3 pr-1`}
    >
      <div className={`${isDragging && 'hidden'} gap-2 grid`}>
        <div className="flex w-full justify-between">
          <ThemeProvider theme={PaginationTheme}>
            <div
              className={`${
                !editView ? 'lp-2 pb-2' : '-ml-3 -mt-2 '
              } flex items-center`}
            >
              {editView && (
                <Checkbox
                  color="primary"
                  checked={method.chanels?.some((channel) => channel.status)}
                  onChange={(e) => {
                    handleSelectAllChannel(
                      method.id,
                      !method.chanels?.some((channel) => channel.status),
                      !method.chanels?.some((channel) => channel.status)
                    );
                  }}
                />
              )}
              <b className="text-[18px]  font-extrabold lg:text-[15px]">
                {method?.name}
              </b>
            </div>
          </ThemeProvider>
          {editView && (
            <DragIndicatorIcon sx={{ color: '#9E9E9E', cursor: 'grab' }} />
          )}
        </div>

        <div className="mt-[-10px] mr-0 lg:mr-5">
          <div className="text-[16px] text-[#4F4C4D] ">
            Bayar menggunakan aplikasi pembayaran digital pilihan Anda
          </div>
        </div>

        <Collapse in={showMore} timeout="auto" unmountOnExit>
          {editView && (
            <div
              className={`block lg:flex w-full justify-between pr-3 items-center`}
            >
              <b className="text-[12px] lg:text-[16px]">
                Payment Channels List :
              </b>
              <ThemeProvider theme={PaginationTheme}>
                <div className="flex items-center">
                  <Checkbox
                    color="primary"
                    checked={method.chanels?.every((channel) => channel.status)}
                    onChange={(e) => {
                      handleSelectAllChannel(method.id, e.target.checked);
                    }}
                  />
                  <div className="font-bold">Select All</div>
                </div>
              </ThemeProvider>
            </div>
          )}

          <div className="p-3">{renderedChannels}</div>
        </Collapse>

        <div
          className="mx-auto font-bold cursor-pointer items-center text-[#21AF7D] "
          onClick={() => setshowMore(!showMore)}
        >
          Show more detail
          {showMore ? <ExpandLess /> : <ExpandMore />}
        </div>
      </div>
    </div>
  );
};

export default PaymentMethodCard;
