/* eslint-disable no-useless-escape */
import React from 'react';
import useSetting from 'hooks/useSetting';
import { SettingContext } from 'context/settingContext';
import { toast } from 'react-toastify';
import useSandbox from 'hooks/useSandbox';
import WrapEditSubmit from '../components/WrapEditSubmit';
import EmailDestination from './emailDestination';
import MessageDestination from './messageDestination';
import SwitchWithLabel from './switchWithLabel';
import WrapContent from './wrapContent';

const Messaging = () => {
  let { SettingState, submitEdit, getApiSettingData } = useSetting();
  let { systemSetting } = SettingState;
  let { messaging } = systemSetting;
  let { isEdit, setisEdit, setisModal } = React.useContext(SettingContext);

  let regexEmail =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let regexPhone =
    /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

  const [isenable, setIsenable] = React.useState({
    is_enable_email: Number(messaging?.is_enable_email),
    is_enable_mobile: Number(messaging?.is_enable_mobile),
    is_enable_web_notif: Number(messaging?.is_enable_web_notif),
  });

  const [isData, setisData] = React.useState({
    email_to_customer: messaging?.email_to_customer,
    email_to_merchant: messaging?.email_to_merchant,
    email_support: messaging?.email_support,
    finance_email: messaging?.finance_email,
    tech_email: messaging?.tech_email,

    is_enable_wa: messaging?.is_enable_wa,
    is_enable_sms: messaging?.is_enable_sms,
    cs_phone: messaging?.cs_phone,
  });

  const [defaultFormInput, setdefaultFormInput] = React.useState(isData);

  const [defaultisenable, setdefaultisenable] = React.useState(isenable);

  const [isInvalid, setisInvalid] = React.useState({
    email_support: false,
    finance_email: false,
    tech_email: false,
    cs_phone: false,
  });

  let sandbox = useSandbox()?.userData['Env'];

  const handleEdit = () => {
    let BodyData = {
      ...isData,
      is_enable_email: Number(isenable?.is_enable_email),
      is_enable_mobile: Number(isenable?.is_enable_mobile),
      is_enable_web_notif: Number(isenable?.is_enable_web_notif),
    };

    submitEdit('messaging', BodyData, (response: any) => {
      if (response.code !== 200) {
        toast.error('Terjadi Kesalahan', { autoClose: 4000 });
      } else {
        setisModal(true);
        getApiSettingData('messaging', (response: any) => {
          if (response.code !== 200)
            toast.error('Terjadi Kesalahan', { autoClose: 4000 });
        });
      }
    });
  };

  const handleEnable = (e: any) => {
    setIsenable({
      ...isenable,
      [e.target.name]: e.target.checked,
    });
  };

  const handleChecked = (e: any) => {
    setisData({
      ...isData,
      [e.target.name]: Number(e.target.checked),
    });
  };

  const handleChangeDataInput = (e: any) => {
    setisData({
      ...isData,
      [e.target.name]: e.target.value,
    });

    // validate
    const regex = e.target.name === 'cs_phone' ? regexPhone : regexEmail;
    setisInvalid({
      ...isInvalid,
      [e.target.name]: !e.target.value.match(regex) && e.target.value !== '',
    });
  };

  const handleCancel = () => {
    setisEdit(false);
    setisData(defaultFormInput);
    setIsenable(defaultisenable);
    getApiSettingData('messaging', (response: any) => {
      if (response.code !== 200)
        toast.error('Terjadi Kesalahan', { autoClose: 4000 });
    });
  };

  React.useEffect(() => {
    setisEdit(false);
    getApiSettingData('messaging', (response: any) => {
      if (response.code !== 200) {
        toast.error('Terjadi Kesalahan', { autoClose: 4000 });
      } else {
        let newData = {
          email_to_customer: response?.data?.email_to_customer,
          email_to_merchant: response?.data?.email_to_merchant,
          email_support: response?.data?.email_support || '',
          finance_email: response?.data?.finance_email || '',
          tech_email: response?.data?.tech_email || '',

          is_enable_wa: response?.data?.is_enable_wa,
          is_enable_sms: response?.data?.is_enable_sms,
          cs_phone: response?.data?.cs_phone || '',
        };

        let newEnable = {
          is_enable_email: response?.data?.is_enable_email,
          is_enable_mobile: response?.data?.is_enable_mobile,
          is_enable_web_notif: response?.data?.is_enable_web_notif,
        };

        setisData(newData);
        setdefaultFormInput(newData);
        setIsenable(newEnable);
        setdefaultisenable(newEnable);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getApiSettingData, sandbox]);

  return (
    <div className="flex flex-col gap-3">
      <WrapContent>
        <SwitchWithLabel
          name="is_enable_email"
          onChange={handleEnable}
          disabled={!isEdit}
          checked={Boolean(isenable?.is_enable_email)}
          value={Boolean(isenable?.is_enable_email)}
          label="Enable Email"
        />
        <EmailDestination
          {...{
            isData,
            isEdit,
            isenable,
            handleChecked,
            messaging,
            isInvalid,
            handleChangeDataInput,
          }}
        />
      </WrapContent>
      <WrapContent>
        <SwitchWithLabel
          name="is_enable_mobile"
          onChange={handleEnable}
          disabled={!isEdit}
          checked={Boolean(isenable?.is_enable_mobile)}
          value={Boolean(isenable?.is_enable_mobile)}
          label="Enable Mobile Message"
        />
        <MessageDestination
          {...{
            isData,
            handleChecked,
            isEdit,
            isenable,
            isInvalid,
            handleChangeDataInput,
          }}
        />
      </WrapContent>
      <SwitchWithLabel
        name="is_enable_web_notif"
        onChange={handleEnable}
        disabled={!isEdit}
        checked={Boolean(isenable?.is_enable_web_notif)}
        value={Boolean(isenable?.is_enable_web_notif)}
        label="Enable Web Notification"
      />
      <WrapEditSubmit {...{ handleCancel, isEdit, handleEdit }} />
    </div>
  );
};

export default Messaging;
