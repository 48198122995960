// name: name variable, tableName: nameTableUi/nama yang tampil di user, icon: filterIcon
const addTableName = (
  name: string,
  tableName: string,
  filter: boolean,
  typeValue?: string,
  width?: string,
  formatText?: string
) => ({
  name,
  tableName,
  filter,
  typeValue,
  width,
  formatText,
});

export const tableName = [
  addTableName('merchant_name', 'Merchant Name', false, '', '160.4px'),
  addTableName('total_trx', 'Frequency Transaction ', false, '', '220.4px'),
  addTableName('total_amount', 'Total Amount', false, 'RupiahFormat'),
];
