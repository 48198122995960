import 'assets/styles';

import akun from './icons/akun.svg';
import dasboard from './icons/dasboard.svg';
import laporan from './icons/laporan.svg';
import lonceng from './icons/lonceng.svg';
import pengaturan from './icons/pengaturan.svg';
import plus from './icons/plus.svg';
import plusmini from './icons/plusMini.svg';
import side from './icons/side.svg';
import transaksi from './icons/transaksi.svg';
import titik3 from './icons/titik3.svg';
import line from './icons/line.svg';
import dot from './icons/dot.svg';
import seru from './icons/seru.svg';
import filter from './icons/filter.svg';
import exportIcon from './icons/export.svg';
import Ind from './icons/Group 24.svg';
import Eng from './icons/Group 27.svg';
import seruOutline from './icons/seruOutline.svg';
import ceklisModal from './icons/ceklisModal.svg';
import plus2 from './icons/plus-2.svg';
import plusMini2 from './icons/plusMini-2.svg';

import SignUp from './img/check-register-email.png';
import Forgot from './img/check-forget-email.png';
import QPlusWide from './img/qoinhub-black.svg';
import ExpiredLink from './img/reset-link-expired-email.png';
import UsedLink from './img/reset-link-used-email.png';

import iconNotif from './icons/iconNotif.svg';

import oval from './img/Oval.png';
import profile from './img/profile.jpg';
import SideMobile from './icons/sideMobile.svg';
import refreshIcon from './icons/refresh.svg';

import Info from './icons/info.svg';
import Info2 from './icons/info-bullet.png';
import Reject from './icons/reject.svg';
import Transaction from './icons/transaction.svg';
import Approve from './icons/approve.svg';
import Default from './icons/default.svg';
import Amplop from './icons/amplop.svg';
import Calender from './icons/calender.svg';

import oSuccess from './icons/success-circle.png';
import oWarning from './icons/warning-circle.png';
import oQuestion from './icons/question-circle.png';
import adjust from './icons/adjust.svg';
import sortBy from './icons/sortBy.svg';
import user from './icons/user.svg';
import add from './icons/add.svg';
import unduh from './icons/unduh.svg';
import pencil from './icons/pencil.svg';
import vectorImg from './icons/vectorImage.svg';
import colorPicker from './icons/colorPicker.svg';
import Search from './icons/search.svg';

export const icons = {
  akun,
  dasboard,
  laporan,
  lonceng,
  pengaturan,
  plus,
  plusmini,
  side,
  transaksi,
  titik3,
  line,
  dot,
  seru,
  exportIcon,
  filter,
  Ind,
  Eng,
  seruOutline,
  iconNotif,
  ceklisModal,
  plus2,
  plusMini2,
  Info,
  Info2,
  Reject,
  Transaction,
  Approve,
  Default,
  Amplop,
  SideMobile,
  refreshIcon,
  Calender,
  adjust,
  sortBy,
  user,
  add,
  unduh,
  pencil,
  vectorImg,
  colorPicker,
  Search,
};

export const img = {
  oval,
  profile,
};

export const ThanksIcon = {
  SignUp,
  Forgot,
  QPlusWide,
  ExpiredLink,
  UsedLink,
};

export const circle = { oQuestion, oSuccess, oWarning };
