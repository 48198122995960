// Action Types
export enum UtilsType {
  SET_CHECKBOX_POLICY = 'SET_CHECKBOX_POLICY',
  SET_ISSUBMENU = 'SET_ISSUBMENU',
  CHANGE_DRAWER_CONTENT = 'CHANGE_DRAWER_CONTENT',
}

// IState Example
export interface IUtilsState {
  isCheckbox: boolean;
  isSubmenu: boolean;
  id: number;
  content: string;
}

// IAction Example
interface GetCheckboxPolicyAction {
  type: UtilsType.SET_CHECKBOX_POLICY;
  payload?: any;
}

interface SetIsSubmenu {
  type: UtilsType.SET_ISSUBMENU;
  payload: RegisterData;
}

interface SetDrawerContent {
  type: UtilsType.CHANGE_DRAWER_CONTENT;
  payload: string;
}

export interface RegisterData {
  id: Number;
}

export type UtilsAction =
  | GetCheckboxPolicyAction
  | SetIsSubmenu
  | SetDrawerContent;
