import React, { useContext } from 'react';

import { FiArrowLeft } from 'react-icons/fi';

// import TableItemDetail from 'pages/laporan/transaction/table/TableItemDetail';
// import Card from 'components/cardComponents';

import useReport from 'hooks/useTransaction';
import { TransactionContext } from 'context/transactionContext';
import CustomerDetail from './customerDetail';
import OrderSummary from './orderSummary';
import PaymentDetail from './paymentDetail';
import StatusTimeline from './statusTimeLine';
import TitleFeature from 'components/cardComponents/titleFeature';

const DetailTransaction: React.FC = () => {
  let { TransactionList } = useReport();
  let { isLoading } = TransactionList;
  let {
    custommer,
    summary,
    payment,
    timeline,
    // items
  } = TransactionList.detail;
  let { setisDetail, isDetail } = useContext(TransactionContext);

  return (
    <>
      <TitleFeature
        text='Detail Transaction'
        icon={<FiArrowLeft style={{ fontSize: 25 }} />}
        className='mb-5 mt-[5rem] lg:mt-[1rem]'
        onClick={() => setisDetail(!isDetail)}
      />
      <div className='grid gap-4 grid-cols-1 lg:grid-cols-2 '>
        <OrderSummary {...{ summary, isLoading }} />
        <CustomerDetail {...{ custommer, isLoading }} />
        <PaymentDetail {...{ payment, isLoading }} />
        <StatusTimeline {...{ timeline }} />
        {/* {items && (
          <div className="col-span-full">
            <Card>
              <div className="py-5 px-8">
                <div className="text-lg font-bold mb-5 mt-2">Item Detail</div>
                <TableItemDetail />
              </div>
              <br />
            </Card>
          </div>
        )} */}
        <br />
      </div>
    </>
  );
};

export default DetailTransaction;
