import { AxiosError } from 'axios';
import provider, { IProvider } from 'provider';
import MethodProvider from 'provider/methods';
import { ThunkAction } from 'redux-thunk';
import { RootState } from 'stores';
import { IError } from 'stores/types/errorTypes';
import { SettingType, SettingActions } from 'stores/types/settingTypes';
import { errorGlobal } from './errorAction';
import endpoints from 'api/endpoints';
import { apiPut, postConfig } from 'api/apiFunction';
import { getBrowserId } from './utilsAction';

export const qoinViewPending = (): SettingActions => ({
  type: SettingType.SET_QOINVUEW_PENDING,
});

export const GetSecurityKeyPending = (): SettingActions => ({
  type: SettingType.GET_SECURITY_PENDING,
});

export const SubmitEditPending = (): SettingActions => ({
  type: SettingType.EDIT_SYSTEM_SETTING_PENDING,
});

export const SubmitEditSucces = (): SettingActions => ({
  type: SettingType.EDIT_SYSTEM_SETTING,
});

export const SetPayment = (data: any): SettingActions => ({
  type: SettingType.GET_PAYMENT,
  payload: {
    payment: data,
  },
});

export const GetMerchantData = (data: any): SettingActions => ({
  type: SettingType.GET_DATAMERCHANT,
  payload: {
    display_name: data?.display_name,
    language: data?.language,
    theme_pict: data?.thema_pict,
  },
});

export const GetSecurityKeySucces = (data: any): SettingActions => ({
  type: SettingType.GET_DATA_SECURITY,
  payload: {
    securityKey: data,
  },
});

export const SetSettingError = (error: AxiosError): SettingActions => ({
  type: SettingType.SETTING_ERROR,
  payload: {
    error,
  },
});

export const SetSettingErrorGet = (error: AxiosError): SettingActions => ({
  type: SettingType.SETTING_ERROR_GET,
  payload: {
    error,
  },
});

export const GetDataUrlPending = (): SettingActions => ({
  type: SettingType.GET_URL_PENDING,
});

export const GetDataUrlSucces = (data: any): SettingActions => ({
  type: SettingType.GET_DATA_URL,
  payload: {
    createOrderURL: data,
  },
});

export const GetMessagingPending = (): SettingActions => ({
  type: SettingType.GET_MESSAGING_PENDING,
});

export const GetMessagingSucces = (data: any): SettingActions => ({
  type: SettingType.GET_DATA_MESSAGING,
  payload: {
    messaging: data,
  },
});

export const GetIPWhitelistPending = (): SettingActions => ({
  type: SettingType.GET_IPSETTING_PENDING,
});

export const GetIPWhitelistSucces = (data: any): SettingActions => ({
  type: SettingType.GET_DATA_IPSETTING,
  payload: {
    iPWhitelist: data,
  },
});

export const GetSettingSettlementPending = (): SettingActions => ({
  type: SettingType.GET_SETTLEMENT_PENDING,
});

export const GetSettingSettlementSucces = (data: any): SettingActions => ({
  type: SettingType.GET_DATA_SETTLEMENT,
  payload: {
    settlement: data,
  },
});

export const GenerateSecretPending = (): SettingActions => ({
  type: SettingType.GENERETE_SECRET_PENDING,
});

export const GenerateSecret = (data: any): SettingActions => ({
  type: SettingType.GENERETE_SECRET,
  payload: {
    api_secret: data,
  },
});
export const GenerateKeyPending = (): SettingActions => ({
  type: SettingType.GENERETE_KEY_PENDING,
});

export const GenerateKey = (data: any): SettingActions => ({
  type: SettingType.GENERETE_KEY,
  payload: {
    api_key: data,
  },
});

export const GetApiSettingData = (
  url: any,
  callback?: (data: any) => void
): ThunkAction<void, RootState, null, SettingActions | IError> => {
  return async (dispatch, getState) => {
    url === 'security' && dispatch(GetSecurityKeyPending());
    url === 'url' && dispatch(GetDataUrlPending());
    url === 'messaging' && dispatch(GetMessagingPending());
    url === 'ip_whitelist' && dispatch(GetIPWhitelistPending());
    url === 'settlement' && dispatch(GetSettingSettlementPending());

    let sandbox = getState()?.auth?.userData['Env'];

    try {
      const objProvider: IProvider = {
        sandbox: sandbox,
        method: MethodProvider.GET,
        path: endpoints.apiSetting + '/' + url,
      };
      const response = await provider(objProvider);

      url === 'security' &&
        dispatch(GetSecurityKeySucces(response?.data?.data));
      url === 'url' && dispatch(GetDataUrlSucces(response?.data?.data));
      url === 'messaging' && dispatch(GetMessagingSucces(response?.data?.data));
      url === 'ip_whitelist' &&
        dispatch(GetIPWhitelistSucces(response?.data?.data));
      url === 'settlement' &&
        dispatch(GetSettingSettlementSucces(response?.data?.data));

      callback(response?.data);
    } catch (e) {
      dispatch(errorGlobal(e));
      callback(e);
      dispatch(SetSettingErrorGet(e));
    }
  };
};

export const GenereteActions = (
  url: any,
  callback?: (data: any) => void
): ThunkAction<void, RootState, null, SettingActions | IError> => {
  return async (dispatch, getState) => {
    url === 'key' && dispatch(GenerateKeyPending());
    url === 'secret' && dispatch(GenerateSecretPending());
    let sandbox = getState()?.auth?.userData['Env'];
    let broserId = getBrowserId();
    try {
      const objProvider: IProvider = {
        sandbox: sandbox,
        method: MethodProvider.POST,
        path: endpoints.apiGenerate + '/' + url,
        data: { broserId },
      };
      const response = await provider(objProvider);

      url === 'key'
        ? dispatch(GenerateKey(response?.data?.data))
        : dispatch(GenerateSecret(response?.data?.data));

      callback(response?.data);
    } catch (e) {
      dispatch(errorGlobal(e));
      callback(e);
      dispatch(SetSettingError(e));
    }
  };
};

export const SubmitEditActions = (
  url: string,
  dataBody?: any,
  callback?: (data: any) => void
): ThunkAction<void, RootState, null, SettingActions | IError> => {
  return async (dispatch, getState) => {
    dispatch(SubmitEditPending());
    let sandbox = getState()?.auth?.userData['Env'];

    try {
      const objProvider: IProvider = {
        sandbox: sandbox,
        method: MethodProvider.PUT,
        path: endpoints.editApiSetting + '/' + url,
        data: dataBody,
      };
      const response = await provider(objProvider);
      dispatch(SubmitEditSucces());
      callback(response?.data);
    } catch (e) {
      dispatch(errorGlobal(e));
      callback(e);
      dispatch(SetSettingError(e));
    }
  };
};

export const GetPaymentChannel = (
  callback?: (data: any) => void
): ThunkAction<void, RootState, null, SettingActions | IError> => {
  return async (dispatch, getState) => {
    dispatch(qoinViewPending());
    let sandbox = getState()?.auth?.userData['Env'];

    try {
      const objProvider: IProvider = {
        sandbox: sandbox,
        method: MethodProvider.GET,
        path: endpoints.getPaymentQoinView,
      };
      const response = await provider(objProvider);

      dispatch(SetPayment(response?.data?.data?.payments));
      callback(response?.data);
    } catch (e) {
      dispatch(errorGlobal(e));
      callback(e);
      dispatch(SetSettingErrorGet(e));
    }
  };
};

export const getDataMerchantTheme = (
  callback?: (data: any) => void
): ThunkAction<void, RootState, null, SettingActions | IError> => {
  return async (dispatch, getState) => {
    dispatch(qoinViewPending());
    let sandbox = getState()?.auth?.userData['Env'];

    try {
      const objProvider: IProvider = {
        sandbox: sandbox,
        method: MethodProvider.GET,
        path: endpoints.getDataMerchantTheme,
      };
      const response = await provider(objProvider);
      dispatch(GetMerchantData(response?.data?.data));
      callback(response?.data);
    } catch (e) {
      dispatch(errorGlobal(e));
      callback(e);
      dispatch(SetSettingErrorGet(e));
    }
  };
};

export const updateDataMerchantTheme = (
  data: any,
  callback?: (data: any) => void
): ThunkAction<void, RootState, null, SettingActions | IError> => {
  return async (dispatch, getState) => {
    let envProd = getState()?.auth?.userData['Env'];

    try {
      const response = await apiPut(
        endpoints.updateDataMerchantTheme,
        data,
        envProd,
        postConfig.standard
      );
      callback(response?.data);
    } catch (e) {
      dispatch(errorGlobal(e));
      callback(e);
      dispatch(SetSettingErrorGet(e));
    }
  };
};

export const updatePaymentChannel = (
  data: any,
  callback?: (data: any) => void
): ThunkAction<void, RootState, null, SettingActions | IError> => {
  return async (dispatch, getState) => {
    let sandbox = getState()?.auth?.userData['Env'];

    let dataBody = {
      chanels: data,
    };

    try {
      const objProvider: IProvider = {
        sandbox: sandbox,
        method: MethodProvider.PUT,
        path: endpoints.updatePaymentQoinView,
        data: dataBody,
        Session: '2',
      };
      const response = await provider(objProvider);
      callback(response?.data);
    } catch (e) {
      dispatch(errorGlobal(e));
      callback(e);
      dispatch(SetSettingError(e));
    }
  };
};
