import React from 'react';
import { GridLine, GridPreview } from './Components';
import { type PaymentMethodData } from 'stores/types/onboardingTypes';
import { Button, Collapse, Grid } from '@mui/material';
import { BasicButton } from 'components/Button';
import { ArrowForward, ExpandLess, ExpandMore } from '@mui/icons-material';
import { type new_format } from './interface';
import { optionH2H } from './payment-list';

interface ICollapsePreview {
  title: string;
  open?: { val: boolean; set: (val: boolean) => void };
  normal?: { list?: { title: string; value: string; image?: boolean }[] };
  mode?: 'normal' | 'list' | 'mix-image' | 'list-image';
  listed?: {
    val: PaymentMethodData[];
    compare?: string[];
    options?: new_format[];
  };
  show?: boolean;
  // popup?: { set: (val: boolean) => void };
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  mandatory?: boolean;
}

const CollapsePreview: React.FC<ICollapsePreview> = (props) => {
  const { normal, title, open, mode = 'normal', listed, mandatory } = props;
  // eslint-disable-next-line
  const h2hLabel = (idx: number, isQR?: boolean) => {
    let result: string = '';
    if (isQR) result = listed?.options?.find((x) => x.id === 'QR')?.type;
    else result = listed?.options?.find((x) => x.id === String(idx))?.type;
    return optionH2H.find((x) => x.value === result)?.label;
  };

  const chosenTitle = (item: PaymentMethodData) => {
    return item?.Channels?.find((x) => listed?.compare?.includes(String(x.id)));
  };

  function setMode() {
    switch (mode) {
      case 'normal':
        return (
          <>
            {normal.list.map((item, idx) => (
              <React.Fragment key={idx}>
                <GridLine
                  fieldTitle={item.title}
                  value={item.value}
                  disabled
                  white
                  mandatory={mandatory}
                />
              </React.Fragment>
            ))}
          </>
        );
      case 'list':
        return (
          <>
            {listed.val.map((item, itemIdx) =>
              item.name === 'QRIS' ? (
                <GridLine
                  key={item.name}
                  fieldTitle={item.name}
                  disabled
                  white
                  value={listed?.compare?.includes('QR') ? item.name : ''}
                  mandatory={mandatory}
                />
              ) : (
                <GridLine
                  key={itemIdx}
                  fieldTitle={item.name}
                  disabled
                  white
                  mandatory={mandatory}
                  value={item.Channels.filter((ini) =>
                    listed.compare?.includes(String(ini.id))
                  )
                    .map((itu) => itu.name)
                    .join(', ')}
                />
              )
            )}
            {props?.show ? (
              <Grid container>
                <Grid item xs={12} md={8} className="flex justify-end">
                  <BasicButton
                    color="primary"
                    variant="text"
                    endIcon={<ArrowForward />}
                    onClick={props?.onClick}
                  >
                    See Detail QRIS
                  </BasicButton>
                </Grid>
              </Grid>
            ) : null}
          </>
        );
      case 'mix-image':
        return (
          <>
            {normal.list.map((item, idx) => (
              <React.Fragment key={idx}>
                {item.image ? (
                  <GridPreview
                    fieldTitle={item?.title}
                    thePhoto={item?.value}
                    titleRange={6}
                    contentRange={6}
                    mandatory={mandatory}
                  />
                ) : (
                  <GridLine
                    fieldTitle={item?.title}
                    value={item?.value}
                    disabled
                    white
                    mandatory={mandatory}
                  />
                )}
              </React.Fragment>
            ))}
          </>
        );
      case 'list-image':
        return (
          <>
            {listed.val.map((item, itemIdx) =>
              item.name === 'QRIS' ? (
                listed?.compare?.includes('QR') ? (
                  <div className="flex flex-col gap-y-3" key={itemIdx}>
                    <p className="font-semibold">{item.name}</p>
                    <div className="grid grid-cols-2 lg:grid-cols-4 2xl:grid-cols-6 gap-4 py-1">
                      <PayChannelImage
                        name={item?.name}
                        url={item?.pict_url}
                        // typeLabel={h2hLabel(item?.id, true)}
                      />
                    </div>
                  </div>
                ) : null
              ) : (
                <div key={itemIdx} className="flex flex-col gap-y-3">
                  {chosenTitle(item) && (
                    <>
                      <p className="font-semibold">{item.name}</p>
                      <div className="grid grid-cols-2 lg:grid-cols-4 2xl:grid-cols-6 gap-4 py-1">
                        {item?.Channels?.filter((its) =>
                          listed.compare?.includes(String(its.id))
                        ).map((ch) => (
                          <PayChannelImage
                            key={ch.id}
                            name={ch.name}
                            url={ch.pict_url}
                            // typeLabel={h2hLabel(ch.id, false)}
                          />
                        ))}
                      </div>
                    </>
                  )}
                </div>
              )
            )}
          </>
        );
      default:
        return;
    }
  }

  return (
    <>
      <div className="flex justify-between space-y-1">
        <div className="font-bold text-xl">{title}</div>
        <Button onClick={() => open?.set(!open?.val)}>
          {open?.val ? (
            <ExpandLess htmlColor="#000" />
          ) : (
            <ExpandMore htmlColor="#000" />
          )}
        </Button>
      </div>
      <Collapse in={open?.val}>
        <div className="space-y-1">{setMode()}</div>
      </Collapse>
    </>
  );
};
export default CollapsePreview;

type pcImgProps = { url: string; name: string; typeLabel?: string };
const PayChannelImage = ({ url, name }: pcImgProps) => {
  return (
    <div className="border w-full rounded-md mx-auto space-y-2 py-3">
      <img src={url} alt={name} className="h-12 w-12 object-contain m-auto" />
      <p className="font-medium text-center">{name}</p>
      {/* <div className="w-fit mx-auto rounded-md py-1 px-2 text-center border border-[--text-primary] text-[--text-primary] bg-[#E9F7F2]">
        {typeLabel}
      </div> */}
    </div>
  );
};
