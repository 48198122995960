import { SettingContext } from 'context/settingContext';
import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { icons } from 'assets';
import { ChromePicker } from 'react-color';
import _ from 'lodash';
import Upload from './upload';
type Props = {};

const ThemeNlogo = (props: Props) => {
  let {
    merchantName,
    setMerchantName,
    bgImage,
    setbgImage,
    bgImageFile,
    setbgImageFile,
    logoImage,
    setLogoImage,
    logoFile,
    setLogoFile,
    backGroundColor,
    setbackGroundColor,
    buttonColor,
    setbuttonColor,
  } = React.useContext(SettingContext);

  let colorList = [
    '#E6AD28',
    '#EE4E2B',
    '#3967E0',
    '#581F7B',
    '#177C59',
    '#000000',
  ];

  const availableBgColors = _.includes(colorList, backGroundColor);
  const availableButtonColors = _.includes(colorList, buttonColor);

  const handleChangeBgColor = (e: string) => {
    setbgImage('');
    setbgImageFile();
    setbackGroundColor(e);
  };

  const handleChangeButtonColor = (e: string) => {
    setbuttonColor(e);
  };

  return (
    <div className="mt-5 lg:mt-0 pr-0 lg:pr-5">
      <div className="text-[21px] font-extrabold">Display Setting</div>
      <div className="my-3">
        <div className="font-bold mb-3">Display Name</div>
        <input
          className="px-3 py-2 rounded-md
        w-full border-[1px] border-[#AFAFAF]"
          placeholder="Type Display name"
          value={merchantName}
          onChange={(e) => setMerchantName(e.target.value)}
        />
      </div>
      <div className="my-3">
        <div className="font-bold mb-3">Background Color</div>
        <div className="flex gap-2">
          {colorList.map((e, i) => {
            return (
              <div
                className={` ${
                  backGroundColor === e && 'border-[1px] border-[#21AF7D] '
                }padding-2 p-1 rounded-md`}
                onClick={() => handleChangeBgColor(e)}
              >
                <div
                  className={`p-2 cursor-pointer w-[34px] h-[34px] rounded-lg `}
                  style={{ backgroundColor: e }}
                ></div>
              </div>
            );
          })}

          <div
            className={` ${
              (backGroundColor === 'picker' || !availableBgColors) &&
              backGroundColor !== 'img' &&
              'border-[1px] border-[#21AF7D] '
            }padding-2 p-1 rounded-md`}
            onClick={() => handleChangeBgColor('picker')}
          >
            <div
              className={`flex item-center p-2 cursor-pointer w-[34px] h-[34px] rounded-lg`}
              style={{ backgroundColor: '#DEDEDE' }}
            >
              <img src={icons?.colorPicker} alt="s" />
            </div>
          </div>
          <div
            className={` ${
              backGroundColor === 'img' && 'border-[1px] border-[#21AF7D] '
            }padding-2 p-1 rounded-md`}
            onClick={() => handleChangeBgColor('img')}
          >
            <div
              className={`flex item-center p-2 cursor-pointer w-[34px] h-[34px] rounded-lg`}
              style={{ backgroundColor: '#21AF7D' }}
            >
              <img src={icons?.vectorImg} alt="s" />
            </div>
          </div>
        </div>
      </div>

      {!availableBgColors && backGroundColor !== 'img' && (
        <div className="flex justify-center">
          <ChromePicker
            color={backGroundColor}
            onChange={(e) => handleChangeBgColor(e?.hex)}
            disableAlpha
            className="w-[10px] bg-red-900 cursor-pointer"
          />
        </div>
      )}

      {backGroundColor === 'img' && (
        <Upload
          files={bgImageFile}
          photo={bgImage}
          onFileUpload={(e) => setbgImageFile(e)}
          onPhotoUpload={setbgImage}
        />
      )}

      <div className="my-3">
        <div className="font-bold mb-3">Button Color</div>
        <div className="flex gap-2">
          {colorList.map((e, i) => {
            return (
              <div
                className={` ${
                  buttonColor === e && 'border-[1px] border-[#21AF7D] '
                }padding-2 p-1 rounded-md`}
                onClick={() => handleChangeButtonColor(e)}
              >
                <div
                  className={`p-2 cursor-pointer w-[34px] h-[34px] rounded-lg `}
                  style={{ backgroundColor: e }}
                ></div>
              </div>
            );
          })}

          <div
            className={` ${
              (buttonColor === 'picker' || !availableButtonColors) &&
              buttonColor !== 'img' &&
              'border-[1px] border-[#21AF7D] '
            }padding-2 p-1 rounded-md`}
            onClick={() => handleChangeButtonColor('picker')}
          >
            <div
              className={`flex item-center p-2 cursor-pointer w-[34px] h-[34px] rounded-lg`}
              style={{ backgroundColor: '#DEDEDE' }}
            >
              <img src={icons?.colorPicker} alt="s" />
            </div>
          </div>
        </div>
      </div>

      {!availableButtonColors && buttonColor !== 'img' && (
        <div className="flex justify-center">
          <ChromePicker
            color={buttonColor}
            onChange={(e) => handleChangeButtonColor(e?.hex)}
            disableAlpha
            className="w-[10px] bg-red-900 cursor-pointer"
          />
        </div>
      )}

      <div className="font-bold mt-7">Logo Display</div>

      <div className="bg-[#FFF9EA] p-2 pl-5 mt-3 border-[#FDBE2C] border-[1px] rounded-md">
        <div className="flex gap-2">
          <img src={icons?.seru} alt="" className="w-5" />
          <div className="font-bold">Logo Requirements</div>
        </div>
        <ul className="w-full list-disc ml-3 lg:ml-7 mt-2">
          <li>Logo Logo will be resized to 55 × 55 pixels</li>
          <li>Use PNG image if your logo is transparent</li>
          <li>Max file size is 1 MB</li>
        </ul>
      </div>
      <div className="font-bold mt-7">Unggah Logo</div>

      <Upload
        files={logoFile}
        photo={logoImage}
        onFileUpload={(e) => setLogoFile(e)}
        onPhotoUpload={setLogoImage}
      />
      <br />
      <CreatableSelect
        className="w-full"
        placeholder="Select Language"
        isDisabled={true}
        isClearable
        options={[
          {
            value: 'id',
            label: 'Indonesia',
          },
          {
            value: 'en',
            label: 'English',
          },
        ]}
      />
    </div>
  );
};

export default ThemeNlogo;
