import React from 'react';
import {
  Paper,
  Popover,
  PopoverProps,
  PopoverVirtualElement,
} from '@mui/material';

interface BasicPopoverProps extends PopoverProps {
  open: boolean;
  anchorEl:
    | Element
    | (() => Element)
    | PopoverVirtualElement
    | (() => PopoverVirtualElement);
  onClose?: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
  children: React.ReactNode;
  width?: number;
  position?: 'right' | 'center' | 'left';
}

const Component: React.FC<BasicPopoverProps> = (props) => {
  const { open, anchorEl, onClose, children, width, position = 'left' } = props;
  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: position, vertical: 'bottom' }}
      transformOrigin={{ horizontal: position, vertical: 'top' }}
      className="mt-2"
      {...props}
    >
      <Paper
        className={`py-1 flex flex-col`}
        sx={{ width: width ? width : 'auto' }}
      >
        {children}
      </Paper>
    </Popover>
  );
};

export default Component;
