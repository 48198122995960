import React from 'react';
import { icons } from 'assets';
import { btnTheme2, ModalSx } from 'components/muiTheme';
import LoadingButton from '@mui/lab/LoadingButton';
import { ThemeProvider } from '@mui/material';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { RefundContext } from 'context/refundContext';
import useRefund from 'hooks/useRefund';

const ModalCard = () => {
  let {
    handleOpenDetail,
    isFailedReq,
    handleCloseModal,
    openModal,
    transactionNo,
    setTransactionNo,
  } = React.useContext(RefundContext);

  const { refundList } = useRefund();

  let { isLoadingDetail } = refundList;

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModal}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <Box sx={ModalSx}>
          <div className="flex justify-center text-start flex-col">
            {isFailedReq ? (
              <div className="text-center">
                <div className="flex justify-center align-center">
                  <img className="" src={icons.seruOutline} alt="as" />
                </div>
                <div className="text-md mt-3 mb-2 font-semibold">
                  Data tidak ditemukan
                </div>
                <div className="text-sm ">
                  Silahkan masukkan data yang sesuai
                </div>
              </div>
            ) : (
              <div>
                <div>
                  <div className="font-semibold text-lg mb-3 -mt-3">
                    Request Refund
                  </div>
                  <hr />
                  <div className="text-sm mt-3 mb-3 font-semibold">
                    Transaction number :
                  </div>
                  <form className="-mb-2">
                    <input
                      className="px-3 w-full py-2 border-[1px] rounded-md outline-none border-[#94A3B8] "
                      type="text"
                      placeholder="Input transaction number"
                      name="search"
                      value={transactionNo}
                      onChange={(e) => setTransactionNo(e.target.value)}
                    />
                  </form>
                </div>
                <div className="flex mt-8">
                  <ThemeProvider theme={btnTheme2}>
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      loading={isLoadingDetail}
                      sx={{
                        width: '400px',
                        fontSize: '12px',
                        padding: 1.4,
                      }}
                      onClick={handleOpenDetail}
                    >
                      Check
                    </LoadingButton>
                  </ThemeProvider>
                </div>
              </div>
            )}
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ModalCard;
