import * as XLSX from 'xlsx';

export const downloadExcel = (data, name) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  XLSX.writeFile(workbook, `${name}.xlsx`);
};

export const downloadCSV = (data, name) => {
  // Mengonversi data ke worksheet
  const worksheet = XLSX.utils.json_to_sheet(data);

  // Mengonversi worksheet ke format CSV
  const csv = XLSX.utils.sheet_to_csv(worksheet);

  // Membuat Blob untuk file CSV
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('download', `${name}.csv`); // Mengatur nama file
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
