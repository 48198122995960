import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
// import { NumericFormat } from 'react-number-format';
import ChartDataLabels from 'chartjs-plugin-datalabels';
// import useDashboard from 'hooks/useDashboard';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const options = {
  responsive: true,
  cutout: '55%',
  borderRadius: 0,
  offset: 5,

  plugins: {
    legend: {
      position: 'left',
      labels: {
        usePointStyle: true,
        padding: 25,
        textAlign: 'left',
      },

      onHover: (event) => {
        event.native.target.style.cursor = 'pointer';
      },
    },
    datalabels: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (tooltipItem) {
          const dataset = tooltipItem.dataset; // Access the dataset

          const countValue = dataset.data[tooltipItem.dataIndex]; // Get the count value
          const rupiahValue = dataset.rupiah[tooltipItem.dataIndex]; // Get the corresponding Rupiah value

          // Format the values if necessary
          const formattedCount = countValue.toLocaleString(); // For count
          const formattedRupiah = new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
          }).format(rupiahValue); // For Rupiah

          return `${formattedCount} - ${formattedRupiah}`; // Combine them in the tooltip
        },
      },
    },
  },
  onHover: function (e, chartElement) {
    e.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
  },
};

const plugin = {
  beforeInit: function (chart) {
    const originalFit = chart.legend.fit;

    chart.legend.fit = function fit() {
      originalFit.bind(chart.legend)();
      this.width += 50;
    };
  },
};

const DoughnutD = ({ dataChart }) => {
  let dataChartNoData = {
    labels: [],
    datasets: [
      {
        data: [],
        rupiah: [],
        backgroundColor: [],
      },
    ],
  };

  const isValidDataFormat = (data) => {
    return (
      data &&
      Array.isArray(data.labels) &&
      Array.isArray(data.datasets) &&
      data.datasets.length === 1 &&
      Array.isArray(data.datasets[0].data) &&
      Array.isArray(data.datasets[0].rupiah) &&
      Array.isArray(data.datasets[0].backgroundColor)
    );
  };
  const checkDataFormat = (dataformapi) => {
    if (!isValidDataFormat(dataformapi)) {
      return dataChartNoData;
    }
    return dataformapi;
  };

  return (
    <div className='w-[100%] bg-red-10 flex justify-evenly  md:h-[520px] -my-[95px] pt-5 pb-8 -p-1'>
      {/* {chartData?.length > 0 && (
        <div className='hidden md:block absolute ml-[145px] md:mt-[170px] mt-[120px]'>
          <div className='hidden flex flex-col w-[100px] justify-center items-center'>
            <div className='text-[#AEAEAE]'>Total</div>
            {rp ? (
              <NumericFormat
                value={total}
                className='text-[0.8rem] font-bold'
                displayType={'text'}
                thousandSeparator={'.'}
                decimalSeparator={','}
                prefix={'Rp.'}
              />
            ) : (
              <div className='text-sm font-bold'>{total}</div>
            )}
          </div>
        </div>
      )} */}
      <Doughnut
        options={options}
        data={checkDataFormat(dataChart)}
        plugins={[plugin]}
      />
    </div>
  );
};

const CardDoughnut = ({ text, data }) => {
  return (
    <div className=''>
      <div className='bg-[#FFFFFF] shadow-sm w-full h-full rounded-md '>
        <div className='p-5'>
          <div className='text-xl font-bold'>{text}</div>
          <DoughnutD dataChart={data} />
        </div>
      </div>
    </div>
  );
};

export default CardDoughnut;
