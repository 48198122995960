import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'stores';
import {
  GetWdDetailAction,
  GetWdListAction,
  GetWdSettledActions,
  SetWdReqAction,
} from 'stores/actions/withdrawalAction';
import { IApiResponse } from 'stores/types/generalTypes';
import { permissionFunction } from 'utils/permission';
import permission from 'constants/permissionRole';

const useWithdrawal = () => {
  const dispatch = useDispatch();
  const transactionState = useSelector((state: RootState) => state.withdrawal);
  const authState = useSelector((state: RootState) => state.auth.userData);
  const Env = authState['Env'] ? 'PRODUCTION' : 'SANDBOX';

  const handleWdList = useCallback(
    (params?: any, callback?: (data: IApiResponse) => void) => {
      permissionFunction(permission[Env].LIST_WITHDARWAL) &&
        dispatch(GetWdListAction(params, callback));
    },
    [Env, dispatch]
  );

  const handleWdDetail = useCallback(
    (params?: any, id?: string, callback?: (data: IApiResponse) => void) => {
      permissionFunction(permission[Env].GET_WITHDRAWAL) &&
        dispatch(GetWdDetailAction(params, id, callback));
    },
    [Env, dispatch]
  );

  const handleWdSettled = useCallback(
    (params?: any, callback?: (data: IApiResponse) => void) => {
      permissionFunction(permission[Env].SETTLED_WITHDRAWAL) &&
        dispatch(GetWdSettledActions(params, callback));
    },
    [Env, dispatch]
  );

  const handleWdReq = useCallback(
    (params?: any, callback?: (data: IApiResponse) => void) => {
      permissionFunction(permission[Env].REQ_WITHDRAWAL) &&
        dispatch(SetWdReqAction(params, callback));
    },
    [Env, dispatch]
  );

  return {
    Env,
    withdrawal: transactionState.withdrawal,
    getWdList: handleWdList,
    getWdDetail: handleWdDetail,
    getWdSettled: handleWdSettled,
    setWdPReq: handleWdReq,
  };
};

export default useWithdrawal;
