import React from 'react';
import { Close, Warning } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Prop } from '.';

const Component: React.FC<Prop> = ({ diffDays, onClick, dayLeft, show }) => {
  const text = () => {
    if (diffDays <= 0) {
      return (
        <p>
          Your password has been expired. Please change your password now or{' '}
          <b className="text-red-600 cursor-pointer" onClick={onClick}>
            click here{' '}
          </b>
          to change password
        </p>
      );
    } else {
      return (
        <p>
          Your password will expires in <b>{dayLeft?.val} days.</b> Please
          change your password soon or
          <b className="text-red-600 cursor-pointer" onClick={onClick}>
            {' '}
            click here{' '}
          </b>
          to change password
        </p>
      );
    }
  };

  return (
    <>
      <div className="border border-[#F04545] bg-[#FFEEEE] flex p-2 justify-between my-4 rounded-md">
        <div className="flex gap-2 my-auto px-2">
          <Warning color="error" sx={{ my: 'auto' }} />
          <span className="my-auto">{text()}</span>{' '}
        </div>
        <IconButton onClick={() => show?.set(false)}>
          <Close className="justify-end" color="disabled" />
        </IconButton>
      </div>
    </>
  );
};
export default Component;
