import React from 'react';
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import { InputPass } from '../interface';
import HelperText from 'components/helperText';
import ClickTooltip from 'components/tooltip/ClickTooltip';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export const PasswordAdornment = ({ onClick, onMouseDown, visibility }) => (
  <InputAdornment position="end">
    <IconButton
      aria-label="toggle password visibility"
      onClick={onClick}
      onMouseDown={onMouseDown}
      edge="end"
    >
      {visibility ? <Visibility /> : <VisibilityOff />}
    </IconButton>
  </InputAdornment>
);

const Component: React.FC<InputPass> = (props) => {
  const {
    visibility,
    showClick,
    value,
    onBlur,
    onChange,
    error,
    message,
    id = 'password',
    title,
    placeholder,
    disabled = false,
    hint = true,
  } = props;
  const standardId = id === 'password';
  const newPassId = id === 'NewPassword';

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <div className="space-y-2">
      {standardId ? (
        <div className="flex">
          <div className="font-medium">Kata Sandi</div>
          {hint && <ClickTooltip mode="wide" />}
        </div>
      ) : newPassId ? (
        <div className="flex relative">
          <div className="font-medium">New Password</div>
          <ClickTooltip mode="drawer" />
        </div>
      ) : (
        <div className="font-medium">{title}</div>
      )}

      <OutlinedInput
        id={standardId ? 'password' : id}
        name={standardId ? 'password' : id}
        type={visibility ? 'text' : 'password'}
        autoComplete="off"
        placeholder={standardId ? 'Masukkan Kata Sandi' : placeholder}
        fullWidth
        required
        disabled={disabled}
        endAdornment={
          <PasswordAdornment
            onClick={showClick}
            onMouseDown={handleMouseDownPassword}
            visibility={visibility}
          />
        }
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        error={value !== '' && error !== undefined}
      />
      {value !== '' && error && <HelperText error={message} />}
    </div>
  );
};

export default Component;
