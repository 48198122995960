import { FormGroup, ThemeProvider } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { PaginationTheme } from 'components/muiTheme';

const SelectCheckbox = ({
  data,
  handleChange,
  value,
  from,
}: {
  data: any;
  handleChange: any;
  value: any;
  from?: any;
}) => {
  let failed = from === 'trans status' ? 'Failed' : 'Failed Paid';
  let succeed = from === 'trans status' ? 'Succees' : 'Paid';

  return (
    <FormGroup className='px-2 w-full min-w-[200px]  p-2'>
      <div className='text-[#94A3B8] text-md mb-3'>Show Status:</div>
      <ThemeProvider theme={PaginationTheme}>
        {data.map((e: any, i: number) => {
          const code = e['code'].toLowerCase();

          const labelMap = {
            withdrawable: 'To Be Settle',
            withdrawn: 'Transferred Out',
            refunded: 'Refund',
            refund_process: 'Refund Process',
            process: 'To Be paid',
            open: 'On Process',
            succeed: succeed,
            success: 'Success',
            '1': 'Success',
            failed: failed,
            '2': 'Failed',
            expired: 'Expired',
            INSUFFICIENT_PAYMENT: 'Insufficient Payment',
            OVER_PAYMENT: 'Over Payment',
          };

          let label: any;
          if (Object.keys(labelMap).some((key) => code.includes(key))) {
            label = Object.entries(labelMap).find(([key, value]) =>
              code.includes(key)
            )?.[1];
          }

          return (
            <div key={i}>
              <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                    value={data[i]}
                    checked={value[i]}
                    onChange={() => {
                      handleChange(i, data[i]);
                    }}
                  />
                }
                label={label}
              />
            </div>
          );
        })}
      </ThemeProvider>
    </FormGroup>
  );
};

export default SelectCheckbox;
