import React from 'react';

interface IContentLoadingTable {
  dataList: any;
  isLoading: boolean;
}

const ContentLoadingTable: React.FC<IContentLoadingTable> = ({
  dataList,
  isLoading,
}) => {
  return (
    <>
      {(!dataList || dataList?.length <= 0) && (
        <div className='w-full h-20 items-center  bg-[#F8F8F8] text-[#979797] flex justify-center'>
          {isLoading === true ? 'Loading...' : 'No data'}
        </div>
      )}
    </>
  );
};

export default ContentLoadingTable;
