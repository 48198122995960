import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'stores/index';
import { PropsCommon } from './interface';
import { setMainRoute } from 'stores/actions/appState';

import { ListItemButtonStyle } from 'components/muiTheme';
import React from 'react';

const SidebarItem = ({ item, dot, isOpen, setisOpen }: PropsCommon) => {
  const { appState } = useSelector((state: RootState) => state.appState);
  const userData = useSelector((state: RootState) => state.auth.userData);

  let dispacth = useDispatch();

  let env = userData['Env'];

  const [isSmallScreen, setIsSmallScreen] = React.useState(false);

  React.useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 576);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return item.sidebarProps && item.path ? (
    <ListItem
      component={Link}
      to={item.path}
      disablePadding
      sx={{ display: 'block' }}
    >
      <ListItemButton
        sx={
          !dot
            ? ListItemButtonStyle(appState, item, isOpen, env)
            : ListItemButtonStyle(appState, item, isOpen, env, true)
        }
        onClick={() => {
          !dot && dispacth(setMainRoute(item.state));
          isSmallScreen && setisOpen(!isOpen);
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: isOpen ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          {dot && (
            <div
              className={`rounded-full w-[8px] my-[3px] borderList h-[8px] `}
            />
          )}
          {item.sidebarProps.icon && item.sidebarProps.icon}
        </ListItemIcon>
        <ListItemText
          primary={
            <div
              className={`text-[17px]  ${
                !env && appState === item.state && 'font-bold'
              }`}
            >
              {item.sidebarProps.displayText}
            </div>
          }
          sx={{ opacity: isOpen ? 1 : 0 }}
        />
      </ListItemButton>
    </ListItem>
  ) : null;
};

export default SidebarItem;
