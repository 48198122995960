import React, { useState } from 'react';
import { useFormik } from 'formik';
import { EditPasswordSchema } from 'components/configs/validationSchema';
import { userChangePassword, userLogout } from 'stores/actions/authActions';
import { FormatPassword } from 'components/Input';
import HelperText from 'components/helperText';
import { useNavigate } from 'react-router';
import { DrawerBack } from '../Component';
import { PopupDialog } from 'components/dialog';
import { permissionFunction } from 'utils/permission';
import permission from 'constants/permissionRole';
import useActivityBrowser from 'hooks/useActivityBrowser';
import { BasicButton } from 'components/Button';
import { isFullVersion } from 'utils/exception';
import { useDispatch } from 'react-redux';

const Component: React.FC = () => {
  const [currPass, setCurrPass] = useState(false);
  const [newPass, setNewPass] = useState(false);
  const [confirmPass, setConfirmPass] = useState(false);
  const [openPopup, setOpenPopUp] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const placeholderText = 'Input password';

  const audit = useActivityBrowser();
  const initial = {
    OldPassword: '',
    NewPassword: '',
    PasswordConfirmation: '',
  };
  const formik = useFormik({
    initialValues: initial,
    validationSchema: EditPasswordSchema,
    onSubmit: (v) => {
      submitForm(v);
    },
  });
  const submitForm = (v) => {};

  const handleSave = async () => {
    if (
      (formik.values.OldPassword &&
        formik.values.NewPassword &&
        formik.values.PasswordConfirmation) === ''
    ) {
      setErrMessage('All fields required');
    } else {
      dispatch(
        userChangePassword(
          { ...formik.values, ...audit },
          setOpenPopUp,
          setErrMessage
        )
      );
    }
  };

  const handleClose = () => {
    setOpenPopUp(false);
    userLogout();
    navigate(`${isFullVersion ? '/full/' : '/'}login`);
  };

  return (
    <div className="flex flex-col w-full">
      <div className="py-5 px-6">
        <DrawerBack title="Change Password" />
      </div>
      <form onSubmit={formik.handleSubmit} className="px-6 space-y-4">
        <FormatPassword
          value={formik.values?.OldPassword}
          visibility={currPass}
          error={formik.errors?.OldPassword}
          id="OldPassword"
          message={formik.errors.OldPassword}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          showClick={() => setCurrPass(!currPass)}
          placeholder={placeholderText}
          title="Current Password"
          disabled={!permissionFunction(permission.EDIT_PASSWORD)}
        />
        {errMessage !== '' && <HelperText error={errMessage} />}

        <FormatPassword
          value={formik.values?.NewPassword}
          visibility={newPass}
          error={formik.errors?.NewPassword}
          id="NewPassword"
          message={formik.errors.NewPassword}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder={placeholderText}
          showClick={() => setNewPass(!newPass)}
          disabled={!permissionFunction(permission.EDIT_PASSWORD)}
          title="New Password"
        />
        <FormatPassword
          value={formik.values?.PasswordConfirmation}
          visibility={confirmPass}
          error={formik.errors?.PasswordConfirmation}
          id="PasswordConfirmation"
          message={formik.errors?.PasswordConfirmation}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder={placeholderText}
          showClick={() => setConfirmPass(!confirmPass)}
          title="Confirm Password"
          disabled={!permissionFunction(permission.EDIT_PASSWORD)}
        />
      </form>

      <div className="px-6 absolute bottom-6 w-full">
        <BasicButton
          onClick={handleSave}
          disabled={!permissionFunction(permission.EDIT_PASSWORD)}
          fullWidth
        >
          Save Changes
        </BasicButton>
      </div>
      <PopupDialog
        open={openPopup}
        onClick={handleClose}
        text="Ubah Kata Sandi Berhasil!"
        subtext="Silahkan masuk kembali pada halaman login dengan kata sandi baru."
      />
    </div>
  );
};

export default Component;
