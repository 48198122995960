import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';

import HeaderTable from './HeaderTable';
import ModalFilterTable from './ModalFilterTable';
import LoadingTable from './LoadingTable';
import DataTableView from './DataTableView';

interface ITable {
  tableNameList: any;
  dataList: any;
  countPage: any;
  page: any;
  headerList?: any;
  FilterComponent: any;
  handleAction?: any;
  handleResetFilter?: any;
  handleSubmitFilter?: any;
  isLoading: any;
  disableNo?: boolean;
  params?: any;
}

const Component: React.FC<ITable> = ({
  tableNameList,
  disableNo,
  dataList,
  countPage,
  page,
  headerList,
  FilterComponent,
  handleAction,
  handleResetFilter,
  handleSubmitFilter,
  isLoading,
  params,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [idPoper, setidPoper] = React.useState(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>, id: number) => {
    setAnchorEl(event.currentTarget);
    setidPoper(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TableContainer className='TableContainer'>
        <Table sx={{ width: '100%' }}>
          <ModalFilterTable
            handleClose={handleClose}
            anchorEl={anchorEl}
            handleReset={handleResetFilter}
            handleSubmit={handleSubmitFilter}
          >
            <FilterComponent id={idPoper} />
          </ModalFilterTable>
          <TableHead className='cursor-default'>
            <TableRow>
              <HeaderTable
                {...{ handleMenu, headerList, disableNo }}
                dataList={tableNameList}
                listParams={params}
              />
            </TableRow>
          </TableHead>
          <TableBody className='[&>*:nth-child(odd)]:bg-white [&>*:nth-child(even)]:bg-[#F8F8F8] border-none'>
            <DataTableView
              isLoading={isLoading}
              {...{
                dataList,
                handleAction,
                tableNameList,
                headerList,
                page,
                countPage,
              }}
            />
          </TableBody>
        </Table>
      </TableContainer>

      <LoadingTable
        dataList={dataList}
        isLoading={false}
      />
    </>
  );
};

export default Component;
