import React from 'react';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';

export const SeeDetailButton: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  return (
    <div
      className="w-[40px] h-[auto] cursor-pointer p-2 bg-[#18AD65] rounded-md hover:bg-[#10814b]"
      onMouseOver={handleClick}
      onMouseLeave={() => setAnchorEl(null)}
    >
      <Popper id={id} open={open} anchorEl={anchorEl}>
        <Box className="text-xs p-2 bg-[#F8F8F8] font-bold border-2 ">
          Detail
        </Box>
      </Popper>
      <RemoveRedEyeOutlinedIcon style={{ fill: 'white', margin: 0 }} />
    </div>
  );
};

export default SeeDetailButton;
