import { Checkbox, ThemeProvider } from '@mui/material';
import { PaginationTheme } from 'components/muiTheme';

const InputForm = ({
  placeholder,
  defaultValue,
  name,
  nameInput,
  onUbah,
  isInvalid,
  isenable,
  isData,
  isEdit,
}) => {
  return (
    <div
      className={`block lg:flex text-[#231F20] font-medium ${
        isenable?.is_enable_email && isEdit && isInvalid[nameInput]
          ? 'w-full lg:w-[94%]'
          : 'w-full lg:w-[79%]'
      } mb-3 items-center`}
    >
      <div className="w-full lg:w-[38%] mb-2 lg:mb-0">{name}</div>
      <input
        placeholder={placeholder}
        defaultValue={defaultValue}
        name={nameInput}
        disabled={isEdit === false ? !isEdit : !isenable?.is_enable_email}
        value={isData[nameInput]}
        onChange={onUbah}
        className={`border-[1px] ${
          isenable?.is_enable_email && isEdit && isInvalid[nameInput]
            ? 'border-[red] outline-[red]'
            : ''
        } w-full lg:w-[75%] border-[#AFAFAF] rounded-md py-2 text-start pl-3 px-51`}
      />
      {isEdit && isInvalid[nameInput] && (
        <div className="ml-5 w-[18%] text-[red]">
          {isenable?.is_enable_email && 'invalid value'}
        </div>
      )}
    </div>
  );
};

const EmailDestination = ({
  isData,
  isEdit,
  isenable,
  handleChecked,
  messaging,
  isInvalid,
  handleChangeDataInput,
}) => {
  return (
    <>
      <div className="block lg:flex text-[#231F20] font-medium w-[80%] mb-3 items-start">
        <div className="w-full lg:w-[48%]">Email Destination</div>
        <div className="w-[100%] mt-3 lg:mt-0">
          <ThemeProvider theme={PaginationTheme}>
            <div className="flex mb-4">
              <Checkbox
                color="primary"
                name="email_to_customer"
                checked={Boolean(Number(isData.email_to_customer))}
                disabled={
                  isEdit === false ? !isEdit : !isenable?.is_enable_email
                }
                onChange={handleChecked}
              />
              <div>
                <div className="font-semibold">Customer</div>
                <div className="text-[12px]">
                  Your customer will receive email notification about
                  transaction
                </div>
              </div>
            </div>
            <div className="flex mb-4">
              <Checkbox
                color="primary"
                checked={Boolean(Number(isData.email_to_merchant))}
                name="email_to_merchant"
                disabled={
                  isEdit === false ? !isEdit : !isenable?.is_enable_email
                }
                onChange={handleChecked}
              />
              <div>
                <div className="font-semibold">Merchant</div>
                <div className="text-[12px]">
                  Your merchant will receive email notification about every
                  transaction
                </div>
              </div>
            </div>
          </ThemeProvider>
        </div>
      </div>
      <InputForm
        placeholder={'Input email support'}
        defaultValue={messaging?.email_support}
        name={'Email Support'}
        nameInput={'email_support'}
        onUbah={handleChangeDataInput}
        {...{ isInvalid, isenable, isData, isEdit }}
      />
      <InputForm
        placeholder={'Input email development'}
        defaultValue={isData.tech_email}
        name={'Email Development'}
        nameInput={'tech_email'}
        onUbah={handleChangeDataInput}
        {...{ isInvalid, isenable, isData, isEdit }}
      />
      <InputForm
        placeholder={'Input email finance'}
        defaultValue={isData.finance_email}
        name={'Email Finance'}
        nameInput={'finance_email'}
        onUbah={handleChangeDataInput}
        {...{ isInvalid, isenable, isData, isEdit }}
      />
    </>
  );
};

export default EmailDestination;
