import { AxiosError } from 'axios';
import { IPagination2 } from './generalTypes';

export enum RefundType {
  SET_REFUND_LIST_PENDING = 'SET_REFUND_LIST_PENDING',
  SET_REFUND_DETAIL_PENDING = 'SET_REFUND_DETAIL_PENDING',

  SET_REFUND_LIST_ERROR = 'SET_REFUND_LIST_ERROR',

  GET_REFUND_LIST_SUCCESS = 'GET_REFUND_LIST_SUCCESS',
  GET_REFUND_DETAIL = 'GET_REFUND_DETAIL',

  SET_REFUND_CREATE_SUCCES = 'SET_REFUND_CREATE_SUCCES',
  SET_GETREFUND_LIST_ERROR = 'SET_GETREFUND_LIST_ERROR',
}

export interface IRefundState {
  isLoading: boolean;
  isLoadingDetail: boolean;
  error: AxiosError;
  pagination: IPagination2;
  refundList: IRefund_list[];
  detailRefund: IDetailRefund;
}

export interface IRefund_list {
  id: Number;
  no: String;
  trx_number: String;
  references_id?: String;
  amount: number;
  charge_fee?: Number;
  status: Number;
  payment: String;
  refund_date: String;
  reason: String;
}

interface IDetailRefund {
  ref_no: String;
  created: String;
  payment: String;
  payer_email: String;
  amount: number;
  status: number;
  payer_name: String;
  payer_phone: String;
}

export interface IBodyDataCreate {
  order_number: String;
  customer_phone: String;
  customer_name: String;
  customer_email: String;
  reason_to_refund: String;
}

interface GetRefundList {
  type: RefundType.GET_REFUND_LIST_SUCCESS;
  payload: {
    pagination: IPagination2;
    refundList: IRefund_list;
  };
}

interface GetRefundListDetail {
  type: RefundType.GET_REFUND_DETAIL;
  payload: {
    detailRefund: IDetailRefund;
  };
}

interface SetPendingRefundDetail {
  type: RefundType.SET_REFUND_DETAIL_PENDING;
  payload?: any;
}

interface SetPendingRefundList {
  type: RefundType.SET_REFUND_LIST_PENDING;
  payload?: any;
}

interface SetCreateRefundSucces {
  type: RefundType.SET_REFUND_CREATE_SUCCES;
  payload?: any;
}

interface SetErrorRefundList {
  type: RefundType.SET_REFUND_LIST_ERROR;
  payload: {
    error: AxiosError;
  };
}

interface SetErrorGetRefundList {
  type: RefundType.SET_GETREFUND_LIST_ERROR;
  payload: {
    error: AxiosError;
  };
}

export type RefundAction =
  | GetRefundList
  | GetRefundListDetail
  | SetPendingRefundList
  | SetErrorRefundList
  | SetPendingRefundDetail
  | SetErrorGetRefundList
  | SetCreateRefundSucces;
