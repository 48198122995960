import { IRouteType } from './interface';

import { icons } from 'assets';

import DashboardPage from 'pages/dasboardPage';
import LaporanPageLayout from 'pages/laporan';
import TransaksiPage from 'pages/transaksi';
import RefundPage from 'pages/transaksi/refund';
import SettingPage from 'pages/pengaturan';
// import AccountPageLayout from 'pages/Account';

import LaporanTransactionPage from 'pages/laporan/transaction';

import DeactiveMerchant from 'pages/pengaturan/deactiveMerchant';
import MerchantPreview from 'pages/pengaturan/merchantPreview';
// import PaymentLink from 'pages/pengaturan/paymentLink';
import Qoinview from 'pages/pengaturan/qoinview';
import ApiSetting from 'pages/pengaturan/systemSetting';

import SettlementPage from 'pages/laporan/Settlement/Component';
import RegisterQRIS from 'pages/pengaturan/registerQRIS';

// import UserRole from 'pages/Account/userRole';

const appRoutes: IRouteType[] = [
  {
    index: true,
    element: <DashboardPage />,
    state: 'home',
  },
  {
    path: '/dashboard',
    element: <DashboardPage />,
    state: 'dashboard',
    sidebarProps: {
      displayText: 'Dashboard',
      icon: <img className="imgIcon" src={icons.dasboard} alt="icon" />,
    },
  },
  {
    path: '/transaksi',
    element: <TransaksiPage />,
    state: 'Transaksi',
    sidebarProps: {
      displayText: 'Transaksi',
      icon: <img className="imgIcon" src={icons.transaksi} alt="icon" />,
    },
    child: [
      {
        path: '/transaksi/Refund',
        element: <RefundPage />,
        state: 'transaksi/Refund',
        sidebarProps: {
          displayText: 'Refund',
        },
      },
    ],
  },
  {
    path: '/laporan',
    element: <LaporanPageLayout />,
    state: 'laporan',
    sidebarProps: {
      displayText: 'Laporan',
      icon: <img className="imgIcon" src={icons.laporan} alt="icon" />,
    },
    child: [
      {
        path: '/laporan/transaction',
        element: <LaporanTransactionPage />,
        state: 'laporan/transaction',
        sidebarProps: {
          displayText: 'Transaction',
        },
      },
      {
        path: '/laporan/Settlement',
        element: <SettlementPage />,
        state: 'transaksi/Settlement',
        sidebarProps: {
          displayText: 'Settlement',
        },
      },
    ],
  },
  // {
  //   path: '/akun',
  //   element: <AccountPageLayout />,
  //   state: 'akun',
  //   sidebarProps: {
  //     displayText: 'Akun',
  //     icon: <img className="imgIcon" src={icons.laporan} alt="icon" />,
  //   },
  //   child: [
  //     {
  //       path: '/akun/UserRole',
  //       element: <UserRole />,
  //       state: 'akun/userRole',
  //       sidebarProps: {
  //         displayText: 'Managemen Pengguna',
  //       },
  //     },
  //   ],
  // },
  {
    path: '/setting',
    element: <SettingPage />,
    state: 'setting',
    sidebarProps: {
      displayText: 'Pengaturan',
      icon: <img className="imgIcon" src={icons.pengaturan} alt="icon" />,
    },
    child: [
      {
        path: '/setting/Merchant-Preview',
        element: <MerchantPreview />,
        state: 'setting/Merchant-Preview',
        sidebarProps: {
          displayText: 'Merchant Preview',
        },
      },
      {
        path: '/setting/systemSetting',
        element: <ApiSetting />,
        state: 'setting/systemSetting',
        sidebarProps: {
          displayText: 'System Setting',
        },
      },
      {
        path: '/setting/Qoinview',
        element: <Qoinview />,
        state: 'setting/Qoinview',
        sidebarProps: {
          displayText: 'Qoinview',
        },
      },
      // {
      //   path: '/setting/Email-Notification',
      //   element: <EmailNotification />,
      //   state: 'setting/Email-Notification',
      //   sidebarProps: {
      //     displayText: 'Email Notification',
      //   },
      // },
      // {
      //   path: '/setting/Payment-Link',
      //   element: <PaymentLink />,
      //   state: 'setting/Payment-Link',
      //   sidebarProps: {
      //     displayText: 'Payment Link',
      //   },
      // },
      {
        path: '/setting/Deactive-Merchant',
        element: <DeactiveMerchant />,
        state: 'setting/Deactive-Merchant',
        sidebarProps: {
          displayText: 'Deactive Merchant',
        },
      },
      {
        path: '/setting/register-qris',
        element: <RegisterQRIS />,
      },
    ],
  },
];

export default appRoutes;
