import React from 'react';
import PaketA from 'assets/img/onboarding-img-1.png';
import PaketB from 'assets/img/onboarding-img-2.png';
import PaketC from 'assets/img/onboarding-img-3.png';
import PaketD from 'assets/img/onboarding-img-4.png';
import PaketE from 'assets/img/onboarding-img-5.png';
import { IPaketBisnis } from './interface';
import { CardOptions } from './Components';
import { useSelector } from 'react-redux';
import { RootState } from 'stores';
import { isFullVersion } from 'utils/exception';

const createCard = (
  image: string,
  title: string,
  subtitle: string,
  benefits?: string[]
) => ({
  image,
  title,
  subtitle,
  benefits,
});

const Bisnis = [
  createCard(
    PaketA,
    'Perorangan',
    'Bisnis yang kepemilikannya atas nama individu seorang'
  ),
  createCard(
    PaketB,
    'Badan Usaha',
    'Bisnis yang kepemilikannya atas nama perusahaan, dalam bentuk PT, Yayasan, CV, UD, lembaga pendidikan, atau asosiasi.'
  ),
];
const Package = [
  createCard(
    PaketC,
    'Payment Gateway',
    'Solusi cerdas untuk menerima pembayaran di mana pun dan kapan pun',
    [
      'Online payment',
      'Beragam Metode Pembayaran',
      'In-store payment',
      'Deteksi Anomali',
    ]
  ),
  createCard(
    PaketD,
    'Payout',
    'Solusi mudah penuhi berbagai jenis kebutuhan pengiriman dana bisnis Anda.',
    [
      'Transfer dana semua bank',
      'Biaya rendah & transparan',
      'Multi beneficiaries',
      'Pembayaran cepat dan real time',
    ]
  ),
  createCard(
    PaketE,
    'Mixed',
    'Fitur lengkap untuk memenuhi semua kebutuhan bisnis Anda.',
    [
      'Transfer dana semua bank',
      'Biaya rendah & transparan',
      'Online Payment',
      'Beragam gabungan fitur Payout & Payment gateway',
    ]
  ),
];

const PaketBisnis: React.FC<IPaketBisnis> = ({ business, packages, error }) => {
  const [check, setCheck] = React.useState<boolean>(false);
  const { merchantPreview } = useSelector((o: RootState) => o.onboarding);
  const { userData, profile } = useSelector((state: RootState) => state.auth);
  const isProd = profile?.MerchantStatus?.includes('prod');

  React.useEffect(() => {
    if (error?.val) {
      if ((business?.val && packages?.val) === '') setCheck(true);
      else setCheck(false);
    }
  }, [error, business, packages, check]);

  React.useEffect(() => {
    if (merchantPreview) {
      if (merchantPreview?.data.package_type !== null) {
        let bisnis = merchantPreview?.data.business_type;
        let pack = merchantPreview?.data.package_type;
        business?.set(String(bisnis));
        packages?.set(String(pack));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantPreview]);

  return (
    <div className="space-y-2 bg-white p-5">
      <CardOptions
        title="Tipe Bisnis"
        subtitle="Pilih tipe bisnis sesuai kebutuhan bisnis kamu."
        list={Bisnis}
        slice={6}
        minHeight="10rem"
        selected={{ val: business.val, set: business.set }}
        error={check && business.val === ''}
        message="Silahkan pilih tipe bisnis. Tipe bisnis tidak boleh kosong."
      />
      <div className="py-2" />
      {isFullVersion && userData?.PackageType !== 2 ? (
        <CardOptions
          title="Paket Produk"
          subtitle="Pilih tipe paket produk sesuai kebutuhan bisnis kamu."
          list={Package}
          slice={4}
          minHeight="20rem"
          selected={{ val: packages.val, set: packages.set }}
          error={check && packages.val === ''}
          message="Silahkan pilih paket produk. Paket produk tidak boleh kosong."
        />
      ) : null}
      {!isFullVersion && userData?.PackageType !== 2 ? (
        <CardOptions
          title="Paket Produk"
          subtitle="Pilih tipe paket produk sesuai kebutuhan bisnis kamu."
          list={Package.slice(0, 2)}
          slice={6}
          minHeight="18rem"
          selected={{
            val: isProd ? packages?.val : String(userData?.PackageType),
            set: packages?.set,
          }}
          message="Silahkan pilih paket produk. Paket produk tidak boleh kosong."
          disabled={!isProd}
        />
      ) : null}
      {/* {!isFullVersion && userData?.PackageType ? (
        <CardOptions
          title='Paket Produk'
          subtitle='Pilih tipe paket produk sesuai kebutuhan bisnis kamu.'
          list={Package.slice(0, 2)}
          slice={6}
          minHeight='18rem'
          selected={{
            val: isProd ? packages?.val : String(userData?.PackageType),
            set: packages?.set,
          }}
          message='Silahkan pilih paket produk. Paket produk tidak boleh kosong.'
          disabled={!isProd}
        />
      ) : (
        <CardOptions
          title='Paket Produk'
          subtitle='Pilih tipe paket produk sesuai kebutuhan bisnis kamu.'
          list={Package}
          slice={4}
          minHeight='20rem'
          selected={{ val: packages.val, set: packages.set }}
          error={check && packages.val === ''}
          message='Silahkan pilih paket produk. Paket produk tidak boleh kosong.'
          // disabled={merchantPreview?.data.package_type !== null} // sementara di balikin jadi enable lagi
        />
      )} */}
    </div>
  );
};

export default PaketBisnis;
