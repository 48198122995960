/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { PaymentMethodData } from 'stores/types/onboardingTypes';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'stores';
import { IMetode } from './interface';
import HelperText from 'components/helperText';
import {
  Button,
  Checkbox,
  Collapse,
  Grid,
  List,
  ThemeProvider,
} from '@mui/material';
import { ArrowForward, ExpandLess, ExpandMore } from '@mui/icons-material';
import { checkboxTheme } from 'components/muiTheme/theme';
import { BasicButton } from 'components/Button';
import { bdColor as bd, bgColor as bg, txColor as tx } from 'constants/colors';
import { useNavigate } from 'react-router-dom';
import { setMetodePembayaran } from 'stores/actions/onboardingAction';
import { isFullVersion } from 'utils/exception';
import useOnboardData from 'hooks/useOnboardData';

const Metode: React.FC<IMetode> = ({ compiled, error, setOpenQR, isQR }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    metodePembayaran,
    QRIS,
    merchantPreview: MP,
  } = useSelector((o: RootState) => o.onboarding);
  const { payData, payError } = useOnboardData();
  const { profile } = useSelector((state: RootState) => state.auth);
  const [preview, setPreview] = useState(false);
  const filterNull = payData?.filter((method) => method?.Channels !== null);
  const [check, setCheck] = React.useState<boolean>(false);
  const [lists, setLists] = useState(filterNull?.map((item) => item));
  const [selected, setSelected] = useState<string[]>(metodePembayaran);
  const [open, setOpen] = useState(
    filterNull?.map((item) => item.Channels !== null)
  );
  const qrisChannel = lists
    ?.find((x) => x.name === 'QRIS' && x?.Channels !== null)
    ?.Channels?.map((y) => String(y.id));
  const pickQRIS =
    selected?.length !== 0 && selected?.find((x) => qrisChannel?.includes(x));

  useEffect(() => {
    // to refresh the array generated (cuz first render become 0)
    if (profile?.MerchantStatus !== 'registered') {
      const notNull = MP.data?.payments?.length !== 0;
      if (notNull) setPreview(true);
    }
    if (payData) {
      setLists(filterNull?.map((item) => item));
    }
    if (metodePembayaran?.length !== 0) setSelected(metodePembayaran);
    setOpen(new Array(filterNull.length).fill(false));
    // eslint-disable-next-line
  }, [payData, MP, preview]);

  // handle export data for outside (compiled)
  useEffect(() => {
    if (error?.val) {
      if (compiled?.val?.length === 0) setCheck(true);
      else setCheck(false);
    }
    if (QRIS !== null) isQR?.set(true);
    compiled?.set(selected);
    // eslint-disable-next-line
  }, [selected, isQR?.val, error, compiled]);

  useEffect(() => {
    if (pickQRIS !== undefined) {
      const exception = selected?.filter((it) => !qrisChannel?.includes(it));
      setSelected(exception);
    }
    let url = window.location.href.split('/').pop();
    if (url !== 'onboarding') {
      let pay = MP.data?.payments?.map((x) => String(x));
      setSelected(pay);
    }
    // eslint-disable-next-line
  }, []);

  const handleOpen = (index: number) => {
    const updatedOpens = [...open];
    updatedOpens[index] = !updatedOpens[index];
    setOpen(updatedOpens);
  };

  const handleDipilih = (index: number) => {
    const pilihan = filterNull?.map((item) =>
      item.Channels?.filter((check) => selected?.includes(String(check.id)))
    );
    return pilihan[index];
  };

  const handleSelectAll = (list: PaymentMethodData, idx: number) => {
    if (list.name === 'QRIS') {
      let newArr: string[];
      if (selected?.includes('QR')) {
        newArr = selected.filter((it) => it !== 'QR');
      } else newArr = [...selected, 'QR'];
      setSelected(newArr);
    }
    if (handleDipilih(idx).length !== list?.Channels.length) {
      // select all based on idx
      setSelected((prev) => {
        const newArr = [...prev];
        for (let index = 0; index < list?.Channels.length; index++) {
          newArr.push(String(list?.Channels[index].id));
        }
        return newArr;
      });
    } else {
      // uncheck the select all based on idx
      setSelected((prev) => {
        const prevArr = [...prev];
        const methodChannelIds = list?.Channels?.map((ch) => String(ch.id));
        return prevArr?.filter((id) => !methodChannelIds?.includes(id));
      });
    }
  };

  const xFormQRIS = () => {
    let url = window.location.href.split('/').pop();
    if (url === 'onboarding') {
      setOpenQR(true);
    } else {
      dispatch(setMetodePembayaran(selected));
      navigate(`${isFullVersion ? '/full' : '/'}setting/register-qris`);
    }
  };

  const WIDTH = window.innerWidth < 600;

  return (
    <div className="space-y-2 bg-white p-5">
      <div className="font-bold text-xl">Pilihan Metode Pembayaran</div>
      {check ? (
        <HelperText error="Silahkan pilih metode pembayaran. Metode pembayaran tidak boleh kosong." />
      ) : null}
      {payError && <div>{payError['data']?.status}</div>}

      {lists?.map((item, idx) => (
        <React.Fragment key={idx}>
          {/* desktop */}
          <List
            sx={{
              justifyContent: 'space-between',
              display: WIDTH ? 'none' : 'flex',
            }}
          >
            <div className="flex my-auto">{CollapseTitle(item, idx)}</div>
            {CollapseArrow(idx)}
          </List>
          {/* mobile */}
          <List sx={{ display: WIDTH ? 'flex' : 'none' }}>
            <div className="flex flex-col m-auto">
              {CollapseTitle(item, idx)}
              {CollapseArrow(idx)}
            </div>
          </List>
          <Collapse in={open[idx]}>
            <Grid container spacing={2}>
              {item?.name === 'QRIS' ? (
                <ListOptions
                  key={item.name}
                  name={item?.name}
                  url={item?.pict_url}
                  id={item?.id}
                  list={item}
                  selected={{ val: selected, set: setSelected }}
                />
              ) : (
                item?.Channels?.map((pc) => (
                  <ListOptions
                    name={pc?.name}
                    url={pc?.pict_url}
                    id={pc?.id}
                    key={`${pc.id}-${pc.name}`}
                    list={pc}
                    selected={{ val: selected, set: setSelected }}
                  />
                ))
              )}
              {/* added condition if any of qris exist and clicked then show this one */}
              {/* {item.name === 'QRIS' && selected?.includes('QR') ? (
                <div
                  className={`border border-[${bd.warning}] rounded-lg bg-[${bg.warning}] w-full m-4 mb-3 p-5 space-y-2`}
                >
                  <p className="text-xl lg:text-2xl font-bold">
                    Informasi Pendaftaran QRIS
                  </p>
                  <p>
                    Lengkapi Informasi yang dibutuhkan pendaftaran merchant QRIS
                    untuk melanjutkan proses pendaftaran
                  </p>
                  <BasicButton
                    variant="text"
                    color="warning"
                    endIcon={<ArrowForward />}
                    sx={{ pl: 0, pt: 0 }}
                    onClick={xFormQRIS}
                  >
                    {isQR?.val ? 'Ubah data' : 'Lengkapi data'}
                  </BasicButton>
                </div>
              ) : null} */}
            </Grid>
            <ThemeProvider theme={checkboxTheme}>
              <div className="flex space-x-2">
                <Checkbox
                  id={'select-all-' + item?.id}
                  sx={{ padding: 0 }}
                  checked={
                    item.name !== 'QRIS'
                      ? handleDipilih(idx).length === item?.Channels.length
                      : selected?.includes('QR')
                  }
                  onChange={() => handleSelectAll(item, idx)}
                />
                <div>Select All</div>
              </div>
            </ThemeProvider>
          </Collapse>
        </React.Fragment>
      ))}
    </div>
  );

  function CollapseArrow(idx: number) {
    return (
      <Button
        className="flex font-bold"
        onClick={() => handleOpen(idx)}
        id={'collapsed-arrow-' + (idx + 1)}
      >
        <div className={`text-[${tx.primary}]`}>Pilih Metode</div>
        {open[idx] ? (
          <ExpandLess htmlColor={tx.primary} />
        ) : (
          <ExpandMore htmlColor={tx.primary} />
        )}
      </Button>
    );
  }

  function CollapseTitle(item: PaymentMethodData, idx: number) {
    const isQRIS = item.name === 'QRIS';
    const pilihan = () => {
      if (!isQRIS) return handleDipilih(idx).length;
      if (selected?.includes('QR')) return 1;
      return 0;
    };

    return (
      <>
        <div className="font-bold mx-auto">{item?.name}</div>
        <div className="px-2 mx-auto">( {pilihan()} dipilih )</div>
      </>
    );
  }
};

export default Metode;

type list_btn = {
  name: string;
  id?: number;
  url: string;
  selected?: { val: string[]; set: (v: string[]) => void };
  list?: PaymentMethodData;
};
const ListOptions: React.FC<list_btn> = (props) => {
  const { selected, list } = props;
  let ids = String(props?.id);
  // karena PC QRIS dijadikan 1 opsi, sengaja dibedakan seperti ini
  if (list && list.name === 'QRIS') ids = 'QR';

  const xClick = (
    checked: string[],
    setChecked: (checked: string[]) => void
  ) => {
    let value = checked || []; // to handle error the checked value is not iterable
    let updateList = [...value];
    if (checked?.includes(ids)) {
      updateList.splice(checked.indexOf(ids), 1);
    } else updateList = [...value, ids];
    setChecked(updateList);
  };
  return (
    <Grid item key={props?.name} xs={6} sm={3} sx={{ my: 1 }}>
      <Button
        id={'item-' + props?.name}
        value={props?.id}
        onClick={() => xClick(selected?.val, selected?.set)}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: 110,
          backgroundColor: `${selected?.val?.includes(ids) && bg.primary}`,
          border: `2px solid ${
            selected?.val?.includes(ids) ? tx.primary : bg.disabled
          }`,
        }}
      >
        <img
          src={props?.url}
          alt={props?.name}
          className="w-16 h-12 m-auto my-2 object-contain"
        />
        <div className="text-center text-black text-sm">{props?.name}</div>
      </Button>
    </Grid>
  );
};
