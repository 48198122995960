import React from 'react';
import SectionQRIS from '../template';
import UploadWide from 'components/upload/wide';
import { PreviewImage } from 'components/cardComponents/PreviewImage';
import { SingleDetailGrid } from 'components/grid/DetailGrid/Component';

type justFile = { val: File; set: React.Dispatch<React.SetStateAction<File>> };
type photos = { val: string; set: (val) => void };
type Props = {
  ktpFile?: justFile;
  ktpPhoto?: photos;
  skuFile?: justFile;
  skuPhoto?: photos;
  akteFile?: justFile;
  aktePhoto?: photos;
  skaFile?: justFile;
  skaPhoto?: photos;
  disabled?: boolean;
};

const KelengkapanDocs: React.FC<Props> = (props) => {
  const url = window.location.href.split('/').pop();

  type docs_content = {
    title: string;
    optional?: boolean;
    file?: justFile;
    photo?: photos;
    url?: string;
  };
  const listDocs: docs_content[] = [
    {
      title: 'Foto KTP Pemilik Usaha',
      file: props.ktpFile,
      photo: props.ktpPhoto,
      url: '',
    },
    {
      title: 'Foto SKU/SIP, SITU, TDP Usaha',
      file: props.skuFile,
      photo: props.skuPhoto,
      url: '',
    },
    {
      title: 'Foto Akte Perusahaan',
      file: props.akteFile,
      photo: props.aktePhoto,
      url: '',
    },
    {
      title: 'Surat Kuasa Ahli',
      optional: true,
      file: props.skaFile,
      photo: props.skaPhoto,
      url: '',
    },
  ];
  return (
    <SectionQRIS
      noTitle={url !== 'onboarding' && url !== 'register-qris'}
      title="Kelengkapan Dokumen"
    >
      <SingleDetailGrid>
        {listDocs.map((list) => (
          <div key={list.title} className="my-6 flex flex-col gap-4">
            <div className="flex space-x-2">
              <p>
                {list.title}{' '}
                {list.optional ? (
                  <b className="font-normal opacity-70">(Bila diwakilkan)</b>
                ) : null}
              </p>
            </div>
            <div className="border-2 w-full border-gray-400 border-dotted rounded-md p-5">
              {props?.disabled ? (
                list.photo.val !== '' ? (
                  <div key={list?.title}>
                    <PreviewImage file={list?.photo?.val} />{' '}
                  </div>
                ) : null
              ) : (
                <UploadWide
                  files={list.file.val}
                  onFileUpload={list.file.set}
                  photo={list.photo.val}
                  onPhotoUpload={list.photo.set}
                  key={list?.title}
                />
              )}
            </div>
            {list?.file?.val && list?.file?.val?.size > 2 * 1024 * 1024 ? (
              <b className="text-red-500 ps-3 -mt-1">
                *File size exceeds limit
              </b>
            ) : null}
          </div>
        ))}
      </SingleDetailGrid>
    </SectionQRIS>
  );
};

export default KelengkapanDocs;
