import CardComponents from 'components/cardComponents';
import React, { useState } from 'react';
import PaymentMethod from './paymentMethod';
import ThemeNlogo from './themeNlogo';
import { ThemeProvider } from '@emotion/react';
import { Button } from '@mui/material';
import { btnTheme, PaginationTheme } from 'components/muiTheme';
import Priview from './priview';
import { toast, ToastContainer } from 'react-toastify';
import useSetting from 'hooks/useSetting';
import { SettingContext } from 'context/settingContext';
import { permissionFunction } from 'utils/permission';
import permission from 'constants/permissionRole';
import useSandbox from 'hooks/useSandbox';
import ButtonWithIcon from 'components/Button/ButtonWithIcon';
import { icons } from 'assets';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
const _ = require('lodash');

const Qoinview = () => {
  let {
    getPayment,
    getMerchantTheme,
    Env,
    updatePayment,
    setPayment,
    updateTheme,
  } = useSetting();

  const [paymentsetUp, setpaymentsetUp] = useState(false);
  const [editView, seteditView] = useState(false);
  const [data, setData]: any = React.useState();
  const [isLoading, setisLoading] = useState(false);

  const [bgImageDefault, setbgImageDefault] = useState();
  const [backGroundColorDefault, setbackGroundColorDefault] = useState();
  const [merchantNameDefault, setmerchantNameDefault] = useState();
  const [buttonColorDefault, setbuttonColorDefault] = useState();

  const {
    setMerchantName,
    // setFiles,
    merchantName,

    setbgImage,
    bgImageFile,
    setbgImageFile,
    logoFile,
    setLogoFile,
    backGroundColor,
    setbackGroundColor,
    buttonColor,
    setbuttonColor,
  } = React.useContext(SettingContext);

  let sandbox = useSandbox()?.userData['Env'];

  const handleGetPayment = () => {
    getPayment((response) => {
      if (response.code !== 200) {
        toast.error('Terjadi Kesalahan', { autoClose: 4000 });
      } else {
        const modifiedData = _.cloneDeep(response?.data?.payments);
        _.forEach(modifiedData, (obj) => {
          _.forEach(obj.chanels, (channel) => {
            if (!channel.pc_integration_type) {
              channel.pc_integration_type = 3;
            }
          });
        });

        setData(modifiedData);
      }
    });
  };

  function transformData(originalData) {
    const transformedData = [];

    originalData?.forEach((paymentMethod, pmIndex) => {
      if (paymentMethod.chanels) {
        paymentMethod.chanels.forEach((channel, pcIndex) => {
          const transformedChannel = {
            id: channel.id,
            page_id: channel.page_id,
            ie: channel.ie,
            status: channel.status,
            pc_queue: pcIndex + 1,
            pm_queue: pmIndex + 1,
            pc_integration_type: channel.pc_integration_type,
          };

          transformedData.push(transformedChannel);
        });
      }
    });

    return transformedData;
  }

  const transformedData = transformData(data);

  React.useEffect(() => {
    getMerchantTheme((response) => {
      if (response.code !== 200) {
        setMerchantName('');
      } else {
        setMerchantName(response?.data?.display_name);
        setbgImage(response?.data?.background_pict);
        setbackGroundColor(response?.data?.background);
        setbuttonColor(response?.data?.button);
        if (response?.data?.background_pict !== '') {
          setbackGroundColor('img');
        }
        setbgImageDefault(response?.data?.background_pict);
        setbackGroundColorDefault(response?.data?.background);
        setmerchantNameDefault(response?.data?.display_name);
        setbuttonColorDefault(response?.data?.button);
      }
    });
    // eslint-disable-next-line
  }, [getMerchantTheme, setMerchantName, sandbox]);

  React.useEffect(() => {
    seteditView(false);
    handleGetPayment();
    // eslint-disable-next-line
  }, [getPayment, sandbox]);

  // React.useEffect(() => {
  //   return () => {
  //     setLogoFile();
  //     setbgImage();
  //     setbgImageFile();
  //   };
  //   // eslint-disable-next-line
  // }, [setFiles]);

  // React.useEffect(() => {
  //   setLogoFile();
  //   setbgImage();
  //   setbgImageFile();
  //   // eslint-disable-next-line
  // }, [paymentsetUp]);

  React.useEffect(() => {
    setMerchantName(merchantNameDefault);
    setbgImage(bgImageDefault);
    if (bgImageDefault !== '') {
      setbackGroundColor('img');
    } else {
      setbackGroundColor(backGroundColorDefault);
    }
    setbuttonColor(buttonColorDefault);
    // eslint-disable-next-line
  }, [paymentsetUp]);

  const handleEdit = (to: string) => {
    seteditView(false);
    if (to === 'save') {
      setisLoading(true);
      setPayment(data);

      updatePayment(transformedData, (response) => {
        seteditView(false);
        setisLoading(false);
        if (response.code !== 200) {
          toast.error('Terjadi Kesalahan', { autoClose: 4000 });
        } else {
          toast.success('succes', { autoClose: 4000 });
          setPayment(data);
        }
      });
    } else {
      seteditView(false);
      handleGetPayment();
    }
  };

  const handleSelectChannel = (pmId, pcId, status) => {
    setData((prevData) =>
      prevData.map((dataItem) =>
        dataItem.id === pmId
          ? {
              ...dataItem,
              chanels: dataItem.chanels?.map((channel) =>
                channel.page_id === pcId
                  ? { ...channel, status: Number(status) }
                  : channel
              ),
            }
          : dataItem
      )
    );
  };

  const handleSelectAllChannel = (pmId, status, method) => {
    setData((prevData) =>
      prevData.map((dataItem) =>
        dataItem.id === pmId
          ? {
              ...dataItem,
              chanels: dataItem.chanels?.map((channel, index) => ({
                ...channel,
                status: method
                  ? index === 0
                    ? Number(status)
                    : 0
                  : Number(status),
              })),
            }
          : dataItem
      )
    );
  };

  const handleChangeFee = (pmId, pcId, ie) => {
    setData((prevData) =>
      prevData.map((dataItem) =>
        dataItem.id === pmId
          ? {
              ...dataItem,
              chanels: dataItem.chanels?.map((channel) =>
                channel.page_id === pcId
                  ? { ...channel, ie: Number(ie) }
                  : channel
              ),
            }
          : dataItem
      )
    );
  };

  const handleChangeType = (pmId, pcId, type) => {
    setData((prevData) =>
      prevData.map((dataItem) =>
        dataItem.id === pmId
          ? {
              ...dataItem,
              chanels: dataItem.chanels?.map((channel) =>
                channel.page_id === pcId
                  ? { ...channel, pc_integration_type: Number(type) }
                  : channel
              ),
            }
          : dataItem
      )
    );
  };

  const tambahkanObjekKeFormData = (obj, formData) => {
    Object.entries(obj).forEach(([key, value]) => {
      formData.append(key, value);
    });
  };

  const handleUpdateTheme = () => {
    let data: any = {
      display_name: merchantName,
      language: 'indonesia',
      button: buttonColor,
    };

    if (backGroundColor !== 'img') {
      data.background = backGroundColor;
    }

    if (logoFile) {
      data.thema_pict = logoFile;
    }

    if (bgImageFile) {
      data.background_pict = bgImageFile;
    }

    const newFormData = new FormData();

    tambahkanObjekKeFormData(data, newFormData);

    updateTheme(newFormData, (response) => {
      if (response.code !== 200) {
        toast.error('Terjadi Kesalahan', { autoClose: 4000 });
      } else {
        getMerchantTheme((response) => {
          if (response.code !== 200) {
            toast.error('Terjadi Kesalahan', { autoClose: 4000 });
          } else {
            toast.success('succes', { autoClose: 4000 });
            setLogoFile();
            setbgImage();
            setbgImageFile();
          }
        });
      }
    });
  };

  return (
    <div>
      <ToastContainer position="top-right" autoClose={4000} pauseOnHover />

      <div className="text-2xl sm:text-3xl  items-center font-bold mb-5 mt-[5rem] lg:mt-[1rem] cursor-pointer">
        Settings
      </div>

      <CardComponents>
        <br />
        <div className="block lg:flex ml-7 cursor-pointer rounded-md bg-[#EAE9E9] w-[82%] lg:w-[700px]">
          {[
            {
              name: 'Theme and Logo',
              value: false,
            },
            {
              name: 'Payment method and Channel',
              value: true,
            },
          ].map((e, i) => {
            return (
              <div key={i}>
                <div
                  className={`
                   
                  w-[100%] lg:w-[350px] p-2 text-center `}
                  onClick={() => setpaymentsetUp(e.value)}
                >
                  <div
                    className={`${
                      paymentsetUp === e.value
                        ? 'text-[#FFFFFF] bg-[#21AF7D]'
                        : 'bg-[#EAE9E9] text-[#909090]'
                    } w-[100%] lg:w-[330px] p-2 text-center rounded-md`}
                  >
                    {e.name}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {paymentsetUp && (
          <div className=" flex justify-between px-8 mt-8">
            <div className="font-bold text-[26px] text-[#231F20]">Webview</div>
            <div className="gap-4 flex">
              {!editView ? (
                isLoading ? (
                  <LoadingButton
                    size="large"
                    onClick={undefined}
                    loading={isLoading}
                    loadingPosition="start"
                    variant="outlined"
                    disabled
                  >
                    <div className="ml-5">Edit View</div>
                  </LoadingButton>
                ) : (
                  <ButtonWithIcon
                    width="full"
                    btnTheme={btnTheme}
                    startIcon={icons.pencil}
                    onClick={() => seteditView(true)}
                    variant="outlined"
                    color="primary"
                  >
                    Edit View
                  </ButtonWithIcon>
                )
              ) : (
                <>
                  <ButtonWithIcon
                    width="full"
                    btnTheme={btnTheme}
                    Icon={<CloseIcon />}
                    onClick={() => handleEdit('cancel')}
                    variant="outlined"
                    color="error"
                  >
                    Cancel
                  </ButtonWithIcon>
                  <ButtonWithIcon
                    width="full"
                    btnTheme={btnTheme}
                    Icon={<DoneIcon />}
                    onClick={() => handleEdit('save')}
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </ButtonWithIcon>
                </>
              )}
            </div>
          </div>
        )}

        <div className="w-full flex px-8 mt-5 flex-col-reverse lg:flex-row justify-center lg:justify-around">
          <div className="ml-0  w-[100%] lg:w-[30rem]">
            {paymentsetUp ? (
              <PaymentMethod
                {...{
                  data,
                  setData,
                  handleSelectChannel,
                  handleSelectAllChannel,
                  handleChangeFee,
                  handleChangeType,
                }}
                editView={editView}
              />
            ) : (
              <ThemeNlogo />
            )}

            <br />
            {!paymentsetUp && (
              <ThemeProvider theme={PaginationTheme}>
                <Button
                  className="w-full lg:w-[96%] "
                  variant="contained"
                  color="primary"
                  disabled={
                    !permissionFunction(permission[Env].UPDATE_QOINVIEW)
                  }
                  onClick={() => handleUpdateTheme()}
                >
                  update
                </Button>
              </ThemeProvider>
            )}
          </div>
          <div className=" lg:ml-7 w-[100%] max-h-[600px] lg:w-[25rem]">
            <b className="text-[18px]">Preview</b>
            <div className="h-full bg-[#EAEAEA] flex justify-center p-0 lg:p-7 rounded-md">
              <Priview {...{ data }} />
            </div>
          </div>
        </div>
        <br />
        <br />
      </CardComponents>
      <br />
    </div>
  );
};

export default Qoinview;
