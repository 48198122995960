import React from 'react';
import { ICard } from './interface';

const Component: React.FC<ICard> = ({ children }) => {
  return (
    <div className='bg-[#FFFFFF] shadow-sm w-full '>
      <div>{children}</div>
    </div>
  );
};

export default Component;
