import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ClearIcon from '@mui/icons-material/Clear';

const LabelInfo = ({ isInfo, setisInfo, text }) => {
  return (
    <div
      className={`${
        !isInfo && 'hidden'
      } flex bg-[#FFF6E1] border py-[14px] px-[24px] items-start border-[#FDBE2C] rounded-md mb-5`}
    >
      <ErrorOutlineIcon className="mr-3" sx={{ color: '#FDBE2C' }} />
      <div className="text-[#241F20]">{text}</div>
      <ClearIcon sx={{ color: '#94A3B8' }} onClick={() => setisInfo(false)} />
    </div>
  );
};

export default LabelInfo;
