import { icons } from 'assets';
import { Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useNotification from 'hooks/useNotification';
import moment from 'moment';
import { ListIcon } from './listKategory';
import { isFullVersion } from 'utils/exception';

const PoperNotifications = ({ handleOutNotif }) => {
  let navigate = useNavigate();
  const { notificationState, setDetail, readList, getList } = useNotification();
  let { message_top_list } = notificationState.data;

  let listCategory = notificationState?.category;

  const handleReadAll = () => {
    handleOutNotif();
    readList((response: any) => {
      if (response.status_code !== 200) {
        console.log('error', 'error');
      } else {
        getList(
          { page: 1, limit: -1 },
          (response: any) => {
            if (response.status_code !== 200) console.log('error', 'error');
          },
          false,
          true
        );
      }
    });
  };

  const unreadItems = message_top_list
    .filter((e) => e.is_read === 2)
    .slice(0, 3);

  return (
    <div className="flex justify-center flex-col px-5">
      <div className="text-lg font-bold mb-5 mt-4">Notification</div>

      {unreadItems.map((e, i) => {
        let IconsNotif = null;
        let categoryName = '';

        // eslint-disable-next-line array-callback-return
        listCategory?.map((e2) => {
          // eslint-disable-next-line array-callback-return
          e2?.topics?.map((data) => {
            if (e?.message_topic?.toLowerCase() === data?.topic_name) {
              IconsNotif = ListIcon[e2?.category_name];
              categoryName = e2?.category_name;
            }
          });
        });

        return (
          <div
            key={i}
            className="flex w-full justify-around items-center border-b-2 py-4"
            onClick={() => {
              navigate(`/notifications/${e.id}`);
              setDetail(e.id);
              handleOutNotif();
            }}
          >
            <div className="w-3/12 pr-2">
              <img
                src={IconsNotif !== null ? IconsNotif : icons.Default}
                width="50px"
                height="40px"
                alt=""
              />
            </div>
            <div className=" w-11/12">
              <div className="flex items-start justify-between">
                <div className="font-bold text-[14px]">{e.message_title}</div>
                <Avatar
                  sx={{
                    bgcolor: '#3967E0',
                    width: '16px',
                    height: '16px',
                    marginTop: '3px',
                  }}
                >
                  {' '}
                </Avatar>
              </div>

              <div className="flex items-center text-[#585F65]">
                <div className="mr-2 text-[17px]">
                  {categoryName.charAt(0).toUpperCase() + categoryName.slice(1)}
                </div>
                <div className="text-[14px] mt-1">
                  <li> {moment(e.message_date).endOf('minutes').fromNow()}</li>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      <div className="flex justify-between text-[#21AF7D] pt-4">
        <div onClick={() => handleReadAll()}>Mark all as read</div>
        <div
          onClick={() => {
            navigate(`${isFullVersion ? '/full/' : '/'}notifications`);
            handleOutNotif();
          }}
        >
          See All
        </div>
      </div>

      <br />
    </div>
  );
};

export default PoperNotifications;
