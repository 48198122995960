import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'stores';
import { BasicButton } from 'components/Button';
import EditPen from 'assets/icons/editPen';
import {
  BankDetail,
  InfoBisnis,
  Metode,
  PaketBisnis,
  Preview,
} from 'pages/Onboarding/Elements';
import {
  DetailBankData,
  InformasiBisnisData,
  MerchantPreviewData,
  PaymentMethodData,
} from 'stores/types/onboardingTypes';
import { IChangedData } from 'stores/types/ticketingTypes';
import { submitTicketChanged } from 'stores/actions/ticketingAction';
import { getFileNameUrl as getFileName } from 'utils/exception';
// import { useRegisterFormQRIS } from 'hooks/useFormQRIS';
// import { submitRegisterQRIS } from 'stores/actions/onboardingAction';

type isBool = { val: boolean; set: (v: boolean) => void };
type adds = {
  data: MerchantPreviewData;
  isLoading: boolean;
  error?: any;
};
interface Props {
  edit: isBool;
  loading?: isBool;
  popup?: isBool;
  error?: { val: string; set: (v: string) => void };
  data: { MP: adds; payData: PaymentMethodData[] };
}
const MerchantSetting: React.FC<Props> = (props) => {
  const { MP, payData } = props.data;
  const dispatch = useDispatch();
  const { profile, userData } = useSelector((a: RootState) => a.auth);
  // const { QRIS } = useSelector((o: RootState) => o.onboarding);

  const doneOnboard = profile.MerchantStatus !== 'registered';
  const doneReview = profile.MerchantStatus !== 'in-review';
  const inReview = MP.data.state === 5;

  const [sandbox, setSandbox] = useState(!userData?.Env);
  const [loading, setLoading] = useState(false);
  const [edits, setEdits] = useState(doneOnboard);
  const [bisnis, setBisnis] = useState(MP.data?.business_type?.toString());
  const [paket, setPaket] = useState(MP.data?.package_type?.toString());
  const [selectedList, setSelectedList] = useState<string[]>([]);
  const [infoBiz, setInfoBiz] = useState<InformasiBisnisData | null>(null);
  const [infoBank, setInfoBank] = useState<DetailBankData | null>(null);
  const [fileKtp, setFileKtp] = useState('');
  const [fileNpwp, setFileNpwp] = useState('');
  const [chooseQR, setChooseQR] = useState<boolean>(false);

  // ? left out some comment just to disable for now, check later
  // const infoBizField =
  //   infoBiz?.BizUrl === '' ||
  //   infoBiz?.PicFinanceEmail === '' ||
  //   infoBiz?.PicFinancePhone === '' ||
  //   infoBiz?.PicTechEmail === '' ||
  //   infoBiz?.PicTechPhone === '';
  // const ownerField =
  //   infoBiz?.OwnerName === '' ||
  //   infoBiz?.OwnerAddress === '' ||
  //   infoBiz?.OwnerPhone === '' ||
  //   infoBiz?.OwnerZip === '';
  // const docField =
  //   infoBiz?.IdCardName === '' ||
  //   infoBiz?.IdCardNumber === '' ||
  //   infoBiz?.NpwpName === '' ||
  //   infoBiz?.NpwpAddress === '' ||
  //   infoBiz?.NpwpNumber === '';
  const bankField =
    infoBank?.BankAccountName === '' || infoBank?.BankAccountNumber === '';

  const handleEdit = () => {
    if (doneReview && doneOnboard) {
      props.edit.set(!props.edit.val);
    }
  };

  const preValues = (defaultV: keyof IChangedData, updateV: string) => {
    // handle update of new data value or previous data value
    if (updateV === '') return MP.data[defaultV];
    else return updateV;
  };

  const prePayments = (v?: 'prev') => {
    // handle manipulation of payments after changed qris payment channel
    let value: number[];
    if (selectedList.length === 0) {
      // if there's no changes of payments, use the previous payments
      value = MP.data?.payments?.map((x) => parseInt(x));
    }
    let states = v ? MP.data?.payments : selectedList;
    const newValue = states.filter((s) => s !== 'QR').map((x) => parseInt(x));
    if (states.find((s) => s === 'QR')) {
      // if the changes consist of QRIS, which is defined different on onboarding
      const qrValue = payData
        ?.find((mp) => mp?.name === 'QRIS')
        .Channels?.map((ch) => ch.id);
      value = [...qrValue, ...newValue];
    } else value = newValue;
    return value;
  };

  // const qrisForm = useRegisterFormQRIS();
  const xSubmit = () => {
    if (MP.data) {
      setLoading(true);
      const { state, info_status, ktp_url, npwp_url } = MP.data;
      const prevData: IChangedData = {
        ...MP.data,
        payments: prePayments('prev'),
        name: profile.Fullname,
        ktp_url: fileKtp,
        npwp_url: fileNpwp,
      };
      // jika tidak ada perubahan maka new data masih menggunakan prev data
      const newData: IChangedData = {
        business_type: parseInt(bisnis),
        package_type: parseInt(paket),
        bis_type_code: preValues('bis_type_code', infoBiz.BizTypeCode),
        bis_category_code: preValues(
          'bis_category_code',
          infoBiz.BizCategoryCode
        ),
        bis_url: preValues('bis_url', infoBiz.BizUrl),
        name: profile.Fullname,
        ktp_name: preValues('ktp_name', infoBiz.IdCardName),
        ktp_no: preValues('ktp_no', infoBiz.IdCardNumber),
        ktp_url: infoBiz.IdCardPhotoFile !== null ? '' : ktp_url,
        npwp_name: preValues('npwp_name', infoBiz.NpwpName),
        npwp_number: preValues('npwp_number', infoBiz.NpwpNumber),
        npwp_address: preValues('npwp_address', infoBiz.NpwpAddress),
        npwp_url: infoBiz.NpwpPhotoFile !== null ? '' : npwp_url,
        own_name: preValues('own_name', infoBiz.OwnerName),
        own_address: preValues('own_address', infoBiz.OwnerAddress),
        own_citizen: preValues('own_citizen', infoBiz.OwnerNationality),
        own_phone: preValues('own_phone', infoBiz.OwnerPhone),
        own_zip: preValues('own_zip', infoBiz.OwnerZip),
        payments: prePayments(),
        state: state,
        finance_email: preValues('finance_email', infoBiz.PicFinanceEmail),
        finance_phone: preValues('finance_phone', infoBiz.PicFinancePhone),
        tech_email: preValues('tech_email', infoBiz.PicTechEmail),
        tech_phone: preValues('tech_phone', infoBiz.PicTechPhone),
        bank: preValues('bank', infoBank.BankName),
        bank_acc_name: preValues('bank_acc_name', infoBank.BankAccountName),
        bank_acc_no: preValues('bank_acc_no', infoBank.BankAccountNumber),
        info_status: info_status,
      };
      const submitData = {
        data: {
          merchant_email: profile.Email,
          request_by_name: profile.Username,
          request_by_role: profile.RoleName,
          before: prevData,
          after: newData,
        },
      };

      // if (QRIS !== null) {
      //   // if the user have filled the QRIS form, submit form
      //   dispatch(submitRegisterQRIS(qrisForm));
      // }

      // submit data specific in edit merchant preview
      dispatch(
        submitTicketChanged(
          JSON.stringify(submitData),
          infoBiz.IdCardPhotoFile,
          infoBiz.NpwpPhotoFile,
          props.error?.set,
          props.popup?.set
        )
      );
    }
  };

  // checking current env (sandbox / prod)
  useEffect(() => {
    if (!userData.Env) {
      setSandbox(true);
      if (!userData.Env && sandbox) setSandbox(true);
    } else setSandbox(false);
  }, [userData, sandbox]);

  // checking Package Type
  useEffect(() => {
    if (userData.PackageType !== null && doneOnboard) {
      if (MP.data?.package_type !== null) {
        let bis = MP.data?.business_type;
        let pkg = MP.data?.package_type;
        setBisnis(bis.toString());
        setPaket(pkg.toString());

        if (MP.data?.ktp_url !== '') {
          const ktpName = getFileName(MP.data?.ktp_url);
          const npwpName = getFileName(MP.data?.npwp_url);
          setFileKtp(ktpName);
          setFileNpwp(npwpName);
        }
      }
    }
  }, [userData, MP, doneOnboard]);

  return (
    <>
      {props?.edit?.val ? (
        <>
          <div className="flex justify-between space-y-2 bg-white p-5">
            <div className="font-bold text-xl my-auto">Informasi Merchant</div>

            {userData?.Env && (
              <BasicButton
                color="primary"
                startIcon={<EditPen color="#FFF" />}
                sx={{
                  px: 3,
                  color: `${inReview && '#FFF !important'}`,
                  bgcolor: `${inReview && '#9E9E9E !important'}`,
                }}
                onClick={handleEdit}
                disabled={inReview}
              >
                Edit
              </BasicButton>
            )}
          </div>
          <Preview status />
        </>
      ) : (
        <>
          {sandbox ? (
            <Preview status />
          ) : (
            <div className="flex flex-col gap-4">
              <>
                <PaketBisnis
                  business={{ set: setBisnis, val: bisnis }}
                  packages={{ set: setPaket, val: paket }}
                />
                <Metode
                  compiled={{ set: setSelectedList, val: selectedList }}
                  isQR={{ set: setChooseQR, val: chooseQR }} // konfirmasi sudah isi form
                />
                <InfoBisnis data={{ set: setInfoBiz }} />
                <BankDetail data={{ set: setInfoBank }} />
              </>
              <div className="flex gap-4 flex-grow justify-end p-4">
                <BasicButton
                  sx={{ width: 120 }}
                  onClick={() => setEdits(!edits)}
                  color="secondary"
                  id="cancel-btn"
                >
                  Cancel
                </BasicButton>
                <BasicButton
                  sx={{ width: 120 }}
                  onClick={xSubmit}
                  color="primary"
                  id="submit-btn"
                  disabled={
                    loading ||
                    selectedList?.length === 0 ||
                    // infoBizField ||
                    // ownerField ||
                    // docField ||
                    bankField
                  }
                >
                  Save
                </BasicButton>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default MerchantSetting;
