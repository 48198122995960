import React from 'react';
import { SingleOption } from 'components/select';
import SectionQRIS, { GridDetailQRIS } from '../template';
import { QRoptions } from '..';

type Props = {
  usaha: { val: string; set: (val: string) => void };
  badan?: { val: string; set: (val: string) => void };
  disabled?: boolean;
};
const InfoUsaha: React.FC<Props> = ({ usaha, badan, disabled }) => {
  const url = window.location.href.split('/').pop();
  return (
    <SectionQRIS
      noTitle={url !== 'onboarding' && url !== 'register-qris'}
      title={`${url === 'qris-detail' ? 'Tentang' : 'Informasi'} Usaha`}
    >
      <GridDetailQRIS title="Jenis Usaha">
        <SingleOption
          options={QRoptions.listUsaha}
          selection={usaha}
          fullWidth
          disabled
        />
      </GridDetailQRIS>
      {usaha?.val === '2' ? (
        <GridDetailQRIS title="Jenis Badan Usaha">
          <SingleOption
            options={QRoptions.listBU}
            selection={badan}
            fullWidth
            placeholder="Pilih Jenis Badan Usaha"
            disabled={disabled}
          />
        </GridDetailQRIS>
      ) : null}
    </SectionQRIS>
  );
};

export default InfoUsaha;
