import React from 'react';
import { Badge, Toolbar, Zoom } from '@mui/material';
import { icons, img } from 'assets';
import { useEffect, useState } from 'react';
import { PropsCommon } from './interface';
import { RootState } from 'stores';
import { useDispatch, useSelector } from 'react-redux';
import Drawer from 'components/drawer';
import { setDrawerContent } from 'stores/actions/utilsAction';
import { getProfile } from 'stores/actions/authActions';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import PoperNotifications from 'components/notifications/poperNotifiaction';
import useNotification from 'hooks/useNotification';
import { Fade, Paper, Popper, PopperPlacementType } from '@mui/material';
import PocketBase from 'pocketbase';

import { Box, IconButton } from '@mui/material';

import SideMobile from './SidebarMobile';

const Topbar = ({ handleDrawerOpen }: PropsCommon) => {
  const { profile } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openNotif, setOpenNotif] = useState(false);
  const [placement, setPlacement] = useState<PopperPlacementType>();

  const [openDrawer, setopenDrawer] = useState(false);

  const handleClick =
    (newPlacement: PopperPlacementType) =>
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      setOpenNotif((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  const handleDrawer = () => {
    dispatch(setDrawerContent('user'));
    setOpen(true);
  };

  const handleOutNotif = () => {
    setOpenNotif(false);
  };

  const { getList, notificationState, user } = useNotification();
  let { message_list } = notificationState.data;

  React.useEffect(() => {
    getList({ page: 1, limit: 10 }, (response: any) => {
      if (response.status_code !== 200) console.log('error', 'error');
    }); // eslint-disable-next-line
    const pb = new PocketBase('https://pb.loyalto.id');
    pb.collection('messages').subscribe('*', function (e) {
      let data = e.record;
      if (
        data.client_id === user['ClientId'] &&
        data.merchant_id === user['merchant_id'] &&
        data.user_id === user['user_id']
      ) {
        setTimeout(() => {
          getList({ page: 1, limit: 10 }, (response: any) => {
            if (response.status_code !== 200) console.log('error', 'error');
          }); // eslint-disable-next-line
        }, 2000);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Toolbar>
        <Drawer open={open} onClose={() => setOpen(false)} />
        <SideMobile
          isOpen={openDrawer}
          setisOpen={setopenDrawer}
          mouseOver={undefined}
          setmouseOver={undefined}
        />
        <Popper
          open={openNotif}
          anchorEl={anchorEl}
          placement={placement}
          transition
        >
          {({ TransitionProps }) => (
            <ClickAwayListener
              onClickAway={() => {
                if (openNotif) setOpenNotif(!openNotif);
              }}
            >
              <Zoom in={openNotif}>
                <Fade
                  {...TransitionProps}
                  timeout={10}
                  className="cursor-pointer w-[350px] mr-4 mt-[20px]"
                  style={{
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
                    borderRadius: 12,
                  }}
                >
                  <Paper>
                    <div>
                      <PoperNotifications {...{ handleOutNotif }} />
                    </div>
                  </Paper>
                </Fade>
              </Zoom>
            </ClickAwayListener>
          )}
        </Popper>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => setopenDrawer(!openDrawer)}
        >
          <img src={icons.SideMobile} alt="side" />
        </IconButton>

        <div className="w-full flex justify-center">
          <img src={icons.plus} width={90} alt="" />
        </div>

        <Box sx={{ flexGrow: 1 }} />
        <IconButton
          color="inherit"
          onClick={handleClick('bottom')}
          className="cursor-pointer"
        >
          <Badge
            color="secondary"
            badgeContent={
              message_list.filter((item) => item.is_read === 2).length
            }
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: '#FF3535',
              },
            }}
          >
            <img src={icons.lonceng} alt="loncong" className="cursor-pointer" />
          </Badge>
        </IconButton>
        <IconButton color="inherit" onClick={handleDrawer}>
          <img
            style={{ width: 40, height: 40, borderRadius: '100%' }}
            src={
              profile?.ProfileImage === '' ? img.profile : profile?.ProfileImage
            }
            className="flex align-center border-2 border-[#D3EDE4] mt-1 cursor-pointer object-contain"
            alt="as"
          />
        </IconButton>
      </Toolbar>
    </>
  );
};

export default Topbar;
