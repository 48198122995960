import React from 'react';
import { InfoOutlined } from '@mui/icons-material';
import { FormHelperText } from '@mui/material';

type HelperProps = {
  error: string;
};

const Component: React.FC<HelperProps> = ({ error }) => {
  return (
    <div className="text-red-600 flex">
      <InfoOutlined className="my-auto mr-2" />
      <div className="my-auto">
        <FormHelperText sx={{ margin: 0, fontSize: 16 }} error>
          {error}
        </FormHelperText>
      </div>
    </div>
  );
};

export default Component;
