import SectionCard from 'components/cardComponents/sectionCard';
import { IPaymentDetailProps } from './interface';
import LoadingWrapper from 'components/loadingWrapper';

const PaymentDetail: React.FC<IPaymentDetailProps> = ({
  payment,
  isLoading,
}) => {
  const paymentDetails = [
    {
      name: 'Payment Method',
      value: payment.method,
    },
    {
      name: 'Payment Channel',
      value: payment.channel,
    },
    {
      name: 'VA Number',
      value: payment.va_number,
    },
    {
      name: 'Expired Date',
      value: payment.expired_date,
    },
  ];

  return (
    <SectionCard title="Payment Detail">
      {paymentDetails.map((detail) => {
        return (
          <LoadingWrapper isLoading={isLoading}>
            <div className="flex w-12/12 text-[12px] lg:text-sm mt-1">
              <div className="w-[55%] lg:w-[50%] font-bold">{detail.name}</div>
              <div className="w-[45%] break-all">{detail.value || '-'}</div>
            </div>
          </LoadingWrapper>
        );
      })}
    </SectionCard>
  );
};

export default PaymentDetail;
