import { AxiosError } from 'axios';
import provider, { IProvider } from 'provider';
import MethodProvider from 'provider/methods';
import { ThunkAction } from 'redux-thunk';
import { RootState } from 'stores';
import { IError } from 'stores/types/errorTypes';
import { errorGlobal } from './errorAction';
import endpoints from 'api/endpoints';
import {
  DashboardAction,
  DashboardType,
  IDatav2,
} from 'stores/types/dashboardType';
import moment from 'moment';

const dataFilter = (dataState) => {
  let data = dataState?.reduce((accumulator, item) => {
    let date = item?.date?.substring(0, 10); // Extract date without time

    let existingItem = accumulator?.find((entry) => entry?.date === date);

    if (!existingItem) {
      existingItem = {
        date: date,
      };
      accumulator.push(existingItem);
    }

    if (item?.status === 'process' || item?.status === '0') {
      existingItem.process = parseInt(item?.amount);
    } else if (item?.status === 'success' || item?.status === '1') {
      existingItem.success = parseInt(item?.amount);
    } else if (item?.status === 'failed' || item?.status === '2') {
      existingItem.failed = parseInt(item?.amount);
    }

    return accumulator;
  }, []);

  data.sort((a, b) => (a.date > b.date ? 1 : -1));

  return data;
};

const dataLabel = (dataState) => {
  let uniqueDates = new Set();
  let dataLabels = dataState?.reduce((accumulator, item) => {
    let formattedDate = moment(item?.date).format('DD/MM/YYYY');
    if (!uniqueDates.has(formattedDate) && moment(item?.date).isValid()) {
      accumulator.push(formattedDate);
      uniqueDates.add(formattedDate);
    }
    return accumulator;
  }, []);

  return dataLabels;
};

const Color = (data) => {
  let color: Array<string> = data.map((item: any) =>
    item.status === 'Success'
      ? '#21AF7D'
      : item.status === 'Process'
      ? '#FFCD5F'
      : item.status === 'Failed' && '#ff6383'
  );
  return color;
};

const LabelStatus = (data) => {
  let lebelStatus: Array<string> = data.map((item: any) => item.status);
  return lebelStatus;
};

const Total = (data) => {
  let total: Array<string> = data.map((item: any) => String(item.amount));
  return total;
};

const Sum = (data) => {
  let sum: number = 0;

  for (let i = 0; i < data.length; i++) {
    sum += parseInt(data[i]);
  }

  return sum;
};

export const DashboardError = (error: AxiosError): DashboardAction => ({
  type: DashboardType.DASHBOARD_ERROR,
  payload: {
    error,
  },
});

export const DashboardPending = (): DashboardAction => ({
  type: DashboardType.DASHBOARD_PENDING,
});

export const setDataV2 = (data: IDatav2): DashboardAction => ({
  type: DashboardType.DASHBOARD_SET_DTA_V2,
  payload: data,
});

export const getTotalTransaction = (
  totalTransaction: Array<string>,
  sumTransaction: number,
  labelStatusTransaction: Array<string>,
  colorTransaction: Array<string>
): DashboardAction => ({
  type: DashboardType.DASHBOARD_GET_TOTAL_TRANSACTION,
  payload: {
    totalTransaction,
    sumTransaction,
    labelStatusTransaction,
    colorTransaction,
  },
});

export const getTransactionFreq = (
  data: Array<any>,
  dataLabel: Array<string>
): DashboardAction => ({
  type: DashboardType.DASHBOARD_GET_TRANSACTION_FREQ,
  payload: {
    transactionFreq: {
      data,
      dataLabel,
    },
  },
});

export const getTotalIncome = (
  totalIncome: Array<string>,
  sumIncome: number,
  labelStatusIncome: Array<string>,
  colorIncome: Array<string>
): DashboardAction => ({
  type: DashboardType.DASHBOARD_GET_TOTAL_INCOME,
  payload: {
    totalIncome,
    sumIncome,
    labelStatusIncome,
    colorIncome,
  },
});

export const getIncomeFreq = (
  data: Array<any>,
  dataLabel: Array<string>
): DashboardAction => ({
  type: DashboardType.DASHBOARD_GET_INCOME_FREQ,
  payload: {
    incomeFreq: {
      data,
      dataLabel,
    },
  },
});

export const getFreqTop10Product = (
  labels: Array<any>,
  jumlahTransaksi: Array<string>
): DashboardAction => ({
  type: DashboardType.DASHBOARD_GET_TRANSACTION_TOP10,
  payload: {
    top10Product: {
      labels,
      jumlahTransaksi,
    },
  },
});

export const JumlahFreqTransaksiPm = (
  labels: Array<any>,
  jumlahTransaksi: Array<string>
): DashboardAction => ({
  type: DashboardType.DASHBOARD_GET_TRANSACTION_BYPM,
  payload: {
    freqTransactionByPm: {
      labels,
      jumlahTransaksi,
    },
  },
});

export const GetTotalTransaction = ({
  params,
}): ThunkAction<void, RootState, null, DashboardAction | IError> => {
  return async (dispatch, getState) => {
    let sandbox = getState()?.auth?.userData['Env'];

    dispatch(DashboardPending());

    try {
      const objProvider: IProvider = {
        sandbox: sandbox,
        method: MethodProvider.GET,
        queryParams: params,
        path: endpoints.dashboardTotalTransaction,
      };
      const response = await provider(objProvider);

      const res = {
        code: response?.status,
        message: 'get Data Success!',
        data: response?.data?.data,
      };

      if (res?.data?.length > 0) {
        let totalTransaction: Array<string> = await Total(res?.data);
        let sumTransaction: number = Sum(totalTransaction);
        let labelStatusTransaction: Array<string> = LabelStatus(res?.data);
        let colorTransaction: Array<string> = Color(res?.data);
        dispatch(
          getTotalTransaction(
            totalTransaction,
            sumTransaction,
            labelStatusTransaction,
            colorTransaction
          )
        );
      } else {
        dispatch(getTotalTransaction([], 0, [], []));
      }
    } catch (e) {
      dispatch(getTotalTransaction([], 0, [], []));
      dispatch(errorGlobal(e));
      dispatch(DashboardError(e));
    }
  };
};

export const GetTotalIncome = ({
  params,
}): ThunkAction<void, RootState, null, DashboardAction | IError> => {
  return async (dispatch, getState) => {
    let sandbox = getState()?.auth?.userData['Env'];

    dispatch(DashboardPending());

    try {
      const objProvider: IProvider = {
        sandbox: sandbox,
        method: MethodProvider.GET,
        queryParams: params,
        path: endpoints.dashboardTotalIncome,
      };

      const response = await provider(objProvider);

      const res = {
        code: response?.status,
        message: 'get Data Success!',
        data: response?.data?.data,
      };

      if (res?.data?.length > 0) {
        let totalIncome: any = Total(res?.data);
        let sumIncome = Sum(totalIncome);
        let labelStatusIncome: Array<string> = LabelStatus(res?.data);
        let colorIncome: Array<string> = Color(res?.data);
        dispatch(
          getTotalIncome(totalIncome, sumIncome, labelStatusIncome, colorIncome)
        );
      } else {
        dispatch(getTotalIncome([], 0, [], []));
      }
    } catch (e) {
      dispatch(getTotalIncome([], 0, [], []));
      dispatch(errorGlobal(e));
      dispatch(DashboardError(e));
    }
  };
};

export const GetTransctionFrequency = ({
  params,
}): ThunkAction<void, RootState, null, DashboardAction | IError> => {
  return async (dispatch, getState) => {
    let sandbox = getState()?.auth?.userData['Env'];

    dispatch(DashboardPending());

    try {
      const objProvider: IProvider = {
        sandbox: sandbox,
        method: MethodProvider.GET,
        queryParams: params,
        path: endpoints.dashboardTotalTransactionFreq,
      };

      const response = await provider(objProvider);

      const res = {
        code: response?.status,
        message: 'get Data Success!',
        data: response?.data?.data,
      };

      if (res?.data?.length > 0) {
        let data = res?.data;
        // let data = dataTransaction;
        dispatch(getTransactionFreq(dataFilter(data), dataLabel(data)));
      } else {
        dispatch(getTransactionFreq([], []));
      }
    } catch (e) {
      dispatch(getTransactionFreq([], []));
      dispatch(errorGlobal(e));
      dispatch(DashboardError(e));
    }
  };
};

export const GetAmountFrequency = ({
  params,
}): ThunkAction<void, RootState, null, DashboardAction | IError> => {
  return async (dispatch, getState) => {
    let sandbox = getState()?.auth?.userData['Env'];

    dispatch(DashboardPending());

    try {
      const objProvider: IProvider = {
        sandbox: sandbox,
        method: MethodProvider.GET,
        queryParams: params,
        path: endpoints.dashboardTotalAmountByTime,
      };

      const response = await provider(objProvider);

      const res = {
        code: response?.status,
        message: 'get Data Success!',
        data: response?.data?.data,
      };

      if (res?.data?.length > 0) {
        let data = res?.data;
        // let data = dataAmount;
        dispatch(getIncomeFreq(dataFilter(data), dataLabel(data)));
      } else {
        dispatch(getIncomeFreq([], []));
      }
    } catch (e) {
      dispatch(getIncomeFreq([], []));
      dispatch(errorGlobal(e));
      dispatch(DashboardError(e));
    }
  };
};

export const GetTop10Product = ({
  params,
}): ThunkAction<void, RootState, null, DashboardAction | IError> => {
  return async (dispatch, getState) => {
    let sandbox = getState()?.auth?.userData['Env'];

    dispatch(DashboardPending());

    try {
      const objProvider: IProvider = {
        sandbox: sandbox,
        method: MethodProvider.GET,
        queryParams: params,
        path: endpoints.dashboardTop10Product,
      };

      const response = await provider(objProvider);

      const res = {
        code: response?.status,
        message: 'get Data Success!',
        data: response?.data?.data,
      };

      if (res?.data?.length > 0) {
        let labels: Array<string> = res?.data?.map((e) => e.product_brand);
        let JumlahTransaksi: Array<string> = res?.data?.map(
          (e) => e.jumlah_transaksi
        );
        dispatch(getFreqTop10Product(labels, JumlahTransaksi));
      } else {
        dispatch(getFreqTop10Product([], []));
      }
    } catch (e) {
      dispatch(getFreqTop10Product([], []));
      dispatch(errorGlobal(e));
      dispatch(DashboardError(e));
    }
  };
};

export const GetTopPaymentMethod = ({
  params,
}): ThunkAction<void, RootState, null, DashboardAction | IError> => {
  return async (dispatch, getState) => {
    let sandbox = getState()?.auth?.userData['Env'];

    dispatch(DashboardPending());

    try {
      const objProvider: IProvider = {
        sandbox: sandbox,
        method: MethodProvider.GET,
        queryParams: params,
        path: endpoints.dashboardTotalPm,
      };

      const response = await provider(objProvider);

      const res = {
        code: response?.status,
        message: 'get Data Success!',
        data: response?.data?.data,
      };

      if (res?.data?.length > 0) {
        let labels = res?.data?.map((e) => e.PaymentMethod);
        let JumlahTransaksi = res?.data?.map((e) => e.JumlahTransaksi);
        dispatch(JumlahFreqTransaksiPm(labels, JumlahTransaksi));
      } else {
        dispatch(JumlahFreqTransaksiPm([], []));
      }
    } catch (e) {
      dispatch(JumlahFreqTransaksiPm([], []));
      dispatch(errorGlobal(e));
      dispatch(DashboardError(e));
    }
  };
};

export const GetDataDashboardv2 = ({
  params,
}): ThunkAction<void, RootState, null, DashboardAction | IError> => {
  return async (dispatch, getState) => {
    let sandbox = getState()?.auth?.userData['Env'];

    dispatch(DashboardPending());

    try {
      const objProvider: IProvider = {
        sandbox: sandbox,
        method: MethodProvider.GET,
        queryParams: params,
        path: endpoints.dashboardv2,
      };

      const response = await provider(objProvider);

      const res = {
        code: response?.status,
        message: 'get Data Success!',
        data: response?.data?.data,
      };

      if (res.code === 200) {
        dispatch(setDataV2(res.data));
      }
    } catch (e) {
      dispatch(errorGlobal(e));
      dispatch(DashboardError(e));
    }
  };
};
