import { useContext } from 'react';
import { tableNameList } from './dataList';
import CardFilter from './filter';
import useReport from 'hooks/useTransaction';
import { TransactionContext } from 'context/transactionContext';
import BasicTable from 'components/table';
import Pagination from 'components/pagination';

const TableTransactionList = () => {
  const { TransactionList } = useReport();
  let { pagination, isLoading } = TransactionList;
  let {
    countPage,
    handleChangeCountPage,
    ChangePage,
    page,
    handleOpenDetail,
    header,
    handleSubmit,
    handleReset,
    params,
  } = useContext(TransactionContext);

  let dataTransactionList = TransactionList.transactions;

  return (
    <>
      <BasicTable
        FilterComponent={CardFilter}
        headerList={header.val}
        dataList={dataTransactionList}
        handleAction={handleOpenDetail}
        handleSubmitFilter={handleSubmit}
        handleResetFilter={handleReset}
        {...{ tableNameList, countPage, page, params, isLoading }}
      />
      <Pagination
        value={countPage}
        data={pagination}
        page={page}
        handleChange={handleChangeCountPage}
        handlePage={ChangePage}
      />
    </>
  );
};

export default TableTransactionList;
