export { default } from './Component';
export type Props = {
  text: { val: string; set: (val: string) => void };
  captchaId?: { val: string; set: (val: string) => void };
  isFail?: { val: boolean; set: (v: boolean) => void };
};

export function captchaForm(data: FormData, text: string) {
  if (text) data.append('VerifyValue', text);
}
