import React from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  RadioGroup,
  Select,
  TextField,
  ThemeProvider,
} from '@mui/material';
import { formLabelTheme } from 'components/muiTheme/theme';
import SectionQRIS from '../template';
import { QRoptions } from '..';
import { bdColor as bd, bgColor as bg } from 'constants/colors';
import { SingleDetailGrid } from 'components/grid/DetailGrid/Component';
import { Radio1 } from 'components/radio/Radio';
import { NumericFormat } from 'react-number-format';
import { getCookie, setCookie } from 'utils/cookie';
import { BasicButton } from 'components/Button';
import { icons } from 'assets';

type changes = { val: string; set: (val: string) => void };
type list_opt = { name: string; desc?: string };

type Props = {
  types: { val: string[]; set: (val: string[]) => void };
  category?: changes;
  tips?: changes;
  disabled?: boolean;
  nominals?: changes;
  preview?: boolean;
  preview_url?: string;
};
type option_props = {
  list: list_opt[];
  change?: changes;
  disabled?: boolean;
  title?: string;
  tipsOpt?: boolean;
  nominals?: changes;
};
type tips_props = {
  choice?: 'true' | 'false';
  nominal?: string;
  setNominal?: (v: string) => void;
};

const colors: Record<'active' | 'none' | 'disabled', string> = {
  active: `bg-[${bg.primary}] border-[${bd.primary}]`,
  none: `bg-none border-[${bd.disabled}]`,
  disabled: `bg-[#F5F5F5] border-[#DEDEDE]`,
};

const VariantTips: React.FC<tips_props> = (props) => {
  type opt = { value: string; label: string };
  const option: opt[] = [
    { value: '2', label: 'Input User' },
    { value: '3', label: 'Static' },
    { value: '4', label: 'Percentage' },
  ];

  const [selected, setSelected] = React.useState(getCookie('tips-qr') || '');
  const tipsText = (text: string) => {
    return (
      <div className="flex flex-col my-2">
        <b>{text}</b>
        <NumericFormat
          customInput={TextField}
          size="small"
          placeholder={`Masukkan ${text}`}
          value={props?.nominal}
          onChange={(e) => {
            let value = e.target.value;
            setCookie('tips-qr', selected, 0.5);
            props?.setNominal(value);
          }}
          sx={{ mt: 1, width: 400 }}
          allowLeadingZeros={false}
          allowNegative={false}
          decimalSeparator=","
          suffix={selected === '4' ? '%' : ''}
          isAllowed={(value) => {
            const { floatValue } = value;
            if (selected === '4') return floatValue <= 100;
            if (selected === '3') return floatValue <= 999999;
          }}
        />
      </div>
    );
  };
  const tips = () => {
    if (selected === '3') return tipsText('Nominal Tip');
    if (selected === '4') return tipsText('Percentage Tip');
    return null;
  };

  return (
    <>
      {props?.choice === 'true' ? (
        <>
          <Select
            size="small"
            onChange={(e) => {
              const { value } = e.target;
              setSelected(value);
              props?.setNominal('0');
            }}
            required
            value={selected}
            sx={{ fontSize: '14px', mt: 1, width: 400 }}
          >
            {option.map((list) => (
              <MenuItem key={list.value} value={list.value} id={list.label}>
                <div>{list.label}</div>
              </MenuItem>
            ))}
          </Select>
          {tips()}
        </>
      ) : null}
    </>
  );
};

const ListOption: React.FC<option_props> = (props) => {
  const { disabled: dsb } = colors;
  const isTips = getCookie('tips-qr') || '1';
  const tipsVal = getCookie('tips-qr') ? isTips : '';
  const tipsNum = tipsVal !== '' && tipsVal === '1' ? 'TIDAK' : 'YA';
  return (
    <SectionQRIS noTitle title={props?.title}>
      <p className="mb-2">{props?.title}</p>
      <SingleDetailGrid>
        <FormGroup className="w-full p-0 mb-5">
          <RadioGroup
            onChange={(e) =>
              props?.change?.set((e.target as HTMLInputElement).value)
            }
          >
            <Grid container spacing={2}>
              {props?.list?.map((c, cIdx) => (
                <Grid key={cIdx + c.name} item xs={12}>
                  <div
                    className={`border rounded-lg p-2 pl-5 pb-3 space-y-2 flex gap-2 
                    ${props?.disabled && dsb}`}
                    onClick={(e) => {
                      let res = (e.target as HTMLButtonElement).textContent;
                      // to make the changes when the title is clicked
                      if (res === c.name && res !== undefined) {
                        if (!props?.disabled) props?.change?.set(res);
                      }
                    }}
                  >
                    <Radio1
                      value={c.name}
                      checked={
                        // add a special condition for value tips which (1=TIDAK) || (2,3,4=YA)
                        c.name === ('YA' || 'TIDAK')
                          ? tipsVal === ''
                            ? props?.change?.val === c.name
                            : tipsNum === c.name
                          : props?.change?.val === c.name
                      }
                      sx={{ pl: 0 }}
                      disabled={props?.disabled}
                    />
                    <div className="flex flex-col">
                      <b
                        className={`text-lg ${
                          !props?.disabled && 'cursor-pointer'
                        }`}
                      >
                        {c.name}
                      </b>
                      <p className="pr-5">{c.desc}</p>
                      {props?.tipsOpt && !props?.disabled && (
                        <VariantTips
                          nominal={props?.nominals?.val}
                          setNominal={props?.nominals?.set}
                          choice={
                            (isTips || props?.change?.val === 'YA') &&
                            c.name === 'YA'
                              ? 'true'
                              : 'false'
                          }
                        />
                      )}
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </RadioGroup>
        </FormGroup>
      </SingleDetailGrid>
    </SectionQRIS>
  );
};

const JenisQR: React.FC<Props> = (props) => {
  const { types, disabled, category, tips, nominals, preview } = props;
  const url = window.location.href.split('/').pop();
  const { categoryQR, QRtip, listQR } = QRoptions;
  const { active, disabled: dsb, none } = colors;
  return (
    <>
      <SectionQRIS noTitle={url !== 'onboarding'} title="Informasi QR">
        <p className="my-2">Jenis QRIS</p>
        <SingleDetailGrid>
          <FormGroup className="w-full p-0 mb-5">
            <Grid container spacing={2}>
              {listQR.map((item, iIdx) => (
                <Grid key={iIdx + item.name} item xs={12} lg={6}>
                  <ThemeProvider theme={formLabelTheme}>
                    <div
                      className={`border rounded-lg p-2 pl-5 pb-3 space-y-2 ${
                        types?.val?.find((x) => x === item.name) ? active : none
                      } ${disabled && dsb}`}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={types?.val?.includes(item.name)}
                            value={item.name}
                            onChange={(e) => {
                              let v = e.target.value;
                              let updates = [...types?.val];
                              if (!updates.includes(v)) {
                                types?.set([...updates, v]);
                              } else {
                                types?.set(types?.val?.filter((x) => x !== v));
                              }
                            }}
                          />
                        }
                        color="success"
                        label={<b className="text-lg">{item.name}</b>}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          m: 0,
                        }}
                        labelPlacement="start"
                        disabled={disabled}
                      />
                      <p className="pr-5">{item.desc}</p>
                    </div>
                  </ThemeProvider>
                </Grid>
              ))}
            </Grid>
          </FormGroup>
        </SingleDetailGrid>
      </SectionQRIS>
      <ListOption
        list={categoryQR}
        change={category}
        {...{ disabled }}
        title="Kategori QR :"
      />
      <ListOption
        list={QRtip}
        change={tips}
        {...{ disabled, nominals }}
        title="Terima Tip Transaksi :"
        tipsOpt
      />
      {!preview ? null : (
        <SingleDetailGrid>
          <p className="pb-2">QRIS Preview</p>
          <div className="bg-[#EEEEEE] py-8 flex justify-center">
            <img src={props?.preview_url} alt="preview-img" />
          </div>
          <div className="bg-white flex justify-end">
            <BasicButton
              startIcon={<img src={icons.unduh} alt="ic" />}
              className="px-2"
              variant="text"
              onClick={() => {
                const dl = document.createElement('a');
                dl.href = props.preview_url;
                dl.download = 'qris_preview';
                dl.click();
              }}
            >
              Download QR
            </BasicButton>
          </div>
        </SingleDetailGrid>
      )}
    </>
  );
};

export default JenisQR;
