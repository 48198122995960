export const onlyNumbers = (e: React.ChangeEvent<HTMLInputElement>) => {
  e.target.value = e.target.value.replace(/[^0-9]/g, '');
};
export const onlyAlphanumeric = (e: React.ChangeEvent<HTMLInputElement>) => {
  e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
};

export const limitInput = (
  val: string,
  setVal: (v: string) => void,
  limit: number
) => {
  if (val.length <= limit) setVal(val);
};
