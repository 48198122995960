import { Skeleton } from '@mui/material';

const WithdrawalInformation = ({ name, value, withdrawal }) => {
  return (
    <div className="flex w-12/12 text-[12px] lg:text-sm mt-1">
      <div className="w-[55%] lg:w-[45%] font-bold">{name}</div>
      <div
        className={`${
          (value === 'Success' && 'font-bold text-[#18AD65]') ||
          (value === 'Process' && 'font-bold  text-[#FBB12F]') ||
          (value === 'Failed' && 'font-bold  text-[#F04545]')
        }  w-[45%] lg:w-[55%]`}
      >
        {withdrawal.isLoading === true ? (
          <Skeleton variant="text" sx={{ width: 100, fontSize: '1rem' }} />
        ) : (
          value || '-'
        )}
      </div>
    </div>
  );
};

export default WithdrawalInformation;
