import React from 'react';
import { Dialog, ThemeProvider } from '@mui/material';
import { btnTheme } from 'components/muiTheme';
import { BasicButton } from 'components/Button';
import { Props } from '.';
import { circle } from 'assets';

type list_icon = 'success' | 'warning' | 'question';
const icons: Record<list_icon, string> = {
  success: circle.oSuccess,
  warning: circle.oWarning,
  question: circle.oQuestion,
};
const Component: React.FC<Props> = (props) => {
  const variants = () => {
    if (props.type === 'single') {
      return (
        <BasicButton
          fullWidth
          variant={props.btn.variant || 'contained'}
          onClick={props.btn.onClick}
          color={props.btn.color}
        >
          {props.btn.text}
        </BasicButton>
      );
    } else if (props.type === 'double') {
      return (
        <div className="flex gap-4 justify-center">
          <BasicButton
            fullWidth
            variant={props.leftbtn.variant || 'contained'}
            onClick={props.leftbtn.onClick}
            color={props.leftbtn.color}
            disabled={props.leftbtn?.disabled}
          >
            {props.leftbtn.text}
          </BasicButton>
          <BasicButton
            fullWidth
            variant={props.rightbtn.variant || 'contained'}
            onClick={props.rightbtn.onClick}
            color={props.rightbtn.color}
            disabled={props.rightbtn?.disabled}
          >
            {props.rightbtn.text}
          </BasicButton>
        </div>
      );
    }
  };

  return (
    <ThemeProvider theme={btnTheme}>
      <Dialog open={props.open} {...props} disablePortal>
        <div
          className={`p-5 md:p-10 text-center space-y-3 md:w-[${
            props.width || '400'
          }px]`}
        >
          <img
            src={icons[props?.icon || 'error']}
            alt="iconz"
            className="mx-auto"
          />
          <p className="font-bold text-lg">{props.text}</p>
          <p className="px-5">{props.subtext}</p>
          {variants()}
        </div>
      </Dialog>
    </ThemeProvider>
  );
};
export default Component;
