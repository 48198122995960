import React from 'react';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineContent,
  timelineItemClasses,
} from '@mui/lab';
import { icons } from 'assets';
import SectionCard from 'components/cardComponents/sectionCard';
import { ITimelineProps } from './interface';

const StatusTimeline: React.FC<ITimelineProps> = ({ timeline }) => {
  const handleStatus = (code: string): string => {
    const statusMap = {
      withdrawable: 'Payment Withdrawable',
      hold: 'Payment Hold',
      withdrawn: 'Payment Withdrawn',
      refunded: 'Payment Refunded',
      refund_process: 'Refund Process',
      refund_success: 'Refund Success',
      refund_failed: 'Refund Failed',
      process: 'Payment Process',
      succeed: 'Payment Paid',
      create_order: 'Payment Created',
      failed: 'Payment Failed',
      expired: 'Payment Expired',
      insufficient_payment: 'Insufficient Payment',
      over_payment: 'Over Payment',
    };
    const codeLower = code.toLowerCase();
    for (const key in statusMap) {
      if (codeLower.includes(key)) {
        return statusMap[key];
      }
    }
    return '';
  };

  return (
    <SectionCard title="Status Timeline">
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
            marginLeft: '-15px',
            transform: 'rotate(90deg)',
          },
        }}
        position="right"
      >
        {timeline.map((e, i) => (
          <TimelineItem key={i}>
            <TimelineSeparator>
              <TimelineDot
                sx={{
                  backgroundColor: '#C9F3E4',
                  width: 30,
                  height: 30,
                  boxShadow: 'none',
                  display: 'flex',
                  padding: '5px 0px 0 5px',
                }}
              >
                <div className="bg-[#21AF7D] rounded-full h-[15px] w-[15px]"></div>
              </TimelineDot>
              {i !== timeline.length - 1 && (
                <img src={icons.line} className="h-[25px]" alt="as" />
              )}
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <div className="font-bold text-[15px]">{`${handleStatus(
                e.code
              )}`}</div>
              <div className="text-sm text-[#222222]">{e.date}</div>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </SectionCard>
  );
};

export default StatusTimeline;
