import { createTheme } from '@mui/material';

const font = createTheme({
  typography: { allVariants: { fontFamily: 'Manrope' } },
});
export const colorTheme = createTheme({
  palette: {
    primary: {
      main: '#21AF7D',
      darker: '#053e85',
      contrastText: '#FFFFFF',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
    success: { main: '#21AF7D', contrastText: '#FFFFFF' },
    error: { main: '#EE4E2B', contrastText: '#FFFFFF' },
    secondary: {
      main: '#231F20',
      darker: '#053e85',
      contrastText: '#21AF7D',
    },
    info: {
      main: '#94A3B8',
      darker: '#CBD5E1',
      contrastText: '#0971f1',
    },
  },
});

export const btnTheme = createTheme({
  ...colorTheme,
  typography: {
    fontFamily: 'Manrope',
    allVariants: {
      textTransform: 'capitalize',
      fontWeight: 'bold',
      fontSize: 16,
    },
  },
  components: {
    MuiButton: {
      // styleOverrides: { outlined: { border: '2px solid' } },
    },
  },
});

export const checkboxTheme = createTheme({
  palette: {
    primary: { main: '#21AF7D' },
  },
});

export const paginationTheme = createTheme({
  components: {
    MuiPagination: {
      styleOverrides: {
        root: {
          '& .MuiPaginationItem-root': {
            '&.Mui-selected': {
              background: '#18AD65',
              color: 'white',
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: { color: '#18AD65' },
      },
    },
  },
});

export const textFieldTheme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiTextField-root': {
            '&.Mui-focused': {
              color: '#18AD65',
            },
          },
        },
      },
    },
  },
});

export const formControlTheme = createTheme({ ...font, ...colorTheme });
