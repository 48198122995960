import React from 'react';
import { tableNameWdDetail } from './dataList';
import useWithdrawal from 'hooks/useWithdrawal';
import { WithdrawalContext } from 'context/withdrawalContext';

import BasicTable from 'components/table';
import Pagination from 'components/pagination';

const TableWdTransactionList = () => {
  const { withdrawal } = useWithdrawal();
  let { data } = withdrawal.withdrawal_detail;

  let {
    pageDetail,
    countPageDetail,
    headerDetail,
    ChangePaginationWdDetail,
    ChangePageWdDetail,
  } = React.useContext(WithdrawalContext);

  let { pagination } = withdrawal.withdrawal_detail;

  return (
    <div>
      <BasicTable
        FilterComponent={undefined}
        headerList={headerDetail.val}
        dataList={data}
        tableNameList={tableNameWdDetail}
        countPage={countPageDetail}
        page={pageDetail}
        isLoading={withdrawal.isLoading}
      />

      <Pagination
        value={countPageDetail}
        page={pageDetail}
        data={pagination}
        handleChange={ChangePaginationWdDetail}
        handlePage={ChangePageWdDetail}
      />
    </div>
  );
};

export default TableWdTransactionList;
