import { Skeleton } from '@mui/material';
import Card from 'components/cardComponents';
import useWithdrawal from 'hooks/useWithdrawal';
import { NumericFormat } from 'react-number-format';

const FieldSettlementSummary = ({
  name,
  value,
  minus,
  bold,
  isLoading,
}: {
  name: string;
  value: number;
  minus?: boolean;
  bold?: boolean;
  isLoading: boolean;
}) => {
  return (
    <>
      <div className="flex w-12/12 text-[12px] lg:text-sm mt-1">
        <div className="w-[65%] lg:w-[70%] font-bold">{name}</div>
        <div className={`text-end w-60 `}>
          {isLoading === true ? (
            <Skeleton variant="text" sx={{ width: 100, fontSize: '1rem' }} />
          ) : (
            <>
              <NumericFormat
                value={value}
                className={`${minus && 'text-[#F04545]'} ${
                  bold && 'font-bold'
                }`}
                displayType={'text'}
                thousandSeparator={'.'}
                decimalSeparator={','}
                allowNegative
                prefix={'Rp. '}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

const SettlementSummary = () => {
  const { withdrawal } = useWithdrawal();

  let { isLoading } = withdrawal;

  let { settlement_summary } = withdrawal.withdrawal_detail;

  return (
    <>
      <Card>
        <div className="py-5 px-8">
          <div className="text-[16px] mb-5 mt-2 font-bold">
            Settlement Summary
          </div>
          <div>
            <FieldSettlementSummary
              name="Total Initial Amount"
              value={settlement_summary?.total_amount_initial}
              {...{ isLoading }}
            />
            <FieldSettlementSummary
              name="Total Trx Amount"
              value={settlement_summary?.total_amount_trx}
              {...{ isLoading }}
            />
            <FieldSettlementSummary
              name="Total MDR Fee"
              value={-settlement_summary?.total_fee_mdr}
              minus
              {...{ isLoading }}
            />
            <FieldSettlementSummary
              name="Total Admin Fee"
              value={-settlement_summary?.total_fee_admin}
              minus
              {...{ isLoading }}
            />
            <FieldSettlementSummary
              name="Total Settled Fee"
              value={-settlement_summary?.total_fee_settled}
              minus
              {...{ isLoading }}
            />
            <div className="pb-2 pt-1 px-3 mt-4 rounded-md bg-[#F5F5F5]">
              <FieldSettlementSummary
                name="Settlement Amount"
                value={settlement_summary?.amount_settlement}
                bold
                {...{ isLoading }}
              />
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default SettlementSummary;
