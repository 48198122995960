import { FormControlLabel } from "@mui/material";
import { IOSSwitch2 } from "components/muiTheme/muiStyled";

const SwitchWithLabel = ({
  checked,
  value,
  disabled,
  onChange,
  name,
  label,
}) => {
  return (
    <>
      <div className="flex text-[#231F20] font-medium w-full lg:w-[80%] mb-3 items-center">
        <div className="w-full lg:w-[38%] font-bold text-sm lg:text-lg">
          {label}
        </div>
        <FormControlLabel
          className="lg:w-[74%]"
          control={
            <IOSSwitch2
              sx={{ m: 1 }}
              name={name}
              checked={checked}
              value={value}
              disabled={disabled}
              onChange={onChange}
            />
          }
          label=""
        />
      </div>
    </>
  );
};

export default SwitchWithLabel;
