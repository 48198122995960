import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'stores';
import { IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { BasicButton } from 'components/Button';
import { SingleDetailGrid } from 'components/grid/DetailGrid/Component';
import {
  getMerchantPreview,
  setformQRIS,
  submitRegisterQRIS,
} from 'stores/actions/onboardingAction';
import { getCookie, setCookie } from 'utils/cookie';
import { isFullVersion } from 'utils/exception';

import useFormQRIS, { useRegisterFormQRIS } from 'hooks/useFormQRIS';
import AlamatMerchant from 'pages/Onboarding/QRIS/section/alamatMerchant';
import DaftarTerminal from 'pages/Onboarding/QRIS/section/daftarTerminal';
import InfoMerchant from 'pages/Onboarding/QRIS/section/infoMerchant';
import InfoUsaha from 'pages/Onboarding/QRIS/section/infoUsaha';
import JenisQR from 'pages/Onboarding/QRIS/section/jenisQR';
import KelengkapanDocs from 'pages/Onboarding/QRIS/section/kelengkapanDokumen';
import TermsQRIS from 'pages/Onboarding/QRIS/section/termsQRIS';

const text = ['Static', 'Dynamic'];
export function changedQRvalue(v: string) {
  if (v === '1') return text[0];
  if (v === '2') return text[1];
  if (v === '3') return text.join(', ');
  else return '';
}
export function changeValueQR(v = text) {
  if (v === null) return '';
  if (v.length === 2) return '3';
  if (v.find((x) => x === text[1])) return '2';
  else return '1';
}

const Component: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { profile, userData } = useSelector((a: RootState) => a.auth);
  const { merchantPreview, QRIS } = useSelector((s: RootState) => s.onboarding);
  const { data } = merchantPreview;

  const [qrType, setQRType] = useState<string[]>(
    QRIS !== null ? changedQRvalue(QRIS?.JenisQR)?.split(', ') : []
  );
  // const hasTips = getCookie('tips-qr') ? getCookie('tips-qr') : '1';
  // const [qrTips, setQrTips] = useState(getCookie('tips-qr') ? hasTips : '');
  const [qrCategory, setQrCategory] = useState(QRIS?.KategoriQR || '');
  const [qrTips, setQrTips] = useState(QRIS?.TipeTips || '');
  const [nominal, setNominal] = useState(QRIS?.NominalTips);
  const [usaha, setUsaha] = useState(data?.business_type?.toString());
  const [badan, setBadan] = useState(QRIS?.JenisBadanUsaha || '');
  const [category, setCategory] = useState(QRIS?.KategoriMerchant || '');
  const [criteria, setCriteria] = useState(QRIS?.KriteriaMerchant || '');
  const [identity, setIdentity] = useState(QRIS?.JenisIdentitas || '');
  const [ktpFile, setKtpFile] = useState(QRIS?.FileKTP || null);
  const [ktpPhoto, setKtpPhoto] = useState(QRIS?.PhotoKTP || '');
  const [skuFile, setSKUFile] = useState<File | null>(QRIS?.FileSKU || null);
  const [skuPhoto, setSKUPhoto] = useState(QRIS?.PhotoSKU || '');
  const [akteFile, setAkteFile] = useState<File | null>(QRIS?.FileAkta || null);
  const [aktePhoto, setAktePhoto] = useState(QRIS?.PhotoAkta || '');
  const [skaFile, setSKAFile] = useState<File | null>(QRIS?.FileSKA || null);
  const [skaPhoto, setSKAPhoto] = useState(QRIS?.PhotoSKA || '');
  const [isTerms, setIsTerms] = useState([false, false]);
  const renderOne = React.useRef(true);

  useEffect(() => {
    if (renderOne.current) {
      if (userData?.PackageType !== null) dispatch(getMerchantPreview());
      renderOne.current = false;
    }
  }, [dispatch, userData?.PackageType]);

  const { formik, terminals, valid, refs } = useFormQRIS();
  let { values } = formik;

  useEffect(() => {
    if (data?.business_type !== 0) setUsaha(data?.business_type?.toString());
    // if (data?.ktp_url !== '') setKtpPhoto(data.ktp_url);
    if (data?.npwp_number !== '') {
      formik.setValues({
        ...formik.values,
        NamaPemilik: profile?.Username,
        NoTelp: data?.own_phone,
        NoNPWP: data?.npwp_number,
        NomorRekening: data?.bank_acc_no,
        NamaMerchant: profile?.Fullname,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const qrisForm = useRegisterFormQRIS(); // qris form data
  const xSave = () => {
    let tipsVal = getCookie('tips-qr') || '1';
    let TypeQR = changeValueQR(qrType);
    let otherValues = {
      JenisQR: TypeQR,
      KategoriQR: qrCategory,
      JenisUsaha: usaha,
      JenisIdentitas: identity,
      KriteriaMerchant: criteria,
      KategoriMerchant: category,
      Terminals: terminals?.val,
      PhotoKTP: ktpPhoto,
      FileKTP: ktpFile,
      PhotoSKU: skuPhoto,
      FileSKU: skuFile,
      PhotoAkta: aktePhoto,
      FileAkta: akteFile,
      NominalTips: nominal,
      TipeTips: tipsVal,
    };
    if (usaha !== '1') {
      Object.assign(otherValues, { JenisBadanUsaha: badan });
    }
    if (skaPhoto !== null) {
      Object.assign(otherValues, { PhotoSKA: skaPhoto, FileSKA: skaFile });
    }
    let result = { ...values, ...otherValues };

    const fieldMerchant =
      values.NomorRekening === '' ||
      identity === '' ||
      values.NoIdentitas === '' ||
      values.NoNPWP === '' ||
      values.NoTelp === '' ||
      criteria === '' ||
      category === '';
    const fieldDocs =
      ktpPhoto === null || aktePhoto === null || skuPhoto === null;

    if (qrType?.length === 0 || (usaha === '2' ? badan === '' : null)) {
      refs?.ref1?.current?.scrollIntoView({ behavior: 'smooth' });
      console.error('masih ada yg kosong diatas');
    } else if (fieldMerchant) {
      refs?.ref2?.current?.scrollIntoView({ behavior: 'smooth' });
      console.error('info merchant masih ada yg kosong');
    } else if (valid.address) {
      refs?.ref3?.current?.scrollIntoView({ behavior: 'smooth' });
      console.error('info alamat masih ada yg kosong');
    } else if (terminals.val?.length === 0 || valid.terminal.length !== 0) {
      refs?.ref4?.current?.scrollIntoView({ behavior: 'smooth' });
      console.error('info terminal masih ada yg kosong');
    } else if (fieldDocs) {
      refs?.ref5?.current?.scrollIntoView({ behavior: 'smooth' });
      console.error('info dokumen masih ada yg kosong');
    } else {
      const data = { isEdit: false };
      dispatch(setformQRIS(result));
      setCookie('FQR', JSON.stringify(data), 1); // Form QR

      if (profile?.MerchantStatus?.includes('review')) {
        // if the merchant current status is already on progress ticketing
        dispatch(submitRegisterQRIS(qrisForm));
      } else {
        navigate(`${isFullVersion ? '/full/' : '/'}setting/Merchant-Preview`);
      }
    }
  };

  return (
    <div className="mt-24">
      <div className="flex gap-2 mb-4">
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButton>
        <b className="text-[32px]">Formulir Pendaftaran QRIS</b>
      </div>
      <div className="bg-white p-8" ref={refs?.ref1}>
        <b className="text-[22px] mb-3">Informasi QR</b>
        <JenisQR
          types={{ val: qrType, set: setQRType }}
          category={{ val: qrCategory, set: setQrCategory }}
          tips={{ val: qrTips, set: setQrTips }}
          nominals={{ val: nominal, set: setNominal }}
        />
        <hr className="pt-4" ref={refs?.ref2} />
        <InfoUsaha
          usaha={{ val: usaha, set: setUsaha }}
          badan={{ val: badan, set: setBadan }}
        />
        <InfoMerchant
          category={{ val: category, set: setCategory }}
          criteria={{ val: criteria, set: setCriteria }}
          identity={{ val: identity, set: setIdentity }}
          formik={formik}
        />
        <div ref={refs?.ref3} />
        <AlamatMerchant formik={formik} />
        <hr ref={refs?.ref4} />
        <DaftarTerminal terminals={terminals} />
        <hr ref={refs?.ref5} />
        <KelengkapanDocs
          ktpFile={{ val: ktpFile, set: setKtpFile }}
          ktpPhoto={{ val: ktpPhoto, set: setKtpPhoto }}
          skuFile={{ val: skuFile, set: setSKUFile }}
          skuPhoto={{ val: skuPhoto, set: setSKUPhoto }}
          akteFile={{ val: akteFile, set: setAkteFile }}
          aktePhoto={{ val: aktePhoto, set: setAktePhoto }}
          skaFile={{ val: skaFile, set: setSKAFile }}
          skaPhoto={{ val: skaPhoto, set: setSKAPhoto }}
        />
        <hr />
        <TermsQRIS val={isTerms} set={setIsTerms} />
        <SingleDetailGrid>
          <BasicButton
            fullWidth
            disabled={!isTerms[0] || !isTerms[1]}
            onClick={xSave}
          >
            Simpan
          </BasicButton>
        </SingleDetailGrid>
      </div>
    </div>
  );
};

export default Component;
