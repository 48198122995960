import React, { MouseEventHandler, ReactNode } from 'react';
import { ThemeProvider } from '@emotion/react';
import { LoadingButton } from '@mui/lab';

interface IButtonWithIconProps {
  startIcon?: string;
  endIcon?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  children?: ReactNode;
  variant?: 'contained' | 'outlined' | 'text'; // Variasi tombol
  color?:
    | 'warning'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'; // Warna tombol
  btnTheme: any;
  Icon?: any;
  width?: any;
  disabled?: boolean;
  isLoading?: boolean;
}

const Component: React.FC<IButtonWithIconProps> = ({
  startIcon,
  endIcon,
  onClick,
  children,
  variant,
  color,
  btnTheme,
  Icon,
  width,
  isLoading,
  disabled,
}) => {
  return (
    <ThemeProvider theme={btnTheme}>
      <LoadingButton
        disabled={disabled ? disabled : false}
        loading={isLoading ? isLoading : false}
        variant={variant || 'contained'} // Variasi tombol (contained, outlined, dll.)
        color={color || 'primary'} // Warna tombol (primary, secondary, dll.)
        startIcon={
          Icon ||
          (startIcon && !isLoading && (
            <img
              src={startIcon}
              alt=''
            />
          ))
        } // Ikon yang akan ditampilkan sebelum teks tombol (opsional)
        endIcon={
          endIcon &&
          !isLoading && (
            <img
              src={endIcon}
              alt=''
            />
          )
        } // Ikon yang akan ditampilkan setelah teks tombol (opsional)
        onClick={onClick} // Handler fungsi yang akan dijalankan saat tombol diklik (opsional)
        sx={{ width: width }}
      >
        {children}
      </LoadingButton>
    </ThemeProvider>
  );
};

export default Component;
