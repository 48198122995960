/* eslint-disable no-console */
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
// import { generateRefreshToken } from 'stores/actions/auth';
import { refreshToken } from 'stores/actions/authActions';
import { intercept2FA } from 'stores/actions/auth2faActions';
import { getAuthToken } from 'utils/token';

const token = getAuthToken();

const headers = {
  'Content-type': 'application/json',
  Accept: 'application/json',
};

if (token) {
  Object.assign(headers, { Authorization: `Bearer ${token}` });
}

const instances = axios.create({
  timeout: 1000000,
  headers,
});

function onFulFilledRequest(conf: AxiosRequestConfig) {
  return conf;
}

function onRejectedRequest(err: any): Promise<any> {
  return Promise.reject(err);
}

function onFulFilledResponse(res: any): Promise<any> {
  return Promise.resolve(res);
}

function onRejectedResponse(err: AxiosError) {
  const { config, status } = err.response;

  if (status === 401 && config?.headers?.Authorization) {
    try {
      // generateRefreshToken();
      refreshToken();
    } catch (error) {
      console.log(error);
    }

    return Promise.reject(err);
  }

  intercept2FA(err); // handle interception 2FA errors

  return Promise.reject(err);
}

instances.interceptors.request.use(onFulFilledRequest, onRejectedRequest);
instances.interceptors.response.use(onFulFilledResponse, onRejectedResponse);

export default instances;
