import { emailRegex, phoneRegex, urlRegex } from 'components/configs/regex';

export const noUndef = (value: string | number) => {
  return value !== undefined ? value?.toString() : '';
};

export const checkFull = () => {
  const params = window.location.href;
  const isFull = params.includes('full');
  if (isFull) return true;
  else return false;
};

export const getFileNameUrl = (value: string) => {
  const removeMark = value.split('?');
  const result = removeMark[0].split('/').pop();
  return result;
};

let url = window.location.href;
export const isFullVersion = url.includes('full');
export const routePath = isFullVersion ? '/full/' : '/';

export const errorCase = (
  type: 'url' | 'phone' | 'email' | 'text',
  value: string,
  limit?: number
) => {
  switch (type) {
    case 'phone':
      return (
        (value.length !== 0 && !phoneRegex.test(value)) ||
        (value.length !== 0 && (value.length < 6 || value.length > 20))
      );
    case 'url':
      return (value.length !== 0 && !urlRegex.test(value)) || value.length > 30;
    case 'email':
      return (
        (value.length !== 0 && !emailRegex.test(value)) || value.length > 50
      );
    case 'text':
      return value.length > limit;

    default:
      return false;
  }
};
