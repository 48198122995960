import React from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  ThemeProvider,
} from '@mui/material';
import SectionQRIS from '../template';
import { colorTheme } from 'components/muiTheme/theme';
import { SingleDetailGrid } from 'components/grid/DetailGrid/Component';

const termsQRIS = [
  'Dengan ini saya menyatakan bahwa semua data dan informasi yang kami berikan adalah benar.',
  'Dengan ini saya menyatakan setuju dan bersedia menaati ketentuan yang berkaitan dengan Merchant QRIS dan ketentuan Bank Indonesia yang mengatur tentang QRIS',
];

type Props = { val: boolean[]; set: (val: boolean[]) => void };
const TermsQRIS: React.FC<Props> = (props) => {
  const termChange = (idx: number) => {
    const updated = [...props.val];
    updated[idx] = !props.val[idx];
    props.set(updated);
  };
  return (
    <SectionQRIS noTitle={true}>
      <SingleDetailGrid>
        <div className="mb-5">
          {termsQRIS.map((term, termIdx) => (
            <FormGroup key={term.slice(20, 35)}>
              <ThemeProvider theme={colorTheme}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={props.val[termIdx]}
                      onChange={() => termChange(termIdx)}
                    />
                  }
                  label={term}
                />
              </ThemeProvider>
            </FormGroup>
          ))}
        </div>
      </SingleDetailGrid>
    </SectionQRIS>
  );
};

export default TermsQRIS;
