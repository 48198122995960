import { ProtectedRoute } from "components/templates";
import React, { lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { getLocalItem } from "utils/localStorage";
import MainLayout from "./components/layout/MainLayout";
import MainLayoutMobile from "./components/layout/MainLayoutMobile";
import { routes } from "./routes";
import { routes as router2 } from "./routes/indexfull";

import UnProtectedRoute from "components/templates/unProtectedRoute";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { HelmetProvider } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import { isFullVersion } from "utils/exception";
import Notification from "./components/notifications";
import NotificationDetail from "./components/notifications/detail";

const Login = lazy(() => import("pages/Auth/Login"));
const Register = lazy(() => import("pages/Auth/Register"));
const RegisterPassword = lazy(() => import("pages/Auth/RegisterPassword"));
const ForgotPassword = lazy(() => import("pages/Auth/ForgotPassword"));
const ValidatePassword = lazy(() => import("pages/Auth/ValidatePassword"));
const Policy = lazy(() => import("pages/PrivacyPolicy"));
const PayoutPolicy = lazy(() => import("pages/PayoutPrivacyPolicy"));
const Onboarding = lazy(() => import("pages/Onboarding/Onboarding"));
const Berhasil = lazy(() => import("pages/Onboarding/Berhasil"));
const EmailSuccess = lazy(() => import("pages/Auth/EmailSuccess"));
const LinkFailed = lazy(() => import("pages/Auth/LinkFailed"));
const RegisterBI = lazy(() => import("pages/Auth/RegisterBI"));
const RegisterBIPayout = lazy(() => import("pages/Auth/RegisterBIPayout"));
const RedirectLink = lazy(() => import("containers/RedirectLink"));
const LoginOTP = lazy(() => import("pages/Auth/LoginOTP"));

const NewOnboarding = lazy(() => import("pages/Onboarding2"));
const NewDoneBoard = lazy(() => import("pages/Onboarding2/Berhasil"));

const routePath = isFullVersion ? "/full/" : "/";

function App() {
  const token = getLocalItem("token-web-merchant");

  const isSmallScreen = window.matchMedia("(max-width: 767px)").matches;

  return (
    <BrowserRouter>
      <HelmetProvider>
        <React.Suspense fallback={<p>Loading...</p>}>
          <Routes>
            <Route
              path={`${routePath}login`}
              element={
                <UnProtectedRoute isAllowed={!!token}>
                  <GoogleReCaptchaProvider
                    reCaptchaKey={process.env.REACT_APP_SITE_KEY}
                  >
                    <Login />
                  </GoogleReCaptchaProvider>
                </UnProtectedRoute>
              }
            />
            <Route
              path={`${routePath}login-otp`}
              element={
                <UnProtectedRoute isAllowed={!!token}>
                  <LoginOTP />
                </UnProtectedRoute>
              }
            />
            <Route
              path={`${routePath}register`}
              element={
                <UnProtectedRoute isAllowed={!!token}>
                  <GoogleReCaptchaProvider
                    reCaptchaKey={process.env.REACT_APP_SITE_KEY}
                  >
                    {isFullVersion ? <Register /> : <RegisterBI />}
                  </GoogleReCaptchaProvider>
                </UnProtectedRoute>
              }
            />
            <Route
              path={`${routePath}payoutreg`}
              element={
                <UnProtectedRoute isAllowed={!!token}>
                  <GoogleReCaptchaProvider
                    reCaptchaKey={process.env.REACT_APP_SITE_KEY}
                  >
                    {isFullVersion ? <Register /> : <RegisterBIPayout />}
                  </GoogleReCaptchaProvider>
                </UnProtectedRoute>
              }
            />
            <Route
              path={`${routePath}register-password`}
              element={
                <UnProtectedRoute isAllowed={!!token}>
                  <RegisterPassword />
                </UnProtectedRoute>
              }
            />
            <Route
              path={`${routePath}forgot-password`}
              element={
                <UnProtectedRoute isAllowed={!!token}>
                  <GoogleReCaptchaProvider
                    reCaptchaKey={process.env.REACT_APP_SITE_KEY}
                  >
                    <ForgotPassword />
                  </GoogleReCaptchaProvider>
                </UnProtectedRoute>
              }
            />
            <Route
              path={`${routePath}email/validate`}
              element={
                <UnProtectedRoute isAllowed={!!token}>
                  <ValidatePassword />
                </UnProtectedRoute>
              }
            />
            <Route
              path={`${routePath}privacy-policy-&-terms-condition`}
              element={<Policy />}
            />
            <Route
              path={`${routePath}privacy-policy-&-terms-condition/payout`}
              element={<PayoutPolicy />}
            />
            <Route
              path={`${routePath}register-success`}
              element={
                <UnProtectedRoute isAllowed={!!token}>
                  <EmailSuccess mode="register" />
                </UnProtectedRoute>
              }
            />
            <Route
              path={`${routePath}forgot-success`}
              element={
                <UnProtectedRoute isAllowed={!!token}>
                  <EmailSuccess mode="forgot" />
                </UnProtectedRoute>
              }
            />
            <Route
              path={`${routePath}resend-email`}
              element={
                <UnProtectedRoute isAllowed={!!token}>
                  <EmailSuccess mode="resend" />
                </UnProtectedRoute>
              }
            />
            <Route
              path={`${routePath}email/failed`}
              element={
                <UnProtectedRoute isAllowed={!!token}>
                  <LinkFailed mode="used" />
                </UnProtectedRoute>
              }
            />
            <Route
              path={`${routePath}email/expired`}
              element={
                <UnProtectedRoute isAllowed={!!token}>
                  <LinkFailed mode="expired" />
                </UnProtectedRoute>
              }
            />
            <Route
              path={`${routePath}`}
              element={
                <ProtectedRoute isAllowed={!!token}>
                  {isSmallScreen ? <MainLayoutMobile /> : <MainLayout />}
                </ProtectedRoute>
              }
            >
              {isFullVersion ? router2 : routes}
              <Route
                path={`${routePath}notifications`}
                element={<Notification />}
              />
              <Route
                path={`${routePath}notifications/:id`}
                element={<NotificationDetail />}
              />
            </Route>
            <Route
              path={`${routePath}onboarding`}
              element={
                <ProtectedRoute isAllowed={!!token}>
                  <Onboarding />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${routePath}berhasil-bergabung`}
              element={
                <ProtectedRoute isAllowed={!!token}>
                  <Berhasil />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${routePath}onboarding/payout`}
              element={
                <ProtectedRoute isAllowed={!!token}>
                  <NewOnboarding />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${routePath}berhasil-bergabung/payout`}
              element={
                <ProtectedRoute isAllowed={!!token}>
                  <NewDoneBoard />
                </ProtectedRoute>
              }
            />

            <Route path="auth" element={<RedirectLink />} />
            <Route path="*" element={<p>There's nothing here: 404!</p>} />
          </Routes>
        </React.Suspense>
      </HelmetProvider>
      <ToastContainer position="top-right" />
    </BrowserRouter>
  );
}

export default App;
