import { useFormik, FormikValues, FormikHelpers, FormikConfig } from 'formik';

function useCustomFormik<T extends FormikValues>(
  initialValues: T,
  validationSchema?: FormikConfig<T>['validationSchema'],
  onSubmit?: (
    values: T,
    formikHelpers?: FormikHelpers<T>
  ) => void | Promise<void>
) {
  const formikBag = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  // You can add any additional customization logic here
  return formikBag;
}
export default useCustomFormik;
