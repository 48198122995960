import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Card from '@mui/material/Card';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Import the plugin
import { useRef } from 'react';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels // Register the plugin
);

const BarChart = ({ text, dataChart }) => {
  const chartRef = useRef(null);

  const handleMouseOver = (event) => {
    const chart = chartRef.current;
    const points = chart.getElementsAtEventForMode(
      event,
      'nearest',
      { intersect: true },
      false
    );
    if (points.length) {
      event.native.target.style.cursor = 'pointer';
    } else {
      event.native.target.style.cursor = 'default';
    }
  };

  let dataChartNoData = {
    labels: [],
    datasets: [
      {
        data: [],
      },
    ],
  };

  const isValidDataFormat = (data) => {
    return (
      data &&
      Array.isArray(data.labels) &&
      Array.isArray(data.datasets) &&
      data.datasets.length === 1 &&
      Array.isArray(data.datasets[0].data) &&
      Array.isArray(data.datasets[0].rupiah) &&
      Array.isArray(data.datasets[0].backgroundColor)
    );
  };

  const checkDataFormat = (dataformapi) => {
    if (!isValidDataFormat(dataformapi)) {
      return dataChartNoData;
    }
    return dataformapi;
  };

  const options = {
    indexAxis: 'y' as const,
    elements: {
      bar: {
        borderWidth: 0,
        borderRadius: 10,
      },
    },
    responsive: true,
    scales: {
      x: {
        ticks: {
          padding: 10,
        },
        title: {
          display: false,
          text: 'Jumlah Transaksi',
          padding: { top: 10, bottom: 10 },
        },
        suggestedMin: 0,
        suggestedMax:
          dataChart && dataChart?.datasets?.length > 0
            ? 1.4 * Math.max(...(dataChart.datasets[0].data || [0]))
            : undefined,
      },
      y: {
        ticks: {
          padding: 10,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        position: 'right' as const,
      },
      title: {
        display: false,
        position: 'bottom' as const,
        text: 'Jumlah Transaksi',
      },
      datalabels: {
        anchor: 'end' as const,
        align: 'end' as const,
        color: '#000',
        formatter: (value, context) => {
          const dataset = context.dataset;
          const rupiahValues = dataset.rupiah;

          const index = context.dataIndex;
          const rupiahValue = Array.isArray(rupiahValues)
            ? rupiahValues[index]
            : 0;

          if (typeof rupiahValue === 'number') {
            const formattedRupiah = new Intl.NumberFormat('id-ID', {
              style: 'currency',
              currency: 'IDR',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(rupiahValue);

            return `${value} - ( ${formattedRupiah} )`;
          }

          return `${value} `;
        },
      },
    },
    interaction: {
      mode: 'nearest' as const,
      intersect: true,
    },
  };

  return (
    <Card>
      <div className='p-5'>
        <div className='text-base font-bold mb-5'>{text}</div>
        <Bar
          ref={chartRef}
          options={{
            ...options,
            onHover: handleMouseOver,
          }}
          data={checkDataFormat(dataChart)}
        />
      </div>
      <div className='w-100 text-center mt-[-10px] mb-2 text-sm'>
        Jumlah Transaksi
      </div>
    </Card>
  );
};

export default BarChart;
