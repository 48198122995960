import { NumericFormat } from 'react-number-format';
import { Skeleton } from '@mui/material';

const TotalAmountSummary = ({ name, value, withdrawal }) => {
  return (
    <div className="flex w-12/12 text-[12px] lg:text-sm mt-1">
      <div className="w-[65%] lg:w-[45%] font-bold">{name}</div>
      <div
        className={`${
          name === 'Withdrawn Amount' && 'font-bold text-[#FBB12F]'
        }`}
      >
        {withdrawal?.isLoading === true ? (
          <Skeleton variant="text" sx={{ width: 100, fontSize: '1rem' }} />
        ) : (
          <>
            Rp.
            <NumericFormat
              value={value}
              displayType={'text'}
              thousandSeparator={'.'}
              decimalSeparator={','}
              prefix={'  '}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default TotalAmountSummary;
