import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Collapse, Grid } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { RootState } from 'stores';
import { GridLine, GridPreview2 } from './Components';
import { isFullVersion } from 'utils/exception';
import { bgColor as bg, txColor as tx } from 'constants/colors';
import { useNavigate } from 'react-router-dom';
import { BasicDialog } from 'components/dialog';
import useOnboardData from 'hooks/useOnboardData';
import CollapsePreview from './collapsed-preview';

const HRLine = () => {
  return <hr className="pt-2 border-t bottom-1 border-red-[#ECECEC]" />;
};

const OnboardingStatus: React.FC = () => {
  const { merchantPreview } = useSelector((s: RootState) => s.onboarding);
  const { data } = merchantPreview;
  function statusColor(state: number) {
    if (state === 4) {
      return `bg-[${bg.primary}] text-[${tx.primary}] border-[#BAE6D7]`;
    } else if ((state > 0 && state < 4) || state === 5) {
      return 'bg-[#FFF2B0] text-[#FBB42D]';
    }
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} lg={3}>
        Status Merchant
      </Grid>
      <Grid item xs={12} sm={6} lg={5}>
        <div
          className={`px-5 w-fit border rounded-lg font-bold ${statusColor(
            data?.state
          )}`}
        >
          {data?.state === 5 ? 'in review ticketing' : data?.info_status}
        </div>
      </Grid>
    </Grid>
  );
};

type IPreview = { status?: boolean };
const Preview: React.FC<IPreview> = ({ status }) => {
  const navigate = useNavigate();
  const { userData, profile } = useSelector((state: RootState) => state.auth);
  const {
    tipeUsaha,
    tipeProduk,
    metodePembayaran,
    informasiBisnis,
    detailBank,
    merchantPreview: MP,
  } = useSelector((state: RootState) => state.onboarding);
  const { payData, bizData } = useOnboardData();
  const qrisList = payData
    ?.find((m) => m.name === 'QRIS' && m?.Channels !== null)
    ?.Channels.map((c) => String(c.id));
  const filterNull = payData?.filter((pay) => pay.Channels !== null);
  const done = profile.MerchantStatus !== 'registered';
  const url = window.location.href.split('/').pop();
  const categoryExist = bizData?.filter((b) => b?.categories !== undefined);

  const createList = (title: string, value: string, image?: boolean) => ({
    title,
    value,
    image,
  });

  const contextTipeBisnis = (type: string) => {
    if (type === '1') return 'Perorangan';
    if (type === '2') return 'Badan Usaha';
  };
  const contextTipeProduk = (type: string) => {
    if (type === '1') return 'Payment Gateway';
    if (type === '2') return 'Payout';
    if (type === '3') return 'Mixed';
    if (type === '4') return 'PG Crypto';
  };
  const contextBizType = (text: string) => {
    if (categoryExist !== null && categoryExist?.length !== 0) {
      const check = categoryExist.find((x) => x.code === text);
      if (check) return check.name;
    }
  };

  const contextBizCat = (text: string) => {
    for (let idx = 0; idx < categoryExist?.length; idx++) {
      let comparison: string = '';
      if (url !== 'onboarding') {
        comparison = MP.data?.bis_type_code;
      } else comparison = informasiBisnis.BizTypeCode;

      if (comparison === categoryExist[idx]?.code) {
        let category = categoryExist[idx].categories;
        for (let catIdx = 0; catIdx < category?.length; catIdx++) {
          if (text === category[catIdx].code) {
            return category[catIdx]?.name;
          }
        }
      }
    }
  };

  const [biz, setBiz] = useState(tipeUsaha);
  const [pack, setPack] = useState(
    isFullVersion ? tipeProduk : String(userData?.PackageType)
  );
  const [tipeBiz, setTipeBiz] = useState(informasiBisnis.BizTypeCode);
  const [catBiz, setCatBiz] = useState(informasiBisnis.BizCategoryCode);
  const [urlBiz, setUrlBiz] = useState(informasiBisnis.BizUrl);
  const [emailFin, setEmailFin] = useState(informasiBisnis.PicFinanceEmail);
  const [phoneFin, setPhoneFin] = useState(informasiBisnis.PicFinancePhone);
  const [emailTec, setEmailTec] = useState(informasiBisnis.PicTechEmail);
  const [phoneTec, setPhoneTec] = useState(informasiBisnis.PicTechPhone);
  const [ownName, setOwnName] = useState(informasiBisnis.OwnerName);
  const [ownNation, setOwnNation] = useState(informasiBisnis.OwnerNationality);
  const [ownAddress, setOwnAddress] = useState(informasiBisnis.OwnerAddress);
  const [ownZip, setOwnZip] = useState(informasiBisnis.OwnerZip);
  const [ownPhone, setOwnPhone] = useState(informasiBisnis.OwnerPhone);

  const [paymentList, setPaymentList] = useState<string[]>([]);

  // console.log(paymentList, "op");

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ktpFile, setKtpFile] = useState(informasiBisnis.IdCardPhotoFile);
  const [ktpUrl, setKtpUrl] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [npwpFile, setNpwpFile] = useState(informasiBisnis.NpwpPhotoFile);
  const [npwpUrl, setNpwpUrl] = useState('');
  const [ktpName, setKtpName] = useState(informasiBisnis.IdCardName);
  const [ktpNo, setKtpNo] = useState(informasiBisnis.IdCardNumber);
  const [npwpName, setNpwpName] = useState(informasiBisnis.NpwpName);
  const [npwpNo, setNpwpNo] = useState(informasiBisnis.NpwpNumber);
  const [npwpAddress, setNpwpAddress] = useState(informasiBisnis.NpwpAddress);

  const [bankName, setBankName] = useState(detailBank.BankName);
  const [bankAccount, setBankAccount] = useState(detailBank.BankAccountNumber);
  const [bankOwner, setBankOwner] = useState(detailBank.BankAccountName);
  // const [isQRIS, setIsQRIS] = useState(false);
  const [isForm, setIsForm] = useState(false);

  useEffect(() => {
    if (done && MP) {
      const { data } = MP;
      if (tipeProduk === '') {
        let bis = String(data?.business_type);
        let pkg = String(data?.package_type);
        setBiz(bis);
        setPack(pkg);
      }
      if (data?.package_type !== null) {
        setTipeBiz(data?.bis_type_code);
        setCatBiz(data?.bis_category_code);
        setUrlBiz(data?.bis_url);
        setEmailFin(data?.finance_email);
        setPhoneFin(data?.finance_phone);
        setEmailTec(data?.tech_email);
        setPhoneTec(data?.tech_phone);
        setOwnName(data?.own_name);
        setOwnNation(data?.own_citizen);
        setOwnAddress(data?.own_address);
        setOwnZip(data?.own_zip);
        setOwnPhone(data?.own_phone);
        setKtpName(data?.ktp_name);
        setKtpNo(data?.ktp_no);
        setNpwpName(data?.npwp_name);
        setNpwpNo(data?.npwp_number);
        setNpwpAddress(data?.npwp_address);
        setBankName(data?.bank);
        setBankAccount(data?.bank_acc_no);
        setBankOwner(data?.bank_acc_name);
        if (data?.ktp_url !== '') {
          setKtpUrl(data?.ktp_url);
          setNpwpUrl(data?.npwp_url);
        }
      }
      if (data?.payments?.length !== 0 && data?.payments !== null) {
        let pays = data?.payments.map((item) => String(item));
        // if there is id of QRIS, then make it true to show the button
        // if (pays.find((s) => qrisList?.includes(s))) setIsQRIS(true);
        setPaymentList(pays);
      }
    }
  }, [done, MP, biz, tipeProduk, metodePembayaran, qrisList]);

  useEffect(() => {
    if (url === 'onboarding') setPaymentList(metodePembayaran);
  }, [metodePembayaran, url]);

  const ListedBisnis = [
    createList('Tipe Entitas Bisnis', contextBizType(tipeBiz)),
    createList('Kategori Bisnis', contextBizCat(catBiz)),
    createList('URL Bisnis', urlBiz),
    createList('Email PIC Finance', emailFin),
    createList('No. HP PIC Finance', phoneFin),
    createList('Email PIC Teknis', emailTec),
    createList('No. HP PIC Teknis', phoneTec),
  ];

  const ListedOwner = [
    createList('Nama Pemilik Usaha', ownName),
    createList('Kewarganegaraan', ownNation),
    createList('Alamat', ownAddress),
    createList('Kode POS', ownZip),
    createList('No. HP Pemilik', ownPhone),
  ];
  const ListedBank = [
    createList('Bank Name', bankName),
    createList('No. Rekening', bankAccount),
    createList('Nama Pemilik Rekening', bankOwner),
  ];

  const [about, setAbout] = useState(true);
  const [payment, setPayment] = useState(true);
  const [bisnis, setBisnis] = useState(true);
  const [owner, setOwner] = useState(true);
  const [docs, setDocs] = useState(true);
  const [bank, setBank] = useState(true);
  const basedBiz = tipeUsaha === '1' ? 'Pemilik' : 'Direktur';

  type field = { title: string; value?: string; file?: string | File };
  const aboutField: field[] = [
    { title: 'Nama Usaha', value: userData.Fullname },
    { title: 'Tipe Bisnis', value: contextTipeBisnis(biz) },
    { title: 'Paket Produk', value: contextTipeProduk(pack) },
  ];
  const docsField: field[] = [
    {
      title: `KTP ${basedBiz} Usaha`,
      file: ktpFile !== null ? ktpFile : ktpUrl,
    },
    { title: 'Nama Sesuai KTP', value: ktpName },
    { title: 'Nomor KTP', value: ktpNo },
    { title: `NPWP ${basedBiz} Usaha`, file: npwpFile ?? npwpUrl },
    { title: 'Nama Sesuai NPWP', value: npwpName },
    { title: 'Nomor NPWP', value: npwpNo },
    { title: 'Alamat Sesuai NPWP', value: npwpAddress },
  ];

  // const xShowDetail = () => {
  //   if (getCookie('QR')) {
  //     navigate(`${isFullVersion ? '/full/' : '/'}setting/qris-detail`);
  //   } else {
  //     setIsForm(true);
  //     setCookie('QR', JSON.stringify({ QRIS: true }));
  //   }
  // };
  return (
    <div className="space-y-2 bg-white p-5">
      <div className="flex justify-between space-y-1">
        <div className="font-bold text-xl">Tentang Merchant</div>
        <Button onClick={() => setAbout(!about)}>
          {about ? (
            <ExpandLess htmlColor="#000" />
          ) : (
            <ExpandMore htmlColor="#000" />
          )}
        </Button>
      </div>
      <Collapse in={about}>
        <div className="space-y-1">
          {status && <OnboardingStatus />}
          {aboutField.map((a) => (
            <GridLine
              key={a.title}
              fieldTitle={a.title}
              value={a.value}
              disabled
              white
              mandatory={false}
            />
          ))}
        </div>
      </Collapse>
      <HRLine />
      {url === 'onboarding' && metodePembayaran.length === 0 ? null : (
        <>
          <CollapsePreview
            title="Metode Pembayaran"
            mode="list"
            open={{ set: setPayment, val: payment }}
            listed={{ val: filterNull, compare: paymentList }}
            mandatory={false}
            // show={isQRIS}
            // popup={{ set: setIsForm }}
            // onClick={xShowDetail}
          />
          <HRLine />
        </>
      )}
      <CollapsePreview
        title="Informasi Bisnis"
        normal={{ list: ListedBisnis }}
        open={{ set: setBisnis, val: bisnis }}
        mandatory={false}
      />

      <HRLine />
      <CollapsePreview
        title="Informasi Pemilik"
        normal={{ list: ListedOwner }}
        open={{ set: setOwner, val: owner }}
        mandatory={false}
      />
      <HRLine />
      <div className="flex justify-between space-y-1">
        <div className="font-bold text-xl">Dokumen</div>
        <Button onClick={() => setDocs(!docs)}>
          {docs ? (
            <ExpandLess htmlColor="#000" />
          ) : (
            <ExpandMore htmlColor="#000" />
          )}
        </Button>
      </div>
      <Collapse in={docs}>
        <div className="space-y-1">
          {docsField.map((doc) => {
            if (doc.file) {
              return (
                <GridPreview2
                  key={doc.title}
                  fieldTitle={doc.title}
                  file={doc.file}
                />
              );
            } else
              return (
                <GridLine
                  key={doc.title}
                  fieldTitle={doc.title}
                  disabled
                  white
                  value={doc.value}
                  mandatory={false}
                />
              );
          })}
        </div>
      </Collapse>
      <HRLine />
      <CollapsePreview
        title="Detail Bank"
        normal={{ list: ListedBank }}
        open={{ set: setBank, val: bank }}
        mandatory={false}
      />

      <BasicDialog
        maxWidth="xs"
        open={isForm}
        type="double"
        leftbtn={{
          color: 'error',
          variant: 'outlined',
          text: 'Batal',
          onClick: () => setIsForm(false),
        }}
        rightbtn={{
          color: 'success',
          variant: 'contained',
          text: 'Lengkapi',
          onClick: () =>
            navigate(`${isFullVersion ? '/full' : '/'}setting/register-qris`),
        }}
        text="Lengkapi Data Registrasi QRIS"
        subtext="Silahkan lengkapi data yang dibutuhkan untuk menggunakan fitur pembayaran QRIS"
      />
    </div>
  );
};

export default Preview;
