import { type CSSProperties, type FC } from 'react';
import type { XYCoord } from 'react-dnd';
import { useDragLayer } from 'react-dnd';

import BoxDragPreview from './BoxDragPreview';
import { ItemTypes } from './ItemTypes';

const layerStyles: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
};

function getItemStyles(
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null
) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }

  let { x, y } = currentOffset;

  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

export interface CustomDragLayerProps {
  dataDragPm?: any;
  dataDragPc?: any;
  pcDrag;
  setpcDrag;
}

export const CustomDragLayer: FC<CustomDragLayerProps> = ({
  dataDragPm,
  dataDragPc,
  pcDrag,
  setpcDrag,
}) => {
  const { itemType, isDragging, initialOffset, currentOffset, item } =
    useDragLayer((monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }));

  function renderItem() {
    switch (itemType) {
      case ItemTypes.PAYMENTMETHOD:
        return (
          <BoxDragPreview itemType={'PAYMENTMETHOD'} dataDrag={dataDragPm} />
        );
      case ItemTypes.CHANNEL:
        return <BoxDragPreview itemType={'CHANNEL'} dataDrag={dataDragPc} />;
      default:
        return null;
    }
  }

  isDragging && setpcDrag(item.pcPage_id);

  if (!isDragging) {
    setpcDrag(null);
    return null;
  }
  return (
    <div style={layerStyles}>
      <div style={getItemStyles(initialOffset, currentOffset)}>
        {renderItem()}
      </div>
    </div>
  );
};
