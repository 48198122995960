import { createTheme } from '@mui/material/styles';

export const fontTheme = createTheme({
  typography: { allVariants: { fontFamily: 'Manrope' } },
});

export const colorTheme = createTheme({
  palette: {
    primary: { main: '#009883', contrastText: '#FFF' },
    success: { main: '#21AF7D', contrastText: '#fff' },
    warning: { main: '#EE4E2B', contrastText: '#fff' },
    secondary: { main: '#222222' },
    info: { main: '#94A3B8' },
  },
});

export const textFieldTheme = createTheme({ ...fontTheme, ...colorTheme });

export const PaginationTheme = createTheme({
  palette: {
    primary: {
      main: '#21AF7D',
      darker: '#053e85',
      contrastText: '#fff',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
  },
});

export const btnTheme = createTheme({
  palette: {
    primary: {
      main: '#21AF7D',
      darker: '#053e85',
      contrastText: '#FFFFFF',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
    success: { main: '#21AF7D', contrastText: '#FFFFFF' },
    secondary: { main: '#FFFFFF' },
    error: { main: '#EE4E2B', contrastText: '#FFFFFF' },
    warning: { main: '#FDBE2C', contrastText: '#FFFFFF' },
  },
  typography: {
    fontFamily: 'Manrope',
    allVariants: { fontWeight: 'bold', fontSize: 16 },
    button: { textTransform: 'capitalize' },
  },
});

export const btnTheme2 = createTheme({
  palette: {
    primary: {
      main: '#21AF7D',
      darker: '#053e85',
      contrastText: '#fff',
    },
    secondary: {
      main: '#EBECED',
      contrastText: '#222222',
      darker: '#053e85',
    },
  },
});

export const btnOulineTheme = createTheme({
  palette: {
    primary: {
      main: '#21AF7D',
      darker: '#053e85',
      contrastText: '#21AF7D',
    },
    secondary: {
      main: '#222222',
      darker: '#053e85',
      contrastText: '#0971f1',
    },
    info: {
      main: '#94A3B8',
      darker: '#CBD5E1',
      contrastText: '#0971f1',
    },
  },
});
export const btnOulineTheme2 = createTheme({
  palette: {
    primary: {
      main: '#231F20',
      darker: '#053e85',
      contrastText: '#21AF7D',
    },
  },
});

export const FontDefaultTheme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Manrope',
      textTransform: 'none',
      fontSize: 16,
    },
  },
});

export const checkboxTheme = createTheme({
  palette: {
    primary: {
      main: '#009883',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
    success: {
      main: '#18AD65',
    },
  },
});

export const formLabelTheme = createTheme({
  palette: {
    primary: { main: '#009883' },
    success: { main: '#E9F7F2' },
  },
  typography: {
    allVariants: {
      fontFamily: 'Manrope',
      fontWeight: 'bolder',
    },
  },
});
