import React from 'react';
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  ThemeProvider,
} from '@mui/material';
import { NumericFormat } from 'react-number-format';
import UploadWide from 'components/upload/wide';
import {
  IGridImage,
  IGridLine,
  IGridSelect,
  ISelectionCard,
} from './interface';
import { Check, CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { formLabelTheme } from 'components/muiTheme/theme';
import HelperText from 'components/helperText';
import { Document, Page, pdfjs } from 'react-pdf';
import './custom.scss';

const WIDTH = window.innerWidth < 600;

const CardOptions: React.FC<ISelectionCard> = (props) => {
  const {
    title,
    subtitle,
    list,
    slice = 6, // will be divided each by? (range of 12)
    minHeight = '8rem', // to make the height of each same
    selected,
    error,
    message,
    disabled,
  } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    selected?.set((event.target as HTMLInputElement).value);
  };
  return (
    <>
      <div className="text-xl font-bold">{title}</div>
      <div>{subtitle}</div>
      {error && <HelperText error={message} />}
      <FormControl className="w-full">
        <RadioGroup onChange={handleChange}>
          <Grid container spacing={2}>
            {list.map((item, itemIdx) => (
              <Grid item xs={12} sm={slice} key={itemIdx}>
                <div
                  className={`flex flex-col border-2 border-[#DEDEDE] rounded-md my-2`}
                >
                  <img src={item.image} alt={item.title} className="w-full" />
                  <div
                    style={{ minHeight: `${minHeight}` }}
                    className="px-3 py-2 gap-2"
                  >
                    <ThemeProvider theme={formLabelTheme}>
                      <FormControlLabel
                        control={
                          <Radio
                            icon={<CheckBoxOutlineBlank />}
                            checkedIcon={<CheckBox />}
                            value={String(itemIdx + 1)}
                            checked={selected?.val === String(itemIdx + 1)}
                            disabled={disabled}
                          />
                        }
                        label={item.title}
                      />
                    </ThemeProvider>
                    <div>{item.subtitle}</div>
                    <div className="flex flex-col">
                      {item.benefits !== undefined &&
                        item.benefits.map((benefit, idx) => (
                          <div className="flex mt-4 space-x-2" key={idx}>
                            <Check htmlColor="#42CF8B" />
                            <span>{benefit}</span>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </RadioGroup>
      </FormControl>
    </>
  );
};

const GridSelect: React.FC<IGridSelect & { id?: string }> = (props) => {
  const {
    fieldTitle,
    options,
    selected,
    placeholder,
    disable,
    white = false,
    error,
    message,
    mandatory = true,
  } = props;
  const handleChange = (event: SelectChangeEvent) => {
    selected.set(event.target.value);
  };
  const specialCase = () => {
    if (disable && white) return '#FFFFFF';
    else if (disable) return '#F7F7F7';
    else return '';
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={3} mt={1} className="flex gap-1">
        {fieldTitle}
        {mandatory && <p className="text-[#EE4E2B]"> *</p>}
      </Grid>
      <Grid item xs={12} md={6} lg={5}>
        <FormControl
          fullWidth
          disabled={disable}
          error={error}
          sx={{ bgcolor: `${specialCase()}` }}
        >
          <InputLabel id="bisnis-tipe" size="small">
            {selected.val === '' ? placeholder : ''}
          </InputLabel>
          <Select
            labelId="bisnis-tipe"
            value={selected.val}
            id={props?.id}
            size="small"
            onChange={handleChange}
            MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
          >
            {options?.map((biz, bizIdx) => (
              <MenuItem key={bizIdx} value={biz.value}>
                {biz.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className="mt-2">{error && <HelperText error={message} />}</div>
      </Grid>
    </Grid>
  );
};
const GridLine: React.FC<IGridLine & { id?: string }> = (props) => {
  const {
    fieldTitle,
    placeholder,
    value,
    onChange,
    disabled,
    mode = 'text',
    white,
    error,
    message,
    mandatory = true,
  } = props;

  const specialCase = () => {
    if (disabled && white) return '#FFFFFF';
    else if (disabled) return '#F7F7F7';
    else return '';
  };
  function setMode(modes: string) {
    switch (modes) {
      case 'text':
        return (
          <TextField
            placeholder={placeholder}
            size="small"
            value={value}
            onChange={onChange}
            disabled={disabled}
            fullWidth
            multiline
            error={error}
            sx={{ bgcolor: `${specialCase()}` }}
            id={props?.id}
          />
        );
      case 'number':
        return (
          <NumericFormat
            customInput={TextField}
            placeholder={placeholder}
            size="small"
            value={value}
            onChange={onChange}
            disabled={disabled}
            fullWidth
            sx={{ bgcolor: `${specialCase()}` }}
            allowLeadingZeros
            allowNegative={false}
            error={error}
            id={props?.id}
          />
        );
      case 'phone':
        return (
          <NumericFormat
            customInput={TextField}
            placeholder={placeholder}
            size="small"
            value={value}
            onChange={onChange}
            disabled={disabled}
            fullWidth
            sx={{ bgcolor: `${specialCase()}` }}
            allowLeadingZeros
            allowNegative={false}
            error={error}
            id={props?.id}
          />
        );
      case 'zip':
        return (
          <NumericFormat
            customInput={TextField}
            placeholder={placeholder}
            size="small"
            value={value}
            onChange={onChange}
            disabled={disabled}
            fullWidth
            sx={{ bgcolor: `${specialCase()}` }}
            allowLeadingZeros={false}
            allowNegative={false}
            error={error}
            id={props?.id}
          />
        );
      default:
        return;
    }
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={3} mt={1} className="flex gap-1">
        {fieldTitle}
        {mandatory && <p className="text-[#EE4E2B]"> *</p>}
      </Grid>
      <Grid item xs={12} md={6} lg={5}>
        {setMode(mode)}
        <div className="mt-2">{error && <HelperText error={message} />}</div>
      </Grid>
    </Grid>
  );
};
const GridImage: React.FC<IGridImage> = (props) => {
  const {
    fieldTitle,
    setTheFile,
    message,
    error,
    theFile,
    thePhoto,
    setThePhoto,
    id,
    mandatory = true,
  } = props;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6} className="flex gap-1">
        {fieldTitle}
        {mandatory && <p className="text-[#EE4E2B]"> *</p>}
      </Grid>
      <Grid item xs={12} lg={8}>
        <div className="border-2 w-full border-gray-400 border-dotted rounded-md p-5">
          <UploadWide
            id={id}
            files={theFile}
            onFileUpload={setTheFile}
            onPhotoUpload={setThePhoto}
            photo={thePhoto}
          />
        </div>
        <div className="my-2">{error && <HelperText error={message} />}</div>
      </Grid>
    </Grid>
  );
};
const GridPreview: React.FC<IGridImage> = (props) => {
  const { fieldTitle, thePhoto, contentRange = 12, titleRange = 12 } = props;
  return (
    <Grid container spacing={2}>
      <Grid item xs={titleRange} lg={3}>
        {fieldTitle}
      </Grid>
      <Grid item xs={contentRange} lg={contentRange < 12 ? 5 : 8}>
        <img
          src={thePhoto}
          alt=""
          className="w-full rounded-md object-contain"
        />
      </Grid>
    </Grid>
  );
};

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PreviewProps {
  file: string | File;
  fieldTitle: string;
}
type ImageProp = { src: string };
const IMAGE: React.FC<ImageProp> = ({ src }) => {
  return (
    <img
      src={src}
      alt="preview"
      width={WIDTH ? 270 : 400}
      className="max-h-64"
    />
  );
};

const GridPreview2: React.FC<PreviewProps> = ({ file, fieldTitle }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [numPages, setNumPages] = React.useState<number | null>(null);
  const [preview, setPreview] = React.useState('');

  const getFileType = (file: File | string) => {
    if (typeof file === 'string') {
      if (file.startsWith('data:image/')) {
        return 'image';
      } else if (file.endsWith('.pdf')) {
        return 'pdf';
      } else {
        return null;
      }
    } else {
      if (file?.type.startsWith('image/')) {
        return 'image';
      } else if (file?.type === 'application/pdf') {
        return 'pdf';
      } else {
        return null;
      }
    }
  };

  const renderPreview = (fileType: string | null) => {
    switch (fileType) {
      case 'image':
        if (typeof file === 'string') {
          return <IMAGE src={file} />;
        } else {
          const reader = new FileReader();
          reader.onloadend = () => {
            if (reader.readyState === FileReader.DONE)
              setPreview(reader.result as string);
          };
          reader.readAsDataURL(file);
          return <IMAGE src={preview} />;
        }
      case 'pdf':
        return (
          <Document
            file={file}
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
          >
            <Page
              width={WIDTH ? 270 : 400}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              pageNumber={1}
            />
          </Document>
        );

      default:
        return <div>No preview available</div>;
    }
  };

  const fileType = getFileType(file);

  return (
    <Grid container>
      <Grid item xs={12} md={6} lg={3}>
        <div>{fieldTitle}</div>
      </Grid>
      <Grid item xs={12} md={6} lg={5}>
        {fileType ? (
          renderPreview(fileType)
        ) : (
          <>
            <Document
              file={file as string}
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
              error=""
              noData="-"
            >
              <Page
                width={400}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                pageNumber={1}
              />
            </Document>
            <img src={file as string} alt="" className="lg:w-96" />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export {
  CardOptions,
  GridImage,
  GridLine,
  GridSelect,
  GridPreview,
  GridPreview2,
};
