import { useState } from 'react';
import LabelInfo from './components/labelInfo';
import { ThemeProvider } from '@emotion/react';
import { PaginationTheme } from 'components/muiTheme';
import {
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  OutlinedInput,
  Select,
} from '@mui/material';
import WrapEditSubmit from './components/WrapEditSubmit';
import React from 'react';
import { SettingContext } from 'context/settingContext';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

import MenuItem from '@mui/material/MenuItem';

import dayjs from 'dayjs';
// import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';

import 'react-datepicker/dist/react-datepicker.css';
import useSetting from 'hooks/useSetting';
import { toast } from 'react-toastify';
import useSandbox from 'hooks/useSandbox';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const ScheduledComponents = ({
  schedule,
  dataSettlementSetting,
  setdataSettlementSetting,
}) => {
  const daysOfWeek = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ];

  const monthNames = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
  ];

  let { isEdit } = React.useContext(SettingContext);

  const handleChangeDay = (event) => {
    setdataSettlementSetting({
      ...dataSettlementSetting,
      settlement_day: event.target.value,
    });
  };

  const handleChangeMonth = (event) => {
    setdataSettlementSetting({
      ...dataSettlementSetting,
      settlement_month: event.target.value,
    });
  };

  const handleChangeTime = (event) => {
    setdataSettlementSetting({
      ...dataSettlementSetting,
      settlement_time: event.format('HH:mm:ss'),
    });
  };

  const handleChangeDate = (event) => {
    setdataSettlementSetting({
      ...dataSettlementSetting,
      settlement_date: event.target.value.toString(),
    });
  };

  const handleChangeDateSpecific = (event) => {
    setdataSettlementSetting({
      ...dataSettlementSetting,
      settlement_spec: event.format('YYYY-MM-DD HH:mm:ss'),
    });
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const DateField = () => {
    return (
      <div className="lg:items-center w-[100%] flex lg:flex-row flex-col gap-2">
        <b className={`${!isEdit && 'text-gray-400'}`}>Date</b>

        <FormControl sx={{ mt: 1, width: '100%' }}>
          <Select
            displayEmpty
            value={dataSettlementSetting?.settlement_date}
            onChange={handleChangeDate}
            input={<OutlinedInput />}
            disabled={!isEdit}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <em>Select Date</em>;
              }

              return <div className="font-bold">{selected}</div>;
            }}
            MenuProps={MenuProps}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {Array.from(Array(31).keys()).map((date) => {
              const dateNumber = date + 1;
              return (
                <MenuItem key={dateNumber} value={dateNumber}>
                  {dateNumber}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
    );
  };

  const DateSpecific = () => {
    return (
      <div className="flex flex-col w-[100%] gap-2">
        <div>
          <b className={`${!isEdit && 'text-gray-400'}`}>Date</b>
        </div>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            sx={{ mt: 1, width: 'auto' }}
            disabled={!isEdit}
            defaultValue={dayjs(dataSettlementSetting?.settlement_spec)}
            onChange={handleChangeDateSpecific}
          />
        </LocalizationProvider>
      </div>
    );
  };

  const TimeField = ({ row }: { row?: boolean }) => {
    return (
      <div
        className={`${row ? 'flex flex-col' : 'lg:items-center lg:flex gap-2'}`}
      >
        <b className={`${!isEdit && 'text-gray-400'}`}>Time</b>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopTimePicker
            sx={{
              mt: 1,
              width: 'auto',
              '& .css-x35sup-MuiInputBase-root-MuiOutlinedInput-root': {
                height: '39px',
              },
            }}
            defaultValue={dayjs(
              `${
                dataSettlementSetting?.settlement_time
                  ? `${dataSettlementSetting?.settlement_time} 0`
                  : ''
              }`
            )}
            label="Select time"
            disabled={!isEdit}
            onChange={handleChangeTime}
          />
        </LocalizationProvider>
      </div>
    );
  };

  const MonthField = () => {
    return (
      <div className="lg:items-center flex lg:flex-row flex-col gap-2">
        <b className={`${!isEdit && 'text-gray-400'}`}>Month</b>

        <FormControl sx={{ mt: 1, width: '100%' }}>
          <Select
            displayEmpty
            disabled={!isEdit}
            value={dataSettlementSetting?.settlement_month}
            onChange={handleChangeMonth}
            input={<OutlinedInput />}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <em>Select Month</em>;
              }

              return (
                <div className="font-bold">
                  {selected.charAt(0).toUpperCase() + selected.slice(1)}
                </div>
              );
            }}
            MenuProps={MenuProps}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {monthNames.map((name) => (
              <MenuItem key={name} value={name}>
                {name.charAt(0).toUpperCase() + name.slice(1)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };

  const DayField = () => {
    return (
      <div className="lg:items-center flex lg:flex-row flex-col gap-2">
        <b className={`${!isEdit && 'text-gray-400'}`}>Day</b>

        <FormControl sx={{ mt: 1, width: '100%' }}>
          <Select
            displayEmpty
            value={dataSettlementSetting.settlement_day}
            onChange={handleChangeDay}
            disabled={!isEdit}
            input={<OutlinedInput />}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <em>Select day</em>;
              }
              return (
                <div className="font-bold">
                  {selected.charAt(0).toUpperCase() + selected.slice(1)}
                </div>
              );
            }}
            MenuProps={MenuProps}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {daysOfWeek.map((name) => (
              <MenuItem key={name} value={name}>
                {name.charAt(0).toUpperCase() + name.slice(1)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };

  return (
    <div>
      {schedule === 'daily' && (
        <div className="mt-3">
          <TimeField row />
        </div>
      )}

      {schedule === 'weekly' && (
        <div className="mt-3 flex flex-col lg:flex-row gap-5">
          <div className="lg:w-[50%]">
            <DayField />
          </div>
          <TimeField />
        </div>
      )}

      {schedule === 'monthly' && (
        <div className="mt-3  flex flex-col lg:flex-row gap-5">
          <div className="lg:w-[50%]">
            <DateField />
          </div>
          <TimeField />
        </div>
      )}

      {schedule === 'yearly' && (
        <div className="mt-3  flex flex-col lg:flex-row gap-5">
          <div className="lg:w-[30%]">
            <DateField />
          </div>
          <div className="lg:w-[30%]">
            <MonthField />
          </div>
          <TimeField />
        </div>
      )}

      {schedule === 'Specific' && (
        <div className="mt-3 flex gap-5">
          <DateSpecific />
        </div>
      )}
    </div>
  );
};

const SetSettleSchedule = () => {
  const [isInfo, setisInfo] = useState(true);

  const names = ['daily', 'weekly', 'monthly', 'yearly'];

  let { isEdit, setisEdit, setisModal } = React.useContext(SettingContext);
  let { SettingState, submitEdit, getApiSettingData } = useSetting();

  const [dataSettlementSetting, setdataSettlementSetting] = React.useState(
    SettingState.systemSetting.settlement
  );

  let sandbox = useSandbox()?.userData['Env'];

  const handleToggle = (method) => {
    setdataSettlementSetting({
      ...dataSettlementSetting,
      settlement_method: method,
    });
  };

  const handleCancel = () => {
    setisEdit(false);
  };

  const handleEdit = () => {
    submitEdit('settlement', dataSettlementSetting, (response: any) => {
      if (response.code !== 200) {
        toast.error('Terjadi Kesalahan', { autoClose: 4000 });
      } else {
        setisModal(true);
      }
    });
  };

  React.useEffect(() => {
    setisEdit(false);

    getApiSettingData('settlement', (response: any) => {
      if (response.code !== 200) {
        toast.error('Terjadi Kesalahan', { autoClose: 4000 });
      } else {
        setdataSettlementSetting(response?.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getApiSettingData, sandbox]);

  const handleChange = (event) => {
    const { value } = event.target;
    setdataSettlementSetting({
      ...dataSettlementSetting,
      settlement_type: value,
    });
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <>
      <LabelInfo
        {...{ isInfo, setisInfo }}
        text="Set your merchant transaction settlement schedule to align with the specific requirements of your business operations."
      />
      <div className="text-lg font-bold">Schedule </div>
      <div className="block mt-5 lg:flex text-[#231F20] font-medium min-h-[50vh] lg:w-[80%] mb-3 items-start">
        <div className="w-full lg:w-[48%]">Schedule Option</div>
        <div className="w-[100%] mt-3 lg:mt-0 ">
          <ThemeProvider theme={PaginationTheme}>
            <div className="lg:ml-3 pb-4 border-b-2 mb-4 w-full">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dataSettlementSetting?.settlement_method === 1}
                    onChange={() => handleToggle(1)}
                    disabled={!isEdit}
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<RadioButtonCheckedIcon />}
                  />
                }
                label={
                  <div className="ml-3">
                    <div className="font-semibold">Auto Settlement</div>
                    <div className="text-[14px]">
                      Settlement will be automatically processed each time a
                      merchant completes a transaction settle.
                    </div>
                  </div>
                }
              />
            </div>
            <div className="ml-3 pb-4 border-b-2 mb-4">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dataSettlementSetting?.settlement_method === 2}
                    onChange={() => handleToggle(2)}
                    disabled={!isEdit}
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<RadioButtonCheckedIcon />}
                  />
                }
                label={
                  <div className="ml-4">
                    <div className="font-semibold">Scheduled Settlement</div>
                    <div className="text-[14px]">
                      The settlement will be done on the date and time that you
                      have selected.
                    </div>
                  </div>
                }
              />

              <Collapse in={dataSettlementSetting?.settlement_method === 2}>
                <div className="mt-3">
                  <b className={`${!isEdit && 'text-gray-400'}`}>Period</b>
                  <div>
                    <FormControl sx={{ mt: 1, width: '100%' }}>
                      <Select
                        displayEmpty
                        disabled={!isEdit}
                        value={dataSettlementSetting?.settlement_type}
                        onChange={handleChange}
                        input={<OutlinedInput />}
                        renderValue={(selected) => {
                          if (!selected) return 'Select Period';
                          return (
                            <div className="font-bold">
                              {selected.charAt(0).toUpperCase() +
                                selected.slice(1)}
                            </div>
                          );
                        }}
                        MenuProps={MenuProps}
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        {names.map((name) => (
                          <MenuItem key={name} value={name}>
                            {name.charAt(0).toUpperCase() + name.slice(1)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <ScheduledComponents
                  {...{ dataSettlementSetting, setdataSettlementSetting }}
                  schedule={dataSettlementSetting?.settlement_type}
                />
              </Collapse>
            </div>
            <div className="ml-3 pb-4">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dataSettlementSetting?.settlement_method === 3}
                    onChange={() => handleToggle(3)}
                    disabled={!isEdit}
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<RadioButtonCheckedIcon />}
                  />
                }
                label={
                  <div className="ml-4">
                    <div className="font-semibold">Specific Date</div>
                    <div className="text-[14px]">
                      Settlement will be done only once on a specific date that
                      has been selected.
                    </div>
                  </div>
                }
              />

              <Collapse in={dataSettlementSetting?.settlement_method === 3}>
                <ScheduledComponents
                  {...{ dataSettlementSetting, setdataSettlementSetting }}
                  schedule={'Specific'}
                />
              </Collapse>
            </div>
          </ThemeProvider>
        </div>
      </div>
      <WrapEditSubmit {...{ handleCancel, isEdit, handleEdit }} />
      <br />
    </>
  );
};

export default SetSettleSchedule;
