import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'stores';
import {
  getBizType,
  getListBank,
  getPaymentMethod,
} from 'stores/actions/onboardingAction';

const useOnboardData = () => {
  const { bizType, getMetodePembayaran, listBank } = useSelector(
    (o: RootState) => o.onboarding
  );
  const dispatch = useDispatch();
  const renderOne = React.useRef(false);

  // re declare names
  const bizData = bizType.data;
  const payData = getMetodePembayaran.data;
  const bankData = listBank.data;
  const payError = getMetodePembayaran.error;

  React.useEffect(() => {
    // Onboarding Data
    if (!renderOne.current) {
      if (bizData.length === 0) dispatch(getBizType());
      if (payData.length === 0) dispatch(getPaymentMethod());
      if (bankData.length === 0) dispatch(getListBank());
      renderOne.current = true;
    }
  }, [bankData.length, bizData.length, dispatch, payData.length]);

  return {
    bizData,
    payData,
    bankData,
    payError,
  };
};

export default useOnboardData;
