import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'stores';
import { reqProd, userLogin } from 'stores/actions/authActions';
import { IUserLoginBodyData, IUserLoginResponse } from 'stores/types/authTypes';

const useAuth = () => {
  const dispatch = useDispatch();
  const authState = useSelector((state: RootState) => state.auth);

  const handleUserLogin = useCallback(
    (
      bodyData: IUserLoginBodyData,
      callback?: (data: IUserLoginResponse) => void
    ) => {
      dispatch(userLogin(bodyData, callback));
    },
    [dispatch]
  );

  const handlereqProd = useCallback(
    (callback?: (data: any) => void) => {
      dispatch(reqProd(callback));
    },
    [dispatch]
  );

  return {
    authState,
    userLogin: handleUserLogin,
    reqProd: handlereqProd,
  };
};

export default useAuth;
