import React, { useState } from 'react';

import { icons } from 'assets';

import Card from 'components/cardComponents';

import useNotification from 'hooks/useNotification';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ListIcon } from './listKategory';

const DetailNotifications = () => {
  const { getDetail, getList, notificationState } = useNotification();
  const [detail, setdetail] = useState({});
  const [isLoading, setisLoading] = useState(true);

  let { id } = useParams();

  let listCategory = notificationState?.category;

  React.useEffect(() => {
    getDetail(id, (response: any) => {
      if (response.status_code !== 200) {
        window.history.back();
      } else {
        setdetail(response.data);
        getList(
          { page: 1, limit: -1 },
          (response: any) => {
            if (response.status_code !== 200) console.log('error', 'error');
          },
          false,
          true
        );
      }
    }); // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    Object.keys(detail).length !== 0 && setisLoading(false);
  }, [detail]);

  React.useEffect(() => {
    return () => {
      setisLoading(true);
    };
  }, []);

  let topic = detail?.['message_topic'];

  let IconsNotif = null;
  let categoryName = '';
  // eslint-disable-next-line array-callback-return
  listCategory?.map((e2) => {
    // eslint-disable-next-line array-callback-return
    e2?.topics?.map((data) => {
      if (topic?.toLowerCase() === data?.topic_name) {
        IconsNotif = ListIcon[e2?.category_name];
        categoryName = e2?.category_name;
      }
    });
  });

  return (
    <>
      <div>
        <div
          className="text-2xl sm:text-3xl flex items-center font-bold mb-5 mt-[6rem] cursor-pointer"
          onClick={() => window.history.back()}>
          <ArrowBackIcon className="mr-3" style={{ fontSize: 30 }} />
          Detail Notification{' '}
        </div>
        {isLoading ? (
          <div className="text-center">LODING....</div>
        ) : (
          <Card>
            <div className="border-b-2 border flex p-[1.5rem]">
              <img
                src={IconsNotif !== null ? IconsNotif : icons.Default}
                width="50px"
                height="40px"
                alt=""
              />
              <div
                className="flex flex-col ml-5
              ">
                <div className="font-bold text-xl">
                  {categoryName.charAt(0).toUpperCase() + categoryName.slice(1)}
                </div>
                <div>{moment(detail?.['message_date']).format('ll')}</div>
              </div>
            </div>
            <div className="p-[1.5rem]">
              <div className="font-bold text-2xl">
                {detail['message_title']}
              </div>
              <div className="text-xl mt-4">{detail['message_body']}</div>
            </div>

            <br />
          </Card>
        )}
        <br />
      </div>
    </>
  );
};

export default DetailNotifications;
