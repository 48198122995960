import { Button, ButtonProps, ThemeProvider } from '@mui/material';
import { btnTheme } from 'components/muiTheme';

type Props = ButtonProps & {};

export default function SubmitButton(props: Props) {
  const { onClick, disabled = false, children, className } = props;
  return (
    <ThemeProvider theme={btnTheme}>
      <Button
        type="submit"
        disabled={disabled}
        className={`w-full capitalize ${className ? className : ''}`}
        variant="contained"
        onClick={onClick}
        {...props}
        color="primary"
      >
        {children}
      </Button>
    </ThemeProvider>
  );
}
