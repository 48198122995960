import React, { useEffect } from 'react';

import useSetting from 'hooks/useSetting';
import { toast } from 'react-toastify';
import { SettingContext } from 'context/settingContext';
import useSandbox from 'hooks/useSandbox';
import WrapEditSubmit from '../components/WrapEditSubmit';
import InputField from '../ipWhitelist/InputField';
import LabelInfo from '../components/labelInfo';

const IpWhitelist = () => {
  const [isInfo, setisInfo] = React.useState(true);

  let { SettingState, submitEdit, getApiSettingData } = useSetting();
  let { systemSetting } = SettingState;
  let { iPWhitelist } = systemSetting;

  let { isEdit, setisEdit, setisModal } = React.useContext(SettingContext);
  const [data, setdata] = React.useState({
    ip_public_dev: iPWhitelist.ip_public_dev,
    ip_public_staging: iPWhitelist.ip_public_staging,
    ip_public_prod: iPWhitelist.ip_public_prod,
    ip_private: iPWhitelist.ip_private,
  });

  const [defaultFormInput, setdefaultFormInput] = React.useState(data);

  const [isInvalid, setisInvalid] = React.useState({
    ip_public_dev: false,
    ip_public_staging: false,
    ip_public_prod: false,
    ip_private: false,
  });

  const [defaultIsInvalid] = React.useState(isInvalid);

  let regexIpv4 =
    /^((?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])[.]){3}(?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])$/;

  let sandbox = useSandbox()?.userData['Env'];

  useEffect(() => {
    setisEdit(false);
  }, [setisEdit]);

  const handleEdit = () => {
    submitEdit('ip_whitelist', data, (response: any) => {
      if (response.code !== 200) {
        toast.error('Terjadi Kesalahan', { autoClose: 4000 });
      } else {
        getApiSettingData('ip_whitelist', (response: any) => {
          if (response.code !== 200)
            toast.error('Terjadi Kesalahan', { autoClose: 4000 });
        });
        setisModal(true);
      }
    });
  };

  const handleCancel = () => {
    setisEdit(false);
    setisInvalid(defaultIsInvalid);
    setdata(defaultFormInput);
  };

  const handleChange = (e: any) => {
    setdata({
      ...data,
      [e.target.name]: e.target.value,
    });
    const regex = regexIpv4;
    setisInvalid({
      ...isInvalid,
      [e.target.name]: !e.target.value.match(regex) && e.target.value !== '',
    });
  };

  React.useEffect(() => {
    setisEdit(false);

    getApiSettingData('ip_whitelist', (response: any) => {
      if (response.code !== 200) {
        toast.error('Terjadi Kesalahan', { autoClose: 4000 });
      } else {
        let newData = {
          ip_public_dev: response.data.ip_public_dev,
          ip_public_staging: response.data.ip_public_staging,
          ip_public_prod: response.data.ip_public_prod,
          ip_private: response.data.ip_private,
        };
        setdata(newData);
        setdefaultFormInput(newData);
        setisInvalid(defaultIsInvalid);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getApiSettingData, sandbox]);

  return (
    <div className=" flex flex-col gap-5">
      <LabelInfo
        {...{ isInfo, setisInfo }}
        text="This is a security setting that will make the map only accessible
            from IP address listed in table below. Should you enter the wrong IP
            address, your access to the MAP will be lost. Please make sure you
            have the correct IP address before adding it to the whitelist."
      />

      <div>
        <div className="text-lg font-bold">Public </div>
        <InputField
          {...{ isEdit, isInvalid, handleChange }}
          nameInput="ip_public_prod"
          name="IP Production"
          defaultvalue={iPWhitelist.ip_public_prod}
          value={data['ip_public_prod']}
          disabled={!isEdit}
        />
        <InputField
          {...{ isEdit, isInvalid, handleChange }}
          nameInput="ip_public_dev"
          name="IP Development"
          defaultvalue={iPWhitelist.ip_public_dev}
          value={data['ip_public_dev']}
          disabled={!isEdit}
        />
        <InputField
          {...{ isEdit, isInvalid, handleChange }}
          nameInput="ip_public_staging"
          name="IP Staging"
          defaultvalue={iPWhitelist.ip_public_staging}
          value={data['ip_public_staging']}
          disabled={!isEdit}
        />
      </div>

      <div>
        <div className="text-lg font-bold">Private </div>
        <InputField
          {...{ isEdit, isInvalid, handleChange }}
          nameInput="ip_private"
          name="IP Private / Local"
          defaultvalue={iPWhitelist.ip_private}
          value={data['ip_private']}
          disabled={!isEdit}
        />
      </div>

      <WrapEditSubmit {...{ handleCancel, isEdit, handleEdit }} />
    </div>
  );
};

export default IpWhitelist;
