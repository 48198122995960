import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'stores';
import {
  ActionCreateRefund,
  ActionGetRefundDetail,
  ActionGetRefundList_Report,
} from 'stores/actions/refundActions';
import { IBodyDataCreate } from 'stores/types/refundTypes';
import { permissionFunction } from 'utils/permission';
import permission from 'constants/permissionRole';
import { tableNameListRefund } from 'pages/transaksi/refund/table/datalist';

const useRefund = () => {
  const dispatch = useDispatch();
  const refundtState = useSelector((state: RootState) => state.refund);
  const authState = useSelector((state: RootState) => state.auth.userData);
  const Env = authState['Env'] ? 'PRODUCTION' : 'SANDBOX';

  const [header, setHeader] = useState(
    tableNameListRefund.map((item) => item.tableName)
  );

  const handleGetRefundList = useCallback(
    (params?: any, callback?: (data: any) => void) => {
      permissionFunction(permission[Env].LIST_REFUND) &&
        dispatch(ActionGetRefundList_Report(params, callback));
    },
    [Env, dispatch]
  );

  const handleGetRefundDetail = useCallback(
    (id: string, callback?: (data: any) => void) => {
      permissionFunction(permission[Env].GET_REFUND) &&
        dispatch(ActionGetRefundDetail(id, callback));
    },
    [Env, dispatch]
  );
  const handleCreateRefund = useCallback(
    (bodyData: IBodyDataCreate, callback?: (data: any) => void) => {
      permissionFunction(permission[Env].CREATE_REFUND) &&
        dispatch(ActionCreateRefund(bodyData, callback));
    },
    [Env, dispatch]
  );

  return {
    Env,
    authState,
    refundList: refundtState,
    getRefundList: handleGetRefundList,
    getRefundDetail: handleGetRefundDetail,
    createRefund: handleCreateRefund,
    header: {
      val: header,
      set: setHeader,
    },
  };
};

export default useRefund;
