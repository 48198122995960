import React, { useState } from 'react';
import CachedIcon from '@mui/icons-material/Cached';
import { icons } from 'assets';

import Card from 'components/cardComponents';
import { Avatar, Button, Skeleton, ThemeProvider } from '@mui/material';
import { btnOulineTheme } from 'components/muiTheme';
import useNotification from 'hooks/useNotification';
import moment from 'moment';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import { ListIcon } from './listKategory';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { isFullVersion } from 'utils/exception';

const Notifications = () => {
  const { getList, notificationState } = useNotification();

  let { isDetail, detail } = notificationState;

  let { setIsDetail, setDetail, readList } = useNotification();

  const [isRefresh, setIsRefresh] = useState(false);

  const [pageId, setpageId] = useState(1);
  const [totalRecord, settotalRecord] = useState(0);
  const [hasMore, sethasMore] = useState(true);

  let { message_list } = notificationState.data;

  let navigate = useNavigate();

  let listCategory = notificationState?.category;

  const handleRefresh = () => {
    setIsRefresh(true);
    getList(
      { page: 1, limit: 8 },
      (response: any) => {
        if (response.status_code !== 200) console.log('error', 'error');

        if (message_list.length >= totalRecord) {
          sethasMore(false);
        } else {
          sethasMore(true);
        }
        setpageId(1);
        setIsRefresh(false);
      },
      true,
      false
    ); // eslint-disable-next-line
  };

  React.useEffect(() => {
    getList(
      { page: 1, limit: 8 },
      (response: any) => {
        if (response.status_code !== 200) {
          console.log('error');
        } else {
          settotalRecord(response['pagination']['total_records']);
          if (
            message_list.length >= response['pagination']['total_records'] ||
            message_list.length < 1
          ) {
            sethasMore(false);
          }
        }
      },
      true,
      false
    ); // eslint-disable-next-line
  }, []);

  const handleDetail = (id: number) => {
    navigate(`${isFullVersion ? '/full/' : '/'}notifications/${id}`);
    setIsDetail(true);
    setDetail(id);
    readList((response: any) => {
      if (response.status_code !== 200) {
        console.log('error', 'error');
      }
    });
  };

  const fetchMoreData = async () => {
    if (message_list.length >= totalRecord) {
      sethasMore(false);
    }
    setpageId(pageId + 1);
    getList({ page: pageId + 1, limit: 5 }, (response: any) => {
      if (response.status_code !== 200) console.log('error', 'error');
    });
  };

  const handleReadAll = () => {
    readList((response: any) => {
      if (response.status_code !== 200) {
        console.log('error', 'error');
      } else {
        handleRefresh();
      }
    });
  };

  return (
    <div className="overflow-hidden">
      <div></div>

      <div>
        <div className="text-2xl sm:text-3xl flex items-center font-bold mb-5 mt-[5rem] sm:mt-[1rem] cursor-pointer">
          Notifikasi
        </div>
        <Card>
          <div className="lg:flex  justify-between my-[0.3rem] mx-[1rem] px-[1rem] text-[#585F65] border-b-[1px] py-[1.5rem]">
            <div className="font-bold mb-4 text-[1.3rem] text-black">
              Notification List
            </div>
            <div>
              <ThemeProvider theme={btnOulineTheme}>
                <Button
                  variant="outlined"
                  color="secondary"
                  className="mr-5"
                  sx={{
                    marginRight: 2,
                    marginBottom: 2,
                  }}
                  startIcon={<TaskAltOutlinedIcon />}
                  onClick={() => handleReadAll()}
                >
                  Mark all as read
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  sx={{
                    marginRight: 2,
                    marginBottom: 2,
                  }}
                  startIcon={<CachedIcon />}
                  onClick={handleRefresh}
                >
                  {'Refresh'}
                </Button>
              </ThemeProvider>
            </div>
          </div>
          <br />
          <div className="flex w-full h-[60vh]">
            <div
              id="scrollableDiv"
              className={` overflow-y-scroll h-full 
                w-[100%]
            `}
            >
              <InfiniteScroll
                dataLength={message_list.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={<h4>Loading...</h4>}
                scrollableTarget="scrollableDiv"
                endMessage={
                  <div className="m-5 py-5 text-[#707070] flex flex-col justify-center items-center text-center text-xl">
                    <img src={icons.Amplop} width={90} alt="amplop" />
                    <br />
                    <p style={{ textAlign: 'center' }}>
                      <b>That's all your notifications from the last 7 days.</b>
                    </p>
                  </div>
                }
              >
                <div className="h-full pr-3">
                  {isRefresh === true ? (
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                  ) : (
                    message_list.map((e, i) => {
                      let IconsNotif = null;
                      let categoryName = '';

                      // eslint-disable-next-line array-callback-return
                      listCategory?.map((e2) => {
                        // eslint-disable-next-line array-callback-return
                        e2?.topics?.map((data) => {
                          if (
                            e?.message_topic?.toLowerCase() === data?.topic_name
                          ) {
                            IconsNotif = ListIcon[e2?.category_name];
                            categoryName = e2?.category_name;
                          }
                        });
                      });

                      return (
                        <div
                          className={`flex items-center ${!isDetail && 'pb-2'}`}
                        >
                          <div
                            className={`flex w-full justify-around items-center ${
                              e.id === detail &&
                              `bg-[#21af7d13] pr-[20px] py-[10px] rounded-md ${
                                isDetail && 'border-[#21AF7D] border-l-[6px]'
                              }`
                            }  `}
                            onClick={() => {
                              handleDetail(e.id);
                            }}
                          >
                            <div className="w-[70px] p-[1rem] flex justify-center">
                              <img
                                src={IconsNotif || icons.Default}
                                width="80px"
                                height="80px"
                                alt=""
                              />
                            </div>
                            <div className=" w-11/12">
                              <div className="flex items-start justify-between">
                                <div className="font-bold text-[1rem] truncate w-[220px] sm:w-full sm:text-[1.3rem]">
                                  {e.message_title}
                                </div>
                                {e.is_read === 2 && (
                                  <Avatar
                                    sx={{
                                      bgcolor: '#3967E0',
                                      width: '16px',
                                      height: '16px',
                                      marginTop: '3px',
                                    }}
                                  >
                                    {' '}
                                  </Avatar>
                                )}
                              </div>
                              <div className="flex items-center text-[#585F65]">
                                <div className="mr-2 text-[17px]">
                                  {categoryName.charAt(0).toUpperCase() +
                                    categoryName.slice(1)}
                                </div>
                                <div className="text-[14px] mt-1">
                                  <li>
                                    {' '}
                                    {moment(e.message_date)
                                      .endOf('minutes')
                                      .fromNow()}
                                  </li>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </InfiniteScroll>
            </div>
          </div>
        </Card>
        <br />
      </div>
    </div>
  );
};

export default Notifications;
