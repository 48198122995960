import React, { useEffect } from 'react';
import Card from 'components/cardComponents';

import TableWdTransactionList from '../table/tableTransactionListWd';
import { FiArrowLeft } from 'react-icons/fi';
import useWithdrawal from 'hooks/useWithdrawal';
import { WithdrawalContext } from 'context/withdrawalContext';
import { IApiResponse } from 'stores/types/generalTypes';
import WithdrawalInformation from './WithdrawalInformation';
import TotalAmountSummary from './TotalAmountSummary';

const DetailWd = () => {
  let { handleChangeUi, idDetail, handleAlert, paramsDetail } =
    React.useContext(WithdrawalContext);

  const { withdrawal, getWdDetail } = useWithdrawal();

  let { withdrawl_information, summary } = withdrawal.withdrawal_detail;

  useEffect(() => {
    getWdDetail(paramsDetail, idDetail, (response: IApiResponse) => {
      if (response.code !== 200) {
        handleAlert('error', 'Error');
      }
    }); // eslint-disable-next-line
  }, [getWdDetail, paramsDetail, idDetail]);

  let handleProses = () => {
    if (withdrawl_information.status === 'PROCESSING') return 'Process';
    if (withdrawl_information.status === 'SUCCESS') return 'Success';
    if (withdrawl_information.status === 'FAILED') return 'Failed';
  };

  return (
    <>
      <div
        className="text-2xl sm:text-2xl flex items-center font-bold mb-5 mt-[5rem] lg:mt-[1rem] cursor-pointer"
        onClick={() => handleChangeUi('')}
      >
        <FiArrowLeft className="mr-3" style={{ fontSize: 25 }} />
        Detail Withdrawal{' '}
      </div>
      <div className="flex gap-3 max-lg:flex-col">
        <Card>
          <div className="py-5 px-8">
            <div className="text-[16px] mb-5 mt-2 font-bold">
              Withdraw Information
            </div>
            <WithdrawalInformation
              name="Withdrawal Status"
              value={handleProses()}
              withdrawal={withdrawal}
            />
            <WithdrawalInformation
              name="Withdrawal Date"
              value={withdrawl_information.date}
              withdrawal={withdrawal}
            />
            <WithdrawalInformation
              name="Withdrawal No"
              value={withdrawl_information.trx_no}
              withdrawal={withdrawal}
            />
            <WithdrawalInformation
              name="Receipt Number"
              value={withdrawl_information.receipt_no}
              withdrawal={withdrawal}
            />
            <WithdrawalInformation
              name="Total Rec Transaction"
              value={withdrawl_information.total_records}
              withdrawal={withdrawal}
            />
          </div>
        </Card>
        <Card>
          <div className="py-5 px-8">
            <div className="text-[16px] mb-5 mt-2 font-bold">
              Total Amount Summary
            </div>

            <TotalAmountSummary
              name="Total Initial Amount"
              value={summary.amount_initial}
              withdrawal={withdrawal}
            />
            <TotalAmountSummary
              name="Total Trx Amount"
              value={summary.amount_trx}
              withdrawal={withdrawal}
            />
            <TotalAmountSummary
              name="Total WD Settled Fee"
              value={summary.fee_settled}
              withdrawal={withdrawal}
            />
            <TotalAmountSummary
              name="Total WD 3rd Party Fee"
              value={summary.fee_third_party}
              withdrawal={withdrawal}
            />
            <TotalAmountSummary
              name="Withdrawn Amount"
              value={summary.withdrawn}
              withdrawal={withdrawal}
            />
          </div>
        </Card>
      </div>
      <br />
      <Card>
        <div className="py-5 px-8">
          <div className="text-md font-bold py-2">
            Withdrawal Transaction List
          </div>
        </div>
        <TableWdTransactionList />
      </Card>
    </>
  );
};

export default DetailWd;
