import { AxiosError } from 'axios';
import { ActivityData } from 'hooks/useActivityBrowser';

// Action Types
export enum AuthType {
  USER_LOGIN_PENDING = 'USER_LOGIN_PENDING',
  USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS',
  USER_LOGIN_ERROR = 'USER_LOGIN_ERROR',

  GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS',
  GET_PROFILE_FAILED = 'GET_PROFILE_FAILED',
  GET_PROFILE_PENDING = 'GET_PROFILE_PENDING',

  UPLOAD_PROFILE_PHOTO = 'UPLOAD_PROFILE_PHOTO',
  CHANGE_ENV = 'CHANGE_ENV',
  reqProd = 'reqProd',

  UPDATE_USERDATA = 'UPDATE_USERDATA',
}

// IState Login
export interface IAuthState {
  isLoading: boolean;
  userData?: IUserData;
  profile?: IProfileData;
  error?: AxiosError;
  updatePhoto?: null;
  registeredPkg?: string;
}

export interface IChangePassData {
  OldPassword: string;
  NewPassword: string;
  PasswordConfirmation: string;
}

export interface IProfileData {
  ClientId?: number;
  Email?: string;
  EmailConfirmed?: boolean;
  Fullname?: string;
  Id?: number;
  MerchantStatus?: string;
  Phone?: string;
  PhoneConfirmed?: boolean;
  ProfileImage?: null;
  RoleName?: string;
  Status?: string;
  Username?: string;
}

export type encryptProps = {
  version: 'qoinhub' | 'full';
  sandbox?: boolean;
};

export interface IUserData {
  ClientId?: number;
  DeviceId?: null;
  Email?: string;
  EmailConfirmed?: boolean;
  Env?: boolean;
  FirstAlertPasswordExpiryDay?: number;
  Fullname?: string;
  Id?: number;
  InstallId?: null;
  MerchantId?: number;
  PackageType?: null | number;
  PassExpired?: boolean;
  PassExpiredDate?: string;
  Phone?: string;
  PhoneConfirmed?: boolean;
  SecondAlertPasswordExpiryDay?: number;
  Status?: string;
  ThirdAlertPasswordExpiryDay?: number;
  Token?: string;
  Username?: string;
  Permission?: string[];
}

export type registerData = {
  ClientId?: '1';
  Fullname: string;
  Email: string;
  Status?: 'active';
  Username: string;
  ClientWebsite?: string;
  ClientInstagram?: string;
  Phone: string;
  PackageType?: string;
};
export type captchaProps = { VerifyValue: string };

// IAction Login
interface IUserLoginPending {
  type: AuthType.USER_LOGIN_PENDING;
  payload?: any;
}
interface IUserLoginSuccess {
  type: AuthType.USER_LOGIN_SUCCESS;
  payload: {
    userData: IUserLoginResponse;
  };
}
interface IUserLoginError {
  type: AuthType.USER_LOGIN_ERROR;
  payload: {
    error: AxiosError;
  };
}

interface GetProfileUser {
  type: AuthType.GET_PROFILE_SUCCESS;
  payload: IProfileData;
}
interface GetProfileError {
  type: AuthType.GET_PROFILE_FAILED;
  payload: { error: AxiosError };
}
interface GetProfilePending {
  type: AuthType.GET_PROFILE_PENDING;
  payload: null;
}

interface SetProfilePhoto {
  type: AuthType.UPLOAD_PROFILE_PHOTO;
  payload: any;
}

// IBody Login
export interface IUserLoginBodyData {
  email: string;
  password: string;
}

export interface EditProfileData extends ActivityData {
  name: string;
  phone: string;
}

// IResponse Login
export interface IUserLoginResponse {
  code: number;
  message: string;
  data?: IUserData;
}

export interface IUserPasswordResponse {
  code: number;
  status: string;
  message: string;
  data?: null;
}

// handle switch
export interface setEnt {
  type: AuthType.CHANGE_ENV;
  payload: boolean;
}

// handle reqProd
export interface reqProd {
  type: AuthType.reqProd;
  payload?: any;
}
interface updateUserData {
  type: AuthType.UPDATE_USERDATA;
  payload: IUserData;
}

type IProfile =
  | GetProfileUser
  | SetProfilePhoto
  | GetProfileError
  | GetProfilePending
  | updateUserData;
type IUserLogin = IUserLoginPending | IUserLoginSuccess | IUserLoginError;

export type IAuth = IUserLogin | IProfile | setEnt | reqProd;
