import * as Yup from 'yup';
import { emailRegex, passwordRegex, phoneRegex, urlRegex } from './regex';

const req = 'tidak boleh kosong';
const short = 'terlalu pendek';
const long = 'terlalu panjang';
const invalid = 'tidak valid';

const emailSchema1 = Yup.string()
  .required('This is a required field')
  .email('Email must be a valid email');
const emailSchema2 = Yup.string()
  .email()
  .required(`Email ${req}`)
  .max(50, `Email ${long}`)
  .matches(emailRegex, `Format email tidak sesuai`);
const emailSchema3 = Yup.string().matches(emailRegex, `Email tidak valid`);
const passwordSchema = Yup.string()
  .min(8, `Password ${short}`)
  .max(30, `Password ${long}`)
  .matches(passwordRegex, `Format Password tidak sesuai`);
const phoneSchema = Yup.string()
  .required(`Phone ${req}`)
  .min(6, `Nomor HP ${short}`)
  .max(20, `Nomor HP ${long}`)
  .matches(phoneRegex, `Format nomor tidak sesuai`);
// const phoneSchema2 = Yup.string().matches(
//   /^08[0-9]{9,}$/,
//   `Nomor telepon tidak valid`
// );
const zipSchema = Yup.string()
  .required(`Kode POS ${req}`)
  .min(5, `Kode POS ${short}`)
  .max(5, `Kode POS ${long}`)
  .matches(/^\d{5}(?:[-\s]\d{4})?$/, 'Kode POS tidak valid');
const ktpSchema = Yup.string()
  .required(`Nomor KTP ${req}`)
  .min(16, `Panjang KTP ${short}`)
  .max(16, `Panjang KTP ${long}`)
  .matches(/[0-9]$/, `Nomor KTP ${invalid}`);
const npwpSchema = Yup.string()
  .required(`Nomor NPWP ${req}`)
  .min(15, `Panjang Nomor NPWP ${short}`)
  .max(16, `Panjang Nomor NPWP ${long}`)
  .matches(/[0-9]$/, `Nomor NPWP ${invalid}`);

export const RegisterSchema = Yup.object().shape({
  businessBrand: Yup.string().required('Business Brand is required'),
  name: Yup.string().required(`Name ${req}`).max(100, `Nama ${long}`),
  phone: phoneSchema,
  website: Yup.string().matches(urlRegex, 'Website Links is invalid'),
  email: emailSchema2,
  Username: Yup.string()
    .max(100, `Nama Pemilik Usaha ${long}`)
    .required(`Nama Pemilik Usaha ${req}`),
  Fullname: Yup.string()
    .max(100, `Nama Usaha ${long}`)
    .required(`Nama Usaha ${req}`),
});

export const LoginSchema = Yup.object().shape({
  email: emailSchema2,
  password: passwordSchema.required('Password tidak boleh kosong'),
});

export const ValidatePasswordSchema = Yup.object().shape({
  password: passwordSchema.required('Password tidak boleh kosong'),
  passwordConfirmation: Yup.string()
    .required('Password tidak boleh kosong')
    .oneOf([Yup.ref('password'), null], 'Password tidak sama'),
});

export const ForgotPasswordSchema = Yup.object().shape({
  email: emailSchema2,
});

export const InformasiBisnisPemilikSchema = Yup.object().shape({
  BizTypeCode: Yup.string().required(`Tipe Bisnis ${req}`),
  BizCategoryCode: Yup.string().required(`Kategori Bisnis ${req}`),
  BizUrl: Yup.string()
    .max(30, `URL ${long}`)
    .matches(urlRegex, 'URL tidak valid')
    .required(`URL ${req}`),
  PicFinanceEmail: emailSchema3
    .required(`Email ${req}`)
    .max(50, `Email ${long}`),
  PicFinancePhone: phoneSchema,
  PicTechEmail: emailSchema3.required(`Email ${req}`).max(50, `Email ${long}`),
  PicTechPhone: phoneSchema,
  OwnerName: Yup.string()
    .required(`Nama Pemilik ${req}`)
    .max(50, `Nama ${long}`),
  OwnerNationality: Yup.string().required(`${req}`),
  OwnerAddress: Yup.string()
    .required(`Alamat ${req}`)
    .max(50, `Alamat ${long}`),
  OwnerZip: zipSchema,
  OwnerPhone: phoneSchema,
  IdCardName: Yup.string().required(`Nama ${req}`).max(50, `Nama ${long}`),
  IdCardNumber: ktpSchema,
  NpwpName: Yup.string().required(`Nama ${req}`).max(50, `Nama ${long}`),
  NpwpNumber: npwpSchema,
  NpwpAddress: Yup.string().required(`Alamat ${req}`).max(50, `Alamat ${long}`),
  // BankAccountNumber: Yup.string().required('Nomor Rekening tidak boleh kosong')
  //   .min(5, 'Nomor Rekening tidak valid')
  //   .matches(/[0-9]$/, 'Nomor Rekening tidak valid'),
  // urlAPI: Yup.string().matches(urlRegex, 'URL is not Valid'),
  // urlMarketplace: Yup.string().matches(urlRegex, 'URL is not Valid'),
  // urlMediaSosialBisnis: Yup.string().matches(urlRegex, 'URL is not Valid'),
});

export const EditPasswordSchema = Yup.object().shape({
  OldPassword: passwordSchema.required(`Password ${req}`),
  NewPassword: passwordSchema
    .required(`Password ${req}`)
    .notOneOf([Yup.ref(`OldPassword`), null], `Tolong buat password baru`),
  PasswordConfirmation: Yup.string()
    .required(`Password is required`)
    .oneOf([Yup.ref(`NewPassword`), null], `Password baru tidak sama`),
});

export const EditProfileSchema = Yup.object().shape({
  username: Yup.string().required(`Username ${req}`),
  phone: phoneSchema,
});

export const bankSchema = Yup.object().shape({
  BankName: Yup.string().required(`Nama Bank ${req}`),
  BankAccountNumber: Yup.string()
    .required(`Nomor Rekening ${req}`)
    .max(30, `Nomor Rekening ${long}`),
  BankAccountName: Yup.string()
    .required(`Nama Pemilik ${req}`)
    .max(50, `Nama Pemilik ${long}`),
});

export const QRISFormSchema = Yup.object().shape({
  // JenisUsaha: Yup.string().required(),
  // JenisBadanUsaha: Yup.string().required(),
  NamaPemilik: Yup.string(),
  NomorRekening: Yup.number().required(`Nomor Rekening ${req}`),
  // .max(5, `Nomor Rekening ${long}`),
  // JenisIdentitas: Yup.string().required(),
  NoIdentitas: Yup.number()
    .required(`Nomor Identitas ${req}`)
    .max(30, `No Identitas ${long}`),
  NoNPWP: npwpSchema,
  NoTelp: Yup.number()
    .required(`Nomor Telepon ${req}`)
    .max(20, `Nomor Telepon ${long}`),
  // KriteriaMerchant: Yup.string().required(),
  NamaMerchant: Yup.string(),
  // KategoriMerchant: Yup.string().required(),
  Alamat: Yup.string().required().max(255),
  Provinsi: Yup.string().required().max(255),
  Kota: Yup.string().required().max(255),
  Kecamatan: Yup.string().required().max(255),
  KodePos: zipSchema,
});
