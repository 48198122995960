import * as React from 'react';
import update from 'immutability-helper';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { CustomDragLayer } from './CustomDraggable';
import { Container } from './container';

const PaymentMethod = ({
  editView,
  data,
  setData,
  handleSelectChannel,
  handleSelectAllChannel,
  handleChangeFee,
  handleChangeType,
}) => {
  const [dataDragPm, setdataDragPm] = React.useState(null);
  const [dataDragPc, setdataDragPc] = React.useState(null);

  const [pcDrag, setpcDrag] = React.useState();

  const movePaymentMethod = (fromIndex: number, toIndex: number) => {
    setData((prevData) =>
      update(prevData, {
        $splice: [
          [fromIndex, 1],
          [toIndex, 0, prevData[fromIndex]],
        ],
      })
    );
  };

  const findMethod = (fromIndex: number) => {
    const updatedData = [...data];
    const movedProject = updatedData[fromIndex];
    if (movedProject !== dataDragPm) {
      setdataDragPm(movedProject);
    }
  };

  const findChannel = (fromPmId: number, fromPcId: number) => {
    const updatedData = [...data];
    const fromProject = updatedData.find((proj) => proj.id === fromPmId);
    const fromChannel = fromProject.chanels.find(
      (chanel) => chanel.id === fromPcId
    );
    setdataDragPc(fromChannel);
  };

  const moveChannel = (
    fromPmId: number,
    fromIndex: number,
    toIndex: number
  ) => {
    setData((prevData) => {
      const updatedData = [...prevData];
      const paymentMethodIndex = updatedData.findIndex(
        (mtd) => mtd.id === fromPmId
      );

      return update(updatedData, {
        [paymentMethodIndex]: {
          chanels: {
            $splice: [
              [fromIndex, 1],
              [toIndex, 0, updatedData[paymentMethodIndex].chanels[fromIndex]],
            ],
          },
        },
      });
    });
  };

  return (
    <div className="pr-0 lg:pr-5 grid gap-1 w-full">
      <div className="font-bold mb-3">Payment Method List</div>
      <DndProvider backend={HTML5Backend}>
        <Container
          data={data}
          movePaymentMethod={movePaymentMethod}
          moveChannel={moveChannel}
          findMethod={findMethod}
          findChannel={findChannel}
          editView={editView}
          {...{
            pcDrag,
            setpcDrag,
            handleSelectChannel,
            handleSelectAllChannel,
            handleChangeFee,
            handleChangeType,
          }}
        />

        <CustomDragLayer
          {...{ pcDrag, setpcDrag }}
          dataDragPm={dataDragPm}
          dataDragPc={dataDragPc}
        />
      </DndProvider>
    </div>
  );
};

export default PaymentMethod;
