import React from 'react';
import { Accordion, AccordionSummary, Collapse, Skeleton } from '@mui/material';
import Card from 'components/cardComponents';
import { WithdrawalContext } from 'context/withdrawalContext';
import useWithdrawal from 'hooks/useWithdrawal';
import { NumericFormat } from 'react-number-format';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FieldTransferSummary = ({ name, value, isLoading }) => {
  return (
    <div className="flex w-12/12 text-[12px] lg:text-sm mt-1">
      <div className="w-[45%] lg:w-[35%] font-bold">{name}</div>
      <div
        className={`${
          (value === 'Success' && 'font-bold text-[#18AD65]') ||
          (value === 'Process' && 'font-bold  text-[#FBB12F]') ||
          (value === 'Failed' && 'font-bold  text-[#F04545]')
        }  w-[45%] lg:w-[55%]`}
      >
        {isLoading === true ? (
          <Skeleton variant="text" sx={{ width: 100, fontSize: '1rem' }} />
        ) : name === 'Settlement Amount' ? (
          <NumericFormat
            value={value}
            displayType={'text'}
            thousandSeparator={'.'}
            decimalSeparator={','}
            prefix={'Rp. '}
          />
        ) : (
          value || '-'
        )}
      </div>
    </div>
  );
};

const TransferSummary = () => {
  let { handleProses2 } = React.useContext(WithdrawalContext);
  const { withdrawal } = useWithdrawal();
  let { isLoading } = withdrawal;
  // eslint-disable-next-line
  let { transfer_summary, transfer_summary_v2 } = withdrawal.withdrawal_detail;
  const [open, setopen] = React.useState(false);
  console.log(transfer_summary_v2, 'l');

  return (
    <Card>
      <div className="py-5 px-8">
        <div className="text-[16px] mb-5 mt-1 font-bold">Transfer Summary</div>

        {transfer_summary_v2.length > 1 && (
          <>
            {transfer_summary_v2?.map((e) => {
              return (
                <div className="mb-2">
                  <Accordion
                    sx={{ backgroundColor: '#F5F5F5', border: 'none' }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="text-[#21AF7D]" />}
                      id="panel1-header"
                      sx={{ border: 'none' }}
                    >
                      <div className="w-[75%] flex gap-8 font-bold ">
                        <div>Account Name</div>
                        <div>{e?.account_name}</div>
                      </div>
                      <div className="text-[#21AF7D] font-extrabold">
                        Show detail settle info
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div>
                        <FieldTransferSummary
                          name="Transfer Status"
                          value={handleProses2(
                            e?.transfer_status,
                            e?.transfer_status_text
                          )}
                          {...{ isLoading }}
                        />
                        <FieldTransferSummary
                          name="Transfer Number"
                          value={e?.['transfer_number']}
                          {...{ isLoading }}
                        />
                        <FieldTransferSummary
                          name="Bank Name"
                          value={e?.bank_name}
                          {...{ isLoading }}
                        />
                        <FieldTransferSummary
                          name="Account Number"
                          value={e?.account_number}
                          {...{ isLoading }}
                        />
                        <FieldTransferSummary
                          name="Account Name"
                          value={e?.account_name}
                          {...{ isLoading }}
                        />
                        <FieldTransferSummary
                          name="Transfer Date"
                          value={e?.transfer_date}
                          {...{ isLoading }}
                        />
                        <FieldTransferSummary
                          name="Settlement Amount"
                          value={e?.amount_settlement}
                          {...{ isLoading }}
                        />

                        <div className="mt-5 bg-white w-full p-4 rounded-md cursor-pointer">
                          <div
                            className="text-[#21AF7D]"
                            onClick={() => setopen(!open)}
                          >
                            Show less detail
                            {open ? <ExpandLess /> : <ExpandMore />}
                          </div>
                          <Collapse in={open} timeout="auto" unmountOnExit>
                            <div className="flex w-12/12 text-[12px] lg:text-sm mt-1">
                              <div className="w-[45%] lg:w-[35%]">
                                Bank Transfer Fee
                              </div>
                              <NumericFormat
                                value={e?.detail?.fee_bank_transfer}
                                className="text-[#F04545]"
                                displayType={'text'}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                allowNegative
                                prefix={'Rp. '}
                              />
                            </div>
                            <div className="flex w-12/12 text-[12px] lg:text-sm mt-1">
                              <div className="w-[45%] lg:w-[35%]">
                                Service Fee
                              </div>
                              <NumericFormat
                                value={e?.detail?.service_fee}
                                className="text-[#F04545]"
                                displayType={'text'}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                allowNegative
                                prefix={'Rp. '}
                              />
                            </div>
                            <div className="flex w-12/12 text-[12px] lg:text-sm mt-3 pt-3 border-dotted border-t-2">
                              <div className="w-[45%] lg:w-[35%]">
                                Transfer Amount
                              </div>
                              <NumericFormat
                                value={e?.detail?.amount_transfer}
                                className="font-bold"
                                displayType={'text'}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                prefix={'Rp. '}
                              />
                            </div>
                          </Collapse>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              );
            })}
          </>
        )}

        {transfer_summary_v2.length <= 1 && (
          <div>
            <FieldTransferSummary
              name="Transfer Status"
              value={handleProses2(
                transfer_summary_v2[0]?.transfer_status,
                transfer_summary_v2[0]?.['transfer_status_text']
              )}
              {...{ isLoading }}
            />
            <FieldTransferSummary
              name="Transfer Number"
              value={transfer_summary_v2[0]?.['transfer_number']}
              {...{ isLoading }}
            />
            <FieldTransferSummary
              name="Bank Name"
              value={transfer_summary_v2[0]?.bank_name}
              {...{ isLoading }}
            />
            <FieldTransferSummary
              name="Account Number"
              value={transfer_summary_v2[0]?.account_number}
              {...{ isLoading }}
            />
            <FieldTransferSummary
              name="Account Name"
              value={transfer_summary_v2[0]?.account_name}
              {...{ isLoading }}
            />
            <FieldTransferSummary
              name="Transfer Date"
              value={transfer_summary_v2[0]?.transfer_date}
              {...{ isLoading }}
            />
            <FieldTransferSummary
              name="Settlement Amount"
              value={transfer_summary_v2[0]?.amount_settlement}
              {...{ isLoading }}
            />

            <div className="mt-5 bg-[#F9F9F9] w-full p-4 rounded-md cursor-pointer">
              <div className="text-[#21AF7D]" onClick={() => setopen(!open)}>
                Show less detail
                {open ? <ExpandLess /> : <ExpandMore />}
              </div>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <div className="flex w-12/12 text-[12px] lg:text-sm mt-1">
                  <div className="w-[45%] lg:w-[35%]">Bank Transfer Fee</div>
                  <NumericFormat
                    value={-transfer_summary_v2[0]?.detail?.fee_bank_transfer}
                    className="text-[#F04545]"
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    allowNegative
                    prefix={'Rp. '}
                  />
                </div>
                <div className="flex w-12/12 text-[12px] lg:text-sm mt-1">
                  <div className="w-[45%] lg:w-[35%]">Service Fee</div>
                  <NumericFormat
                    value={-transfer_summary_v2[0]?.detail?.service_fee}
                    className="text-[#F04545]"
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    allowNegative
                    prefix={'Rp. '}
                  />
                </div>
                <div className="flex w-12/12 text-[12px] lg:text-sm mt-3 pt-3 border-dotted border-t-2">
                  <div className="w-[45%] lg:w-[35%]">Transfer Amount</div>
                  <NumericFormat
                    value={transfer_summary_v2[0]?.detail?.amount_transfer}
                    className="font-bold"
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    prefix={'Rp. '}
                  />
                </div>
              </Collapse>
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};

export default TransferSummary;
