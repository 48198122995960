import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Switch from '@mui/material/Switch';
import TableRow from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import { colorConfigs, sizeConfigs } from 'components/configs';
import { AppBarProps } from './interface';

// layout
const drawerWidth = sizeConfigs.sidebar.width;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  // backgroundColor: colorConfigs.sidebar.bg,
  color: colorConfigs.sidebar.color,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: 400,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: 100,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  color: colorConfigs.sidebar.color,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: 100,
  }),
  padding: 3,
  paddingLeft: 0,
  paddingRight: 0,
  backgroundColor: colorConfigs.topbar.bg,
  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
  color: '#222222',
  ml: sizeConfigs.sidebar.width,
  width: open
    ? `calc(100% - ${sizeConfigs.sidebar.width})`
    : `calc(100% - ${sizeConfigs.sidebar.miniWidth})`,
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: 400,
    }),
  }),
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

//  layout end

// table
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#EEEEEE',
    color: '#231F20',
    border: 0,
    // justifyContent: 'space-between',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    border: 0,
    width: 'auto',
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#F8F8F8',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
export const StyledTableRowDetail = styled(TableRow)(({ theme }) => ({
  backgroundColor: '#EEEEEE !important',

  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const StyledTableCellWd = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'none',
    color: '#979797',
    border: 0,
    justifyContent: 'space-between',
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: 'none',
    fontSize: 13,
    border: 'none',
    p: 0,
    m: 0,
    color: '#231F20',
    width: 'auto',
    fontWeight: 500,
  },
}));

export const StyledTableRowWd = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#F8F8F8',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

// teble end

export const IOSSwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        border: 0,
        backgroundColor: '#18ad65 !important',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.8 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export const IOSSwitch2 = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        border: 0,
        opacity: 1,
        backgroundColor: '#21AF7D !important',
      },

      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#21AF7D',
      border: '6px solid #fff',
    },

    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
