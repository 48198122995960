/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Badge, Button, ThemeProvider, Toolbar, Zoom } from '@mui/material';
import { icons, img } from 'assets';
import { useEffect, useState } from 'react';
import { PropsCommon } from './interface';
import { RootState } from 'stores';
import { useDispatch, useSelector } from 'react-redux';
import Drawer from 'components/drawer';
import { setDrawerContent } from 'stores/actions/utilsAction';
import { getProfile } from 'stores/actions/authActions';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import PoperNotifications from 'components/notifications/poperNotifiaction';
import useNotification from 'hooks/useNotification';
import { Fade, Paper, Popper, PopperPlacementType } from '@mui/material';
import PocketBase from 'pocketbase';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import useSandbox from 'hooks/useSandbox';
import { btnOulineTheme } from 'components/muiTheme';
import { getLocalItem } from 'utils/localStorage';
import { isFullVersion } from 'utils/exception';

const Topbar = ({ handleDrawerOpen }: PropsCommon) => {
  const { profile } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openNotif, setOpenNotif] = useState(false);
  const [placement, setPlacement] = useState<PopperPlacementType>();

  let sandbox = useSandbox()?.userData['Env'];

  const handleClick =
    (newPlacement: PopperPlacementType) =>
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      setOpenNotif((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  const handleDrawer = () => {
    dispatch(setDrawerContent('user'));
    setOpen(true);
  };

  const handleOutNotif = () => {
    setOpenNotif(false);
  };

  const { getList, notificationState, user, getCategoryMessage } =
    useNotification();
  let { message_top_list } = notificationState.data;

  React.useEffect(() => {
    getCategoryMessage((response: any) => {
      if (response.status_code !== 200) console.log('error', 'error');
    });

    getList(
      { page: 1, limit: -1 },
      (response: any) => {
        if (response.status_code !== 200) console.log('error', 'error');
      },
      false,
      true
    );

    const pb = new PocketBase('https://pb.loyalto.id');

    pb.collection(process.env.REACT_APP_COLLECTIONS).subscribe(
      '*',
      function (e) {
        let data = e.record;
        if (
          data.client_id === user['ClientId'] &&
          data.merchant_id === user['merchant_id'] &&
          data.user_id === user['user_id']
        ) {
          setTimeout(() => {
            getList(
              { page: 1, limit: -1 },
              (response: any) => {
                if (response.status_code !== 200) console.log('error', 'error');
              },
              false,
              true
            );
          }, 2000);
        }
      }
    );

    // const fetchList = async () => {
    //   getList({ page: 1, limit: 10 }, (response: any) => {
    //     if (response.status_code !== 200) console.log('error', 'error');
    //   });

    //   const pb = new PocketBase('https://pb.loyalto.id');

    //   try {
    //     await pb
    //       .collection('users')
    //       .authWithPassword('qoinhub@example.com', 'QoinHub987');
    //     pb.collection('messages').subscribe('*', function (e) {
    //       let data = e.record;
    //       if (
    //         data.client_id === user['ClientId'] &&
    //         data.merchant_id === user['merchant_id'] &&
    //         data.user_id === user['user_id']
    //       ) {
    //         setTimeout(() => {
    //           getList({ page: 1, limit: 10 }, (response: any) => {
    //             if (response.status_code !== 200) console.log('error', 'error');
    //           });
    //         }, 2000);
    //       }
    //     });
    //   } catch (error) {
    //     console.log('Authentication failed:', error);
    //   }
    // };

    // fetchList();
  }, []);

  return (
    <Toolbar>
      <Drawer open={open} onClose={() => setOpen(false)} />
      <Popper
        open={openNotif}
        anchorEl={anchorEl}
        placement={placement}
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={() => {
              if (openNotif) setOpenNotif(!openNotif);
            }}
          >
            <Zoom in={openNotif}>
              <Fade
                {...TransitionProps}
                timeout={10}
                className="cursor-pointer w-[350px] mt-[20px]"
                style={{
                  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
                  borderRadius: 12,
                }}
              >
                <Paper>
                  <div>
                    <PoperNotifications {...{ handleOutNotif }} />
                  </div>
                </Paper>
              </Fade>
            </Zoom>
          </ClickAwayListener>
        )}
      </Popper>
      <div className="flex h-fit w-full bg-[#ffffff] items-center place-content-between">
        <div className="w-fix cursor-pointer" onClick={handleDrawerOpen}>
          <img src={icons.side} alt="side" />
        </div>
        <div className="w-fit flex align-center ">
          {!sandbox && (
            <ThemeProvider theme={btnOulineTheme}>
              <Button
                type="submit"
                className=" flex items-center font-bold"
                variant="outlined"
                onClick={() => {
                  window.location.href = `${
                    isFullVersion
                      ? process.env.REACT_APP_SIMULATOR_URL_FULL
                      : process.env.REACT_APP_SIMULATOR_URL
                  }${getLocalItem('token-web-merchant')}`;
                }}
                color="primary"
              >
                <div className="flex">
                  <PaidOutlinedIcon />
                  <div className="ml-2 font-bold">Payment Simulator</div>
                </div>
              </Button>
            </ThemeProvider>
          )}
          <div
            className="mt-3 ml-7 mr-7 cursor-pointer"
            onClick={handleClick('bottom')}
          >
            <Badge
              color="secondary"
              badgeContent={
                message_top_list.filter((item) => item.is_read === 2).length
              }
              sx={{
                '& .MuiBadge-badge': {
                  backgroundColor: '#FF3535',
                },
              }}
            >
              <img
                src={icons.lonceng}
                alt="loncong"
                className="cursor-pointer"
              />
            </Badge>
          </div>
          <img
            style={{ width: 40, height: 40, borderRadius: '100%' }}
            src={
              profile?.ProfileImage === '' ? img.profile : profile?.ProfileImage
            }
            className="flex align-center border-2 border-[#D3EDE4] mt-1 cursor-pointer object-contain"
            alt="as"
            onClick={handleDrawer}
          />
          <div className="ml-5 text-right pr-2">
            <p
              style={{ fontWeight: 500 }}
              className="text-lg text-[#222222] truncate w-full max-w-[10rem]"
            >
              {profile?.Username || 'Your Name Here'}
            </p>
            <p className="text-sm text-[#736F6F] mt-[-5px] truncate w-full max-w-[10rem]">
              {profile?.Fullname}
            </p>
          </div>
        </div>
      </div>
    </Toolbar>
  );
};

export default Topbar;
