import { AxiosError } from 'axios';
import provider, { IProvider } from 'provider';
import MethodProvider from 'provider/methods';
import { ThunkAction } from 'redux-thunk';
import { RootState } from 'stores';
import { IError } from 'stores/types/errorTypes';
import {
  NotificationType,
  NotificationAction,
} from 'stores/types/notificationTypes';
import { errorGlobal } from './errorAction';
import endpoints from 'api/endpoints';
import { getBrowserId } from './utilsAction';

export const getListPending = (): NotificationAction => ({
  type: NotificationType.GET_LIST_PENDING,
});

export const getListSucces = (data: any): NotificationAction => ({
  type: NotificationType.GET_LIST_SUCCES,
  payload: {
    data,
  },
});

export const getListCategory = (category: any): NotificationAction => ({
  type: NotificationType.GET_LIST_CATEGORY,
  payload: {
    category,
  },
});

export const getListTopSucces = (data: any): NotificationAction => ({
  type: NotificationType.GET_LIST_TOP_SUCCES,
  payload: {
    data,
  },
});

export const refreshListSucces = (data: any): NotificationAction => ({
  type: NotificationType.REFRESH_LIST_SUCCES,
  payload: {
    data,
  },
});

export const getListError = (error: AxiosError): NotificationAction => ({
  type: NotificationType.GET_LIST_ERROR,
  payload: {
    error,
  },
});

export const setDetail = (id: number): NotificationAction => ({
  type: NotificationType.SET_DETAIL,
  payload: {
    detail: id,
    isDetail: true,
  },
});

export const setIsDetail = (isDetail: boolean): NotificationAction => ({
  type: NotificationType.SET_ISDETAIL,
  payload: {
    isDetail,
  },
});

export const getListActions = (
  params: any,
  topbar?: boolean,
  refresh?: boolean,
  callback?: (data: any) => void
): ThunkAction<void, RootState, null, NotificationAction | IError> => {
  return async (dispatch) => {
    dispatch(getListPending());
    try {
      const objProvider: IProvider = {
        method: MethodProvider.GET,
        path: endpoints.notification,
        queryParams: params,
      };
      const response = await provider(objProvider);

      !refresh && !topbar && dispatch(getListSucces(response?.data?.data));
      refresh && dispatch(refreshListSucces(response?.data?.data));
      topbar && dispatch(getListTopSucces(response?.data?.data));

      callback(response?.data);
    } catch (e) {
      dispatch(errorGlobal(e));
      dispatch(getListError(e));
    }
  };
};

export const deteleListActions = (
  params: any,
  callback?: (data: any) => void
): ThunkAction<void, RootState, null, NotificationAction | IError> => {
  return async (dispatch) => {
    dispatch(getListPending());
    try {
      const objProvider: IProvider = {
        method: MethodProvider.GET,
        path: endpoints.notificationDelete,
        queryParams: params,
      };
      const response = await provider(objProvider);
      callback(response?.data);
    } catch (e) {
      dispatch(errorGlobal(e));
      dispatch(getListError(e));
    }
  };
};

export const readListAction = (
  callback: (data: any) => void
): ThunkAction<void, RootState, null, NotificationAction | IError> => {
  return async (dispatch) => {
    let browserId = getBrowserId();
    try {
      const objProvider: IProvider = {
        method: MethodProvider.PUT,
        path: `${endpoints.notificationRead}`,
        data: { browserId },
      };
      let response = await provider(objProvider);
      callback(response?.data);
    } catch (e) {
      callback(e);
      dispatch(errorGlobal(e));
      dispatch(getListError(e));
    }
  };
};

export const getDetail = (
  id: number,
  callback?: (data: any) => void
): ThunkAction<void, RootState, null, NotificationAction | IError> => {
  return async (dispatch) => {
    try {
      const objProvider: IProvider = {
        method: MethodProvider.GET,
        path: `${endpoints.notificationDetail}/${id}`,
      };
      const response = await provider(objProvider);
      callback(response?.data);
    } catch (e) {
      callback(e);
      dispatch(errorGlobal(e));
      dispatch(getListError(e));
    }
  };
};

export const getCategoryMessage = (
  callback?: (data: any) => void
): ThunkAction<void, RootState, null, NotificationAction | IError> => {
  return async (dispatch) => {
    try {
      const objProvider: IProvider = {
        method: MethodProvider.GET,
        path: `${endpoints.categoryMessage}`,
      };
      const response = await provider(objProvider);
      callback(response?.data);
      dispatch(getListCategory(response?.data?.data));
    } catch (e) {
      callback(e);
      dispatch(errorGlobal(e));
      dispatch(getListError(e));
    }
  };
};
