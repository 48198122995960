import { FC } from 'react';
import { NumericFormat } from 'react-number-format';

import { IAmount } from './interface';
import LoadingWrapper from 'components/loadingWrapper';
import { permissionFunction } from 'utils/permission';
import permission from 'constants/permissionRole';

const Amounts: FC<any> = ({
  total_freq_transaction,
  total_mdr,
  total_submerchant,

  isLoading,
}) => {
  const amounts: IAmount[] = [
    {
      id: 1,
      name: 'Total Sub Merchant',
      amount: total_submerchant,
      type: 'number',
    },
    {
      id: 2,
      name: 'Total  Freq Transaction',
      amount: total_freq_transaction,
      type: 'number',
    },
    {
      id: 3,
      name: 'MDR Fee',
      amount: total_mdr,
      type: 'rupiah',
    },
  ];

  return (
    <div className='amountTrx flex w-full mt-3 overflow-auto'>
      {amounts.map((e, i) => {
        return (
          <>
            {}
            <div
              key={i}
              className={`${
                e.id === 1 &&
                !permissionFunction(permission?.UPDATE_AGENCY) &&
                'hidden'
              } mb-2 rounded-lg bg-[#F8F8F8] p-[18px] mr-3 min-w-[249px] min-h-[96px]`}
            >
              <div className='text-[14px] font-medium mb-[16px] text-[#979797]'>
                {e.name}
              </div>

              <div className='text-[15px] font-bold'>
                <LoadingWrapper
                  isLoading={isLoading}
                  fontSize='1.5rem'
                >
                  <>
                    <NumericFormat
                      value={e.amount}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      prefix={`${e?.type === 'rupiah' ? 'Rp. ' : ''}`}
                    />
                  </>
                </LoadingWrapper>
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
};

export default Amounts;
