import React from 'react';
import { Button, Dialog, ThemeProvider } from '@mui/material';
import Circle from 'assets/icons/success-circle.png';
import { btnTheme } from 'components/muiTheme';

interface CustDialog {
  open: boolean;
  onClick?: () => void;
  text?: string;
  subtext?: string;
}

const Component: React.FC<CustDialog> = (props) => {
  const { open, onClick, text, subtext } = props;
  return (
    <ThemeProvider theme={btnTheme}>
      <Dialog
        sx={{ zIndex: 2600 }}
        open={open}
        className="w-4/12 h-2/3 m-auto min-w-[500px]">
        <div className="p-5 md:p-10 text-center space-y-3">
          <img src={Circle} alt="success" className="mx-auto" />
          <p className="font-bold text-lg">{text}</p>
          <p className="px-5">{subtext}</p>
          <Button variant="contained" fullWidth onClick={onClick}>
            OK
          </Button>
        </div>
      </Dialog>
    </ThemeProvider>
  );
};

export default Component;
