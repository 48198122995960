import { colorConfigs } from 'components/configs';
import { colorConfigSandbox } from 'components/configs/colorConfigs';

export const ListItemButtonStyle = (
  appState: any,
  item: any,
  isOpen: boolean,
  env?: boolean,
  inColaps?: boolean
) => {
  return {
    '&: hover': {
      backgroundColor:
        env === false
          ? colorConfigSandbox.sidebar.hoverBg
          : colorConfigs.sidebar.hoverBg,
      color:
        env === false
          ? colorConfigSandbox.sidebar.color
          : colorConfigs.sidebar.color,
      '.imgIcon': {
        filter:
          'invert(68%) sepia(75%) saturate(6112%) hue-rotate(124deg) brightness(100%) contrast(81%)',
      },
      '.borderList': {
        backgroundColor: '#18AD65',
      },
      pl: 3,
    },
    px: 2,
    py: 1.2,
    backgroundColor: appState.includes(item.state)
      ? !env
        ? inColaps
          ? colorConfigSandbox.sidebar.activeBg
          : colorConfigSandbox.sidebar.hoverBg
        : colorConfigs.sidebar.activeBg
      : 'unset',
    '.borderList':
      appState === item.state
        ? { backgroundColor: '#18AD65' }
        : { backgroundColor: !env ? '#18AD65' : '#FFFFFF' },
    color: appState === item.state ? '#18AD65' : !env ? '#18AD65' : '#F2F2F2',
    minHeight: 48,
    justifyContent: isOpen ? 'initial' : 'center',
    my: !isOpen ? 1 : 1,
    mx: !isOpen ? 1 : 2,
    borderRadius: 2,
    '.imgIcon': appState.includes(item.state)
      ? {
          filter:
            'invert(68%) sepia(75%) saturate(6112%) hue-rotate(124deg) brightness(100%) contrast(81%)',
        }
      : !env
      ? {
          filter:
            'invert(68%) sepia(75%) saturate(6112%) hue-rotate(124deg) brightness(100%) contrast(81%)',
        }
      : 'unset',
  };
};
export const ListItemButtonStyleCollaps = (
  open: boolean,
  isOpen: boolean,
  env?: boolean
) => {
  return {
    '&: hover': {
      backgroundColor:
        env === false
          ? colorConfigSandbox.sidebar.hoverBg
          : colorConfigs.sidebar.hoverBg,
      color:
        env === false
          ? colorConfigSandbox.sidebar.color
          : colorConfigs.sidebar.color,
      '.imgIcon': {
        filter:
          'invert(68%) sepia(75%) saturate(6112%) hue-rotate(124deg) brightness(100%) contrast(81%)',
      },
      '.borderList': {
        backgroundColor: '#18AD65',
      },
      pl: 3,
    },
    px: 2,
    py: 1.2,
    backgroundColor: open
      ? !env
        ? colorConfigSandbox.sidebar.hoverBg
        : colorConfigs.sidebar.hoverBg
      : 'unset',
    color: open ? '#18AD65' : env === false ? '#18AD65' : '#F2F2F2',
    minHeight: 48,
    justifyContent: isOpen ? 'initial' : 'center',
    my: !isOpen ? 1 : 1,
    mx: !isOpen ? 1 : 2,
    borderRadius: 2,
    '.imgIcon': open
      ? {
          filter:
            'invert(68%) sepia(75%) saturate(6112%) hue-rotate(124deg) brightness(100%) contrast(81%)',
        }
      : !env
      ? {
          filter:
            'invert(68%) sepia(75%) saturate(6112%) hue-rotate(124deg) brightness(100%) contrast(81%)',
        }
      : 'unset',
  };
};

export const paginationsSx = {
  '&:hover': {
    color: '#18AD65',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#18AD65',
    color: '#18AD65 !important',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#18AD65',
    color: '#18AD65',
  },
  '.MuiSvgIcon-root ': {
    fill: '#21AF7D !important',
  },
  width: 70,
  height: 40,
};

export const ModalSx = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '450px',
  bgcolor: '#FFFFFF',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  '@media (max-width: 576px)': {
    width: '80%',
  },
};
