import type { CSSProperties } from 'react';
import { memo } from 'react';

import PaymentMethodCard from './cardPaymentMethod';
import CardPaymentChannel from './cardPaymentChannel';

const styles: CSSProperties = {
  display: 'inline-block',
};

const BoxDragPreview = ({ itemType, dataDrag }) => {
  return itemType === 'PAYMENTMETHOD' ? (
    <div style={styles} className="shadow-blackbg-white m-0 p-0">
      <PaymentMethodCard method={dataDrag} priview />
    </div>
  ) : (
    <div style={styles} className="shadow-black w-[400px] bg-white m-0 p-0">
      <CardPaymentChannel chanel={dataDrag} priview />
    </div>
  );
};

export default memo(BoxDragPreview);
