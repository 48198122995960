import { AxiosError } from 'axios';
import provider, { IProvider } from 'provider';
import endpoints from 'api/endpoints';
import MethodProvider from 'provider/methods';
import { ThunkAction } from 'redux-thunk';
import { RootState } from 'stores';
import { IError } from 'stores/types/errorTypes';
import {
  IBalanceActions,
  IBalanceState,
  BalanceType,
} from 'stores/types/balanceTypes';
import { IApiResponse } from 'stores/types/generalTypes';
import { errorGlobal } from './errorAction';
import { isFullVersion } from 'utils/exception';

export const setBalancePending = (): IBalanceActions => ({
  type: BalanceType.SET_BALANCE_PENDING,
});

export const getBalanceSuccess = (
  data: IBalanceState['balance']
): IBalanceActions => ({
  type: BalanceType.GET_BALANCE_SUCCESS,
  payload: {
    balance: data,
  },
});

export const setBalanceError = (error: AxiosError): IBalanceActions => ({
  type: BalanceType.SET_BALANCE_ERROR,
  payload: {
    error,
  },
});

export const getSaldoActions = (
  params: any,
  callback?: (data: IApiResponse) => void
): ThunkAction<void, RootState, null, IBalanceActions | IError> => {
  return async (dispatch, getState) => {
    dispatch(setBalancePending());
    let sandbox = getState()?.auth?.userData['Env'];
    try {
      const objProvider: IProvider = {
        payout: isFullVersion,
        sandbox: sandbox,
        method: MethodProvider.GET,
        path: isFullVersion ? endpoints.balance : endpoints.earning,
        queryParams: isFullVersion && params,
      };
      const response = await provider(objProvider);
      let data = isFullVersion
        ? response?.data?.Data.balance
        : response?.data?.data.earning;
      const res = {
        code: response?.status,
        message: 'Success',
        data: response?.data,
        statusCode: response?.data?.StatusCode,
      };
      dispatch(getBalanceSuccess(data));
      callback(res);
    } catch (e) {
      dispatch(errorGlobal(e));
      dispatch(setBalanceError(e));
    }
  };
};
