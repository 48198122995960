import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'stores';
import { permissionFunction } from 'utils/permission';
import permission from 'constants/permissionRole';
import { downloadCSV, downloadExcel } from 'utils/exportExcel';
import { getData } from 'stores/actions/utilsAction';
import endpoints from 'api/endpoints';
import { stateStyles, statusStyles } from 'components/table/DataTableView';
import { toast } from 'react-toastify';
import _ from 'lodash';

const useExport = () => {
  const dispatch = useDispatch();

  const authState = useSelector((state: RootState) => state.auth);
  const Env = authState['Env'] ? 'PRODUCTION' : 'SANDBOX';

  const handlelebel = (row, name) => {
    const StatuslowercaseValue =
      typeof row['status'] === 'number'
        ? row['status']
        : row['status']?.toLowerCase();

    const StatelowercaseValue =
      typeof row['state'] === 'number'
        ? row['state']
        : row['state']?.toLowerCase();

    const statusData = statusStyles[
      typeof row['status'] !== 'number'
        ? Object.keys(statusStyles)?.find((key) =>
            StatuslowercaseValue?.includes(key)
          )
        : row[name]
    ] || {
      bgColor: '#FFF',
      borderColor: '#000',
      textColor: '#000',
      label: '',
    };

    const stateData = stateStyles[
      Object.keys(stateStyles)?.find((key) =>
        StatelowercaseValue?.includes(key)
      )
    ] || {
      bgColor: '#FFF',
      borderColor: '#000',
      textColor: '#000',
      label: row[name],
    };
    return { statusData, stateData };
  };

  const handleExportTransactionList = useCallback(
    async (
      params: any,
      tableNameList: any,
      setisLoadingExport: any,
      type: string
    ) => {
      // // Kunci yang akan dikecualikan
      // const excludeKeys = ['sort_by', 'sort', 'page', 'size'];

      // let keynotempty = Object.keys(params).filter(
      //   (key) => !excludeKeys.includes(key) && params[key] !== ''
      // );

      // console.log(
      //   keynotempty,
      //   keynotempty.length,
      //   keynotempty.length !== 0,
      //   'keynotempty'
      // );

      const queryParams = {
        ...params,
        page: 1,
        size: 2000,
      };

      const path = endpoints.transacionListReportv3;
      if (permissionFunction(permission[Env].LIST_TRANSACTION)) {
        let datafinal = [];
        let currentPage = 1;
        let totalPages = 1;

        let failed;

        do {
          await dispatch(
            // eslint-disable-next-line
            getData(queryParams, path, (res: any) => {
              if (res.responseCode !== 200) {
                setisLoadingExport(false);
                toast.error('Failed', { autoClose: 4000 });
                failed = true;
                return;
              }

              let data = res.data?.['transactions'];

              // totalPages =
              //   keynotempty.length === 0
              //     ? 1
              //     : res.data?.pagination?.last_page || 1;

              totalPages = res.data?.pagination?.last_page;

              data.forEach((e, i) => {
                let entry = {};
                tableNameList?.forEach((value) => {
                  let { statusData, stateData } = handlelebel(e, value?.name);
                  if (value?.name === 'No') {
                    entry[value?.tableName] = (currentPage - 1) * 100 + i + 1;
                  } else if (value?.tableName !== 'Action') {
                    entry[value?.tableName] =
                      value?.name === 'state'
                        ? stateData.label
                        : value?.name === 'status'
                        ? statusData.label
                        : e[value?.name];
                  }
                });
                datafinal.push(entry);
              });
            })
          );

          currentPage++;
          queryParams.page = currentPage; // Update page for next iteration
        } while (currentPage <= totalPages);

        if (!failed) {
          toast.success('Success', { autoClose: 4000 });
          setisLoadingExport(false);

          if (type === 'csv') {
            downloadCSV(datafinal, 'transactionList');
          }

          if (type === 'excel') {
            downloadExcel(datafinal, 'transactionList');
          }
        }
      }
    },
    [Env, dispatch]
  );
  const handleExportRefundList = useCallback(
    (
      params: any,
      handleAlert: any,
      tableNameList: any,
      callback?: (data: any) => void
    ) => {
      const queryParams = {
        ...params,
        page: 1,
        limit: 100,
      };
      const path = endpoints['production'].refundListReport;
      permissionFunction(permission[Env].LIST_REFUND) &&
        dispatch(
          getData(queryParams, path, async (callback) => {
            if (callback['status_code'] !== 200) handleAlert('error', 'Failed');

            let data = callback?.data?.['refund_list'];

            let datafinal = [];

            await data.forEach((e, i) => {
              let entry = {};
              tableNameList.forEach((value) => {
                if (value.name === 'No') {
                  entry[value.tableName] = i + 1;
                } else if (value?.tableName !== 'Action') {
                  entry[value.tableName] = e[value.name];
                }
              });
              datafinal.push(entry);
            });

            downloadExcel(datafinal, 'RefundList');
          })
        );
    },
    [Env, dispatch]
  );

  const handleExportWithdrawelList = useCallback(
    (
      params,
      handleAlert: any,
      tableNameList: any,
      callback?: (data: any) => void
    ) => {
      const queryParams = {
        ...params,
        page: 1,
        size: 100,
      };
      const path = endpoints.withdrawalList;
      permissionFunction(permission[Env].LIST_WITHDARWAL) &&
        dispatch(
          getData(queryParams, path, async (callback) => {
            if (callback.code !== 200) handleAlert('error', 'Failed');

            let data = callback?.data?.['withdrawal_lists'];

            let datafinal = [];

            await data.forEach((e, i) => {
              let entry = {};
              tableNameList.forEach((value) => {
                if (value.name === 'No') {
                  entry[value.tableName] = i + 1;
                } else if (value.name !== 'Action') {
                  entry[value.tableName] = e[value.name];
                }
              });
              datafinal.push(entry);
            });

            downloadExcel(datafinal, 'WithdrawelList');
          })
        );
    },
    [Env, dispatch]
  );

  return {
    exportTransactionList: handleExportTransactionList,
    exportRefundList: handleExportRefundList,
    exportWithdrawelList: handleExportWithdrawelList,
  };
};

export default useExport;
