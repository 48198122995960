import React from 'react';
import { Checkbox, CheckboxProps, ThemeProvider } from '@mui/material';
import { checkboxTheme } from 'components/muiTheme/theme';

type custom_checkbox = CheckboxProps;
export default function CustomCheckbox(props: custom_checkbox) {
  return (
    <ThemeProvider theme={checkboxTheme}>
      <Checkbox sx={{ alignItems: 'flex-start' }} {...props} />
    </ThemeProvider>
  );
}
