/* eslint-disable import/no-anonymous-default-export */
import update from 'immutability-helper';
import { AuthType, IAuth, IAuthState } from 'stores/types/authTypes';

const initialState = {
  isLoading: false,
  userData: {
    Email: '',
    Env: false,
    Fullname: '',
    Id: 0,
    MerchantId: 0,
    PackageType: null,
    Phone: '',
    Token: '',
    Username: '',
  },
  profile: {
    MerchantStatus: '',
    ProfileImage: null,
    Fullname: '',
    Username: '',
    RoleName: '',
    Phone: null,
    Email: '',
  },
  updatePhoto: null,
  registeredPkg: null,
} as IAuthState;

export default (state = initialState, { type, payload }: IAuth) => {
  switch (type) {
    case AuthType.USER_LOGIN_PENDING:
      return { ...state, ...payload, error: null, isLoading: true };
    case AuthType.USER_LOGIN_SUCCESS:
      return { ...state, ...payload, isLoading: false };
    case AuthType.USER_LOGIN_ERROR:
      return initialState;
    case AuthType.GET_PROFILE_PENDING:
      return update(state, { isLoading: { $set: true } });
    case AuthType.GET_PROFILE_FAILED:
      return update(state, {
        isLoading: { $set: false },
        error: { $set: payload.error },
      });
    case AuthType.GET_PROFILE_SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        profile: { $set: payload },
      });
    case AuthType.UPLOAD_PROFILE_PHOTO:
      return update(state, {
        updatePhoto: { $set: payload },
      });
    case AuthType.CHANGE_ENV: {
      return update(state, {
        userData: { Env: { $set: payload } },
      });
    }
    case AuthType.reqProd: {
      return update(state, {
        profile: { MerchantStatus: { $set: 'request to prod' } },
      });
    }
    case AuthType.UPDATE_USERDATA: {
      return update(state, { userData: { $set: payload } });
    }

    default:
      return state;
  }
};
