import React from 'react';
import { Button, ButtonProps, ThemeProvider } from '@mui/material';
import { btnTheme } from 'components/muiTheme';

interface IBasicBtn extends ButtonProps {}
const Component: React.FC<IBasicBtn> = (props) => {
  const { children, variant = 'contained' } = props;
  return (
    <ThemeProvider theme={btnTheme}>
      <Button variant={variant} {...props}>
        {children}
      </Button>
    </ThemeProvider>
  );
};

export default Component;
