/* eslint-disable import/no-anonymous-default-export */

import {
  WithdrawalType,
  IWithdrawalState,
  WithdrawalAction,
} from 'stores/types/withdrawalTypes';
import { PaginationState } from './generalReducer';

const initialState = {
  withdrawal: {
    isLoading: false,
    isLoadingReq: false,
    withdrawal_list: {
      data: [],
      pagination: PaginationState,
    },
    withdrawal_detail: {
      id: '',
      data: [],
      pagination: PaginationState,
      withdrawl_information: {
        status: '',
        date: '',
        trx_no: '',
        receipt_no: '',
        total_records: 0,
      },
      summary: {
        amount_initial: 0,
        amount_trx: 0,
        fee_settled: 0,
        fee_third_party: 0,
        withdrawn: 0,
      },
      settlement_information: {
        settlement_date: '',
        settlement_no: 'WD23070000048',
        settlement_status: 'PROCESS',
        total_records: 1,
      },
      settlement_summary: {
        amount_settlement: 0,
        total_amount_initial: 0,
        total_amount_trx: 0,
        total_fee_admin: 0,
        total_fee_mdr: 0,
        total_fee_settled: 0,
      },
      transfer_summary: {
        account_number: '',
        bank_name: '',
        bank_transfer_fee: 0,
        service_fee: 0,
        transfer_date: '',
        transfer_number: '',
        transfer_status: '',
        amount_settlement: 0,
        amount_transfer: 0,
        account_name: '',
      },
      settlement_summary_list: [],
      transfer_summary_v2: [],
    },
    wd_settled_transactions: {
      data: [],
      summary: {
        total_records: 0,
        total_trx_amount: 0,
        total_fee_third_party: 0,
        total_fee_settled: 0,
        total_fee: 0,
        total_payable_amount: 0,
      },
    },
    error: null,
  },
};

export default (
  state: IWithdrawalState = initialState,
  { type, payload }: WithdrawalAction
) => {
  switch (type) {
    case WithdrawalType.SET_WD_PENDING:
      return {
        ...state,
        withdrawal: { ...state['withdrawal'], isLoading: true, error: null },
      };
    case WithdrawalType.SET_WD_REQ_PENDING:
      return {
        ...state,
        withdrawal: { ...state['withdrawal'], isLoadingReq: true, error: null },
      };
    case WithdrawalType.SET_WD_ERROR:
      return {
        ...state,
        withdrawal: {
          ...initialState['withdrawal'],
          isLoading: false,
          isLoadingReq: false,
          ...payload,
        },
      };
    case WithdrawalType.GET_WD_SETTLED:
      return {
        ...state,
        withdrawal: {
          ...state['withdrawal'],
          isLoadingReq: false,
          wd_settled_transactions: { ...payload },
        },
      };
    case WithdrawalType.SET_WD_REQUEST:
      return {
        ...state,
        withdrawal: {
          ...state['withdrawal'],
          isLoading: false,
        },
      };
    case WithdrawalType.GET_WD_LIST:
      return {
        ...state,
        withdrawal: {
          ...state['withdrawal'],
          isLoading: false,
          withdrawal_list: { ...payload },
        },
      };
    case WithdrawalType.GET_WD_DETAIL:
      return {
        ...state,
        withdrawal: {
          ...state['withdrawal'],
          isLoading: false,
          withdrawal_detail: { ...payload },
        },
      };

    default:
      return state;
  }
};
