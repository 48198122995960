import React from 'react';
import ButtonWithIcon from '../ButtonWithIcon';
import { btnTheme } from 'components/configs/theme';
import { icons } from 'assets';

const Component: React.FC = () => {
  return (
    <ButtonWithIcon
      btnTheme={btnTheme}
      startIcon={icons.sortBy}
      //   onClick,
      variant="outlined"
      color="secondary"
    >
      Sort By
    </ButtonWithIcon>
  );
};

export default Component;
