import { AxiosError } from 'axios';
import { terminal_content } from 'pages/Onboarding/QRIS/section/daftarTerminal';
import { MasterCompanies, MccData, QrBizCriteria } from './qrisType';
// Action Types
export enum OnboardingType {
  TIPE_USAHA = 'TIPE_USAHA',
  TIPE_PRODUK = 'TIPE_PRODUK',
  SET_STEP = 'SET_STEP',
  SET_METODE_PEMBAYARAN = 'SET_METODE_PEMBAYARAN',
  SET_METODE_BY_CARD = 'SET_METODE_BY_CARD',
  SET_METODE_BY_VA = 'SET_METODE_BY_VA',
  SET_METODE_BY_DEBIT = 'SET_METODE_BY_DEBIT',
  SET_METODE_BY_WALLET = 'SET_METODE_BY_WALLET',
  SET_METODE_BY_COUNTER = 'SET_METODE_BY_COUNTER',
  SET_QRIS_FORM = 'SET_QRIS_FORM',

  GET_METODE_PEMBAYARAN_SUCCESS = 'GET_METODE_PEMBAYARAN_SUCCESS',
  GET_METODE_PEMBAYARAN_PENDING = 'GET_METODE_PEMBAYARAN_PENDING',
  GET_METODE_PEMBAYARAN_ERROR = 'GET_METODE_PEMBAYARAN_ERROR',

  GET_METODE_PEMBAYARAN_WITH_SELECTED = 'GET_METODE_PEMBAYARAN_WITH_SELECTED',
  SET_PAYMENT_METHOD_SELECTED = 'SET_PAYMENT_METHOD_SELECTED',
  SET_PAYMENT_CHANNEL_SELECTED = 'SET_PAYMENT_CHANNEL_SELECTED',
  SET_INFORMASI_BISNIS = 'SET_INFORMASI_BISNIS',
  SET_IS_DEV = 'SET_IS_DEV',
  SET_INFORMASI_FOTO_KTP = 'SET_INFORMASI_FOTO_KTP',
  SET_INFORMASI_FOTO_NPWP = 'SET_INFORMASI_FOTO_NPWP',
  UPLOAD_KTP = 'UPLOAD_KTP',
  UPLOAD_NPWP = 'UPLOAD_NPWP',

  GET_LIST_BANK_ERROR = 'GET_LIST_BANK_ERROR',
  GET_LIST_BANK_PENDING = 'GET_LIST_BANK_PENDING',
  GET_LIST_BANK_SUCCESS = 'GET_LIST_BANK_SUCCESS',

  SET_DETAIL_BANK = 'SET_DETAIL_BANK',

  GET_BIZ_TYPE_PENDING = 'GET_BIZ_TYPE_PENDING',
  GET_BIZ_TYPE_ERROR = 'GET_BIZ_TYPE_ERROR',
  GET_BIZ_TYPE_SUCCESS = 'GET_BIZ_TYPE_SUCCESS',

  MERCHANT_PREVIEW_PENDING = 'MERCHANT_PREVIEW_PENDING',
  MERCHANT_PREVIEW_ERROR = 'MERCHANT_PREVIEW_ERROR',
  MERCHANT_PREVIEW_SUCCESS = 'MERCHANT_PREVIEW_SUCCESS',
  NEW_PREVIEW_SUCCESS = 'NEW_PREVIEW_SUCCESS',
  NEW_PAYMENT_SAVED = 'NEW_PAYMENT_SAVED',
  SUBMIT_ONBOARD_LOADING = 'SUBMIT_ONBOARD_LOADING',
  GET_DETAIL_QRIS = 'GET_DETAIL_QRIS',
  CHECK_STATUS_QRIS = 'CHECK_STATUS_QRIS',

  GET_MASTER_MCC = 'GET_MASTER_MCC',
  GET_MASTER_BIZ_CRITERIA = 'GET_MASTER_BIZ_CRITERIA',
  GET_MASTER_COMPANY = 'GET_MASTER_COMPANY',
  GET_DATA_ERROR = 'GET_DATA_ERROR',
  GET_DATA_PENDING = 'GET_DATA_PENDING',

  SET_INFORMASI_BISNIS_UPDATE = 'SET_INFORMASI_BISNIS_UPDATE',
  SET_REKENING_BANK = 'SET_REKENING_BANK',
}

export interface IOnboardingState {
  step: number;
  tipeUsaha: string;
  tipeProduk: string;
  getMetodePembayaran: {
    data: PaymentMethodData[];
    isLoading: boolean;
    error?: AxiosError;
  };
  QRIS?: Partial<QRISFormData>; // data registered QRIS either in onboarding or merchant preview
  metodePembayaran: string[];
  informasiBisnis: InformasiBisnisData;
  detailBank: DetailBankData;
  bizType: {
    data: business_type_cat[];
    isLoading: boolean;
    error?: AxiosError;
  };
  listBank: {
    data: any[];
    isLoading: boolean;
    error?: AxiosError;
  };
  merchantPreview?: {
    data: MerchantPreviewData;
    isLoading: boolean;
    error?: AxiosError;
  };
  newMercPreview?: {
    data: Partial<NewMerchantPreviewData>;
  };
  isLoading?: boolean;
  detailQRIS?: Partial<qrisDetailData>; // data detail QRIS in QRIS Setting
  hasQRIS?: boolean; // penanda apabila cek detail qris success / failed
  masterMCC: MccData[];
  masterBCriteria: QrBizCriteria[];
  masterCompanies: MasterCompanies[];
  infoBizUpdate: Partial<BizUpdate>;
  infoRekening: RekeningUpdate;
}

interface SetInfoBizUpdate {
  type: OnboardingType.SET_INFORMASI_BISNIS_UPDATE;
  payload: BizUpdate;
}
interface SetInfoRekeningUpdate {
  type: OnboardingType.SET_REKENING_BANK;
  payload: RekeningUpdate;
}

interface SubmitOnboardLoading {
  type: OnboardingType.SUBMIT_ONBOARD_LOADING;
  payload: boolean;
}
interface GetMerchantPreview {
  type: OnboardingType.MERCHANT_PREVIEW_SUCCESS;
  payload: MerchantPreviewData;
}
interface GetNewPreview {
  type: OnboardingType.NEW_PREVIEW_SUCCESS;
  payload: NewMerchantPreviewData;
}
// interface SaveNewPayment {
//   type: OnboardingType.NEW_PAYMENT_SAVED;
//   payload: new_payment_submit;
// }
interface MerchantPreviewPending {
  type: OnboardingType.MERCHANT_PREVIEW_PENDING;
  payload: any;
}
interface MerchantPreviewError {
  type: OnboardingType.MERCHANT_PREVIEW_ERROR;
  payload: { error: AxiosError };
}
interface SetTipeUsaha {
  type: OnboardingType.TIPE_USAHA;
  payload: string;
}
interface SetTipeProduk {
  type: OnboardingType.TIPE_PRODUK;
  payload: string;
}

interface SetStepAction {
  type: OnboardingType.SET_STEP;
  payload: number;
}

// SET METODE PEMBAYARAN
interface SetMetodePembayaran {
  type: OnboardingType.SET_METODE_PEMBAYARAN;
  payload: string[];
}

interface GetMetodePembayaranSuccess {
  type: OnboardingType.GET_METODE_PEMBAYARAN_SUCCESS;
  payload: any;
}

interface GetMetodePembayaranPending {
  type: OnboardingType.GET_METODE_PEMBAYARAN_PENDING;
  payload: any;
}

interface GetMetodePembayaranError {
  type: OnboardingType.GET_METODE_PEMBAYARAN_ERROR;
  payload: { error: AxiosError };
}

interface SelectPaymentChannel {
  type: OnboardingType.SET_PAYMENT_CHANNEL_SELECTED;
  payload: string[];
}

interface SetInformasiBisnis {
  type: OnboardingType.SET_INFORMASI_BISNIS;
  payload: InformasiBisnisData;
}
interface SetIsDeveloper {
  type: OnboardingType.SET_IS_DEV;
  payload: boolean;
}
interface SetUploadFotoKTP {
  type: OnboardingType.UPLOAD_KTP;
  payload: null;
}
interface SetUploadFotoNPWP {
  type: OnboardingType.UPLOAD_NPWP;
  payload: null;
}
interface SetInformasiBisnisFotoKTP {
  type: OnboardingType.SET_INFORMASI_FOTO_KTP;
  payload: null;
}
interface SetInformasiBisnisFotoNPWP {
  type: OnboardingType.SET_INFORMASI_FOTO_NPWP;
  payload: null;
}

interface SetDetailBank {
  type: OnboardingType.SET_DETAIL_BANK;
  payload: DetailBankData;
}

interface BizTypeError {
  type: OnboardingType.GET_BIZ_TYPE_ERROR;
  payload: { error: AxiosError };
}

interface BizTypePending {
  type: OnboardingType.GET_BIZ_TYPE_PENDING;
  payload: any;
}
interface GetBizTypeCategory {
  type: OnboardingType.GET_BIZ_TYPE_SUCCESS;
  payload: BizTypeData[];
}

interface GetBankError {
  type: OnboardingType.GET_LIST_BANK_ERROR;
  payload: { error: AxiosError };
}

interface GetBankPending {
  type: OnboardingType.GET_LIST_BANK_PENDING;
  payload: any;
}
interface GetBankList {
  type: OnboardingType.GET_LIST_BANK_SUCCESS;
  payload: any[];
}
interface IQRIS {
  type: OnboardingType.SET_QRIS_FORM;
  payload: Partial<QRISFormData>;
}
interface GetDetailQRIS {
  type: OnboardingType.GET_DETAIL_QRIS;
  payload: qrisDetailData;
}
interface CheckStatusQR {
  type: OnboardingType.CHECK_STATUS_QRIS;
  payload: boolean;
}

interface GetMasterMCC {
  type: OnboardingType.GET_MASTER_MCC;
  payload: MccData[];
}
interface GetMasterBCriteria {
  type: OnboardingType.GET_MASTER_BIZ_CRITERIA;
  payload: QrBizCriteria[];
}
interface GetMasterCompanies {
  type: OnboardingType.GET_MASTER_COMPANY;
  payload: MasterCompanies[];
}
interface StartGetData {
  type: OnboardingType.GET_DATA_PENDING;
  payload: null;
}
interface ErrorGetData {
  type: OnboardingType.GET_DATA_ERROR;
  payload: string;
}

// Data's
export type onboard_qris = {
  BrowserId: string;
  Latitude: string;
  Longitude: string;
  MqAccountNo: string;
  MqBusinessAddress: string;
  MqBusinessType: string;
  MqCategory: string;
  MqCity: string;
  MqCriteria: string;
  MqIdentityNo: string;
  MqIdentityType: string;
  MqMerchantName: string;
  MqMerchantQrisType: string; // 51 | 26
  MqNpwp: string;
  MqOwnerName: string;
  MqPhoneNumber: string;
  MqPostalCode: string;
  MqProvince: string;
  MqSubdistrict: string;
  MqTipProcentage: string; // tips 4, range mungkin 1 - 100 %, bisa dengan koma
  MqTipStatic: string; // tips 3, range mungkin 1 - 999.999
  MqTypeQr: string;
  MqTypeTips: string; // 1 : tanpa tips; 2 : tips input user; 3 : tips static; 4 : procentage
  MqtEmail: string; // terminal
  MqtName: string; // terminal
  MqtNumber: string; // terminal
  MqtTerminalId: string; // terminal
  IdCardOwnerPhoto: File | null;
  ProcurationFile: File | null;
  SkuFile: File | null;
  DeedCompanyFile: File | null; // optional
};
export type onboard_w_qris = Partial<onboard_qris> & {
  BankAccountName: string;
  BankAccountNumber: string;
  BankName: string;
  BizCategoryCode: string;
  BizTypeCode: string;
  BizUrl: string;
  BusinessType: string;
  IdCardName: string;
  IdCardNumber: string;
  IsDeveloper: string;
  MqCountryId?: string;
  NpwpAddress: string;
  NpwpName: string;
  NpwpNumber: string;
  OwnerAddress: string;
  OwnerName: string;
  OwnerNationality: string;
  OwnerPhone: string;
  OwnerZip: string;
  PackageType: string;
  Payments: string; // JSON stringify of { id: number; channels: number[] }[]
  PicFinanceEmail: string;
  PicFinancePhone: string;
  PicTechEmail: string;
  PicTechPhone: string;
};
export type qr_status = 0 | 1 | 2;
export type qrisDetailData = {
  mq_id: number;
  m_id: number;
  mq_merchant_id: string;
  mq_merchant_code: string;
  mq_merchant_name: string;
  mq_nmid: string;
  mq_qoin_id: string;
  mq_owner_name: string;
  mq_account_no: string;
  mq_identity_type: number;
  mq_identity_no: string;
  mq_criteria: string;
  mq_category: number;
  mq_business_address: string;
  mq_country_id: string;
  mq_province: string;
  mq_city: string;
  mq_subdistrict: string;
  mq_postal_code: string;
  mq_type_qr: number;
  mq_npwp: string;
  mq_phone_number: string;
  mq_email: string;
  mq_pic_name: string;
  mq_owner_address: string;
  mq_status: qr_status;
  mq_merchant_qris_type: string;
  mq_type_tips: number;
  mq_tip_static: number;
  mq_tip_procentage: number;
  mq_business_type: number;
  mq_ktp_url: string;
  mq_sku_url: string;
  mq_akta_url: string;
  mq_kuasa_url: string;
  mqt_id: number;
  mqt_name: string;
  mqt_terminal_id: string;
  mqt_qr_static: string;
  mqt_status: number;
  mqt_phone: string;
  mqt_email: string;
  mqt_dynamic_client_id: string;
  mqt_dynamic_client_secret: string;
  mqt_static_client_id: string;
  mqt_static_client_secret: string;
};

export interface MerchantPreviewData {
  business_type: number;
  package_type: number;
  bis_type_code: string;
  bis_category_code: string;
  bis_url: string;
  is_developer: number;
  finance_email: string;
  finance_phone: string;
  tech_email: string;
  tech_phone: string;
  own_name?: string;
  own_address: string;
  own_zip: string;
  own_phone: string;
  own_citizen: string;
  ktp_name: string;
  ktp_no: string;
  ktp_url: string;
  npwp_name: string;
  npwp_number: string;
  npwp_address: string;
  npwp_url: string;
  bank: string;
  bank_acc_no: string;
  bank_acc_name: string;
  user_id: number;
  merchant_id: number;
  merchant_code: string;
  payments: string[];
  state: number;
  status: number;
  info_status: string;
}
// type new_payment_submit = { id: number; channel?: string[] | string }[];
type new_pay_2 = new_pay & { chanels?: new_pay[] };
export type NewMerchantPreviewData = Omit<MerchantPreviewData, 'payments'> & {
  payments: new_pay_2[];
};
export type new_pay = {
  id: number;
  name: string;
  page_id: number;
  picture: { url?: string };
};

export interface BizTypeData {
  categories: BizCategoryData[];
  code: string;
  id: number;
  name: string;
  status: number;
}

interface BizCategoryData {
  code: string;
  id: number;
  name: string;
  status: number;
  type: string;
}

export type info_biz_values = {
  BizTypeCode: string;
  BizCategoryCode: string;
  BizUrl: string;
  PicFinanceEmail: string;
  PicFinancePhone: string;
  PicTechEmail: string;
  PicTechPhone: string;
  OwnerName: string;
  OwnerNationality: string;
  OwnerAddress: string;
  OwnerZip: string;
  OwnerPhone: string;
  IdCardName: string;
  IdCardNumber: string;
  NpwpName: string;
  NpwpNumber: string;
  NpwpAddress: string;
};
export type InformasiBisnisData = info_biz_values & {
  IsDeveloper?: boolean;
  IdCardPhoto: null;
  IdCardPhotoFile: File | null;
  NpwpPhoto: null;
  NpwpPhotoFile: File | null;
};

export type DetailBankData = {
  BankName: string;
  BankAccountName: string;
  BankAccountNumber: string;
  // BankAccountPhoto?: string;
};

export interface PaymentMethodData {
  id: number;
  name: string;
  pict_url: string;
  Channels?: ChannelData[];
}

export type QRISFormData = {
  JenisQR?: string;
  KategoriQR?: string;
  allowTip?: boolean;
  JenisUsaha?: string;
  JenisBadanUsaha?: string;
  NamaPemilik?: string;
  NomorRekening?: string;
  JenisIdentitas?: string;
  NoIdentitas?: string;
  NoNPWP?: string;
  NoTelp?: string;
  KriteriaMerchant?: string;
  NamaMerchant?: string;
  KategoriMerchant?: string;
  Alamat?: string;
  Provinsi?: string;
  Kota?: string;
  Kecamatan?: string;
  KodePos?: string;
  Terminals?: terminal_content[];
  PhotoKTP?: null | string;
  FileKTP?: File | null;
  PhotoSKU?: null | string;
  FileSKU?: File | null;
  PhotoAkta?: null | string;
  FileAkta?: File | null;
  PhotoSKA?: null | string;
  FileSKA?: File | null;
  NominalTips?: string;
  TipeTips?: string;
};

export interface ChannelData {
  id: number;
  code: string;
  name: string;
  pict_url: string;
}

export interface submitOnboardingData {
  BusinessType: string;
  PackageType: string;
  BizTypeCode: string;
  BizCategoryCode: string;
  BizUrl: string;
  IsDeveloper: string;
  PicFinanceEmail: string;
  PicFinancePhone: string;
  PicTechEmail: string;
  PicTechPhone: string;
  OwnerName: string;
  OwnerAddress: string;
  OwnerZip: string;
  OwnerPhone: string;
  OwnerNationality: string;
  IdCardPhoto: string;
  IdCardName: string;
  IdCardNumber: string;
  NpwpPhoto: string;
  NpwpName: string;
  NpwpNumber: string;
  NpwpAddress: string;
  BankName: string;
  BankAccountNumber: string;
  BankAccountName: string;
  Payments: string;
}

export type business_type_cat = {
  categories?: business_cat_only[];
  code: string;
  id: number;
  name: string;
  status: number;
};
type business_cat_only = {
  id: number;
  type: string;
  code: string;
  name: string;
  status: number;
};

export type BizUpdate = {
  category: string;
  industry: string;
  barangJasa: string;
  jualanOnline: string;
  linkBarangJasa: string;
  fotoLokasi: File;
  fileKatalog: File;
  sistemBayar: string;
  lokasiUsaha: string;
  kodePos: string;
  posisiJabatan: string;
  ownerKTP: string;
  ownerPhone: string;
  fileOwnerKTP: File;
  fileOwnerNPWP: File;
  fileOwnerRekening: File;

  // on QRIS
  estimasiTransaksi: string;
  ownQRIS: string; // yes or not, if yes fill the name
  registeredQRIS: string;

  jenisPerusahaan: string;
  shareHolderName: string;
  shareholderPhone: string;
  registrantKTP: File;
  directorKTP: File;
  shareHolderKTP: File;
  companyNPWP: File;
  nibTdpSiup: File;
  aktaFile: File;
  SKFile: File;
  updateAktaFile: File;
  updateSKFile: File;
};

export type RekeningUpdate = {
  bankName: string;
  bankAccount: string;
  bankAccountName: string;
};

export interface IOnboardingResponse {
  onboarding: {};
}

type MCCnCriteria =
  | GetMasterMCC
  | GetMasterBCriteria
  | GetMasterCompanies
  | StartGetData
  | ErrorGetData;
type OnAction =
  | SetStepAction
  | SetInformasiBisnis
  | SetUploadFotoKTP
  | SetUploadFotoNPWP
  | SetInformasiBisnisFotoKTP
  | SetInformasiBisnisFotoNPWP
  | GetMetodePembayaranSuccess
  | SetTipeUsaha
  | SetTipeProduk
  | SetMetodePembayaran
  | SetDetailBank
  | SetIsDeveloper
  | GetBizTypeCategory
  | BizTypeError
  | BizTypePending
  | GetBankList
  | GetBankError
  | GetBankPending
  | SelectPaymentChannel
  | GetMerchantPreview
  | GetMetodePembayaranError
  | GetMetodePembayaranPending
  | MerchantPreviewPending
  | MerchantPreviewError
  | IQRIS
  | GetNewPreview
  | SubmitOnboardLoading
  | GetDetailQRIS
  | CheckStatusQR
  | MCCnCriteria
  | SetInfoBizUpdate
  | SetInfoRekeningUpdate;

export type OnboardingAction = OnAction;
