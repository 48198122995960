import axios, { sandboxInstance } from './axios';
import { getLocalItem, setLocalItem } from 'utils/localStorage';
import axios2 from 'axios';

export const config = (header = null) => {
  return {
    Accept: 'application/json',
    locale: 'en',
    Authorization: `Bearer ${getLocalItem('token-web-merchant')}`,
    // 'X-XSRF-TOKEN': getCookie('xsrf-token'),
    ...header,
  };
};

export const configTextPlain = () => {
  return {
    Accept: 'application/json',
    locale: 'en',
    Authorization: `Bearer ${getLocalItem('token-web-merchant')}`,
    'Content-Type': 'text/plain',
  };
};

export const configNoAuth = () => {
  return {
    Accept: 'application/json',
    locale: 'en',
  };
};

const configPut = () => {
  return {
    Accept: 'application/json',
    Authorization: `Bearer ${getLocalItem('token-web-merchant')}`,
    'Content-Type': 'application/x-www-form-urlencoded',
    // 'X-XSRF-TOKEN': getCookie('xsrf-token'),
  };
};

const configPutJson = () => {
  return {
    Accept: 'application/json',
    Authorization: `Bearer ${getLocalItem('token-web-merchant')}`,
    'Content-Type': 'application/json',
    // 'X-XSRF-TOKEN': getCookie('xsrf-token'),
  };
};

const configPostJSON = () => {
  return {
    Accept: 'application/json',
    locale: 'en',
    Authorization: `Bearer ${getLocalItem('token-web-merchant')}`,
    'Content-Type': 'application/json',
    // 'X-XSRF-TOKEN': getCookie('xsrf-token'),
  };
};

// axios.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const config = error?.config;

//     if (error?.response?.status === 401 && !config?.sent) {
//       config.sent = true;

//       try {
//         generateRefreshToken();
//       } catch (err) {
//         removeLocalItem('token-web-merchant');
//         window.location.replace(`${isFullVersion ? '/full/' : '/'}login`);
//       }

//       return axios(config);
//     } else return Promise.reject(error);
//   }
// );

export const generateXSRFToken = async () => {
  const response = await axios.post('v2/tok', null, {
    headers: configPostJSON(),
  });

  setLocalItem('xsrf-token', response?.data?.data?._tok);
};

export const apiGet = (url, isProduction = false) => {
  if (isProduction) {
    const response = axios
      .get(url, {
        headers: config(),
      })
      .then((response) => response)
      .catch((err) => {
        return err.response;
      });
    return response;
  } else {
    const response = sandboxInstance
      .get(url, {
        headers: config(),
      })
      .then((response) => response)
      .catch((err) => {
        return err.response;
      });
    return response;
  }
};

export const apiGetWithBody = async (url, body) => {
  const qs = require('qs');
  const response = await axios2
    .get(url, {
      params: body,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'brackets' }),
      headers: configPostJSON(),
    })
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
  return response;
};

export const apiDelete = async (url, isProduction = false) => {
  await generateXSRFToken();
  if (isProduction) {
    const response = await axios
      .delete(url, {
        headers: config(),
      })
      .then((response) => response)
      .catch((err) => {
        return err.response;
      });

    return response;
  } else {
    const response = await sandboxInstance
      .delete(url, {
        headers: config(),
      })
      .then((response) => response)
      .catch((err) => {
        return err.response;
      });

    return response;
  }
};

export const postConfig = {
  standard: config(),
  plain: configTextPlain(),
  json: configPostJSON(),
  none: configNoAuth(),
};

export const putConfig = {
  standard: configPut(),
  json: configPutJson(),
};

// new version (compiled apiPost & apiPut)
// choose headerConfig based on config above, ie: postConfig.plain
export const apiPost = async (
  url,
  body,
  isProduction = false,
  headerConfig = config()
) => {
  // await generateXSRFToken()
  if (isProduction) {
    const response = await axios
      .post(url, body, { headers: headerConfig })
      .then((response) => response)
      .catch((err) => {
        return err.response;
      });
    return response;
  } else {
    const response = await sandboxInstance
      .post(url, body, { headers: headerConfig })
      .then((response) => response)
      .catch((err) => {
        return err.response;
      });
    return response;
  }
};

export const apiPut = async (url, data, isProduction = false, headerConfig) => {
  // await generateXSRFToken();
  if (isProduction) {
    const response = axios
      .put(url, data, { headers: headerConfig })
      .then((response) => response)
      .catch((err) => {
        return err.response;
      });
    return response;
  } else {
    const response = sandboxInstance
      .put(url, data, { headers: headerConfig })
      .then((response) => response)
      .catch((err) => {
        return err.response;
      });
    return response;
  }
};
