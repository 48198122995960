import { AxiosError } from 'axios';
import { ThunkAction } from 'redux-thunk';
import {
  RefundAction,
  IRefundState,
  RefundType,
  IRefund_list,
  IBodyDataCreate,
} from 'stores/types/refundTypes';
import provider, { IProvider } from 'provider';
import MethodProvider from 'provider/methods';
import { RootState } from 'stores';
import { IError } from 'stores/types/errorTypes';
import { errorGlobal } from './errorAction';
import endpoints from 'api/endpoints';
import { IApiResponse2, IPagination2 } from 'stores/types/generalTypes';
import { generateXSRFToken } from './utilsAction';
import { getCookie } from 'utils/cookie';

export const RefundList_ReportPending = (): RefundAction => ({
  type: RefundType.SET_REFUND_LIST_PENDING,
});
export const RefundDetail_ReportPending = (): RefundAction => ({
  type: RefundType.SET_REFUND_DETAIL_PENDING,
});
export const CreateRefundSucces = (): RefundAction => ({
  type: RefundType.SET_REFUND_CREATE_SUCCES,
});

export const RefundList_ReportSucess = (data: {
  pagination: IPagination2;
  refundList: IRefund_list;
}): RefundAction => ({
  type: RefundType.GET_REFUND_LIST_SUCCESS,
  payload: data,
});

export const IGetDetailRefund = (
  data: IRefundState['detailRefund']
): RefundAction => ({
  type: RefundType.GET_REFUND_DETAIL,
  payload: {
    detailRefund: {
      ref_no: data.ref_no,
      created: data.created,
      payment: data.payment,
      payer_email: data.payer_email,
      amount: data.amount,
      status: data.status,
      payer_name: data.payer_name,
      payer_phone: data.payer_phone,
    },
  },
});

export const RefundList_ReportError = (error: AxiosError): RefundAction => ({
  type: RefundType.SET_REFUND_LIST_ERROR,
  payload: {
    error,
  },
});
export const GetRefundList_ReportError = (error: AxiosError): RefundAction => ({
  type: RefundType.SET_GETREFUND_LIST_ERROR,
  payload: {
    error,
  },
});

export const ActionGetRefundList_Report = (
  params?: any,
  callback?: (data: IApiResponse2) => void
): ThunkAction<void, RootState, null, RefundAction | IError> => {
  return async (dispatch, getState) => {
    dispatch(RefundList_ReportPending());
    let sandbox = getState()?.auth?.userData['Env'];
    try {
      const objProvider: IProvider = {
        sandbox: sandbox,
        method: MethodProvider.GET,
        path: endpoints['production'].refundListReport,
        queryParams: params,
      };
      const response = await provider(objProvider);
      if (response?.data?.status_code === 200) {
        let data = {
          pagination: response?.data?.pagination,
          refundList: response?.data?.data?.refund_list,
        };
        dispatch(RefundList_ReportSucess(data));
        callback(response?.data);
      }
    } catch (e) {
      dispatch(errorGlobal(e));
      callback(e);
      dispatch(GetRefundList_ReportError(e));
    }
  };
};

export const ActionGetRefundDetail = (
  id: string,
  callback?: (data: IApiResponse2) => void
): ThunkAction<void, RootState, null, RefundAction | IError> => {
  return async (dispatch, getState) => {
    dispatch(RefundDetail_ReportPending());
    let sandbox = getState()?.auth?.userData['Env'];
    try {
      const objProvider: IProvider = {
        sandbox: sandbox,
        method: MethodProvider.GET,
        path: `${endpoints['production'].refundListReportDetail}/${id}`,
      };
      const response = await provider(objProvider);
      response?.data?.status_code === 200 &&
        dispatch(IGetDetailRefund(response?.data?.data));
      callback(response?.data);
    } catch (e) {
      dispatch(errorGlobal(e));
      callback(e);
      dispatch(RefundList_ReportError(e));
    }
  };
};
export const ActionCreateRefund = (
  bodyData: IBodyDataCreate,
  callback?: (data: IApiResponse2) => void
): ThunkAction<void, RootState, null, RefundAction | IError> => {
  return async (dispatch, getState) => {
    dispatch(RefundList_ReportPending());
    let sandbox = getState()?.auth?.userData['Env'];

    await generateXSRFToken(sandbox);

    try {
      const objProvider: IProvider = {
        sandbox: sandbox,
        method: MethodProvider.POST,
        path: `${endpoints['production'].refundCreate}`,
        data: bodyData,
        headers: {
          'X-Xsrf-Token': getCookie('xsrf-token'),
        },
      };
      const response = await provider(objProvider);
      callback(response?.data);
      dispatch(CreateRefundSucces());
    } catch (e) {
      dispatch(errorGlobal(e));
      callback(e);
      dispatch(RefundList_ReportError(e));
    }
  };
};
