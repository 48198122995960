import { AxiosError } from 'axios';
import { IPaginationV2 } from './generalTypes';

export enum DashboardType {
  DASHBOARD_PENDING = 'DASHBOARD_PENDING',
  DASHBOARD_SUCCES = 'DASHBOARD_SUCCES',
  DASHBOARD_ERROR = 'DASHBOARD_ERROR',
  DASHBOARD_GET_TOTAL_TRANSACTION = 'DASHBOARD_GET_TOTAL_TRANSACTION',
  DASHBOARD_GET_TOTAL_INCOME = 'DASHBOARD_GET_TOTAL_INCOME',
  DASHBOARD_GET_INCOME_FREQ = 'DASHBOARD_GET_INCOME_FREQ',
  DASHBOARD_GET_TRANSACTION_FREQ = 'DASHBOARD_GET_TRANSACTION_FREQ',
  DASHBOARD_GET_TRANSACTION_BYPM = 'DASHBOARD_GET_TRANSACTION_BYPM',
  DASHBOARD_GET_TRANSACTION_TOP10 = 'DASHBOARD_GET_TRANSACTION_TOP10',
  DASHBOARD_SET_DTA_V2 = 'DASHBOARD_SET_DTA_V2',
}

export interface IDashboardState {
  totalTransaction: Array<string>;
  sumTransaction: number;
  labelStatusTransaction: Array<string>;
  colorTransaction: Array<string>;
  totalIncome: Array<string>;
  sumIncome: number;
  labelStatusIncome: Array<string>;
  colorIncome: Array<string>;
  transactionFreq: iFreq;
  incomeFreq: iFreq;
  isLoading: boolean;
  isError: boolean;
  error: AxiosError;
  freqTransactionByPm: iDataBar;
  top10Product: iDataBar;
  datav2: IDatav2;
}

export interface IDatav2 {
  filter_params: Record<string, any>;
  summary: {
    total_freq_transaction: number;
    total_transaction_amount: number;
  };
  charts: IChart;
  transactions: Array<object>;
  pagination: Record<string, any> | IPaginationV2;
}

interface IChart {
  transaction_status: object | IDataChart;
  total_trx_by_issuer: object | IDataChart;
  total_trx_by_payment_method: object | IDataChart;
  top_ten_merchant: object | IDataChart;
}

interface IDataChart {
  labels: Array<string | number>;
  datasets: {
    data: Array<string | number>;
    rupiah: Array<string | number>;
    background_color: Array<string>;
  };
}
interface iDataBar {
  labels: Array<string>;
  jumlahTransaksi: Array<string>;
}

interface iFreq {
  data: Array<any>;
  dataLabel: Array<string>;
}

interface setDatav2 {
  type: DashboardType.DASHBOARD_SET_DTA_V2;
  payload: IDatav2;
}
interface GetTotalTransaction {
  type: DashboardType.DASHBOARD_GET_TOTAL_TRANSACTION;
  payload: {
    totalTransaction: Array<string>;
    sumTransaction: number;
    labelStatusTransaction: Array<string>;
    colorTransaction: Array<string>;
  };
}

interface GetTotalIncome {
  type: DashboardType.DASHBOARD_GET_TOTAL_INCOME;
  payload: {
    totalIncome: Array<string>;
    sumIncome: number;
    labelStatusIncome: Array<string>;
    colorIncome: Array<string>;
  };
}

interface GetTransactionFreq {
  type: DashboardType.DASHBOARD_GET_TRANSACTION_FREQ;
  payload: {
    transactionFreq: {
      data: Array<any>;
      dataLabel: Array<string>;
    };
  };
}

interface GetIncomeFreq {
  type: DashboardType.DASHBOARD_GET_INCOME_FREQ;
  payload: {
    incomeFreq: {
      data: Array<any>;
      dataLabel: Array<string>;
    };
  };
}
interface getTransaction10 {
  type: DashboardType.DASHBOARD_GET_TRANSACTION_TOP10;
  payload: {
    top10Product: {
      labels: Array<any>;
      jumlahTransaksi: Array<string>;
    };
  };
}

interface getTransactionBypm {
  type: DashboardType.DASHBOARD_GET_TRANSACTION_BYPM;
  payload: {
    freqTransactionByPm: {
      labels: Array<any>;
      jumlahTransaksi: Array<string>;
    };
  };
}

interface SetLoadingAction {
  type: DashboardType.DASHBOARD_PENDING;
  payload?: any;
}

// error?.message
interface SetErrorAction {
  type: DashboardType.DASHBOARD_ERROR;
  payload: {
    error: AxiosError;
  };
}

export interface IResponse {
  code: number;
  message: string;
  data?: Object;
}

export type DashboardAction =
  | setDatav2
  | GetTotalTransaction
  | GetTotalIncome
  | getTransactionBypm
  | getTransaction10
  | SetLoadingAction
  | GetIncomeFreq
  | GetTransactionFreq
  | SetErrorAction;
