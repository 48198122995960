import React from 'react';
import Card from 'components/cardComponents';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, ThemeProvider } from '@mui/material';
import { btnOulineTheme, btnTheme2 } from 'components/muiTheme';
import useRefund from 'hooks/useRefund';
import { RefundContext } from 'context/refundContext';
import DetailRefundItem, { WrapDetailRefundItem } from './DetailRefundItem';
import ModalCard from './ModalCard';

const RefundCheck = () => {
  let { handleOpenModal, setisDetail, transactionNo, setReason, reason } =
    React.useContext(RefundContext);

  const { refundList } = useRefund();

  let { detailRefund } = refundList;

  const tanggal = new Intl.DateTimeFormat('id', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(new Date());

  const handleReason = () => {
    return (
      <div>
        <form>
          <textarea
            className="px-3 h-[82px] mt-1 w-[300px] md:w-[431px] py-2 border-[1px] rounded-sm outline-none border-[#94A3B8] "
            placeholder={'Type reason'}
            name="reason"
            value={reason}
            onChange={(e) => setReason(e.target.value as string)}
          />
        </form>
      </div>
    );
  };

  const handleStatus = (code: number) => {
    if (code === 0) return 'On-Process';
    if (code === 1) return 'Success - Paid';
    if (code === 2) return 'Failed';
  };

  return (
    <>
      <ModalCard />
      <Card>
        <div className="py-5 px-8 w-[60rem] md:w-[80rem] ">
          <div className="text-sm mb-2 mt-2">Transaction number</div>
          <div className="text-lg font-bold">{transactionNo}</div>

          <div className="flex flex-col gap-9 mt-10">
            <WrapDetailRefundItem name="Transaction Detail">
              <DetailRefundItem
                name="Reference Number"
                value={detailRefund?.ref_no || '-'}
              />
              <DetailRefundItem
                name="Transaction Date"
                value={detailRefund.created}
              />
              <DetailRefundItem
                name="Channel"
                value={detailRefund?.payment || '-'}
              />
              <DetailRefundItem
                name="Customer Email"
                value={detailRefund.payer_email}
              />
              <DetailRefundItem
                name="Transaction Amount"
                value={detailRefund.amount}
              />
              <DetailRefundItem
                name="Transaction Status"
                value={handleStatus(detailRefund.status) || '-'}
              />
            </WrapDetailRefundItem>

            <WrapDetailRefundItem name="Customer Information<">
              <DetailRefundItem
                name="Name"
                value={detailRefund.payer_name || '-'}
              />
              <DetailRefundItem
                name="Phone Number"
                value={detailRefund.payer_phone || '-'}
              />
              <DetailRefundItem name="Request Date" value={tanggal || '-'} />
              <div className="hidden md:block">
                <DetailRefundItem
                  name="Reason to Refund"
                  value={handleReason() || '-'}
                />
              </div>

              <div className="md:hidden">
                <DetailRefundItem name="Reason to Refund" value="" />
                {handleReason()}
              </div>
            </WrapDetailRefundItem>
          </div>
        </div>
      </Card>

      <div className="flex mt-8 justify-end">
        <ThemeProvider theme={btnOulineTheme}>
          <Button
            variant="outlined"
            color="info"
            sx={{
              width: '200px',
              marginRight: 3,
              fontSize: '12px',
            }}
            onClick={() => setisDetail(false)}
          >
            Cancel
          </Button>
        </ThemeProvider>
        <ThemeProvider theme={btnTheme2}>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={false}
            sx={{ width: '200px', fontSize: '12px', p: 1.3 }}
            onClick={handleOpenModal}
            disabled={reason ? false : true}
          >
            Submit
          </LoadingButton>
        </ThemeProvider>
      </div>
      <br />
    </>
  );
};

export default RefundCheck;
