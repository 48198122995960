import React, { useState } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { Dayjs } from 'dayjs';
import useReport from 'hooks/useTransaction';
import { IApiResponse } from 'stores/types/generalTypes';
import moment from 'moment';
import { tableNameList } from 'pages/laporan/transaction/table/dataList';

export const TransactionContext = React.createContext(null);

const TransactionProvider = (props) => {
  const { TransactionList, getDetail } = useReport();
  let { transactions, dataFilter, filter_params } = TransactionList;

  let statuses = filter_params?.status?.options || [];
  let { states } = dataFilter;

  const [isDetail, setisDetail] = React.useState(false);
  const [countPage, setcountPage] = React.useState('10');
  const [page, setPage] = React.useState(1);
  const [isAlert, setIsAlert] = React.useState(false);
  const [severty, setSeverty] = React.useState('success');
  const [message, setMessage] = React.useState('');
  const [isStatus, setIsStatus] = React.useState(null);
  const [isChannel, setIsChannel] = React.useState('');
  const [isEmail, setIsEmail] = React.useState('');
  const [isTransaction, setIsTransaction] = React.useState('');
  const [isDateTime, setIsDateTime] = React.useState<Dayjs | null>(null);
  const [isDueDate, setIsDueDate] = React.useState<Dayjs | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [idPoper, setidPoper] = React.useState(null);
  const [dateRange, setDateRange] = React.useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [dueDateRange, setDueDateRange] = React.useState([null, null]);
  const [start_settle_due_date, end_settle_due_date] = dueDateRange;
  const [header, setHeader] = React.useState(
    tableNameList.map((item) => item.tableName)
  );

  const [params, setParams] = React.useState({
    page: 1,
    size: 10,
    sort: 'desc',
    sort_by: 'date',
    // trans_no: '',
    // channel: '',
    // email: '',
    // status: '',
    // state: '',
    start_date: '',
    end_date: '',
    // start_settle_due_date: '',
    // end_settle_due_date: '',
    sub_merchants: '',
    transaction_date: '',
    merchant_name: '',
    transaction_no: '',
    reference_no: '',
    rrn: '',
    issuer: '',
    trx_amount: '',
    mdr: '',
    status: '',
  });

  const [filterSearch, setfilterSearch] = useState({
    sub_merchants: '',
    merchant_name: '',
    transaction_no: '',
    reference_no: '',
    rrn: '',
    issuer: '',
    trx_amount: '',
    mdr: '',
  });

  const [statuschecked, setStatuschecked] = React.useState([]);

  const [stateschecked, setStatechecked] = React.useState([]);

  const [isCheckedState, setisCheckedState] = React.useState(
    new Array(states.length).fill(false)
  );

  const [isCheckedstatuses, setisCheckedstatuses] = React.useState(
    new Array(statuses.length).fill(false)
  );

  const [isCheckedTableNameList, setisCheckedTableNameList] = React.useState(
    new Array(tableNameList.length).fill(true)
  );

  React.useEffect(() => {
    if (isCheckedState.length <= 0) {
      setisCheckedState(new Array(states.length).fill(false)); // eslint-disable-next-line
    }
    if (isCheckedstatuses.length <= 0) {
      setisCheckedstatuses(new Array(statuses.length).fill(false));
    } // eslint-disable-next-line
  }, [isCheckedstatuses, isCheckedState]);

  const handleChangeCountPage = (event: SelectChangeEvent) => {
    if (transactions?.length >= 10) setcountPage(event.target.value);

    setPage(1);
    setParams({
      ...params,
      page: 1,
      size: Number(event.target.value),
    });
  };

  const ChangePage = (event: SelectChangeEvent, value: any) => {
    setPage(value);
    setParams({
      ...params,
      page: value,
      size: Number(countPage),
    });
  };

  const handleAlert = (name: string, msg: string) => {
    setIsAlert(true);
    setSeverty(name);
    setMessage(msg);
  };

  const handleOpenDetail = (id?: string) => {
    setisDetail(!isDetail);
    getDetail(id, (response: IApiResponse) => {
      if (response.code !== 200) {
        handleAlert('error', 'Failed');
        setisDetail(false);
      }
    });
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>, name: string) => {
    setAnchorEl(event.currentTarget);
    setidPoper(name);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReset = () => {
    setIsDateTime(null);
    setIsTransaction('');
    setIsEmail('');
    setIsDueDate(null);
    setIsChannel('');
    setStatuschecked([]);
    setStatechecked([]);
    setisCheckedState(new Array(states.length).fill(false));
    setisCheckedstatuses(new Array(statuses.length).fill(false));
    setDateRange([null, null]);
    setDueDateRange([null, null]);

    setParams({
      page: 1,
      size: 10,
      sort: 'asc',
      sort_by: 'date',
      // trans_no: '',
      // channel: '',
      // email: '',
      // status: '',
      // state: '',
      start_date: '',
      end_date: '',
      // start_settle_due_date: '',
      // end_settle_due_date: '',

      sub_merchants: '',
      transaction_date: '',
      merchant_name: '',
      transaction_no: '',
      reference_no: '',
      rrn: '',
      issuer: '',
      trx_amount: '',
      mdr: '',
      status: '',
    });

    setfilterSearch({
      sub_merchants: '',
      merchant_name: '',
      transaction_no: '',
      reference_no: '',
      rrn: '',
      issuer: '',
      trx_amount: '',
      mdr: '',
    });

    setAnchorEl(null);
  };

  const handleCheckedstate = (position: number) => {
    const updatedCheckedState = isCheckedState.map((item, index) =>
      index === position ? !item : item
    );
    setisCheckedState(updatedCheckedState);
  };

  const handleCheckedsstatuses = (position: number) => {
    const updatedCheckedstatuses = isCheckedstatuses.map((item, index) =>
      index === position ? !item : item
    );
    setisCheckedstatuses(updatedCheckedstatuses);
  };

  const handleCheckedTableNameList = (position: number) => {
    const updatedCheckedstatuses = isCheckedTableNameList.map((item, index) =>
      index === position ? !item : item
    );
    setisCheckedTableNameList(updatedCheckedstatuses);
  };

  const handleResetTableNameList = () => {
    setisCheckedTableNameList(new Array(tableNameList.length).fill(true)); // eslint-disable-next-line
  };

  const handleStatus = (i: any, data: any) => {
    if (statuschecked.includes(data.id)) {
      setStatuschecked(statuschecked.filter((option) => option !== data.id));
    } else {
      setStatuschecked(statuschecked.concat(data.id));
    }
    handleCheckedsstatuses(i);
  };

  const handleState = (i: any, data: any) => {
    if (stateschecked.includes(data.index)) {
      setStatechecked(stateschecked.filter((option) => option !== data.index));
    } else {
      setStatechecked(stateschecked.concat(data.index));
    }
    handleCheckedstate(i);
  };

  const handleSubmit = () => {
    setPage(1);
    setParams({
      ...params,
      ...filterSearch,
      page: 1,
      // trans_no: isTransaction,
      // channel: isChannel,
      // email: isEmail,
      status: statuschecked.join(','),
      // state: stateschecked.join(','),
      start_date: startDate ? moment(startDate).format('yy-MM-DD') : '',
      end_date: endDate ? moment(endDate).format('yy-MM-DD') : '',
      // start_settle_due_date: start_settle_due_date
      //   ? moment(start_settle_due_date).format('yy-MM-DD')
      //   : '',
      // end_settle_due_date: end_settle_due_date
      //   ? moment(end_settle_due_date).format('yy-MM-DD')
      //   : '',
    });

    handleClose();
  };

  const handleChangeSearchFilter = (field: string, value: any) => {
    setfilterSearch({
      ...filterSearch,
      [field]: value,
    });
  };

  let valueContext = {
    isAlert,
    severty,
    message,
    handleSubmit,
    handleMenu,
    handleState,
    handleStatus,
    handleClose,
    setisDetail,
    countPage,
    page,
    setIsAlert,
    params,
    isStatus,
    statuschecked,
    stateschecked,
    isChannel,
    isEmail,
    isTransaction,
    isDateTime,
    isDueDate,
    setIsStatus,
    setStatuschecked,
    setStatechecked,
    setIsChannel,
    handleAlert,
    isCheckedState,
    setisCheckedState,
    setIsEmail,
    setIsTransaction,
    setIsDateTime,
    isCheckedstatuses,
    setIsDueDate,
    setParams,
    handleChangeCountPage,
    ChangePage,
    handleOpenDetail,
    setidPoper,
    idPoper,
    setAnchorEl,
    anchorEl,
    handleReset,
    isDetail,
    handleCheckedstate,
    handleCheckedsstatuses,
    dateRange,
    setDateRange,
    startDate,
    endDate,
    dueDateRange,
    setDueDateRange,
    start_settle_due_date,
    end_settle_due_date,

    isCheckedTableNameList,
    handleCheckedTableNameList,
    handleResetTableNameList,
    filterSearch,
    setfilterSearch,
    handleChangeSearchFilter,

    header: {
      val: header,
      set: setHeader,
    },
  };

  return (
    <TransactionContext.Provider value={valueContext}>
      {props.children}
    </TransactionContext.Provider>
  );
};

export default TransactionProvider;
