import React from 'react';
import LoadingWrapper from 'components/loadingWrapper';
import HoverView from '../HoverView';
import { TableCell, TableRow } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import SeeDetailButton from 'components/Button/SeeDetailButton';

interface IContentDataTable {
  dataList: any;
  tableNameList: any;
  handleAction?: any;
  isLoading: any;
  colorSet?: any;
  page: any;
  countPage: any;
  headerList?: any;
  disableNo?: any;
}

const ContentDataTable: React.FC<IContentDataTable> = ({
  dataList,
  tableNameList,
  handleAction,
  isLoading,
  page,
  countPage,
  headerList,
  disableNo,
}) => {
  let handleValue = (
    name: any,
    row: any,
    typeValue: string,
    formatText: string
  ) => {
    const StatuslowercaseValue =
      typeof row['status'] === 'number'
        ? row['status']
        : row['status']?.toLowerCase();

    const StatusTextlowercaseValue =
      typeof row['status_text'] === 'number'
        ? row['status_text']
        : row['status_text']?.toLowerCase();

    const StatelowercaseValue =
      typeof row['state'] === 'number'
        ? row['state']
        : row['state']?.toLowerCase();

    const statusData = statusStyles[
      typeof row['status'] !== 'number'
        ? Object.keys(statusStyles)?.find((key) =>
            StatuslowercaseValue?.includes(key)
          )
        : row[name]
    ] || {
      bgColor: '#FFF',
      borderColor: '#000',
      textColor: '#000',
      label: '',
    };

    const statusDataText = statusStyles[
      typeof row['status_text'] !== 'number'
        ? Object.keys(statusStyles)?.find((key) =>
            StatusTextlowercaseValue?.includes(key)
          )
        : row[name]
    ] || {
      bgColor: '#FFF',
      borderColor: '#000',
      textColor: '#000',
      label: '',
    };

    const stateData = stateStyles[
      Object.keys(stateStyles)?.find((key) =>
        StatelowercaseValue?.includes(key)
      )
    ] || {
      bgColor: '#FFF',
      borderColor: '#000',
      textColor: '#000',
      label: row[name],
    };

    switch (typeValue) {
      case 'RupiahFormat':
        let value = row[name];
        return (
          <div className='flex justify-between  w-[90%]'>
            <div>Rp.</div>
            <NumericFormat
              value={value}
              displayType={'text'}
              thousandSeparator={'.'}
              decimalSeparator={','}
              prefix={'  '}
            />
          </div>
        );
      case 'status':
        return (
          <div
            className={`bg-[${statusData.bgColor}] border-2 border-[${statusData.borderColor}] text-[${statusData.textColor}] text-center w-[80%] text-[12px] p-1 rounded-md`}
          >
            <b>{statusData.label}</b>
          </div>
        );
      case 'status_text':
        return (
          <div
            className={`bg-[${statusDataText.bgColor}] border-2 border-[${statusDataText.borderColor}] text-[${statusDataText.textColor}] text-center w-[80%] text-[12px] p-1 rounded-md`}
          >
            <b>{statusDataText.label}</b>
          </div>
        );
      case 'state':
        return (
          <div
            className={`bg-[${stateData.bgColor}] border-2 border-[${stateData.borderColor}] text-[${stateData.textColor}] text-center w-[80%] text-[12px] p-1 rounded-md`}
          >
            <b>{stateData.label}</b>
          </div>
        );
      case 'ActionDetail':
        return (
          <div
            className='flex justify-center'
            onClick={() => handleAction(row[name])}
          >
            <SeeDetailButton />
          </div>
        );
      default:
        return (
          <div className={`break-keep max-w-[200px] truncate ${formatText}`}>
            {row[name]?.length >= 24 ? (
              <HoverView text={row[name]} />
            ) : (
              row[name] || '-'
            )}
          </div>
        );
    }
  };

  return (
    <>
      {dataList?.length > 0 &&
        dataList.map((row: any, index: number) => (
          <TableRow key={index}>
            {!disableNo && (
              <TableCell style={{ border: 0 }}>
                <LoadingWrapper isLoading={isLoading}>
                  <div className='ml-[13px] w-[13px]'>
                    {Number(page - 1) * Number(countPage) + index + 1}
                  </div>
                </LoadingWrapper>
              </TableCell>
            )}

            {tableNameList.map((column: any, colIndex: number) => (
              <TableCell
                key={colIndex}
                style={{
                  textAlign: column?.textAlign || 'left',
                  cursor: column.name === 'Action' ? 'pointer' : 'default',
                  border: 0,
                  display: headerList
                    ? headerList?.includes(column.tableName)
                      ? 'table-cell'
                      : 'none'
                    : 'table-cell',
                }}
              >
                <LoadingWrapper isLoading={isLoading}>
                  {handleValue(
                    column.name,
                    row,
                    column.typeValue,
                    column?.formatText
                  )}
                </LoadingWrapper>
              </TableCell>
            ))}
          </TableRow>
        ))}
    </>
  );
};

export default ContentDataTable;

let colorList = {
  green: {
    bgColor: '#E9F7F2',
    borderColor: '#BAE6D7',
    textColor: '#21AF7D',
  },
  succeed: {
    bgColor: '#E9F7F2',
    borderColor: '#BAE6D7',
    textColor: '#21AF7D',
    label: 'Success',
    label2: 'Active',
  },
};

export const statusStyles = {
  0: {
    bgColor: '#FFF4F2',
    borderColor: '#EEB4B0',
    textColor: '#CB3A31',
    label2: 'Tidak Aktif',
  },
  1: {
    ...colorList.succeed,
    label2: 'active',
  },
  2: {
    bgColor: '#FFF4F2',
    borderColor: '#EEB4B0',
    textColor: '#F04545',
    label: 'Failed',
  },
  process: {
    bgColor: '#FFF9F2',
    borderColor: '#FDBE2C',
    textColor: '#FDBE2C',
    label: 'In Process',
  },
  submitted: {
    bgColor: '#FFF9F2',
    borderColor: '#FDBE2C',
    textColor: '#E6AD28',
    label: 'Submitted',
  },
  succeed: {
    ...colorList.succeed,
  },
  open: {
    bgColor: '#FFF9F2',
    borderColor: '#FDBE2C',
    textColor: '#FBB12F',
    label: 'On Process',
  },
  success: {
    ...colorList.succeed,
  },
  failed: {
    bgColor: '#FFF4F2',
    borderColor: '#EEB4B0',
    textColor: '#F04545',
    label: 'Failed',
  },
};

export const stateStyles = {
  refund_process: {
    bgColor: '#FFF9F2',
    borderColor: '#FDBE2C',
    textColor: '#FBB12F',
    label: 'Refund Process',
  },
  process: {
    bgColor: '#FFF9F2',
    borderColor: '#FDBE2C',
    textColor: '#FBB12F',
    label: 'To Be Paid',
  },
  hold: {
    bgColor: '#E0FDEF',
    borderColor: '#BAE6D7',
    textColor: '#18AD65',
    label: 'Hold',
  },
  withdrawable: {
    ...colorList.succeed,
    label: 'To Be Settled',
  },
  withdrawn: {
    ...colorList.succeed,
    label: 'Transferred Out',
  },
  refunded: {
    bgColor: '#E0FDEF',
    borderColor: '#BAE6D7',
    textColor: '#18AD65',
    label: 'Refund',
  },
  succeed: {
    ...colorList.succeed,
    label: 'Paid',
  },
  expired: {
    bgColor: '#FFF4F2',
    borderColor: '#EEB4B0',
    textColor: '#F04545',
    label: 'Expired',
  },
  failed: {
    bgColor: '#FFF4F2',
    borderColor: '#EEB4B0',
    textColor: '#F04545',
    label: 'Failed Paid',
  },
  insufficient_payment: {
    bgColor: '#FFF9F2',
    borderColor: '#FDBE2C',
    textColor: '#E6AD28',
    label: 'Insufficient Payment',
  },
  over_payment: {
    bgColor: '#FFF9F2',
    borderColor: '#FDBE2C',
    textColor: '#E6AD28',
    label: 'Over Payment',
  },
};
