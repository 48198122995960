import MethodProvider from 'provider/methods';
import axios, { AxiosError } from 'axios';
import {
  AuthType,
  EditProfileData,
  IAuth,
  IChangePassData,
  IUserData,
  IUserLoginBodyData,
  IUserLoginResponse,
  captchaProps,
  encryptProps,
} from 'stores/types/authTypes';
import { ThunkAction } from 'redux-thunk';
import { IError } from 'stores/types/errorTypes';
import provider, { IProvider } from 'provider';
import { errToast, errorGlobal } from './errorAction';
import { RootState } from 'stores';
import { setAuthToken } from 'utils/token';
import endpoints from 'api/endpoints';
import { toast } from 'react-toastify';
import {
  getLocalItem,
  removeLocalItem,
  setLocalItem,
} from 'utils/localStorage';
import { ActivityData, addAuditrail } from 'hooks/useActivityBrowser';
import { ResendMailData } from 'stores/types/registerTypes';
import { isFullVersion } from 'utils/exception';
import jwtDecode from 'jwt-decode';
import MutationTypes from 'provider/methods';
import { removeCookie, setCookie } from 'utils/cookie';
import { captchaForm } from 'components/captcha';
import { NavigateFunction } from 'react-router-dom';
import { getBrowserId } from './utilsAction';
import Cookies from 'js-cookie';

export const userLoginPending = (): IAuth => ({
  type: AuthType.USER_LOGIN_PENDING,
});

export const reqProdActions = (): IAuth => ({
  type: AuthType.reqProd,
});

export const userLoginSuccess = (userData: IUserLoginResponse): IAuth => ({
  type: AuthType.USER_LOGIN_SUCCESS,
  payload: {
    userData,
  },
});

export const userLoginError = (error: AxiosError): IAuth => ({
  type: AuthType.USER_LOGIN_ERROR,
  payload: { error },
});

const routePath = isFullVersion ? '/full/' : '/';

export const userLogin = (
  bodyData: IUserLoginBodyData,
  callback?: (data: IUserLoginResponse) => void
): ThunkAction<void, RootState, null, IAuth | IError> => {
  return async (dispatch) => {
    dispatch(userLoginPending());
    try {
      const objProvider: IProvider = {
        method: MethodProvider.POST,
        path: 'login',
        data: bodyData,
      };
      const response = await provider(objProvider);
      const res = {
        code: response?.status,
        message: 'Login Success!',
        data: response?.data,
      };

      setAuthToken(response?.data?.token);
      dispatch(userDataUpdate(response?.data));
      callback?.(res);
    } catch (e) {
      const error = {
        code: e?.response?.status,
        message: e?.response?.data?.error,
        data: e?.response?.data,
      };

      callback?.(error);
      dispatch(errorGlobal(e));
      dispatch(userLoginError(e));
    }
  };
};

export const loginProcess = async (dispatch, response) => {
  await dispatch({
    type: AuthType.USER_LOGIN_SUCCESS,
    payload: response.data.data,
  });
  const res = response.data.data;
  Cookies.set('web-merchant', 'expired-limit', {
    sameSite: 'Strict',
    secure: true,
    expires: 1,
  });
  if (res?.ClientId === 1 && res?.PackageType === 2) {
    const token = res.Token;
    const isSB = res.Env;
    if (token !== undefined) {
      delete res?.Token;

      setCookie('ud', JSON.stringify(res));
      const params: encryptProps = {
        version: isFullVersion ? 'full' : 'qoinhub',
        sandbox: !isSB,
      };

      removeLocalItem('token-web-merchant');
      dispatch(createSSO(token, params));
    }
  } else if (res.ClientId === 1 && res.PackageType !== 2) {
    if (isFullVersion || res.PackageType === 1) {
      setLocalItem('token-web-merchant', res.Token);
      await dispatch(userLoginSuccess(res));

      let jwt = getLocalItem('token-web-merchant');
      const decoded: IUserData = jwtDecode(jwt);

      setLocalItem('permission-web-merchant', decoded?.Permission);
      window.location.replace(`${routePath}dashboard`);
    } else toast.error('User Not Found', { autoClose: 5000 });
  } else toast.error('Invalid User Login', { autoClose: 5000 });
};

type loginProps = IUserLoginBodyData & ActivityData & captchaProps;
export const loginAccount = (
  bodyData: loginProps,
  callback?: (v: string) => void
) => {
  return async (dispatch) => {
    try {
      const loginData = new FormData();
      loginData.append('Email', bodyData.email);
      loginData.append('Password', bodyData.password);
      addAuditrail(loginData, bodyData);
      captchaForm(loginData, bodyData.VerifyValue);
      const objProvider: IProvider = {
        method: MutationTypes.POST,
        path: endpoints.login,
        data: loginData,
      };
      const response = await provider(objProvider);
      if (!response) toast.error('Network Error');
      if (response.data?.code === 202) {
        loginProcess(dispatch, response);
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        let msg = '';
        if (e.response.status === 400) {
          const resData = e.response.data?.['message'];
          if (typeof resData === 'string' && resData.includes('wrong')) {
            msg = 'These credentials do not match our records';
          } else msg = resData;
        } else msg = e.message;
        callback(msg);
        toast.error(msg);
        dispatch(errorGlobal(e));
        dispatch(userLoginError(e));
      }
    }
  };
};

export const registerAccount = async (
  body: FormData,
  navigate?: NavigateFunction,
  callback?: (v: boolean) => void,
  message?: (v: string) => void
) => {
  try {
    const objProvider: IProvider = {
      method: MutationTypes.POST,
      path: endpoints.register.concat(isFullVersion ? '' : '/bi'),
      data: body,
    };
    const response = await provider(objProvider);
    if (!response) toast.error('Network Error');
    if (response.data?.code === 202) {
      navigate(`${routePath}register-success`);
    }
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const msg =
        Object.values(e.response.data)[0][0] ??
        e.response.data?.['message'] ??
        e.message;
      toast.error(msg, { autoClose: 4000 });
      callback(true);
      message(msg);
    }
  }
};

export const userChangePassword = (
  data: IChangePassData & ActivityData,
  callback?: (v: boolean) => void,
  message?: (v: string) => void
) => {
  return async (dispatch) => {
    try {
      const passForm = new FormData();
      passForm.append('OldPassword', data.OldPassword);
      passForm.append('NewPassword', data.NewPassword);
      passForm.append('PasswordConfirmation', data.PasswordConfirmation);
      addAuditrail(passForm, data);

      const objProvider: IProvider = {
        method: MutationTypes.PUT,
        path: endpoints.changePassword,
        data: passForm,
      };
      const response = await provider(objProvider);
      if (!response) toast.error('Network Error');
      if (response.data?.code === 200) {
        callback(true);
        dispatch(getProfile());
        message('');
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const msg = e.response.data?.['message'] ?? e.message;
        errToast(msg);
        message(msg);
      }
    }
  };
};

export const getProfile = (token?: string) => async (dispatch) => {
  dispatch({ type: AuthType.GET_PROFILE_PENDING });
  try {
    const objProvider: IProvider = {
      path: endpoints.profile,
      method: MethodProvider.GET,
    };
    if (token) {
      Object.assign(objProvider, {
        headers: { Authorization: `Bearer ${token}` },
      });
    }
    const response = await provider(objProvider);
    if (response.data?.status === 'success') {
      await dispatch({
        type: AuthType.GET_PROFILE_SUCCESS,
        payload: response.data.data,
      });
    }
    return response;
  } catch (err) {
    const error = err as AxiosError;
    errorGlobal(err);
    dispatch({
      type: AuthType.GET_PROFILE_FAILED,
      payload: { error: error },
    });
  }
};

export const setPhotoProfile = async (
  photo,
  audit: ActivityData,
  callback?: (v: boolean) => void
) => {
  const photoData = new FormData();
  photoData.append('Photo', photo);
  addAuditrail(photoData, audit);
  try {
    const objProvider: IProvider = {
      method: MutationTypes.POST,
      path: endpoints.profilePhoto,
      data: photoData,
    };
    const response = await provider(objProvider);
    if (!response.data) toast.error('Network Error');
    else if (response.data?.status === 'success') {
      callback(true);
    }
  } catch (err) {
    if (axios.isAxiosError(err)) {
      errorGlobal(err);
      toast.error(err.response.data?.['message']);
    } else console.log(err);
  }
};

export const resendEmail = async (
  data: ResendMailData,
  navigate?: NavigateFunction
) => {
  try {
    const formData = new FormData();
    formData.append('EmailKey', data.email);
    addAuditrail(formData, data);
    const objProvider: IProvider = {
      method: MutationTypes.POST,
      path: endpoints.resendEmail,
      data: formData,
    };
    const response = await provider(objProvider);
    if (!response) toast.error('Network Error');
    if (response.data?.status === 'success') {
      toast.success(response.data?.message, { autoClose: 4000 });
      navigate(`'${routePath}resend-email`);
    }
  } catch (e) {
    if (axios.isAxiosError(e)) {
      errToast(e);
    }
  }
  // const formData = new FormData();
  // formData.append('EmailKey', data.email);
  // addAuditrail(formData, data);

  // const response = await apiPost(
  //   endpoints.resendEmail,
  //   formData,
  //   envProd,
  //   postConfig.standard
  // );
  // if (response.data.status === 'success') {
  //   toast.success(response.data.message, { autoClose: 4000 });
  // } else if (response.data.status === 'error')
  //   toast.error(response.data.message, { autoClose: 4000 });

  // return response;
};

export const editUserProfile = (
  data: EditProfileData,
  popup?: (v: boolean) => void
) => {
  return async (dispatch) => {
    try {
      const formData = new FormData();
      formData.append('Username', data.name);
      formData.append('Phone', data.phone);
      addAuditrail(formData, data);

      const objProvider: IProvider = {
        method: MutationTypes.PUT,
        path: endpoints.profile,
        data: formData,
      };
      const response = await provider(objProvider);
      if (!response) toast.error('Network Error');
      if (response.data?.code === 200) {
        popup(true);
        await dispatch(getProfile());
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        errToast(e);
        popup(false);
      }
    }
  };
};

export const userLogout = async () => {
  removeLocalItem('token-web-merchant');
  removeLocalItem('permission-web-merchant');
  removeLocalItem('package-type');
  removeCookie('web-merchant');
};

export const changeEnv = (data: any) => async (dispatch) => {
  await dispatch({
    type: AuthType.CHANGE_ENV,
    payload: data,
  });
};

export const reqProd = (
  callback?: (data: any) => void
): ThunkAction<void, RootState, null, IAuth | IError> => {
  return async (dispatch, getState) => {
    let email = getState().auth.profile?.Email;
    try {
      const objProvider: IProvider = {
        method: MethodProvider.POST,
        path: endpoints.reqProd,
        data: {
          email,
        },
      };
      const response = await provider(objProvider);
      dispatch(reqProdActions());
      callback?.(response);
    } catch (e) {
      callback?.(e);
      dispatch(errorGlobal(e));
      dispatch(userLoginError(e));
    }
  };
};

export const userDataUpdate = (data: IUserData) => (dispatch) => {
  dispatch({ type: AuthType.UPDATE_USERDATA, payload: data });
};

// export const decryptSignature =
//   (token: string, path?: string) => async (dispatch) => {
//     try {
//       const objProvider: IProvider = {
//         path: endpoints.decrypt,
//         method: MethodProvider.POST,
//         headers: {
//           Signature: token,
//           Accept: 'application/json',
//         },
//       };
//       const response = await provider(objProvider);
//       if (response.data?.status === 'success') {
//         const token = response.data?.data?.tokenSSO;
//         setLocalItem('token-web-merchant', token);
//         dispatch(getProfile(token));
//         setLocalItem(
//           'permission-web-merchant',
//           JSON.stringify(jwtDecode(token)['Permission'])
//         );
//         let jwt = await getLocalItem('token-web-merchant');
//         setTimeout(() => {
//           if (jwt && path) {
//             let target = path;
//             window.location.replace(`${routePath}${target}`);
//           }
//         }, 4000);
//       }
//     } catch (err) {
//       dispatch(errorGlobal(err));
//       window.location.replace(`${routePath}404`);
//     }
//   };

// export const encryptSignature =
//   (token: string, param: encryptProps) => async (dispatch) => {
//     try {
//       const objProvider: IProvider = {
//         method: MethodProvider.POST,
//         path: endpoints.encrypt,
//         headers: { Authorization: `Bearer ${token}` },
//       };
//       const response = await provider(objProvider);
//       if (response.data?.status === 'success') {
//         const signature = response.data?.data?.SignatureSSO;
//         const payout = process.env.REACT_APP_PAYOUT_URL;
//         const params = new URLSearchParams();
//         params.append('signature', signature);
//         for (let key in param) params.set(key, param[key]);
//         localStorage.clear();
//         window.location.replace(`${payout}auth?${params?.toString()}`);
//       }
//     } catch (err) {
//       if (axios.isAxiosError(err)) {
//         dispatch(errorGlobal(err));
//         toast.error(err.response.data['message']);
//       } else console.log(err);
//     }
//   };

export const createSSO = (token: string, param?: encryptProps) => {
  return async (dispatch) => {
    let browserId = getBrowserId();
    try {
      const objProvider: IProvider = {
        method: MutationTypes.POST,
        path: endpoints.sso,
        data: { browserId },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await provider(objProvider);
      if (!response) toast.error('Network Error!');
      if (response.data?.code === 202) {
        const sign = response.data.data?.ticket_id;
        const payout = process.env.REACT_APP_PAYOUT_URL;
        const params = new URLSearchParams();
        params.append('signature', sign);
        for (let key in param) params.set(key, param[key]);
        localStorage.clear();
        window.location.replace(`${payout}auth?${params?.toString()}`);
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        dispatch(errorGlobal(e));
        toast.error(e.response.data['message']);
      } else throw new Error(e);
    }
  };
};

export const verifySSO = (ticket: string, path?: string) => {
  return async (dispatch) => {
    try {
      const objProvider: IProvider = {
        method: MutationTypes.GET,
        path: endpoints.getStatus + ticket,
      };
      const response = await provider(objProvider);
      if (!response) toast.error('Network Error!');
      if (response.data?.code === 200) {
        const desc: string = response.data.data?.Description;
        if (desc.includes('retry')) {
          setInterval(() => {
            verifySSO(ticket);
          }, 2000);
        } else if (desc.includes('failed')) {
          toast.error(desc);
          setTimeout(() => {
            window.location.replace(`${routePath}`);
          }, 4000);
        } else {
          const res = response.data.data;
          setCookie('web-merchant', 'expired-limit');
          if (path === 'onboarding') Object.assign(res, { PackageType: 2 });
          setLocalItem('token-web-merchant', res?.Token);
          setLocalItem(
            'permission-web-merchant',
            jwtDecode(res?.Token)['Permission']
          );

          let jwt = await getLocalItem('token-web-merchant');
          setTimeout(() => {
            if (jwt && path) {
              let target = path;
              window.location.replace(`${routePath}${target}`);
            }
          }, 4000);
        }
      }
    } catch (e) {
      dispatch(errorGlobal(e));
      window.location.replace(`${routePath}404`);
    }
  };
};

export const refreshToken = async () => {
  const token = getLocalItem('token-web-merchant');
  const formData = new FormData();
  formData.append('Token', token);
  try {
    const objProvider: IProvider = {
      method: MutationTypes.POST,
      path: endpoints.refreshToken,
      data: formData,
    };
    const response = await provider(objProvider);
    if (!response) toast.error('Network Error');
    if (response.data?.status === 'success') {
      setLocalItem('token-web-merchant', response.data?.data?.Token);
    }
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response.status === 401) {
        localStorage.clear();
        window.location.replace(`${isFullVersion ? '/full/' : '/'}login`);
      }
    }
  }
};
