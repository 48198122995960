// name: name variable, tableName: nameTableUi/nama yang tampil di user, icon: filterIcon
const addTableName = (
  name: string,
  tableName: string,
  filter: boolean,
  typeValue?: string,
  width?: string,
  formatText?: string
) => ({
  name,
  tableName,
  filter,
  typeValue,
  width,
  formatText,
});

// 0: name variable, 1: nameTableUi/nama yang tampil di user, 2: filterIcon
export const tableNameWdList = [
  addTableName('date', 'Settlement Date', true, '', '180px'),
  // addTableName('settlement_date', 'Settlement Date', true, '', '180px'),
  addTableName('trx_no', 'Settlement No', true, '', '148px'),
  addTableName('receipt_no', 'Receipt No', true, '', '120px', 'text-center'),
  addTableName(
    'total_trx_record',
    'Total Records',
    false,
    '',
    '110px',
    'text-center'
  ),
  addTableName(
    'settlement_amount',
    'Settlement Amount',
    false,
    'RupiahFormat',
    '160px'
  ),
  addTableName(
    'transfer_amount',
    'Transfer Amount',
    false,
    'RupiahFormat',
    '160px'
  ),
  addTableName('status', 'Status', false, 'status', '100px'),
  addTableName('trx_no', 'Action', false, 'ActionDetail', '50px'),
];

export const tableNameWdDetail = [
  addTableName('trx_no', 'Transaction No', false, ''),
  addTableName('reference_no', 'Reference No', false, ''),
  addTableName('initial_amount', 'Initial Amount', false, 'RupiahFormat'),
  addTableName('amount', 'Transaction Amount', false, 'RupiahFormat', '170px'),
  addTableName('fee_mdr', 'MDR Fee', false, 'RupiahFormat', '100px'),
  addTableName('fee_admin', 'Admin Fee', false, 'RupiahFormat', '100px'),
  addTableName('ie_fee_type', 'I/E Fee type', false, '', '90px'),
  addTableName('nett_amount', 'Nett Amount', false, 'RupiahFormat'),
  addTableName('channel', 'Payment Channel', false, '', '170px'),
];

export const tableNameWdSettled = [
  addTableName('No', 'No', false),
  addTableName('trx_no', 'Transaction No', false),
  addTableName('reference_no', 'Reference No', false),
  addTableName('initial_amount', 'Initial Amount', false),
  addTableName('fee_settled', 'Settled Fee', false),
  addTableName('fee_third_party', '3rd Party Fee', false),
  addTableName('amount', 'Trx Amount', false),
  addTableName('channel', 'Payment Channel', false),
];
