import { ThemeProvider } from '@emotion/react';
import { Button } from '@mui/material';
import { btnOulineTheme, btnTheme2 } from 'components/muiTheme';

const WrapEditSubmit = ({
  handleCancel,
  isEdit,
  handleEdit,
  w,
}: {
  handleCancel: any;
  isEdit: boolean;
  handleEdit: any;
  w?: string;
}) => {
  return (
    <>
      <div
        className={`${w ? `lg:w-[${w}]` : 'lg:w-[80%]'} flex justify-end mb-5 `}
      >
        <div className={`${!isEdit && 'hidden'} flex`}>
          <ThemeProvider theme={btnOulineTheme}>
            <div className="ml-4">
              <Button
                variant="outlined"
                color="secondary"
                className="w-[120px] px-3"
                onClick={handleCancel}
              >
                cancel
              </Button>
            </div>
          </ThemeProvider>
          <ThemeProvider theme={btnTheme2}>
            <div className="ml-4">
              <Button
                variant="contained"
                className="w-[120px] px-3"
                onClick={() => handleEdit()}
              >
                Save
              </Button>
            </div>
          </ThemeProvider>
        </div>
      </div>
    </>
  );
};

export default WrapEditSubmit;
