import { icons } from 'assets';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateTime = ({ setDateRange, startDate, endDate }) => {
  return (
    <div className="flex w-auto border-2 p-3 mb-2 mt-1 rounded-md">
      <DatePicker
        selectsRange={true}
        placeholderText="Select Date & Time"
        className="mr-2  w-[140px] "
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          setDateRange(update);
        }}
        withPortal
      />
      <img src={icons.Calender} alt="asd" />
    </div>
  );
};

export default DateTime;
