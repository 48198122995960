import React from 'react';
import { Navigate } from 'react-router-dom';
import { IProtectRouteProps } from '../ProtectedRoute/interface';
import { isFullVersion } from 'utils/exception';
import { getLocalItem } from 'utils/localStorage';
import { getCookie } from 'utils/cookie';

const Component: React.FC<IProtectRouteProps> = ({
  children,
  isAllowed,
  redirectPath = `${isFullVersion ? '/full/' : '/'}dashboard`,
}) => {
  const token = getLocalItem('token-web-merchant');
  const isPay = getCookie('in');

  if (isPay) window.location.href = process.env.REACT_APP_PAYOUT_URL; // already logged in as a payout
  if (isAllowed && token) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

export default Component;
