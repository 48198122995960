import endpoints from 'api/endpoints';
import { AxiosError } from 'axios';
import { captchaForm } from 'components/captcha';
import { TokenOTP } from 'containers/Auth/Auth';
import { ActivityData, addAuditrail } from 'hooks/useActivityBrowser';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import provider, { IProvider } from 'provider';
import MutationTypes from 'provider/methods';
import { toast } from 'react-toastify';
import { IUserLoginBodyData, captchaProps } from 'stores/types/authTypes';
import { routePath } from 'utils/exception';
import { handleError } from './errorAction';
import { loginProcess } from './authActions';

function reLogin(time = 5) {
  setTimeout(() => window.location.replace(`${routePath}login`), time * 1000);
}

const { sendOtp, validate, login } = endpoints.otp2fa;

type loginProps = IUserLoginBodyData & ActivityData & captchaProps;
export async function requestOTP(
  data: loginProps,
  callback?: (v: boolean) => void
) {
  try {
    const formData = new FormData();
    formData.append('Email', data.email);
    formData.append('Password', data.password);
    addAuditrail(formData, data);
    captchaForm(formData, data.VerifyValue);

    const objProvider: IProvider = {
      method: MutationTypes.POST,
      path: login,
      data: formData,
    };
    const response = await provider(objProvider);
    if (!response) toast.error('Network Error');
    if (response.status === 202) {
      const data: { Token: string } = response.data?.data;
      const decoded: TokenOTP = jwtDecode(data.Token);

      if (decoded) {
        const expires = new Date(decoded.Expired);
        Cookies.set('o2t', data.Token, { expires, secure: true }); // otp 2fa token
      }

      window.location.replace(`${routePath}login-otp`);
    } else console.log({ response });
  } catch (err) {
    callback(true);
    handleError(err);
  }
}

export async function sendOTP(token: string) {
  try {
    const path = `${sendOtp}?token=${token}`;
    const objProvider: IProvider = { method: MutationTypes.GET, path };
    const response = await provider(objProvider);
    if (response.status === 200) {
      const data: { Token: string } = response.data?.data;
      return { code: 200, data: data.Token };
    } else throw new Error(response?.statusText);
  } catch (err) {
    handleError(err);
  }
}

export function validateOTP(key: string, code: number) {
  return async (dispatch) => {
    Cookies.remove('o2t');
    Cookies.remove('RegisterKey'); // idk who set this up
    try {
      const objProvider: IProvider = {
        method: MutationTypes.POST,
        path: validate,
        data: { otp_code: code, otp_key: key },
      };
      const response = await provider(objProvider);
      if (response.status === 202) {
        loginProcess(dispatch, response);
      } else throw new Error(response.statusText);
    } catch (err) {
      handleError(err);
    }
  };
}

export function intercept2FA(err: AxiosError) {
  const { config, status, data } = err.response;
  const { url } = config;

  const errorMessages = [
    'Your account has not been verified. Please check your email to verify your account',
    'Your account has been deactivated or blocked. Please contact support for assistance',
    'Merchant has been Deactivated. Please contact support for assistance',
    'An unexpected error occurred on the server. Please try again later',
  ];

  if (url === sendOtp || url === validate || url === login) {
    let msg: string = '';

    if (typeof data === 'object') {
      if ('internal_code' in data) {
        const { internal_code: inCode } = data;

        if (status === 400) {
          if (inCode === '40001') {
            if (url === login) msg = 'Your Email or Password Wrong';
            if (url === validate) msg = 'Invalid OTP';
          }
          if (url === sendOtp) msg = 'Your OTP Request has been limit';
          if (inCode === '40002') {
            if (url === login) msg = 'Your OTP Request has been limit';
            if (url === validate) {
              msg = 'Your Account has been suspend,Too many OTP attempts';
              reLogin(5);
            }
          }
        }

        if (status === 401) {
          if (url === login) msg = errorMessages[0];
          if (url === sendOtp || url === validate) {
            if (inCode === '40101') msg = 'Token has been expired.';
            if (inCode === '40102') msg = 'Token Invalid';
            reLogin(3);
          }
        }

        if (status === 403) {
          if (url === login) {
            if (inCode === '40318') msg = errorMessages[1];
            if (inCode === '40319') msg = errorMessages[2];
          }
          if (inCode === '40320' && 'message' in data) {
            msg = String(data.message);
          }
        }
      }

      if (status === 400 && 'message' in data) {
        if (typeof data.message !== 'string') return;
        const isCaptcha = data.message?.includes('captcha');
        if (isCaptcha) msg = data.message;
      }

      if (status === 403) {
        if (url === sendOtp) msg = 'Your OTP Request has been limit';
        if (url === validate) msg = 'Invalid OTP';
        reLogin(5);
      }
    }

    if (status === 429 && url === validate) {
      msg = 'Your Account has been suspend,Too many OTP attempts';
      reLogin(7);
    }

    if (status === 500) msg = 'Internal Server Error';
    if (status === 502) msg = 'Bad Gateway';
    if (status === 503) msg = errorMessages[3];
    if (status === 504) msg = 'Request Timeout';

    if (msg.length !== 0) toast.error(msg);
  }
}
