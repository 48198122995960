import React, { useEffect, useState } from 'react';
import { Alert } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'stores';
import { GridLine, GridSelect } from './Components';
import { IBankDetail } from './interface';
import { bankSchema } from 'components/configs/validationSchema';
import useCustomFormik from 'hooks/useCustomFormik';
import { DetailBankData } from 'stores/types/onboardingTypes';
import useOnboardData from 'hooks/useOnboardData';

const BankDetail: React.FC<IBankDetail> = ({ data, error }) => {
  const {
    detailBank,
    QRIS,
    merchantPreview: MP,
  } = useSelector((o: RootState) => o.onboarding);
  const { bankData } = useOnboardData();

  // declare formik related here
  const initVal: DetailBankData = {
    ...detailBank,
    BankAccountNumber: QRIS?.NomorRekening || detailBank.BankAccountNumber,
  };
  const formik = useCustomFormik(initVal, bankSchema);
  const { values, errors } = formik;

  const [check, setCheck] = useState(false);
  const [bankName, setBankName] = useState(
    MP?.data?.bank || detailBank.BankName
  );

  const getBankList = bankData.map(({ name: value, name: label }) => ({
    value,
    label,
  }));

  useEffect(() => {
    if (error?.val) {
      const { BankName, BankAccountName } = values;
      if (BankName === '') setCheck(true);
      if (BankAccountName === '') setCheck(true);
    } else setCheck(false);
  }, [error, values]);
  useEffect(() => {
    // to make sure the package type isn't null then update the values needed to fotm
    if (MP.data?.package_type !== null) {
      const { data: mData } = MP;
      formik.setValues({
        BankName: mData?.bank !== '' ? mData.bank : bankName,
        BankAccountName: mData?.bank_acc_name,
        BankAccountNumber: mData?.bank_acc_no,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [MP]);

  // compiled data here
  type compiled_props = Record<Partial<keyof DetailBankData>, string>;

  useEffect(() => {
    const compiled: compiled_props = {
      ...values,
      BankName: bankName || values?.BankName,
    };
    data?.set(compiled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, values]);

  // error condition & warning here
  const errCond = (val: keyof DetailBankData): boolean => {
    if (errors[val] === undefined) return false;
    if (check && values[val] === '') return true;
    return check || (values[val] !== '' && errors[val] !== undefined);
  };
  const errMsg = (val: keyof DetailBankData): string => {
    if (values[val]?.length === 0) return 'Data tidak boleh kosong';
    if (errors[val] !== undefined) return errors[val];
  };
  // const errCondUpdate = (val: keyof DetailBankData): boolean => {
  //   return check && (values[val] === '' || errCond(val));
  // };
  return (
    <div className="space-y-2 bg-white p-5">
      <div className="font-bold text-xl">Informasi Rekening Bank</div>
      <Alert severity="error" color="warning">
        Mohon pastikan nama pemilik rekening sesuai dengan nama KTP atau KITAS
        yang telah didaftarkan. Gunakan akun bank perusahaan yang sesuai dengan
        NPWP yang telah didaftarkan.
      </Alert>
      <GridSelect
        fieldTitle="Nama Bank"
        options={getBankList}
        selected={{ set: setBankName, val: bankName }}
        placeholder="Pilih nama bank"
        error={check && bankName === ''}
        message="Data tidak boleh kosong"
      />
      <GridLine
        id="BankAccountNumber"
        fieldTitle="Nomor Rekening"
        mode="number"
        value={values?.BankAccountNumber}
        onChange={formik.handleChange}
        placeholder="Masukkan nomor rekening"
        error={errCond('BankAccountNumber')}
        message={errMsg('BankAccountNumber')}
      />
      <GridLine
        id="BankAccountName"
        fieldTitle="Nama Pemilik Rekening"
        mode="text"
        value={values?.BankAccountName}
        onChange={formik.handleChange}
        placeholder="Masukkan nama pemilik rekening"
        error={errCond('BankAccountName')}
        message={errMsg('BankAccountName')}
      />
    </div>
  );
};

export default BankDetail;
