import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'stores';
import {
  getCategoryMessage,
  getDetail,
  getListActions,
  readListAction,
  setDetail,
  setIsDetail,
} from 'stores/actions/notificationActions';
import { permissionFunction } from 'utils/permission';
import permission from 'constants/permissionRole';

const useNotificationns = () => {
  const dispatch = useDispatch();
  const notificationState = useSelector(
    (state: RootState) => state.notificationState
  );
  const user = useSelector((state: RootState) => state.auth.userData);

  const handleGetList = useCallback(
    (
      params: any,
      callback?: (data: any) => void,
      refresh?: boolean,
      topbar?: boolean
    ) => {
      permissionFunction(permission.LIST_MESSAGE) &&
        dispatch(getListActions(params, topbar, refresh, callback));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleGetDetail = useCallback(
    (params: any, callback?: (data: any) => void) => {
      permissionFunction(permission.GET_MESSAGE) &&
        dispatch(getDetail(params, callback));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleSetIsDetail = (isDetail: boolean) => {
    permissionFunction(permission.GET_MESSAGE) &&
      dispatch(setIsDetail(isDetail));
  };

  const handleSetDetail = (id: number) => {
    dispatch(setDetail(id));
  };

  const handleRead = useCallback(
    (callback: (data: any) => void) => {
      permissionFunction(permission.UPDATE_MESSAGE) &&
        dispatch(readListAction(callback));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleCategory = useCallback(
    (callback?: (data: any) => void) => {
      permissionFunction(permission.GET_MESSAGE) &&
        dispatch(getCategoryMessage(callback));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    notificationState,
    user,
    getList: handleGetList,
    setDetail: handleSetDetail,
    setIsDetail: handleSetIsDetail,
    readList: handleRead,
    getDetail: handleGetDetail,
    getCategoryMessage: handleCategory,
  };
};

export default useNotificationns;
