import React from 'react';
import { TextField } from '@mui/material';
import { FormikValues } from 'formik';
import { onlyNumbers } from 'utils/caseFormatter';
import { qris_fields } from '..';
import SectionQRIS, { GridDetailQRIS } from '../template';
import { useSelector } from 'react-redux';
import { RootState } from 'stores';

type Props = { formik: FormikValues; disabled?: boolean };
type field_data = {
  Alamat: string;
  Provinsi: string;
  Kota: string;
  Kecamatan: string;
  KodePos: string;
};
const AlamatMerchant: React.FC<Props> = ({ formik, disabled }) => {
  const url = window.location.href.split('/').pop();
  const { QRIS, detailQRIS: dq } = useSelector((o: RootState) => o.onboarding);
  const fields: qris_fields[] = [
    { title: 'Alamat', id: 'Alamat', max: 255 },
    { title: 'Provinsi', id: 'Provinsi', max: 255 },
    { title: 'Kota / Kabupaten', id: 'Kota', max: 255 },
    { title: 'Kecamatan', id: 'Kecamatan', max: 255 },
    { title: 'Kode POS', id: 'KodePos', isNumber: true, max: 5 },
  ];

  const handleDefValue = (
    regQr: keyof field_data, // register QR value
    detQr: keyof typeof dq, // detail QR value
    etc?: string // other source value
  ) => {
    if (QRIS !== null) return QRIS[regQr];
    if (dq !== null) return String(dq[detQr]);
    if (etc) return etc;
    return '';
  };

  const [data, setData] = React.useState<field_data>({
    Alamat: handleDefValue('Alamat', 'mq_business_address'),
    Kecamatan: handleDefValue('Kecamatan', 'mq_subdistrict'),
    KodePos: handleDefValue('KodePos', 'mq_postal_code'),
    Kota: handleDefValue('Kota', 'mq_city'),
    Provinsi: handleDefValue('Provinsi', 'mq_province'),
  });

  // for validation purposes
  const [errors, setErrors] = React.useState<field_data>({
    Alamat: '',
    Kecamatan: '',
    KodePos: '',
    Kota: '',
    Provinsi: '',
  });
  const validationField = (id: string, val: string, max?: number) => {
    const listName = fields.flatMap((x) => ({ id: x.id, title: x.title }));
    const ids = listName.find((i) => i.id === id).title;
    if (val.length === 0) return `Data tidak boleh kosong`;
    if (max && val.length > max) return `${ids} terlalu panjang`;
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    max?: number
  ) => {
    const { value, id } = e.target;
    setData((prev) => ({ ...prev, [id]: value }));
    formik.setValues((prev) => ({ ...prev, [id]: value })); // because its still need to update the formik
    const error = validationField(id, value, max);
    setErrors((prev) => ({ ...prev, [id]: error })); // get error value
  };
  return (
    <SectionQRIS
      noTitle={url !== 'onboarding' && url !== 'register-qris'}
      title="Alamat Merchant"
    >
      {fields?.map((item) => (
        <GridDetailQRIS key={item.title} title={item.title}>
          <TextField
            id={item.id}
            value={data[item.id]}
            onChange={(e) => handleChange(e, item.max)}
            size="small"
            fullWidth
            placeholder={`Masukkan ${item.title}`}
            onInput={item?.isNumber && onlyNumbers}
            disabled={disabled}
            className={`${disabled ? 'bg-[#F0F0F0]' : ''}`}
          />
          {errors[item?.id] ? (
            <b className="text-red-500">{errors[item.id]}</b>
          ) : null}
        </GridDetailQRIS>
      ))}
    </SectionQRIS>
  );
};

export default AlamatMerchant;
