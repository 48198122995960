import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { RootState } from 'stores';
import { QRISFormSchema } from 'components/configs/validationSchema';
import { terminal_content } from 'pages/Onboarding/QRIS/section/daftarTerminal';
import useActivityBrowser from './useActivityBrowser';
import { getCookie } from 'utils/cookie';

const useFormQRIS = () => {
  const { QRIS, merchantPreview } = useSelector((x: RootState) => x.onboarding);
  const { profile } = useSelector((a: RootState) => a.auth);
  const { data } = merchantPreview;
  const [list, setList] = useState<terminal_content[]>(
    QRIS?.Terminals ?? [{ name: '', email: '', telp: '', term_id: '' }]
  );

  const formRef = useRef<HTMLDivElement | null>(null);
  const infoMerchantRef = useRef<HTMLHRElement | null>(null);
  const addressRef = useRef<HTMLDivElement | null>(null);
  const terminalRef = useRef<HTMLHRElement | null>(null);
  const docsRef = useRef<HTMLHRElement | null>(null);

  const initial = {
    NamaPemilik: QRIS?.NamaPemilik || '',
    NomorRekening: QRIS?.NomorRekening || '',
    NoIdentitas: QRIS?.NoIdentitas || '',
    NoNPWP: QRIS?.NoNPWP || '',
    NoTelp: QRIS?.NoTelp || '',
    NamaMerchant: QRIS?.NamaMerchant || '',
    Alamat: QRIS?.Alamat || '',
    Provinsi: QRIS?.Provinsi || '',
    Kota: QRIS?.Kota || '',
    Kecamatan: QRIS?.Kecamatan || '',
    KodePos: QRIS?.KodePos || '',
  };

  const formik = useFormik({
    initialValues: initial,
    validationSchema: QRISFormSchema,
    onSubmit: (v) => console.log(v),
  });

  useEffect(() => {
    if (data?.npwp_number !== '') {
      formik.setValues({
        ...formik.values,
        NamaPemilik: profile?.Username,
        NoTelp: data?.own_phone,
        NoNPWP: data?.npwp_number,
        NomorRekening: data?.bank_acc_no,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, list]);

  const fieldAddress =
    formik.values.Alamat === '' ||
    formik.values.Provinsi === '' ||
    formik.values.Kota === '' ||
    formik.values.Kecamatan === '' ||
    formik.values.KodePos === '';

  const fieldTerminal =
    list !== undefined && list?.length !== 0
      ? list?.filter(
          (x) =>
            x.name.length === 0 ||
            x.telp.length === 0 ||
            x.email.length === 0 ||
            x.term_id.length === 0
        )
      : null;

  const refs = {
    ref1: formRef,
    ref2: infoMerchantRef,
    ref3: addressRef,
    ref4: terminalRef,
    ref5: docsRef,
  };
  const terminals = { val: list, set: setList };
  const valid = { terminal: fieldTerminal, address: fieldAddress };

  return { formik, terminals, valid, refs };
};

export const useRegisterFormQRIS = () => {
  // eslint-disable-next-line
  const { QRIS, merchantPreview } = useSelector((o: RootState) => o.onboarding);
  // eslint-disable-next-line
  const { profile, userData } = useSelector((a: RootState) => a.auth);
  // eslint-disable-next-line
  const tipsValue = getCookie('tips-qr') || '1';

  useEffect(() => {
    if (QRIS) console.log('');
  }, [QRIS]);

  // formdata for register QRIS via merchant preview
  const formData = new FormData();
  const { browserId, latitude, longitude } = useActivityBrowser();
  formData.append('BrowserId', browserId?.toString());
  formData.append('Longitude', longitude?.toString() || '0');
  formData.append('Latitude', latitude?.toString() || '0');
  // if (QRIS !== null) {
  //   formData.append('MqTypeQr', QRIS.JenisQR);
  //   formData.append('MqMerchantQrisType', QRIS.KategoriQR.slice(0, 2));
  //   // jika tips diisi YA, dan nominal diisi sesuai pilihan 3 || 4
  //   formData.append('MqTypeTips', tipsValue);
  //   if (tipsValue === '3') formData.append('MqTipStatic', QRIS.NominalTips);
  //   if (tipsValue === '4') {
  //     // untuk hilangkan persen yg belakang, di lakukan slice -1
  //     formData.append('MqTipProcentage', QRIS.NominalTips.slice(0, -1));
  //   }
  //   if (merchantPreview.data?.business_type === 2) {
  //     // jika tipe bisnis adalah badan usaha
  //     formData.append('MqBusinessType', QRIS.JenisBadanUsaha);
  //   }
  //   formData.append('MqOwnerName', QRIS.NamaPemilik);
  //   formData.append('MqAccountNo', QRIS.NomorRekening);
  //   formData.append('MqIdentityType', QRIS.JenisIdentitas);
  //   formData.append('MqIdentityNo', QRIS.NoIdentitas);
  //   formData.append('MqNpwp', QRIS.NoNPWP);
  //   formData.append('MqPhoneNumber', QRIS.NoTelp);
  //   formData.append('MqCriteria', QRIS.KriteriaMerchant);
  //   formData.append('MqMerchantName', profile.Fullname || userData.Fullname);
  //   formData.append('MqCategory', QRIS.KategoriMerchant);
  //   formData.append('MqBusinessAddress', QRIS.Alamat);
  //   formData.append('MqProvince', QRIS.Provinsi);
  //   formData.append('MqCity', QRIS.Kota);
  //   formData.append('MqSubdistrict', QRIS.Kecamatan);
  //   formData.append('MqPostalCode', QRIS.KodePos);
  //   formData.append('MqtName', QRIS.Terminals[0]?.name);
  //   formData.append('MqtNumber', QRIS.Terminals[0]?.telp);
  //   formData.append('MqtEmail', QRIS.Terminals[0]?.email);
  //   formData.append('MqtTerminalId', QRIS.Terminals[0]?.term_id);
  //   formData.append('IdCardOwnerPhoto', QRIS.FileKTP);
  //   formData.append('SkuFile', QRIS.FileSKU);
  //   formData.append('DeedCompanyFile', QRIS.FileAkta);
  //   // jika user upload Surat Kuasa Ahli, maka Procuration file digunakan
  //   if (QRIS?.FileSKA) formData.append('ProcurationFile', QRIS.FileSKA);
  // }

  return formData;
};

export default useFormQRIS;
