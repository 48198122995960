import { Skeleton } from '@mui/material';
import Card from 'components/cardComponents';
import { WithdrawalContext } from 'context/withdrawalContext';
import useWithdrawal from 'hooks/useWithdrawal';
import React from 'react';

const FieldSettlementInformation = ({ name, value, isLoading }) => {
  return (
    <div className="flex w-12/12 text-[12px] lg:text-sm mt-1">
      <div className="w-[55%] lg:w-[55%] font-bold">{name}</div>
      <div className={`w-[45%] lg:w-[55%]`}>
        {isLoading === true ? (
          <Skeleton variant="text" sx={{ width: 100, fontSize: '1rem' }} />
        ) : (
          value || '-'
        )}
      </div>
    </div>
  );
};

const SettlementInformation = () => {
  let { handleProses } = React.useContext(WithdrawalContext);
  const { withdrawal } = useWithdrawal();
  let { isLoading } = withdrawal;
  let { settlement_information } = withdrawal.withdrawal_detail;

  return (
    <Card>
      <div className="py-5 px-8">
        <div className="text-[16px] mb-5 mt-2 font-bold">
          Settlement Information
        </div>
        <div>
          <FieldSettlementInformation
            name={'Settlement Status'}
            value={handleProses(settlement_information?.settlement_status)}
            {...{ isLoading }}
          />
          <FieldSettlementInformation
            name={'Settlement Date'}
            value={settlement_information?.settlement_date}
            {...{ isLoading }}
          />
          <FieldSettlementInformation
            name={'Settlement No'}
            value={settlement_information?.settlement_no}
            {...{ isLoading }}
          />
          <FieldSettlementInformation
            name={'Total Rec Transaction'}
            value={settlement_information?.total_records}
            {...{ isLoading }}
          />
        </div>
      </div>
    </Card>
  );
};
export default SettlementInformation;
