import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useEffect, useState } from 'react';

import SidebarItem from './SidebarItem';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'stores/index';
import { PropsCommon } from './interface';
import { setAppState, setMainRoute } from 'stores/actions/appState';

import { ListItemButtonStyleCollaps } from 'components/muiTheme';

const SidebarItemCollapse = ({
  item,
  isOpen,
  mouseOver,
  setisOpen,
}: PropsCommon) => {
  const [open, setOpen] = useState(false);
  const { appState, mainRoute } = useSelector(
    (state: RootState) => state.appState
  );

  const userData = useSelector((state: RootState) => state.auth.userData);

  let dispacth = useDispatch();

  let env = userData['Env'];

  useEffect(() => {
    if (appState.includes(item.state)) {
      setOpen(true);
    } else {
      if (appState.includes(mainRoute)) {
        setOpen(false);
      }
    }
  }, [appState, item, mainRoute]);

  return item.sidebarProps ? (
    <ListItem disablePadding sx={{ display: 'block' }}>
      <ListItemButton
        onClick={() => {
          setOpen(!open);
          !open && dispacth(setAppState(item.state));
          !open && dispacth(setMainRoute(item.state));
        }}
        sx={ListItemButtonStyleCollaps(open, isOpen, env)}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: isOpen ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          {item.sidebarProps.icon && item.sidebarProps.icon}
        </ListItemIcon>
        <ListItemText
          primary={
            <div className="text-[18px]">{item.sidebarProps.displayText}</div>
          }
          sx={{ opacity: isOpen ? 1 : 0 }}
        />
      </ListItemButton>

      {isOpen && (
        <div
          className={`${
            userData['Env'] === false ? 'bg-[#F5F5F5]' : 'bg-[#17251E]'
          }   m-4 mt-[-15px] rounded-b-lg`}
        >
          <Collapse in={open} timeout="auto">
            <List className={`mx-3`}>
              {item.child?.map((route, index) =>
                route.sidebarProps ? (
                  route.child ? (
                    <SidebarItemCollapse
                      item={route}
                      key={index}
                      {...{ isOpen, setisOpen, mouseOver }}
                    />
                  ) : (
                    <SidebarItem
                      item={route}
                      key={index}
                      dot={true}
                      {...{ isOpen, setisOpen, mouseOver }}
                    />
                  )
                ) : null
              )}
            </List>
          </Collapse>
        </div>
      )}
    </ListItem>
  ) : null;
};

export default SidebarItemCollapse;
