import { Outlet } from 'react-router-dom';

import SettingProvider from 'context/settingContext';

const Pengaturan = () => {
  return (
    <SettingProvider>
      <Outlet />
    </SettingProvider>
  );
};

export default Pengaturan;
