import AdjustButton from 'components/Button/AdjustButton';
import TableRefund from '../table';
import ModalCard from './ModalReqRefund';
import SortByButton from 'components/Button/SortByButton';
import useRefund from 'hooks/useRefund';
import { tableNameListRefund } from '../table/datalist';
import ExportButton from 'components/Button/ExportButton';
import useExport from 'hooks/useExport';
import React from 'react';
import { RefundContext } from 'context/refundContext';
import ButtonWithIcon from 'components/Button/ButtonWithIcon';
import { btnTheme } from 'components/configs/theme';
import AddIcon from '@mui/icons-material/Add';
import Card from 'components/cardComponents';

const RefundList = () => {
  let { header } = useRefund();

  let { handleAlert, params, handleOpenModal } =
    React.useContext(RefundContext);

  const { exportRefundList } = useExport();

  const handleDownloadExcel = () => {
    exportRefundList(params, handleAlert, tableNameListRefund);
  };

  return (
    <Card>
      <ModalCard />
      <div className="flex  justify-between pt-7 items-start p-3 mb-3 px-5">
        <div className="md:flex grid gap-2 md:space-x-2">
          <AdjustButton header={tableNameListRefund} list={header} />
          <SortByButton />
        </div>
        <div className="flex gap-3 items-end flex-col">
          <ExportButton haandleClick={handleDownloadExcel} />
          <ButtonWithIcon
            btnTheme={btnTheme}
            onClick={handleOpenModal}
            variant="contained"
            color="primary"
            Icon={<AddIcon />}
          >
            Request Refund
          </ButtonWithIcon>
        </div>
      </div>

      <TableRefund {...{ header }} />
    </Card>
  );
};

export default RefundList;
