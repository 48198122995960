import React from 'react';
import { ITitleFeature } from './interface';

const TitleFeature: React.FC<ITitleFeature> = ({
  text,
  icon,
  onClick,
  className,
}) => {
  return (
    <div
      className={`flex items-center md:mt-5 mb-5 ${className}`}
      onClick={onClick}
    >
      {icon && <div className='mr-3 cursor-pointer'>{icon}</div>}
      <div className='text-2xl sm:text-2xl flex items-center font-bold cursor-pointer'>
        {text}
      </div>
    </div>
  );
};

export default TitleFeature;
