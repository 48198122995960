import useSetting from 'hooks/useSetting';
import React from 'react';

export const SettingContext = React.createContext(null);

const SettingProvider = (props) => {
  let { SettingState } = useSetting();
  const [merchantName, setMerchantName] = React.useState(
    SettingState.qoinView.display_name
  );
  const [files, setFiles] = React.useState([]);
  const [isEdit, setisEdit] = React.useState(false);
  const [isModal, setisModal] = React.useState(false);
  const [dataFiles, setDataFile] = React.useState([]);
  // let dataSettlementSetting = SettingState.systemSetting.settlement;
  const [bgImage, setbgImage] = React.useState();
  const [bgImageFile, setbgImageFile] = React.useState();
  const [logoImage, setLogoImage] = React.useState();
  const [logoFile, setLogoFile] = React.useState();
  const [backGroundColor, setbackGroundColor] = React.useState('');
  const [buttonColor, setbuttonColor] = React.useState('');

  const [dataSettlementSetting, setdataSettlementSetting] = React.useState(
    SettingState.systemSetting.settlement
  );

  const handleCloseModal = () => {
    setisModal(false);
  };

  const thumbs = files.map((file) => (
    <div
      style={{
        borderRadius: 2,
        marginBottom: 8,
        marginRight: 8,
        width: 100,
        height: 100,
        padding: 4,
      }}
      key={file.name}
    >
      <div
        style={{
          display: 'flex',
          minWidth: 0,
          overflow: 'hidden',
        }}
      >
        <img
          src={file.preview}
          style={{
            display: 'block',
            width: 'auto',
            height: '100%',
          }}
          alt=""
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  let valueContext = {
    merchantName,
    setMerchantName,
    thumbs,
    files,
    setFiles,
    isEdit,
    setisEdit,
    isModal,
    setisModal,
    handleCloseModal,

    dataSettlementSetting,
    setdataSettlementSetting,

    dataFiles,
    setDataFile,

    bgImage,
    setbgImage,
    bgImageFile,
    setbgImageFile,
    logoImage,
    setLogoImage,
    logoFile,
    setLogoFile,
    backGroundColor,
    setbackGroundColor,
    buttonColor,
    setbuttonColor,
  };

  return (
    <SettingContext.Provider value={valueContext}>
      {props.children}
    </SettingContext.Provider>
  );
};

export default SettingProvider;
