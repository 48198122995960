import React from 'react';
import {
  FormControl,
  MenuItem,
  Pagination,
  Select,
  Stack,
  ThemeProvider,
} from '@mui/material';
import { PaginationTheme } from 'components/muiTheme';

import { IPagination } from './interface';

import { paginationsSx } from 'components/muiTheme';

const Component: React.FC<IPagination> = ({
  value,
  handleChange,
  handlePage,
  data,
  page,
}) => {
  return (
    <div className="block md:flex lg:flex rounded-b-lg items-center justify-between p-5">
      <div className="text-[#666666] mb-5 lg:mb-0 flex items-center text-sm">
        <div className="mr-2">Show</div>
        <FormControl sx={{ m: 1, minWidth: 5 }}>
          <Select
            value={value}
            onChange={handleChange}
            sx={paginationsSx}
            displayEmpty
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={30}>30</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={-1}>All</MenuItem>
          </Select>
        </FormControl>
        <div>of {data?.total | data?.total_records || 0} data</div>
      </div>
      <div className="pagination hidden md:block lg:block ">
        <ThemeProvider theme={PaginationTheme}>
          <Stack spacing={2}>
            <Pagination
              count={
                data?.last_page || data?.total_page || data?.total_pages || 0
              }
              className="pagination-new"
              shape="rounded"
              color="primary"
              page={page || data?.prev_page || data?.present || 1}
              onChange={handlePage}
            />
          </Stack>
        </ThemeProvider>
      </div>

      <div className="pagination ml-[-10px] mb-5 block sm:hidden">
        <ThemeProvider theme={PaginationTheme}>
          <Stack spacing={2}>
            <Pagination
              size="small"
              className="pagination-new"
              count={
                data?.last_page ||
                data?.total ||
                data?.total_page ||
                data?.total_pages ||
                0
              }
              shape="rounded"
              color="primary"
              page={page || data?.prev_page || data?.present || 1}
              onChange={handlePage}
            />
          </Stack>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default Component;
