import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'stores';
import SubmitButton from 'components/Button/SubmitButton';
import Illust from 'assets/img/paper-notes-illust.png';
import { BasicDialog } from 'components/dialog';
import { Close, InfoOutlined } from '@mui/icons-material';
import { isFullVersion, routePath } from 'utils/exception';
import { getCookie, removeCookie } from 'utils/cookie';
import MerchantSetting from './mercSetting';
import QRSetting from './qrisSetting';
import useOnboardData from 'hooks/useOnboardData';
import {
  getDetailQRIS,
  getMerchantPreview,
} from 'stores/actions/onboardingAction';

const NotOnboarding = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-white w-full p-10 flex flex-col rounded-b-md">
      <img src={Illust} alt="" className="m-auto py-4" />
      <div className="w-1/3 mx-auto py-5 text-center">
        <p>
          You haven't completed your onboarding process To complete your
          onboarding click button below.
        </p>
        <div className="px-10 py-5">
          <SubmitButton
            id="done-onboarding-btn"
            onClick={() =>
              navigate(`${isFullVersion ? '/full/' : '/'}onboarding`)
            }
          >
            Complete Onboarding
          </SubmitButton>
        </div>
      </div>
    </div>
  );
};

const MerchantPreview: React.FC = () => {
  const { profile, userData } = useSelector((state: RootState) => state.auth);
  const { hasQRIS, merchantPreview: MP } = useSelector(
    (o: RootState) => o.onboarding
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { payData } = useOnboardData();

  React.useEffect(() => {
    if (userData?.PackageType !== null && MP.data?.package_type === null)
      dispatch(getMerchantPreview());
  }, [MP.data?.package_type, dispatch, userData?.PackageType]);

  React.useEffect(() => {
    const { payments } = MP.data;
    if (payments.length !== 0 && payments.includes('QR')) {
      dispatch(getDetailQRIS());
    }
  }, [dispatch, MP]);

  const doneOnboard = profile.MerchantStatus !== 'registered';
  const inReview = MP.data?.state === 5;
  const hasQR = MP.data?.payments?.includes('QR');
  const [edits, setEdits] = useState(doneOnboard);
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState(false);
  const [popupQ, setPopupQ] = useState(false);
  const [error, setError] = useState('');
  const [info, setInfo] = useState(false);
  const formQRIS = getCookie('FQR');
  type tabs_var = 'merchant' | 'qris';
  const [currTab, setCurrTab] = useState<tabs_var>('merchant');

  // just to refresh if there's any changes in the content of error (after submit)
  useEffect(() => {
    // if (error !== null) console.log(error);
    if (formQRIS) {
      const data = JSON.parse(formQRIS);
      setEdits(data?.isEdit);
      removeCookie('FQR');
    }
  }, [formQRIS]);

  const xClose = () => {
    setPopup(false);
    window.location.reload();
  };

  // the notif of in review ticketing state
  useEffect(() => {
    if (inReview) setInfo(true);
    else setInfo(false);
  }, [inReview]);

  // tabs in merchant preview page
  const tabTitle: { type: tabs_var; title: string }[] = [
    { type: 'merchant', title: 'Merchant Setting' },
    { type: 'qris', title: 'QRIS Setting' },
  ];

  return (
    <>
      <div className="text-2xl sm:text-3xl items-center font-bold mb-5 mt-[3rem] lg:mt-[0rem] cursor-pointer">
        {!edits && 'Edit '}{' '}
        {currTab === 'merchant' ? 'Merchant Preview' : 'QRIS Detail'}
      </div>

      {/* Warning Messages related to the merchant status */}
      {userData.PackageType === null && !doneOnboard ? (
        <NotOnboarding />
      ) : (
        <>
          {info && (
            <div className="bg-[#FFF6E1] border-[#FDBE2C] border mb-5 p-2 mt-5 font-semibold rounded-md px-5 align-center flex justify-between">
              <div className="flex gap-2">
                <InfoOutlined htmlColor="#FDBE2C" />
                Saat ini status data merchant Anda masih dalam proses
                peninjauan, silakan periksa email Anda secara berkala.
              </div>
              <Close
                className="cursor-pointer"
                onClick={() => setInfo(false)}
              />
            </div>
          )}
        </>
      )}

      {userData.PackageType !== null && (
        <>
          <div className="bg-white flex mb-4">
            {/* Contents Tab */}
            {tabTitle.map((tab) => (
              <div className="flex flex-col w-full" key={tab.title}>
                <button
                  onClick={() => {
                    if (tab.type === 'qris' && (!hasQR || hasQRIS === false)) {
                      setPopupQ(true);
                    } else setCurrTab(tab.type);
                  }}
                  className={`w-full text-center py-3 disabled:text-gray-400/40`}
                  // disabled={tab.type === 'qris' && !hasQR}
                >
                  <b
                    className={`${
                      tab.type === currTab ? 'text-black' : 'text-gray-400/40'
                    }`}
                  >
                    {tab.title}
                  </b>
                </button>
                <hr
                  className={`mx-auto ${
                    tab.type === currTab ? 'bg-[#21AF7D] h-1 w-40' : 'hidden'
                  }`}
                />
              </div>
            ))}
          </div>

          {/* Contents showed after the trigger of the tabs above */}
          {currTab === 'merchant' ? (
            <MerchantSetting
              edit={{ val: edits, set: setEdits }}
              loading={{ val: loading, set: setLoading }}
              popup={{ val: popup, set: setPopup }}
              error={{ val: error, set: setError }}
              data={{ ...{ MP, payData } }}
            />
          ) : (
            <QRSetting {...MP} />
          )}
        </>
      )}

      {/* Popup Dialog */}
      <BasicDialog
        open={popup}
        type="single"
        icon="success"
        text="Ubah Data Merchant Berhasil!"
        subtext="Data merchant Anda berhasil diubah. merchant Anda akan kami tinjau terlebih dahulu. "
        btn={{ color: 'success', text: 'OK', onClick: xClose }}
      />
      <BasicDialog
        type="double"
        open={popupQ}
        leftbtn={{
          color: 'error',
          text: 'Cancel',
          variant: 'outlined',
          onClick: () => setPopupQ(false),
        }}
        rightbtn={{
          color: 'success',
          text: 'Daftar QRIS',
          onClick: () => {
            if (profile?.MerchantStatus?.includes('review')) {
              // jika merchant sedang in review ticketing, redirect ke formulir
              navigate(`${routePath}setting/register-qris`);
            } else {
              // jika tidak, unlock edit merchant preview
              setPopupQ(false);
              setEdits(false);
            }
          },
          disabled: true, // disable karena masih di dev
        }}
        text="Menu Tidak Tersedia"
        icon="warning"
        subtext="Menu QRIS Setting tersedia hanya untuk merchant yang sudah mendaftarkan metode pembayaran QRIS."
        width={480}
      />
    </>
  );
};

export default MerchantPreview;
