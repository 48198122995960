/* eslint-disable import/no-anonymous-default-export */
import update from 'immutability-helper';
import {
  OnboardingType,
  IOnboardingState,
  OnboardingAction,
} from './../types/onboardingTypes';

const initialState: IOnboardingState = {
  step: 0,
  tipeUsaha: '',
  tipeProduk: '',
  informasiBisnis: {
    BizTypeCode: '',
    BizCategoryCode: '',
    BizUrl: '',
    IsDeveloper: false,
    PicFinanceEmail: '',
    PicFinancePhone: '',
    PicTechEmail: '',
    PicTechPhone: '',
    OwnerName: '',
    OwnerAddress: '',
    OwnerZip: '',
    OwnerPhone: '',
    OwnerNationality: '',
    IdCardPhoto: null,
    IdCardPhotoFile: null,
    IdCardName: '',
    IdCardNumber: '',
    NpwpPhoto: null,
    NpwpPhotoFile: null,
    NpwpName: '',
    NpwpNumber: '',
    NpwpAddress: '',
  },
  detailBank: {
    BankName: '',
    BankAccountName: '',
    BankAccountNumber: '',
  },
  bizType: { data: [], error: null, isLoading: false },
  // bizCategory: [],
  getMetodePembayaran: {
    data: [],
    isLoading: false,
    error: null,
  },
  QRIS: null,
  metodePembayaran: [],
  listBank: { data: [], error: null, isLoading: false },
  merchantPreview: {
    data: {
      business_type: 0,
      package_type: null,
      bis_type_code: '',
      bis_category_code: '',
      bis_url: '',
      is_developer: 0,
      finance_email: '',
      finance_phone: '',
      tech_email: '',
      tech_phone: '',
      own_address: '',
      own_zip: '',
      own_phone: '',
      own_citizen: '',
      ktp_name: '',
      ktp_no: '',
      ktp_url: '',
      npwp_name: '',
      npwp_number: '',
      npwp_address: '',
      npwp_url: '',
      bank: '',
      bank_acc_no: '',
      bank_acc_name: '',
      user_id: null,
      merchant_id: null,
      merchant_code: '',
      payments: [],
      state: 0,
      status: 0,
      info_status: '',
    },
    isLoading: false,
    error: null,
  },
  // message: '',
  // loading: false,
  newMercPreview: { data: null },
  isLoading: false,
  detailQRIS: null,
  hasQRIS: false,
  masterBCriteria: null,
  masterMCC: null,
  masterCompanies: null,
  infoBizUpdate: null,
  infoRekening: null,
};

export default (state = initialState, { type, payload }: OnboardingAction) => {
  switch (type) {
    case OnboardingType.SET_STEP:
      return update(state, {
        step: { $set: payload },
      });
    case OnboardingType.TIPE_USAHA:
      return update(state, {
        tipeUsaha: { $set: payload },
      });
    case OnboardingType.TIPE_PRODUK:
      return update(state, {
        tipeProduk: { $set: payload },
      });
    case OnboardingType.SET_INFORMASI_BISNIS:
      return update(state, {
        informasiBisnis: { $set: payload },
      });
    case OnboardingType.SET_IS_DEV:
      return update(state, {
        informasiBisnis: {
          IsDeveloper: { $set: payload },
        },
      });
    case OnboardingType.SET_INFORMASI_FOTO_KTP:
      return update(state, {
        informasiBisnis: {
          IdCardPhoto: { $set: payload },
        },
      });
    case OnboardingType.UPLOAD_KTP:
      return update(state, {
        informasiBisnis: {
          IdCardPhotoFile: { $set: payload },
        },
      });
    case OnboardingType.UPLOAD_NPWP:
      return update(state, {
        informasiBisnis: {
          NpwpPhotoFile: { $set: payload },
        },
      });
    case OnboardingType.SET_INFORMASI_FOTO_NPWP:
      return update(state, {
        informasiBisnis: {
          NpwpPhoto: { $set: payload },
        },
      });
    case OnboardingType.SET_DETAIL_BANK:
      return update(state, {
        detailBank: { $set: payload },
      });
    case OnboardingType.SET_QRIS_FORM:
      return update(state, { QRIS: { $set: payload } });
    case OnboardingType.GET_METODE_PEMBAYARAN_PENDING:
      return update(state, {
        getMetodePembayaran: {
          isLoading: { $set: true },
        },
      });
    case OnboardingType.GET_METODE_PEMBAYARAN_SUCCESS:
      return update(state, {
        getMetodePembayaran: {
          isLoading: { $set: false },
          data: { $set: payload },
        },
      });
    case OnboardingType.GET_METODE_PEMBAYARAN_ERROR:
      return update(state, {
        getMetodePembayaran: {
          isLoading: { $set: false },
          error: { $set: payload.error },
        },
      });

    case OnboardingType.SET_METODE_PEMBAYARAN:
      return update(state, {
        metodePembayaran: { $set: payload },
      });

    case OnboardingType.GET_BIZ_TYPE_PENDING:
      return update(state, {
        bizType: { isLoading: { $set: true } },
      });
    case OnboardingType.GET_BIZ_TYPE_ERROR:
      return update(state, {
        bizType: { isLoading: { $set: false }, error: { $set: payload.error } },
      });
    case OnboardingType.GET_BIZ_TYPE_SUCCESS:
      return update(state, {
        bizType: { data: { $set: payload }, isLoading: { $set: false } },
      });

    case OnboardingType.GET_LIST_BANK_SUCCESS:
      return update(state, {
        listBank: { data: { $set: payload }, isLoading: { $set: false } },
      });
    case OnboardingType.GET_LIST_BANK_PENDING:
      return update(state, {
        listBank: { isLoading: { $set: true } },
      });
    case OnboardingType.GET_LIST_BANK_ERROR:
      return update(state, {
        listBank: {
          error: { $set: payload.error },
          isLoading: { $set: false },
        },
      });

    case OnboardingType.MERCHANT_PREVIEW_PENDING:
      return update(state, {
        merchantPreview: {
          isLoading: { $set: true },
        },
      });
    case OnboardingType.MERCHANT_PREVIEW_SUCCESS:
      return update(state, {
        merchantPreview: {
          data: { $set: payload },
          isLoading: { $set: false },
        },
      });
    case OnboardingType.MERCHANT_PREVIEW_ERROR:
      return update(state, {
        merchantPreview: {
          isLoading: { $set: false },
          error: { $set: payload.error },
        },
      });
    case OnboardingType.NEW_PREVIEW_SUCCESS:
      return update(state, {
        newMercPreview: { data: { $set: payload } },
      });
    case OnboardingType.SUBMIT_ONBOARD_LOADING:
      return update(state, { isLoading: { $set: payload } });
    case OnboardingType.GET_DETAIL_QRIS:
      return update(state, { detailQRIS: { $set: payload } });
    case OnboardingType.CHECK_STATUS_QRIS:
      return update(state, { hasQRIS: { $set: payload } });
    case OnboardingType.GET_DATA_PENDING:
      return update(state, { isLoading: { $set: true } });
    case OnboardingType.GET_MASTER_MCC:
      return update(state, {
        isLoading: { $set: false },
        masterMCC: { $set: payload },
      });
    case OnboardingType.GET_MASTER_BIZ_CRITERIA:
      return update(state, {
        isLoading: { $set: false },
        masterBCriteria: { $set: payload },
      });
    case OnboardingType.GET_MASTER_COMPANY:
      return update(state, {
        isLoading: { $set: false },
        masterCompanies: { $set: payload },
      });
    case OnboardingType.GET_DATA_ERROR:
      return update(state, {
        isLoading: { $set: false },
        masterBCriteria: { $set: null },
        masterMCC: { $set: null },
      });
    case OnboardingType.SET_INFORMASI_BISNIS_UPDATE:
      return update(state, {
        isLoading: { $set: false },
        infoBizUpdate: { $set: payload },
      });
    case OnboardingType.SET_REKENING_BANK:
      return update(state, {
        isLoading: { $set: false },
        infoRekening: { $set: payload },
      });
    default:
      return state;
  }
};
