import React from 'react';

type ResetBtnProp = {
  onClick?: () => void;
};

const Component: React.FC<ResetBtnProp> = ({ onClick }) => {
  return (
    <div
      className="mr-4 text-[#F04545] text-center my-2  font-bold cursor-pointer"
      onClick={onClick}
    >
      reset
    </div>
  );
};

export default Component;
