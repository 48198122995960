import { Outlet, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import colorConfigs from '../configs/colorConfigs';
import TopbarMobile from '../common/TopbarMobile';
import { useEffect, useState } from 'react';
import { AppBar } from 'components/muiTheme/muiStyled';
import CssBaseline from '@mui/material/CssBaseline';
import { changeEnv, userLogout } from 'stores/actions/authActions';
import useSandbox from 'hooks/useSandbox';
import { useDispatch } from 'react-redux';
import { isFullVersion } from 'utils/exception';
import { BasicDialog } from 'components/dialog';
import { setDrawerContent } from 'stores/actions/utilsAction';
import RightDrawer from 'components/drawer';
import { getExpiredDay } from 'components/warning/ExpiredWarning';

const MainLayoutMobile = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const dispatch = useDispatch();

  const [openPopup, setOpenPopup] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  function isSandbox() {
    const param = new URLSearchParams(location.search);
    return param.get('sandbox') === 'false';
  }

  useEffect(() => {
    if (params.get('logout') === 'true') {
      userLogout();
      window.location.replace(isFullVersion ? '/full' : '/');
    } else if (params.get('logout') === 'false') {
      dispatch(changeEnv(isSandbox()));
      window.location.replace(isFullVersion ? '/full' : '/');
    }
    // eslint-disable-next-line
  }, [isSandbox]);

  let { userData } = useSandbox();

  const warning = (days: number) => {
    setShowWarning(true);
    if (days < 1) {
      setOpenPopup(true);
    }
  };

  const { diffDays, expiredLimit } = getExpiredDay(userData, warning);

  useEffect(() => {
    if (showWarning === false) {
      expiredLimit();
    }
    const expired = setInterval(() => {
      expiredLimit();
    }, 60 * 1000);
    return () => {
      clearInterval(expired);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diffDays]);

  const handleDrawer = () => {
    dispatch(setDrawerContent('password'));
    setOpenDrawer(true);
  };

  return (
    <>
      <RightDrawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      />
      <BasicDialog
        type='double'
        open={openPopup}
        icon='warning'
        text='Change Your Expired Password!'
        subtext='Your password has expired. You will not access system until your password is changed.'
        leftbtn={{
          text: 'Cancel',
          color: 'error',
          variant: 'outlined',
          onClick: () => setOpenPopup(false),
        }}
        rightbtn={{
          text: 'Change Now',
          color: 'success',
          onClick: handleDrawer,
        }}
        PaperProps={{ sx: { maxWidth: 450 } }}
      />

      {/* mobile */}
      <div className='block '>
        <CssBaseline />

        {/* navbar */}
        <AppBar
          position='fixed'
          className='w-full'
          color='primary'
          sx={{ top: 0, width: '100%' }}
        >
          <TopbarMobile isOpen={undefined} />
        </AppBar>

        {/* content  */}
        <Box
          component='main'
          sx={{
            flexGrow: 1,
            pb: 0,
            mb: '',
            width: `100%`,
            minHeight: '100vh',
            height: '100%',
            backgroundColor: colorConfigs.mainBg,
          }}
        >
          {!userData['Env'] && (
            <div className='bg-[#F6A820] w-[100%] mt-[4rem] mb-[-3.8rem] px-5 py-3 text-center'>
              You are in Test Mode and any transactions made are simulated (not
              real).
            </div>
          )}

          <div className='px-[2rem] mt-20 mb-5 pt-[0.8rem]'>
            <Outlet />
          </div>
        </Box>
      </div>
    </>
  );
};

export default MainLayoutMobile;
