export const PaginationState = {
  total_records: 0,
  total_page: 0,
  page: 0,
  next: 0,
  previous: 0,
};
export const PaginationState2 = {
  total_records: 0,
  present: 0,
  previous: 0,
  next: 0,
  total_pages: 0,
};
