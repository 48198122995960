import React from 'react';
import {
  FormControlLabel,
  FormControlLabelProps,
  Radio,
  RadioProps,
  ThemeProvider,
} from '@mui/material';
import { btnTheme } from 'components/muiTheme';
import { formLabelTheme } from 'components/muiTheme/theme';

type radio_props = {} & RadioProps;
const Component: React.FC<radio_props> = (props) => {
  return (
    <ThemeProvider theme={btnTheme}>
      <Radio {...props} />
    </ThemeProvider>
  );
};

type no_control = Omit<FormControlLabelProps, 'control'>;
type radio_label = no_control & { radio?: Pick<RadioProps, 'sx'> };
// later if you want to custom the Radio Component, you can just using props radio:{sx:{...}}

export const RadioWithLabel: React.FC<radio_label> = (props) => {
  return (
    <ThemeProvider theme={formLabelTheme}>
      <FormControlLabel
        control={<Component {...props?.radio} />}
        label={props.label}
        {...props}
      />
    </ThemeProvider>
  );
};

export default Component;
