/* eslint-disable import/no-anonymous-default-export */
import update from 'immutability-helper';

import {
  RegisterAction,
  IRegisterState,
  RegisterType,
} from 'stores/types/registerTypes';

const initialState = {
  data: {
    Fullname: '',
    email: '',
    Username: '',
    website: '',
    instagram: '',
    phone: '',
    PackageType: '',
  },
  isLoading: false,
  error: null,
};

export default (
  state: IRegisterState = initialState,
  { type, payload }: RegisterAction
) => {
  switch (type) {
    case RegisterType.SET_DATA_REGISTER:
      return update(state, {
        data: { $set: payload },
      });
    case RegisterType.SET_FORGOT_EMAIL:
      return update(state, {
        data: { $set: payload },
      });
    case RegisterType.SET_REGISTER_VALIDATE:
      return update(state, {
        data: {
          email: { $set: payload },
        },
      });

    default:
      return state;
  }
};
