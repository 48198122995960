import { addOption } from 'components/select';
import { Option } from 'components/select/interface';

export type qris_fields = {
  title: string;
  id: string;
  isNumber?: boolean;
  isOption?: boolean;
  options?: Option[];
  states?: { val: string; set: (val: string) => void };
  disabled?: boolean;
  defaultValue?: string;
  max?: number;
};

type list_opt = { name: string; desc?: string };
// const lorem =   'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt';
// list wording on "formulir pendaftaran QRIS" here
const subtext = [
  'QR yang bersifat tetap dan sama di setiap transaksi yang menggunakan QR.',
  'QR yang bersifat tidak tetap dan akan berubah di setiap transaksi yang menggunakan QR.',
  'QR yang telah terdaftar di PT Penyelesaian Transaksi Elektronik Nasional(PTEN).',
  'QR yang belum terdaftar di PT Penyelesaian Transaksi Elektronik Nasional(PTEN).',
  'Di setiap transaksi Anda nantinya akan termasuk dengan Tip.',
  'Di setiap transaksi Anda nantinya tidak akan termasuk dengan Tip.',
];
const listQR: list_opt[] = [
  { name: 'Static', desc: subtext[0] },
  { name: 'Dynamic', desc: subtext[1] },
];
const categoryQR: list_opt[] = [
  { name: '51 - Nasional', desc: subtext[2] },
  { name: '26 - Lokal', desc: subtext[3] },
];
const QRtip: list_opt[] = [
  { name: 'YA', desc: subtext[4] },
  { name: 'TIDAK', desc: subtext[5] },
];
const listUsaha = [addOption('1', 'Perorangan'), addOption('2', 'Badan Usaha')];

// Merchant QRIS Category
const listBU = [
  addOption('1', 'PT'),
  addOption('2', 'CV'),
  addOption('3', 'UD'),
  addOption('4', 'Firma'),
  addOption('5', 'Koperasi'),
  addOption('6', 'Yayasan'),
];

// Merchant QRIS Identity
const listID = [
  addOption('1', 'KTP'),
  addOption('2', 'Paspor'),
  addOption('3', 'KITAS'),
];

// Merchant QRIS Criteria
const listKriteria = [
  addOption('UMI', 'Usaha Mikro'),
  addOption('UKE', 'Usaha Kecil'),
  addOption('UME', 'Usaha Menengah'),
  addOption('UBE', 'Usaha Besar'),
];

// Merchant QRIS Category
const listKategori = [
  addOption('1', 'Regular'),
  addOption('2', 'Khusus'),
  addOption('3', 'Pendidikan'),
  addOption('4', 'SPBU'),
  addOption('5', 'G2P (Bansos) P2G (Pajak, Paspor) Donasi Sosial (Nirlaba)'),
];

export const QRoptions = {
  listUsaha,
  listBU,
  listID,
  listKriteria,
  listKategori,
  listQR,
  categoryQR,
  QRtip,
};
