import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { Backdrop, Button, ThemeProvider } from '@mui/material';
import { btnTheme2, ModalSx } from 'components/muiTheme';
import { icons } from 'assets';
import useRefund from 'hooks/useRefund';
import { RefundContext } from 'context/refundContext';

const ModalCard = () => {
  let {
    handleCloseModal,
    openModal,
    transactionNo,
    reason,
    handleSubmitRefund,
  } = React.useContext(RefundContext);

  const { refundList, authState } = useRefund();

  let { detailRefund } = refundList;

  let { Fullname } = authState;

  let bodyData = {
    customer_email: detailRefund.payer_email,
    customer_name: detailRefund.payer_name,
    customer_phone: detailRefund.payer_phone,
    merchant_name: Fullname,
    order_number: transactionNo,
    reason_to_refund: reason,
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModal}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <Box sx={ModalSx}>
          <div className="flex justify-center text-center flex-col">
            <div className="text-center flex justify-center">
              <img src={icons.seru} className="w-[80px]" alt="seru" />
            </div>
            <div className="font-bold text-[17px] mt-6">
              Yakin ingin melanjutkan Refund?
            </div>
            <div className="text-[13px] mt-3">
              Kamu sedang melakukan Refund. Pastikan keputusan sudah sesuai.
            </div>
            <div className="flex mt-8">
              <ThemeProvider theme={btnTheme2}>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    width: '200px',
                    marginRight: 3,
                    fontSize: '12px',
                  }}
                  onClick={handleCloseModal}
                >
                  Tidak, Batalkan
                </Button>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  loading={refundList.isLoading}
                  sx={{ width: '200px', fontSize: '12px' }}
                  onClick={() => handleSubmitRefund(bodyData)}
                >
                  Ya, Lanjutkan
                </LoadingButton>
              </ThemeProvider>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ModalCard;
