import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Upload from 'assets/img/drop-image.png';
import { Button } from '@mui/material';
import Resizer from 'react-image-file-resizer';

export const handleImageUpload = (file, setUpload, setPreview) => {
  setUpload(file);
  Resizer.imageFileResizer(
    file,
    500,
    500,
    'PNG',
    100,
    0,
    (uri) => {
      setPreview(uri);
    },
    'base64'
  );
};

const TemplateUpload = () => {
  return (
    <>
      <img
        src={Upload}
        alt="upload"
        width="50em"
        className="justify-center m-auto"
      />
      <div className="flex-col flex">
        <p>Drop your image here</p>
        <p>or</p>
        <Button
          sx={{ margin: 'auto' }}
          className="w-32 border-2 border-black font-bold justify-center"
        >
          Browse
        </Button>
      </div>
    </>
  );
};

const Component: React.FC = () => {
  // eslint-disable-next-line
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    // accept: {
    //   'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
    // },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  // const thumbs = files.map((file) => (
  //   <div key={file.name}>
  //     {/* <img className="w-60" src={file.preview} alt="preview" /> */}
  //     <p>{file.path}</p>
  //   </div>
  // ));

  return (
    <section className="bg-white border-2 border-opacity-20 border-black border-dashed rounded-md col-span-2 h-full py-8">
      <div
        {...getRootProps({
          className:
            'dropzone flex flex-col justify-center align-center text-center gap-2',
        })}
      >
        <input className="border border-black rounded" {...getInputProps()} />
        <TemplateUpload />
        {/* {thumbs && <div className="font-bold h-full">{thumbs}</div>} */}
      </div>
    </section>
  );
};

export default Component;
