import { AxiosError } from 'axios';
import { ThunkAction } from 'redux-thunk';
import {
  TransactionAction,
  TransactionType,
  IFilterTlReport,
  ITransactionListReport,
} from 'stores/types/transactionTypes';
import provider, { IProvider } from 'provider';
import MethodProvider from 'provider/methods';
import { RootState } from 'stores';
import { IError } from 'stores/types/errorTypes';
import { errorGlobal } from './errorAction';
import endpoints from 'api/endpoints';
import { IApiResponse } from 'stores/types/generalTypes';

export const TransactionList_ReportPending = (): TransactionAction => ({
  type: TransactionType.SET_TRANSACTION_LIST_PENDING,
});

export const TransactionList_ReportSucess = (
  data: ITransactionListReport
): TransactionAction => ({
  type: TransactionType.GET_TRANSACTION_LIST_SUCCESS,
  payload: {
    filter_params: data?.filter_params,
    pagination: data.pagination,
    amounts: data.amounts,
    summary: data?.summary,
    transactions: data.transactions,
  },
});

const GetFilterDataTList = (data: IFilterTlReport): TransactionAction => ({
  type: TransactionType.GET_FILTER_LIST,
  payload: {
    dataFilter: {
      channels: data.channels,
      states: data.states,
      statuses: data.statuses,
    },
  },
});

export const IGetDetail = (
  data: ITransactionListReport['detail']
): TransactionAction => ({
  type: TransactionType.GET_DETAIL,
  payload: {
    detail: {
      summary: data.summary,
      custommer: data.custommer,
      payment: data.payment,
      items: data.items,
      items_v2: data?.items_v2,
      timeline: data.timeline,
      fees: data.fees,
    },
  },
});

export const TransactionList_ReportError = (
  error: AxiosError
): TransactionAction => ({
  type: TransactionType.SET_TRANSACTION_LIST_ERROR,
  payload: {
    error,
  },
});

export const TransactionList_ReportErrorGet = (
  error: AxiosError
): TransactionAction => ({
  type: TransactionType.SET_TRANSACTION_LIST_ERROR_GET,
  payload: {
    error,
  },
});

export const ActionGetTransactionList_Report = (
  params?: any,
  callback?: (data: IApiResponse) => void
): ThunkAction<void, RootState, null, TransactionAction | IError> => {
  return async (dispatch, getState) => {
    dispatch(TransactionList_ReportPending());
    let sandbox = getState()?.auth?.userData['Env'];
    try {
      const objProvider: IProvider = {
        sandbox: sandbox,
        method: MethodProvider.GET,
        path: endpoints.transacionListReportv3,
        queryParams: params,
      };
      const response = await provider(objProvider);
      if (response?.status === 200) {
        dispatch(TransactionList_ReportSucess(response?.data?.data));
        callback(response);
      }
    } catch (e) {
      dispatch(errorGlobal(e));
      callback(e);
      dispatch(TransactionList_ReportErrorGet(e));
    }
  };
};

export const ActionGetFilterTL_Report = (
  callback?: (data: IApiResponse) => void
): ThunkAction<void, RootState, null, TransactionAction | IError> => {
  return async (dispatch, getState) => {
    dispatch(TransactionList_ReportPending());
    let sandbox = getState()?.auth?.userData['Env'];
    try {
      const objProvider: IProvider = {
        sandbox: sandbox,
        method: MethodProvider.GET,
        path: endpoints.transacionListReport + '/params',
      };
      const response = await provider(objProvider);
      response?.data?.code === 200 &&
        dispatch(GetFilterDataTList(response?.data?.data));
      callback(response?.data);
    } catch (e) {
      dispatch(errorGlobal(e));
      callback(e);
      dispatch(TransactionList_ReportError(e));
    }
  };
};

export const ActionGetDetail = (
  id: string,
  callback?: (data: IApiResponse) => void
): ThunkAction<void, RootState, null, TransactionAction | IError> => {
  return async (dispatch, getState) => {
    dispatch(TransactionList_ReportPending());
    let sandbox = getState()?.auth?.userData['Env'];

    try {
      const objProvider: IProvider = {
        sandbox: sandbox,
        method: MethodProvider.GET,
        path: `${endpoints.transacionListReportDetail}/${id}`,
      };
      const response = await provider(objProvider);
      response?.data?.code === 200 &&
        dispatch(IGetDetail(response?.data?.data));
      callback(response?.data);
    } catch (e) {
      dispatch(errorGlobal(e));
      callback(e);
      dispatch(TransactionList_ReportError(e));
    }
  };
};
