/* eslint-disable import/no-anonymous-default-export */
import { AxiosRequestConfig } from 'axios';
import config from 'constants/config';
import axiosInstance from './axios.instance';
import MutationTypes from './methods';
import { handleGetIdmKey } from 'stores/actions/utilsAction';

export interface IProvider extends Omit<AxiosRequestConfig, 'method'> {
  path: AxiosRequestConfig['url'];
  queryParams?: AxiosRequestConfig['params'];
  method: MutationTypes;
  sandbox?: boolean;
  payout?: boolean;
  dataType?: string;
  Session?: string;
}

export default ({
  method,
  path,
  data,
  queryParams,
  headers,
  sandbox,
  payout,
  Session,
}: IProvider): any => {
  const instanceConfig: AxiosRequestConfig = {
    baseURL: `${
      payout
        ? sandbox === false
          ? config.API_URL_SANDBOX_PAYOUT
          : config.API_URL_PAYOUT
        : sandbox === false
        ? config.API_URL_SANDBOX
        : config.API_URL
    }`,
    url: path,
    params: queryParams,
    data,
  };

  if (headers) {
    axiosInstance.defaults.headers = {
      ...axiosInstance.defaults.headers,
      ...headers,
    };
  }

  switch (method) {
    case MutationTypes.GET: {
      instanceConfig.method = MutationTypes.GET;
      data && (instanceConfig.data = JSON.stringify(data));
      break;
    }
    case MutationTypes.POST: {
      instanceConfig.method = MutationTypes.POST;
      Object.assign(axiosInstance.defaults.headers, {
        'Idempotency-Key': handleGetIdmKey(path, data, Session),
        Session: Session || '12',
      });
      break;
    }
    case MutationTypes.DELETE: {
      instanceConfig.method = MutationTypes.DELETE;
      instanceConfig.data = JSON.stringify(data);
      break;
    }
    case MutationTypes.PUT: {
      instanceConfig.method = MutationTypes.PUT;
      Object.assign(axiosInstance.defaults.headers, {
        'Idempotency-Key': handleGetIdmKey(path, data, Session),
        Session: Session || '12',
      });
      break;
    }
    case MutationTypes.PATCH: {
      instanceConfig.method = MutationTypes.PATCH;
      instanceConfig.data = JSON.stringify(data);
      break;
    }
    default:
      throw new Error(`Unsupported Data Provider request method ${method}`);
  }

  return axiosInstance.request(instanceConfig);
};
