import React from 'react';
import { TextField } from '@mui/material';
import HelperText from 'components/helperText';
import { InputText } from '../interface';

const Component: React.FC<InputText> = (props) => {
  const {
    id = 'email',
    className,
    onBlur,
    onChange,
    placeholder = 'Masukkan E-mail',
    value,
    error,
    disabled,
    message,
    size = 'medium',
    required = false,
    title,
  } = props;
  const standardId = id === 'email';
  return (
    <div className={`space-y-2 ${className}`}>
      <div className="font-medium">{standardId ? 'Email' : title}</div>
      <TextField
        id={standardId ? 'email' : id}
        name={standardId ? 'email' : id}
        type="email"
        fullWidth
        className={className}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        error={value !== '' && error !== undefined}
        disabled={disabled}
        size={size}
        required={required}
      />
      {value !== '' && error && <HelperText error={message} />}
    </div>
  );
};

export default Component;
