import React, { useEffect } from 'react';
import Card from 'components/cardComponents';

import TableWdTransactionList from '../table/tableSettlementTransactionList';
import { FiArrowLeft } from 'react-icons/fi';
import useWithdrawal from 'hooks/useWithdrawal';
import { WithdrawalContext } from 'context/withdrawalContext';
import { IApiResponse } from 'stores/types/generalTypes';
import SettlementSummary from './SettlementSummar';
import SettlementInformation from './SettlementInformation';
import TransferSummary from './TransferSummary';

const DetailSettlement = () => {
  let { handleChangeUi, idDetail, handleAlert, paramsDetail } =
    React.useContext(WithdrawalContext);

  const { getWdDetail } = useWithdrawal();

  useEffect(() => {
    getWdDetail(paramsDetail, idDetail, (response: IApiResponse) => {
      if (response.code !== 200) {
        handleAlert('error', 'Error');
      }
    }); // eslint-disable-next-line
  }, [getWdDetail, paramsDetail, idDetail]);

  return (
    <>
      <div
        className="text-2xl sm:text-2xl flex items-center font-bold mb-5 mt-[5rem] lg:mt-[1rem] cursor-pointer"
        onClick={() => handleChangeUi('')}
      >
        <FiArrowLeft className="mr-3" style={{ fontSize: 25 }} />
        Detail Settlement{' '}
      </div>

      <div className="flex flex-col md:grid grid-cols-2 gap-4">
        <SettlementInformation />
        <SettlementSummary />

        <div className="flex flex-col gap-4 col-span-2">
          <TransferSummary />
          <Card>
            <div className="py-5 px-8">
              <div className="text-md font-bold py-2">
                Settlement Transaction List
              </div>
            </div>
            <TableWdTransactionList />
          </Card>
        </div>
      </div>
    </>
  );
};

export default DetailSettlement;
