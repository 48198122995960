const endpoints = {
  login: 'login',
  register: 'register/web/merchant',
  getStatus: 'async/check/',
  changePassword: 'password',
  profile: 'clientpg/profile',
  profilePhoto: 'clientpg/profile-photo',
  initialForgotPassword: 'forgot/password',
  validateForgotPassword: 'forgot/password/validation',
  validateEmail: 'email/validation',
  registerValidate: 'register/validation',
  dashboard: 'merchant-dashboard',
  ipWhitelist: 'merchant-manager-ip',
  clientManagerClient: 'client-manager-client',
  minio: 'download',
  refreshToken: 'token/refresh',
  resendEmail: 'resend/email',
  clientManagerUser: 'client-manager-user',
  clientManagerRoleUser: 'client-manager-role-user',
  notification: 'message/list',
  notificationRead: 'message/edit',
  notificationDelete: 'message/delete',
  notificationDetail: 'message/detail',
  sandbox: {
    paymentMethod: 'sandbox/payment-method-go',
    paymentBizType: 'sandbox/payment-biz-type-go',
    paymentBizCategory: 'sandbox/payment-biz-category-go',
    onboarding: 'sandbox/merchantpg/onboard_merchant',
    merchantAccount: 'sandbox/merchant/account',
    merchantTransaction: 'sandbox/merchant/transaction',
    merchantSettlement: 'sandbox/merchant/settlement',
    merchantWithdrawal: 'sandbox/merchant/withdrawal',
    merchantJournal: 'sandbox/merchant/journal-list',
    createPaymentLink: 'sandbox/paymentlink/order',
    getPaymentLink: 'sandbox/paymentlink/list',
    paymentLinkLanding: 'sandbox/paymentlink/landing',
  },
  production: {
    paymentMethod: 'v2/payment',
    paymentBizType: 'merchantpg/bussiness_type',
    paymentBizCategory: 'merchantpg/bussiness_category',
    bizTypeCategory: 'merchantpg/bussiness_type_category',
    onboarding: 'merchantpg/onboard_merchant',
    merchantPreview: 'merchantpg/merchant_preview',
    merchantAccount: 'merchantgo/account',
    merchantTransaction: 'merchantgo/transaction',
    merchantSettlement: 'merchant/settlement',
    merchantWithdrawal: 'merchantgo/withdrawal',
    merchantJournal: 'merchant/journal-list',
    createPaymentLink: 'paymentlink/order',
    getPaymentLink: 'paymentlink/list',
    paymentLinkLanding: 'paymentlink/landing',
    paymentLinkDetail: 'paymentlink/detail',
    refundListReport: 'refund/list',
    refundListReportDetail: 'refund/transaction-detail',
    refundCreate: 'refund/create',
  },
  balance: 'payout/balance',
  apiSetting: 'merchantpg/api_settings',
  apiGenerate: 'merchantpg/generate',
  editApiSetting: 'merchantpg/api_settings',
  transacionListReport: '/merchant/transaction',
  transacionListReportv3: '/v3/merchant/transaction',
  transacionListReportDetail: 'merchant/transaction/detail',
  withdrawalOrder: 'merchant/withdrawal/order',
  withdrawalList: 'merchant/withdrawals/process',
  withdrawalDetail: 'merchant/withdrawals/detail',
  withdrawalSettled: 'merchant/withdrawal/settled',
  getListBank: 'merchantpg/bank',
  merchantSettlementIn: 'settlement-in/list',
  merchantSettlementOut: 'settlement-out/list',
  merchantJournalSummary: 'merchant/journal-summary',
  merchantManagerLogo: 'merchantgo-manager-logo',
  merchantManagerPaymentChannel: 'merchantgo-manager-payment-channel',
  merchantManagerPaymentMethod: 'merchantgo-manager-payment-method',
  merchantManagerSystemSetting: 'merchantgo-manager-system-setting',
  requestProduction: 'webadmin/merchant-biz/request-production',
  merchantBankInfo: 'merchant/merchant-withdrawalgo/bank-info',
  paymentType: 'merchant/merchant-withdrawalgo/payment-type',
  getSummary: 'merchant/merchant-withdrawalgo/summary',
  createWithdrawal: 'merchant/merchant-withdrawalgo/create',
  transactionDetail: 'merchant/transaction-detail',
  emailNotificationUpdate: 'merchant-manager-failed-email/update',
  deactivateMerchant: 'merchant/suspended',
  settlementOutDetail: 'settlement-out/detail',
  settlementInDetail: 'settlement-in/detail',
  merchantBalance: 'merchant/balance',
  activityLog: 'merchant/log/activity',
  disbursement: 'disbursement',
  disbursementBank: 'disbursement/bank',
  disbursementInquiry: 'disbursement/inquiry',
  disbursementTransfer: 'disbursement/transfer',
  disbursementBalance: 'disbursement/balance',
  createPaymentChannelConfig: 'merchant/payment-channel/custom-config/create',
  updatePaymentChannelConfig: '/merchant/payment-channel/custom-config/update',
  getMerchantPaymentChannelConfig: '/merchant/payment-channel/custom-config',
  createSignature: 'access/signature/create',
  getPaymentQoinView: 'merchantpg/qoinview/payment',
  getDataMerchantTheme: 'merchantpg/qoinview/thema',
  updatePaymentQoinView: 'merchantpg/qoinview/payment',
  updateDataMerchantTheme: 'merchantpg/qoinview/thema',
  reqProd: 'merchantpg/request_prod',
  categoryMessage: '/message/categories',
  earning: 'merchant/transaction/earning',
  ticketing: {
    edit: 'merchantpg/edit_profile_ticketing',
    donation: 'v2/list_master_donation',
    deactivate: {
      close: 'merchantpg/submit_deactive_merchant',
      cancel: 'merchantpg/cancel_deactive_merchant',
      current: 'merchantpg/get_last_data_deactive_merchant',
    },
  },
  captcha: 'clientpg/captcha',
  encrypt: 'clientpg/encrypt',
  decrypt: 'clientpg/decrypt',
  sso: 'clientpg/sso',
  dashboardTotalTransaction: 'dashboard/total_transaction?type=merchant',
  dashboardTotalIncome: 'dashboard/total_income?type=merchant',
  dashboardTotalPc: 'dashboard/total_payment_channel?type=merchant',
  dashboardTotalPm: 'dashboard/total_payment_method?type=merchant',
  dashboardTotalAmountByTime: 'dashboard/total_transaksi_amount?type=merchant',
  dashboardTotalTransactionFreq:
    'dashboard/total_transaksi_frequency?type=merchant',
  dashboardTop10Product: 'dashboard/top10_product?type=merchant',
  dashboardv2: '/v2/merchant/dashboard',
  merchantQris: 'merchantpg/merchant_qris',
  otp2fa: {
    login: 'v2/login2fa', // POST
    sendOtp: 'v2/send-otp-2fa', // GET
    validate: 'v2/validate-otp-2fa', // POST
  },
  qris: {
    bizCriteria: 'merchantqris/master-biz-criteria',
    mcc: 'merchantqris/master-mcc',
    company: 'merchantqris/master-company-type',
    payCriteria: 'merchantqris/master-criteria-payout',
  },
  inquiry: {
    get: 'payout/beneficiary/get_inquiry/',
    req: 'payout/beneficiary/inquiry',
  },
};

export default endpoints;
