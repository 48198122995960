/* eslint-disable import/no-anonymous-default-export */

import {
  DashboardAction,
  IDashboardState,
  DashboardType,
} from 'stores/types/dashboardType';

const initialState = {
  data: null,
  isLoading: false,
  error: null,
  isError: false,
  totalTransaction: [],
  sumTransaction: 0,
  labelStatusTransaction: [],
  colorTransaction: [],
  labelStatusIncome: [],
  colorIncome: [],
  totalIncome: [],
  sumIncome: 0,
  transactionFreq: {
    data: [],
    dataLabel: [],
  },
  incomeFreq: {
    data: [],
    dataLabel: [],
  },
  freqTransactionByPm: {
    labels: [],
    jumlahTransaksi: [],
  },
  top10Product: {
    labels: [],
    jumlahTransaksi: [],
  },
  datav2: {
    filter_params: {},
    summary: {
      total_freq_transaction: 0,

      total_transaction_amount: 0,
    },
    charts: {
      transaction_status: {},
      total_trx_by_issuer: {},
      total_trx_by_payment_method: {},
      top_ten_merchant: {},
    },
    transactions: [],
    pagination: {},
  },
};

export default (
  state: IDashboardState = initialState,
  { type, payload }: DashboardAction
) => {
  switch (type) {
    case DashboardType.DASHBOARD_PENDING:
      return {
        ...state,
        ...payload,
        error: null,
        isLoading: true,
        isError: false,
      };
    case DashboardType.DASHBOARD_SET_DTA_V2:
      return { ...state, datav2: payload, isLoading: false, isError: false };
    case DashboardType.DASHBOARD_GET_TOTAL_TRANSACTION:
      return { ...state, ...payload, isLoading: false, isError: false };
    case DashboardType.DASHBOARD_GET_TRANSACTION_FREQ:
      return { ...state, ...payload, isLoading: false, isError: false };
    case DashboardType.DASHBOARD_GET_TOTAL_INCOME:
      return { ...state, ...payload, isLoading: false, isError: false };
    case DashboardType.DASHBOARD_GET_INCOME_FREQ:
      return { ...state, ...payload, isLoading: false, isError: false };
    case DashboardType.DASHBOARD_GET_TRANSACTION_BYPM:
      return { ...state, ...payload, isLoading: false, isError: false };
    case DashboardType.DASHBOARD_GET_TRANSACTION_TOP10:
      return { ...state, ...payload, isLoading: false, isError: false };
    case DashboardType.DASHBOARD_ERROR:
      return { ...state, ...payload, isLoading: false, isError: true };

    default:
      return state;
  }
};
