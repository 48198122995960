import { SettingContext } from 'context/settingContext';
import useSandbox from 'hooks/useSandbox';
import useSetting from 'hooks/useSetting';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import WrapEditSubmit from './components/WrapEditSubmit';

const CreatOrderURL = () => {
  let { SettingState, submitEdit, getApiSettingData } = useSetting();
  let { systemSetting } = SettingState;
  let { createOrderURL } = systemSetting;

  let { isEdit, setisEdit, setisModal } = React.useContext(SettingContext);

  const [data, setdata] = React.useState({
    callback_notif: createOrderURL.callback_notif,
    redirect_finish: createOrderURL.redirect_finish,
    deeplink_redirect: createOrderURL.deeplink_redirect,
  });

  const [defaultFormInput, setdefaultFormInput] = React.useState(data);

  let sandbox = useSandbox()?.userData['Env'];

  const handleEdit = () => {
    submitEdit('url', data, (response: any) => {
      if (response.code !== 200) {
        toast.error('Terjadi Kesalahan', { autoClose: 4000 });
      } else {
        getApiSettingData('url', (response: any) => {
          if (response.code !== 200)
            toast.error('Terjadi Kesalahan', { autoClose: 4000 });
        });
        setisModal(true);
      }
    });
  };

  const handleChange = (e: any) => {
    setdata({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setisEdit(false);
  }, [setisEdit]);

  const handleCancel = () => {
    setisEdit(false);
    setdata(defaultFormInput);
  };

  React.useEffect(() => {
    setisEdit(false);

    getApiSettingData('url', (response: any) => {
      if (response.code !== 200) {
        toast.error('Terjadi Kesalahan', { autoClose: 4000 });
      } else {
        let newData = {
          callback_notif: response.data.callback_notif,
          redirect_finish: response.data.redirect_finish,
          deeplink_redirect: response.data.deeplink_redirect,
        };
        setdata(newData);
        setdefaultFormInput(newData);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getApiSettingData, sandbox]);

  return (
    <div>
      <div className="text-[1.3rem] font-bold">General Information </div>

      <div className="flex flex-col gap-3 ">
        <div className="block lg:flex gap-5 h-[5rem] lg:h-[3rem] mb-5 ">
          <div className="w-[15rem] items-center flex font-medium text-[1.1rem] h-11">
            Callback Notification
          </div>
          <div className="w-[16rem] lg:w-[38rem] h-full flex justify-end">
            <input
              placeholder="Input Callback Notification"
              defaultValue={data.callback_notif}
              disabled={!isEdit}
              name="callback_notif"
              onChange={handleChange}
              value={data['callback_notif']}
              className={`border-[1px] w-full h-[3rem] border-[#AFAFAF] rounded-md py-2 text-start pl-3 px-51`}
            />
          </div>
          <div className={`flex items-center shrink-0 h-11 w-[7rem]`}></div>
        </div>

        <div className="block lg:flex gap-5 mb-5  h-[5rem] lg:h-[3rem] ">
          <div className="w-[15rem] items-center flex font-medium text-[1.1rem] h-11">
            Redirect Finish URL
          </div>
          <div className="w-[16rem] lg:w-[38rem] h-full flex justify-end">
            <input
              placeholder="Input callback inquiry"
              defaultValue={data.redirect_finish}
              onChange={handleChange}
              disabled={!isEdit}
              value={data['redirect_finish']}
              name="redirect_finish"
              className={`border-[1px] w-full h-[3rem] border-[#AFAFAF] rounded-md py-2 text-start pl-3 px-51`}
            />
          </div>
          <div className={`flex items-center shrink-0 h-11 w-[7rem]`}></div>
        </div>

        <div className="text-[1.3rem] font-bold">E-money URL </div>

        <div className="block lg:flex gap-5 mb-5  h-[5rem] lg:h-[3rem] ">
          <div className="w-[15rem] items-center flex font-medium text-[1.1rem] h-11">
            Deeplink Redirect URL
          </div>
          <div className="w-[16rem] lg:w-[38rem] h-full flex justify-end">
            <input
              placeholder="Input callback inquiry"
              defaultValue={data.deeplink_redirect}
              onChange={handleChange}
              disabled={!isEdit}
              value={data['deeplink_redirect']}
              name="deeplink_redirect"
              className={`border-[1px] w-full h-[3rem] border-[#AFAFAF] rounded-md py-2 text-start pl-3 px-51`}
            />
          </div>
          <div className={`flex items-center shrink-0 h-11 w-[7rem]`}></div>
        </div>
        <div className="w-[54rem]">
          <WrapEditSubmit {...{ handleCancel, isEdit, handleEdit }} w="100%" />
        </div>
      </div>
    </div>
  );
};

export default CreatOrderURL;
