import React, { useState } from 'react';
import ButtonWithIcon from '../ButtonWithIcon';
import { btnTheme } from 'components/configs/theme';
import { icons } from 'assets';
import { BasicPopover } from 'components/Popover';
import { BasicCheckbox, handleCheck } from 'components/Checkbox';
import ResetButton from '../ResetButton';

interface AdjustBtnProps {
  header: any;
  selected?: {
    val: any;
    set: (value: React.SetStateAction<string>) => void;
  };
  list: {
    val?: any;
    set?: React.Dispatch<React.SetStateAction<string[]>>;
  };
}

const Component: React.FC<AdjustBtnProps> = ({ header, list, selected }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'popover' : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleReset = () => {
    list?.set(header.map((item) => item.tableName));
  };

  return (
    <>
      <ButtonWithIcon
        btnTheme={btnTheme}
        startIcon={icons.adjust}
        variant="outlined"
        color="secondary"
        onClick={(e) => handleOpen(e)}
      >
        Adjust Table
      </ButtonWithIcon>
      <BasicPopover
        open={open}
        anchorEl={anchorEl}
        id={id}
        onClose={handleClose}
      >
        <div className="text-[#94A3B8] px-4 py-2">Adjust display data:</div>
        {header.map((head, headIdx) => (
          <button
            key={headIdx}
            onClick={() => selected?.set(head.tableName)}
            className={`
       
             px-4 text-start font-bold flex capitalize`}
          >
            <BasicCheckbox
              value={head.tableName}
              checked={list?.val.includes(head.tableName) ? true : false}
              onChange={(e) => handleCheck(e, list?.val, list?.set)}
            />
            <p className="my-auto">{head.tableName}</p>
          </button>
        ))}
        <ResetButton onClick={handleReset} />
      </BasicPopover>
    </>
  );
};

export default Component;
