import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'stores';
import {
  GenereteActions,
  GetApiSettingData,
  getDataMerchantTheme,
  SetPayment,
  GetPaymentChannel,
  SubmitEditActions,
  updateDataMerchantTheme,
  updatePaymentChannel,
} from 'stores/actions/settingAction';
import { permissionFunction } from 'utils/permission';
import permission from 'constants/permissionRole';

const useExample = () => {
  const dispatch = useDispatch();
  const SettingState = useSelector((state: RootState) => state.setting);
  const authState = useSelector((state: RootState) => state.auth.userData);
  const Env = authState['Env'] ? 'PRODUCTION' : 'SANDBOX';

  const handleGetApiSettingData = useCallback(
    (url: string, callback?: (data: any) => void) => {
      permissionFunction(permission[Env].GET_SYSTEMSETTING) &&
        dispatch(GetApiSettingData(url, callback));
    },
    [Env, dispatch]
  );

  const handleGenerate = useCallback(
    (url: string, callback?: (data: any) => void) => {
      permissionFunction(permission[Env].GET_SYSTEMSETTING) &&
        dispatch(GenereteActions(url, callback));
    },
    [Env, dispatch]
  );

  const handleEdit = useCallback(
    (url: string, data?: any, callback?: (data: any) => void) => {
      permissionFunction(permission[Env].UPDATE_SYSTEMSETTING) &&
        dispatch(SubmitEditActions(url, data, callback));
    },
    [Env, dispatch]
  );

  const handleGetPayment = useCallback(
    (callback?: (data: any) => void) => {
      permissionFunction(permission[Env].GET_QOINVIEW) &&
        dispatch(GetPaymentChannel(callback));
    },
    [Env, dispatch]
  );

  const handleGetMerchantTheme = useCallback(
    (callback?: (data: any) => void) => {
      permissionFunction(permission[Env].GET_QOINVIEW) &&
        dispatch(getDataMerchantTheme(callback));
    },
    [Env, dispatch]
  );

  const handleUpdatePayment = useCallback(
    (data?: any, callback?: (data: any) => void) => {
      permissionFunction(permission[Env].UPDATE_QOINVIEW) &&
        dispatch(updatePaymentChannel(data, callback));
    },
    [Env, dispatch]
  );

  const handleUpdateTheme = useCallback(
    (data?: any, callback?: (data: any) => void) => {
      permissionFunction(permission[Env].UPDATE_QOINVIEW) &&
        dispatch(updateDataMerchantTheme(data, callback));
    },
    [Env, dispatch]
  );

  const handleSetPayment = (data) => {
    console.log(data);

    dispatch(SetPayment(data));
  };

  return {
    Env,
    SettingState,
    getApiSettingData: handleGetApiSettingData,
    generate: handleGenerate,
    submitEdit: handleEdit,
    getPayment: handleGetPayment,
    getMerchantTheme: handleGetMerchantTheme,
    updatePayment: handleUpdatePayment,
    updateTheme: handleUpdateTheme,
    setPayment: handleSetPayment,
  };
};

export default useExample;
