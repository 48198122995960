import Doughnat from './ChartDashboard/doughnat';
import BarChart from './ChartDashboard/barChart';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'stores';
import { getLocalItem } from 'utils/localStorage';
import useAuth from 'hooks/useAuth';
import { isFullVersion } from 'utils/exception';
import { ToastContainer } from 'react-toastify';
import useDashboard from 'hooks/useDashboard';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { removeCookie } from 'utils/cookie';
import Datepicker from 'react-tailwindcss-datepicker';
import { icons } from 'assets';
import Table from './table';

const NotifInfo = ({
  userData,
  handleValueInfo,
  statusMerchant,
  isInfo,
  setisInfo,
}) => {
  return (
    <div
      className={`${
        // eslint-disable-next-line no-mixed-operators
        userData.Env === false
          ? (handleValueInfo(statusMerchant) === false || isInfo === false) &&
            'hidden'
          : 'hidden'
      } bg-[#FEEBBE] border-[#FDBE2C] border mb-5 p-2 mt-5 font-semibold rounded-md px-5 align-center flex justify-between`}
    >
      {handleValueInfo(statusMerchant)}
      <CloseIcon className="cursor-pointer" onClick={() => setisInfo(false)} />
    </div>
  );
};

const AutoCompleteInput = ({ suggestions, setparams }) => {
  const [inputValue, setInputValue] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const suggestionsRef = useRef(null);

  const handleChange = (event) => {
    const value = event.target.value;
    setInputValue(value);

    if (value) {
      const filtered = suggestions?.filter((suggestion) =>
        suggestion?.name?.toLowerCase()?.includes(value?.toLowerCase())
      );
      setFilteredSuggestions(filtered);
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setInputValue(suggestion.name);
    setShowSuggestions(false);

    setparams((prevParams) => ({
      ...prevParams,
      merchants: suggestion.code,
    }));
  };

  const handleClear = () => {
    setInputValue('');
    setFilteredSuggestions([]);
    setShowSuggestions(false);
    setparams((prevParams) => ({
      ...prevParams,
      merchants: '',
    }));
  };

  const handleClickOutside = (event) => {
    if (
      suggestionsRef.current &&
      !suggestionsRef.current.contains(event.target)
    ) {
      setShowSuggestions(false);
      // setInputValue('');
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className="relative w-[180.98px] flex items-center h-[44.02px] mr-2"
      ref={suggestionsRef}
    >
      <input
        placeholder="Nama Merchant"
        onChange={handleChange}
        value={inputValue}
        className={`border-2 w-full border-[#6C696A] h-[44.02px] lg:w-full rounded-md py-3 text-start pl-3 pr-10`}
      />
      <img
        src={icons?.Search}
        alt="Search Icon"
        className="absolute right-3 top-1/2 transform -translate-y-1/2 h-5 w-5"
      />

      {inputValue && (
        <CloseIcon
          onClick={handleClear}
          className="absolute -right-7 text-[#9A9898] cursor-pointer"
        />
      )}
      {showSuggestions && inputValue && (
        <ul className="absolute top-10 bg-white border rounded-md mt-1 w-full z-10">
          {filteredSuggestions?.length > 0 ? (
            filteredSuggestions?.map((suggestion, index) => (
              <li
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
                className="py-2 px-3 hover:bg-gray-200 cursor-pointer"
              >
                {suggestion.name}
              </li>
            ))
          ) : (
            <li className="py-2 px-3 text-gray-500">No Results</li>
          )}
        </ul>
      )}
    </div>
  );
};

const Dasboard = () => {
  const userData = useSelector((state: RootState) => state.auth.userData);
  const Profile = useSelector((state: RootState) => state.auth.profile);
  const packageType = getLocalItem('package-type');

  const [isInfo, setisInfo] = useState(true);
  const [statusMerchant, setStatusMerchant] = useState('');
  const [params, setparams] = useState({
    page: 1,
    size: 10,
    sort: 'asc',
    merchants: '',
    start_date: '',
    end_date: '',
  });

  let { reqProd } = useAuth();
  let { dashboardeState, getDatav2, Env } = useDashboard();

  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  let { datav2 } = dashboardeState;

  let { summary, charts, filter_params, transactions, pagination } = datav2;

  let { total_transaction_amount, total_freq_transaction } = summary;
  let {
    top_ten_merchant,
    total_trx_by_issuer,
    total_trx_by_payment_method,
    transaction_status,
  } = charts;

  useEffect(() => {
    removeCookie('o2t');
    if (Profile) {
      setStatusMerchant(Profile['MerchantStatus']);
    }
    // eslint-disable-next-line
  }, [Profile, packageType]);

  useEffect(() => {
    getDatav2(params);
    // eslint-disable-next-line
  }, [params, Env]);

  useEffect(() => {
    if (dateRange.endDate) {
      setparams((prevParams) => ({
        ...prevParams,
        start_date:
          dateRange.startDate &&
          moment(dateRange.startDate).format('YYYY-MM-DD'),
        end_date:
          dateRange.endDate && moment(dateRange.endDate).format('YYYY-MM-DD'),
      }));
    }
  }, [dateRange]);

  let Navigate = useNavigate();

  const handleValueInfo = (status: string) => {
    switch (status) {
      case 'registered':
        return (
          <div className="flex">
            Selamat Datang! Segera lengkapi data Anda dan nikmati kemudahan
            akses ke semua fitur lainnya ,
            <div
              className="text-[#FDBE2C] ml-2 font-bold z-50 cursor-pointer"
              onClick={() => {
                Navigate(`${isFullVersion ? '/full/' : '/'}onboarding`);
              }}
            >
              klik disini
            </div>
          </div>
        );

      case 'in-review':
        return (
          <span className="font-medium">
            Status permohonan akses sandbox Anda dalam proses peninjauan.
            Silakan periksa email Anda secara berkala.
          </span>
        );
      case 'approved for sandbox':
        return (
          <div>
            Selamat! Permintaan akses sandbox anda disetujui,{' '}
            <b
              className="text-[#FDBE2C] font-bold cursor-pointer"
              onClick={handleGetStatusProd}
            >
              klik disini
            </b>{' '}
            untuk melengkapi permintaan akses production.
          </div>
        );
      case 'request for production':
        return (
          <span className="font-medium">
            Status permohonan akses production anda dalam proses peninjauan.
            Silakan periksa email Anda secara berkala.
          </span>
        );

      default:
        return false;
    }
  };

  const handleGetStatusProd = () => {
    setStatusMerchant('request for production');
    reqProd((response) => {
      console.log(response);
    });
  };

  const suggestions = filter_params?.merchants?.options;

  return (
    <>
      <div className="text-xl mt-14 md:mt-0 sm:text-3xl font-bold py-4 cursor-pointer">
        Dashboard
      </div>

      <NotifInfo
        {...{ userData, handleValueInfo, statusMerchant, isInfo, setisInfo }}
      />

      <div className="md:flex gap-3 mb-4">
        <div>
          <Datepicker
            i18n={'id'}
            configs={{
              shortcuts: {
                today: 'Hari Ini',
                yesterday: 'Kemarin',
                past: (period) => period + ' Hari Terakhir',
                currentMonth: 'Bulan Ini',
                pastMonth: 'Bulan Lalu',
              },
              footer: {
                cancel: 'Batal',
                apply: 'Simpan',
              },
            }}
            primaryColor={'green'}
            placeholder="Select Date Range"
            inputClassName="-pl-10 p-5 pr-10 rounded-[4px] border-2 h-[36.02px]  border-[#6C696A]"
            value={dateRange}
            showShortcuts={true}
            onChange={(newValue) => setDateRange(newValue)}
          />
        </div>
        <AutoCompleteInput {...{ setparams, suggestions }} />
      </div>

      <div className="flex flex-col md:grid md:grid-cols-2 gap-[16.2px] mb-10">
        <div className="bg-[#FFFFFF] shadow-sm w-full rounded-md ">
          <div className="flex gap-[24.03px] items-center justify-start pl-10 h-[119.35px]">
            <img
              alt=""
              className="w-[35.24px] h-[35.24px]"
              src={`${process.env.PUBLIC_URL}/assets/icons/dahboard/totalTransaction.svg`}
            />
            <div>
              <div className="text-[#231F20] text-[35.24px] font-bold">
                {total_freq_transaction}
              </div>
              <div className="text-[#77858D] text-[16.46px] font-normal">
                Total Transaction
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[#FFFFFF] shadow-sm w-full rounded-md ">
          <div className="flex gap-[24.03px] items-center justify-start pl-10 h-[119.35px]">
            <img
              alt=""
              className="w-[35.24px] h-[35.24px]"
              src={`${process.env.PUBLIC_URL}/assets/icons/dahboard/totalAmount.svg`}
            />
            <div>
              <div className="text-[#231F20] text-[35.24px] font-bold">
                {new Intl.NumberFormat('id-ID', {
                  style: 'currency',
                  currency: 'IDR',
                }).format(total_transaction_amount)}
              </div>
              <div className="text-[#77858D] text-[16.46px] font-normal">
                Total Amount
              </div>
            </div>
          </div>
        </div>

        <Doughnat text="Total Transaction Status" data={transaction_status} />
        {/* 
        <BarChart
          text={'Total Transactioin By Issuer'}
          dataChart={total_trx_by_issuer}
        /> */}
        <BarChart
          text={'Total Transaction By Payment Method'}
          dataChart={total_trx_by_payment_method}
        />
        <BarChart text={'Top 10 Merchant'} dataChart={top_ten_merchant} />

        <div className="col-span-2 bg-[#FFFFFF] shadow-sm w-full rounded-md py-[16.02px] px-[24.03px]">
          <div className="font-bold text-[15px]  mb-[20.02px]  text-black">
            Merchant Transaction Summary
          </div>
          <Table
            data={transactions}
            paginationData={pagination}
            params={params}
            setparams={setparams}
          />
        </div>
      </div>

      <ToastContainer position="top-right" />
    </>
  );
};

export default Dasboard;
