import { colors } from '@mui/material';

const colorConfigs = {
  sidebar: {
    bg: '#131313',
    color: '#18ad65',
    hoverBg: '#17251E',
    activeBg: '#17251E',
    activecl: '#17251E',
  },
  topbar: {
    bg: '#fff',
    color: '#000',
  },
  mainBg: colors.grey['100'],
};
export const colorConfigSandbox = {
  sidebar: {
    bg: '#131313',
    color: '#006A45',
    hoverBg: '#F5F5F5',
    activeBg: '#E0EEE7',
    activecl: '#17251E',
  },
  topbar: {
    bg: '#fff',
    color: '#000',
  },
  mainBg: colors.grey['100'],
};

export default colorConfigs;
