import React, { useEffect, useState } from 'react';
import { Box, FormControl, Grid, RadioGroup } from '@mui/material';
import { BasicButton } from 'components/Button';
import { CustomCheckbox } from 'components/Input';
import { BasicDialog } from 'components/dialog';
import { AddOption, SingleOption, addOption } from 'components/select';
import { useDispatch, useSelector } from 'react-redux';
import { getOnboarding } from 'stores/actions/onboardingAction';
import { RootState } from 'stores';
import {
  GetDonation,
  cancelDeactivate,
  getDeactivateData,
  submitDeactivate,
} from 'stores/actions/ticketingAction';
import { Option } from 'components/select/interface';
import { RadioWithLabel } from 'components/radio/Radio/Component';

interface Props {}
type variety = 1 | 2;
const Component: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { data: mercPreview } = useSelector(
    (state: RootState) => state.onboarding.merchantPreview
  );
  const { userData, profile } = useSelector((state: RootState) => state.auth);
  const { balance } = useSelector((state: RootState) => state.balance);
  const { donation, deactivated } = useSelector((s: RootState) => s.ticketing);
  const [reason, setReason] = useState<string[]>([]);
  const [option, setOption] = useState<string>('');
  const [checked, setChecked] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [popup, setPopup] = useState<boolean>(false);
  const [popupOk, setPopupOk] = useState<boolean>(false);
  const [donate, setDonate] = useState<string>('');
  const [status, setStatus] = useState<variety | 0>(0);
  const [donateOpt, setDonateOpt] = useState<Option[]>([]);
  const [detailDonation, setDetailDonation] = useState('');
  const { MerchantId, Id: UserId, Fullname, Username, Email, Phone } = userData;
  const noBalance = balance === null || balance === undefined || balance === 0;

  useEffect(() => {
    if (option === 'Transfer') dispatch(getOnboarding());
    else if (option === 'Didonasikan') dispatch(GetDonation());
    // eslint-disable-next-line
  }, [dispatch, option]);

  useEffect(() => {
    if (MerchantId !== undefined)
      dispatch(getDeactivateData(MerchantId?.toString()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (donation) setDonateOpt(donation?.map((val) => addOption(val.md_name)));
    if (deactivated?.merchant_deactive_request) {
      const now = new Date().getTime();
      const req = new Date(deactivated?.merchant_date_request).getTime();
      const diffDay = (now - req) / (1000 * 3600 * 24);

      if (parseInt(diffDay.toFixed()) > deactivated?.merchant_closed_day) {
        setStatus(2);
      } else setStatus(1);
    } else setStatus(0);
  }, [donation, deactivated]);

  const reasonOpt = [
    addOption('Tidak ingin menggunakan QoinHub lagi'),
    addOption('Terlalu banyak issue teknis di QoinHub'),
    addOption('Pindah menggunakan PJP yang lain'),
  ];
  const transferOpt = [
    addOption('Transfer', 'Ditransfer ke Rekening sendiri'),
    addOption('Didonasikan', 'Didonasikan ke Yayasan'),
  ];
  const textInfo =
    'Untuk info lebih lanjut, silakan hubungi tim kami melalui info@qoinhub.id.';
  const isBroke = (tfData: string, dnData: string) => {
    if (noBalance) return '';
    else if (option === 'Transfer') return tfData;
    else return dnData;
  };

  const xSubmit = () => {
    setPopup(true);
    setLoading(true);
  };
  const xChoice = () => {
    setPopup(false);
    if (status === 0) {
      const donateTo = donation?.map((a) =>
        a?.md_name === donate ? a.md_detail : []
      );
      const donateAcc = donateTo.map((x) => {
        let acc = x.filter((y) => y?.mdd_account_name === detailDonation && y);
        return acc[0];
      });

      const isTF = option === 'Transfer';
      const submitData = {
        merchant_id: MerchantId?.toString(),
        mdr_reason: reason.join(', '),
        mdr_rtbalance: noBalance ? '0' : balance?.toString(),
        mdr_request_by: UserId, //user id
        mdr_update_by: UserId, //user id
        donation_account_id: noBalance ? 0 : isTF ? 0 : donateAcc[0]?.mdd_id, // jika 0 arti nya dia transfer, else id donasi
        request: option,
        account_no: isBroke(
          mercPreview?.bank_acc_no,
          donateAcc[0]?.mdd_account_no
        ),
        account_name: isBroke(
          mercPreview?.bank_acc_name,
          donateAcc[0]?.mdd_account_name
        ),
        bank: isBroke(mercPreview?.bank, donateAcc[0]?.mdd_bank_name),
        username: Username,
        fullname: Fullname,
        email: Email,
        phone: Phone,
        request_by_role: profile?.MerchantStatus,
      };
      dispatch(submitDeactivate(submitData, setPopupOk));
      setLoading(!popupOk);
    } else if (status === 1) {
      dispatch(cancelDeactivate(MerchantId?.toString(), setPopupOk));
    }
  };

  const xAccept = () => {
    setPopupOk(false);
    window.location.reload();
  };

  type bank_content = { title: string; content: string };
  const banks: bank_content[] = [
    { title: 'Bank', content: mercPreview?.bank },
    { title: 'Nomor Rekening', content: mercPreview?.bank_acc_name },
    { title: 'Nama Pemiliik Rekening', content: mercPreview?.bank_acc_no },
  ];

  function saldoManagement(option: 'Transfer' | 'Didonasikan' | string) {
    switch (option) {
      case 'Transfer':
        return (
          <div className="mt-4">
            <p className="font-semibold text-lg">Informasi Bank</p>
            {banks.map((x, i) => (
              <Grid container key={x.title} mt={1} gap={2}>
                <Grid item xs={4} lg={3}>
                  {x.title}
                </Grid>
                <Grid item xs={8} lg={7} fontWeight={700}>
                  {x.content}
                </Grid>
              </Grid>
            ))}
          </div>
        );
      case 'Didonasikan':
        return (
          <Grid container my={1} gap={2} mt={2}>
            <p className="font-semibold text-lg">Informasi Donasi</p>
            <Grid item xs={12}>
              <p>Pilih penyalur donasi</p>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <SingleOption
                fullWidth
                options={donateOpt}
                selection={{ val: donate, set: setDonate }}
              />
            </Grid>
            {donate !== '' && (
              <FormControl className="w-full">
                <RadioGroup onChange={(e) => setDetailDonation(e.target.value)}>
                  <Grid container px={1}>
                    {donation?.map(
                      (item) =>
                        item.md_name === donate &&
                        item.md_detail.map((detail, idx) => (
                          <Grid
                            key={idx}
                            id={detail?.mdd_account_name}
                            item
                            md={6}
                            lg={4}
                          >
                            <RadioWithLabel
                              value={detail?.mdd_account_name}
                              checked={
                                detailDonation === detail?.mdd_account_name
                              }
                              label={
                                <>
                                  <b>{detail.mdd_bank_name}</b>
                                  {` - ${detail.mdd_account_name} - ${detail.mdd_account_no}`}
                                </>
                              }
                            />
                          </Grid>
                        ))
                    )}
                  </Grid>
                </RadioGroup>
              </FormControl>
            )}
          </Grid>
        );
      default:
        return;
    }
  }

  function preview(code: 0 | 1 | 2) {
    switch (code) {
      case 0:
        return (
          <>
            <Grid container my={1} gap={2}>
              <Grid item xs={12}>
                Alasan
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <AddOption
                  multiple
                  options={reasonOpt}
                  selection={{ val: reason, set: setReason }}
                  // disabled // ticketing ga naik
                />
              </Grid>
            </Grid>
            <Grid container my={1} gap={2}>
              <Grid item xs={12}>
                <p>Pilihan transfer sisa dana yang sudah bisa ditarik</p>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <SingleOption
                  fullWidth
                  options={transferOpt}
                  selection={{ val: option, set: setOption }}
                  disabled={noBalance}
                  // disabled // ticketing ga naik
                />
              </Grid>
            </Grid>

            {saldoManagement(option)}

            <Box display={'flex'} mt={2}>
              <CustomCheckbox
                id="agreed-check"
                value={checked}
                onChange={() => setChecked((prev) => !prev)}
                // disabled
              />
              <div className="flex flex-col gap-5">
                <p>
                  Dengan melanjutkan, Anda memahami dan menyetujui untuk
                  melakukan penutupan akun merchant, akun merchant Anda akan
                  ditutup permanen bila dalam masa tenggang penutupan akun 90
                  hari tidak ada pembatalan penutupan akun sesuai dengan Syarat
                  dan Ketentuan QoinHub.
                </p>
                <p>{textInfo}</p>
              </div>
            </Box>
            <div className="w-full flex mt-4 pb-2">
              <BasicButton
                variant="contained"
                color="error"
                sx={{ m: 'auto' }}
                onClick={xSubmit}
                disabled={
                  loading || option === 'Didonasikan'
                    ? detailDonation.length === 0
                    : false || noBalance
                    ? !checked || reason.length === 0
                    : !checked || reason.length === 0 || option === ''
                }
              >
                Penutupan Akun merchant
              </BasicButton>
            </div>
          </>
        );
      case 1:
        return (
          <div className="gap-4 flex flex-col mt-4">
            <p>
              Saat ini status permohonan penutupan akun merchant Anda sedang
              diproses oleh admin kami, akun merchant Anda akan benar-benar
              ditutup jika dalam kurun waktu{' '}
              <b>{deactivated?.merchant_closed_day}</b> hari Anda tidak
              melakukan aktivasi ulang. Anda masih bisa membatalkan proses
              penutupan akun dalam kurun waktu tersebut, Lihat{' '}
              <b className="text-[#21AF7D] cursor-pointer">
                syarat dan ketentuan
              </b>{' '}
              QoinHub
            </p>
            <p>{textInfo}</p>
            <div className="w-full flex mt-2">
              <BasicButton
                variant="contained"
                color="success"
                sx={{ m: 'auto' }}
                onClick={xSubmit}
              >
                Batalkan Penutupan Akun
              </BasicButton>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="gap-4 flex flex-col mt-4">
            <p>
              Saat ini stasus permohonan penutupan akun merchant Anda sudah
              diproses oleh Admin, akun merchant Anda ditutup.
            </p>
            <p>{textInfo}</p>
          </div>
        );
      default:
        break;
    }
  }

  const choiceText: Record<variety, string> = {
    '1': 'Penutupan Akun Merchant',
    '2': 'Batalkan Penutupan Akun Merchant',
  };
  const choiceSub: Record<variety, string> = {
    '1': 'Anda yakin ingin Menonaktifkan merchant?',
    '2': 'Proses pembatalan sedang diproses, Anda yakin ingin mengaktifkan kembali Merchant Anda?',
  };
  const okSub: Record<variety, string> = {
    '1': 'Anda berhasil melakukan penutupan akun merchant, anda bisa membatalkan penutupan akun merchnat sebelum diproses oleh admin',
    '2': 'Anda berhasil melakukan pembatalan penutupan akun merchant. Sekarang Anda dapat mengakses kembali fitur yang ada di QoinHub.',
  };
  const closedWord: Record<variety | 0, string> = {
    '0': 'Anda Yakin Ingin Melakukan Penutupan Akun Merchant?',
    '1': 'Akun segera diproses oleh admin untuk dinonaktifkan',
    '2': 'Akun dalam proses penutupan oleh admin',
  };

  return (
    <>
      <div className="font-bold text-2xl">Pengaturan</div>
      <div className="bg-white p-8 my-4">
        <div className="font-bold text-lg flex flex-col gap-4">
          <p>Penutupan Akun Merchant</p>
          <hr />
          <p>{closedWord[status]}</p>
        </div>
        {preview(status)}
      </div>

      {popup && (
        <BasicDialog
          type="double"
          icon="warning"
          open={popup}
          text={choiceText[status + 1]}
          subtext={choiceSub[status + 1]}
          leftbtn={{
            text: 'Tidak, Batalkan',
            color: 'success',
            onClick: () => setPopup(false),
          }}
          rightbtn={{ text: 'Ya, Lanjutkan', color: 'error', onClick: xChoice }}
          onClose={() => setPopup(false)}
          PaperProps={{ sx: { maxWidth: 450 } }}
        />
      )}

      {popupOk && (
        <BasicDialog
          type="single"
          icon="success"
          open={popupOk}
          text="Sukses"
          subtext={okSub[status + 1]}
          btn={{ text: 'OK', color: 'success', onClick: xAccept }}
          PaperProps={{ sx: { maxWidth: 450 } }}
        />
      )}
    </>
  );
};
export default Component;
