// name: name variable, tableName: nameTableUi/nama yang tampil di user, icon: filterIcon
const addTableName = (
  name: string,
  tableName: string,
  filter: boolean,
  typeValue?: string,
  width?: string,
  formatText?: string,
  filterName?: string
) => ({
  name,
  tableName,
  filter,
  typeValue,
  width,
  formatText,
  filterName,
});

// 0: name variable, 1: nameTableUi/nama yang tampil di user, 2: filterIcon
export const tableNameList = [
  addTableName(
    'created',
    'Transaction Date',
    true,
    '',
    '180px',
    '',
    'start_date'
  ),
  addTableName(
    'merchant_name',
    'Merchant Name',
    true,
    '',
    '180px',
    '',
    'merchant_name'
  ),
  addTableName('no', 'Transaction No', true, '', '180px', '', 'transaction_no'),
  addTableName(
    'ref_no',
    'Reference No.',
    true,
    '',
    '180px',
    '',
    'reference_no'
  ),
  addTableName('rrn', 'RRN', false, '', '', '', 'rrn'),
  addTableName('issuer_name', 'Issuer', false, '', '', '', 'issuer'),
  addTableName(
    'amount',
    'Trx Amount',
    true,
    'RupiahFormat',
    '140px',
    '',
    'trx_amount'
  ),
  addTableName('mdr_fee', 'MDR', true, 'RupiahFormat', '', '', 'mdr'),
  addTableName(
    'status_text',
    'Status',
    true,
    'status_text',
    '110px',
    '',
    'status'
  ),
  addTableName('no', 'Action', false, 'ActionDetail', '50px'),
];

export const tableNameListItems = [
  addTableName('No', 'No', false, ''),
  addTableName('name', 'Item', false, ''),
  addTableName('des', 'Description', false, ''),
  addTableName('quantity', 'Quantity', false, ''),
  addTableName('price', 'Amount', false, ''),
];
