/*eslint-disable no-useless-escape*/
// "^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+$"

// prettier-ignore
export const urlRegex = /^(?:http(s)?:\/\/)?(?:www\.)?[\w.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?(\/[\w\-._~:/?#[\]@!$&'()*+,;=]*)?$/;
export const customChar = /^[^~%|`*#\$^]*$/;
// new RegExp('[~%|`*`#$^]');
//  /^(?:http(s)?:\/\/)?[\w.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?(\/[\w\-._~:/?#[\]@!$&'()*+,;=]*)?$/;

// /^(?!(?:https?:\/\/))(?:(?:\w+:)?\/\/)?[\w-]+(?:\.[\w-]+)*(?:\.\w{2,})?(:\d+)?(?:\/[^\s]*)?$/;
// /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#()?&//=]*)/;
export const emailRegex = new RegExp(
  '^[a-zA-Z0-9._-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$'
);
export const phoneRegex = /^(08|02)[0-9]{7,}$/;
export const passwordRegex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/;
export const emailRegex2 = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; //regex email
