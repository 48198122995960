import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'stores';
import { GridDetailQRIS } from 'pages/Onboarding/QRIS/template';
import { bdColor as bd, bgColor as bg, txColor as tx } from 'constants/colors';
import JenisQR from 'pages/Onboarding/QRIS/section/jenisQR';
import InfoUsaha from 'pages/Onboarding/QRIS/section/infoUsaha';
import InfoMerchant from 'pages/Onboarding/QRIS/section/infoMerchant';
import AlamatMerchant from 'pages/Onboarding/QRIS/section/alamatMerchant';
import DaftarTerminal from 'pages/Onboarding/QRIS/section/daftarTerminal';
import KelengkapanDocs from 'pages/Onboarding/QRIS/section/kelengkapanDokumen';
import useFormQRIS from 'hooks/useFormQRIS';
import { Collapse, IconButton } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { BasicButton } from 'components/Button';
import EditPen from 'assets/icons/editPen';
import { QRoptions } from 'pages/Onboarding/QRIS';
import { setCookie } from 'utils/cookie';
import { MerchantPreviewData, qr_status } from 'stores/types/onboardingTypes';
import { cn } from 'utils/twMerge';

type template_qr = {
  title: string;
  children: React.ReactNode;
};
type collapse_detail = template_qr & {
  collapse: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

// Recorded status colors
type status_color = { bg: string; bd: string; tx: string };
const stColor: Record<qr_status, status_color> = {
  '0': { bg: bg.warning, bd: '#EECEB0', tx: '#734011' },
  '1': { bg: '#F0F3FF', bd: '#B1C5F6', tx: '#3267E3' },
  '2': { bg: bg.primary, bd: bd.success, tx: tx.primary },
};
const statusColors = (v: qr_status) => {
  const { bd, bg, tx } = stColor[v];
  return `bg-[${bg}] border-[${bd}] text-[${tx}]`;
};
const statusText: Record<qr_status, string> = {
  '0': 'Registered',
  '1': 'Submited',
  '2': 'Active',
};

const Template: React.FC<template_qr> = (props) => {
  return (
    <div className="bg-white p-8">
      <b className="text-[22px] mb-3">{props.title}</b>
      <hr className="my-3" />
      {props.children}
    </div>
  );
};
const CollapseTemp: React.FC<collapse_detail> = (props) => {
  return (
    <div className="my-2">
      <div className="flex w-full justify-between">
        <b className={`text-[22px]`}>{props?.title}</b>
        <IconButton onClick={props?.onClick}>
          {props?.collapse ? <ExpandMore /> : <ExpandLess />}
        </IconButton>
      </div>
      <Collapse in={props?.collapse}>{props?.children}</Collapse>
    </div>
  );
};

type props = {
  data?: MerchantPreviewData;
  isLoading?: boolean;
  error?: any;
};
const QRSetting: React.FC<props> = (MP) => {
  const { QRIS, detailQRIS } = useSelector((o: RootState) => o.onboarding);

  const [statusQR, setStatusQR] = useState<qr_status>(0);
  const [qrType, setQRType] = useState<string[]>([]);
  const [qrCategory, setQrCategory] = useState('');
  const [qrTips, setQrTips] = useState('');
  const [qrNominal, setQrNominal] = useState('');
  const [isEdit, setIsEdit] = useState(false);

  const [usaha, setUsaha] = useState(String(MP.data?.business_type));
  const [badan, setBadan] = useState('');
  const [category, setCategory] = useState('');
  const [criteria, setCriteria] = useState('');
  const [identity, setIdentity] = useState('');
  const [ktpFile, setKtpFile] = useState(QRIS?.FileKTP || null);
  const [ktpPhoto, setKtpPhoto] = useState(QRIS?.PhotoKTP || MP.data?.ktp_url);
  const [skuFile, setSKUFile] = useState<File | null>(QRIS?.FileSKU || null);
  const [skuPhoto, setSKUPhoto] = useState(QRIS?.PhotoSKU || '');
  const [akteFile, setAkteFile] = useState<File | null>(QRIS?.FileAkta || null);
  const [aktePhoto, setAktePhoto] = useState(QRIS?.PhotoAkta || '');
  const [skaFile, setSKAFile] = useState<File | null>(QRIS?.FileSKA || null);
  const [skaPhoto, setSKAPhoto] = useState(QRIS?.PhotoSKA || '');

  const { formik, terminals } = useFormQRIS();
  const [isCollapse, setIsCollapse] = useState<boolean[]>(
    new Array(5).fill(true)
  );

  React.useEffect(() => {
    if (MP.data?.business_type === 0) {
      setUsaha('1');
    } else setUsaha(String(MP.data?.business_type));
    // dispatch(getDetailQRIS());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [MP.data]);

  React.useEffect(() => {
    if (detailQRIS !== null) {
      const dq = detailQRIS;
      setStatusQR(dq?.mq_status);
      if (dq.mq_type_qr) {
        // check if its dynamic | static | both
        const text = ['Static', 'Dynamic'];
        let val = dq.mq_type_qr;
        if (val === 1) setQRType([text[0]]);
        if (val === 2) setQRType([text[1]]);
        if (val === 3) setQRType(text);
      }
      if (dq.mq_merchant_qris_type) {
        // to manipulate the category, since the value from API is number, while here is string
        let cat = QRoptions.categoryQR.find((x) =>
          x?.name?.includes(String(dq?.mq_merchant_qris_type))
        )?.name;
        setQrCategory(cat);
      }
      setCategory(String(dq.mq_category));
      setCriteria(dq.mq_criteria);
      setIdentity(String(dq.mq_identity_type));
      terminals.set([
        {
          email: dq.mqt_email,
          name: dq.mqt_name,
          telp: dq.mqt_phone,
          term_id: dq.mqt_terminal_id,
        },
      ]);
      if (dq.mq_type_tips !== 1) {
        // to get the tips chosen % or static
        const tipsIs = dq.mq_type_tips;
        if (tipsIs === 3) setQrNominal(String(dq.mq_tip_static));
        if (tipsIs === 4) setQrNominal(String(dq.mq_tip_procentage));
        setCookie('tips-qr', String(tipsIs), 0.2);
        setQrTips(String(tipsIs));
      }
      if (dq.mq_ktp_url) {
        setKtpPhoto(dq.mq_ktp_url);
        setAktePhoto(dq.mq_akta_url);
        setSKUPhoto(dq.mq_sku_url);
        if (dq.mq_kuasa_url !== '') setSKAPhoto(dq.mq_kuasa_url);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailQRIS]);

  const toggleCollapse = (idx: number) => {
    setIsCollapse((prev) => {
      const next = [...prev];
      next[idx] = !next[idx];
      return next;
    });
  };

  type content_list = { title: string; children: React.ReactNode };
  const contents: content_list[] = [
    {
      title: 'Tentang Usaha',
      children: (
        <InfoUsaha
          usaha={{ val: usaha, set: setUsaha }}
          badan={{ val: badan, set: setBadan }}
          disabled={!isEdit}
        />
      ),
    },
    {
      title: 'Informasi Merchant',
      children: (
        <InfoMerchant
          category={{ val: category, set: setCategory }}
          criteria={{ val: criteria, set: setCriteria }}
          identity={{ val: identity, set: setIdentity }}
          formik={formik}
          disabled={!isEdit}
        />
      ),
    },
    {
      title: 'Alamat Merchant',
      children: <AlamatMerchant formik={formik} disabled={!isEdit} />,
    },
    {
      title: 'Daftar Terminal',
      children: <DaftarTerminal terminals={terminals} disabled={!isEdit} />,
    },
    {
      title: 'Kelengkapan Dokumen',
      children: (
        <KelengkapanDocs
          ktpFile={{ val: ktpFile, set: setKtpFile }}
          ktpPhoto={{ val: ktpPhoto, set: setKtpPhoto }}
          skuFile={{ val: skuFile, set: setSKUFile }}
          skuPhoto={{ val: skuPhoto, set: setSKUPhoto }}
          akteFile={{ val: akteFile, set: setAkteFile }}
          aktePhoto={{ val: aktePhoto, set: setAktePhoto }}
          skaFile={{ val: skaFile, set: setSKAFile }}
          skaPhoto={{ val: skaPhoto, set: setSKAPhoto }}
          disabled={!isEdit}
        />
      ),
    },
  ];
  return (
    <>
      <div className="flex justify-between space-y-2 bg-white p-5">
        <div className="font-bold text-xl my-auto">
          {isEdit && 'Edit'} QRIS Setting
        </div>
        {!isEdit ? (
          <BasicButton
            startIcon={<EditPen color="#FFF" />}
            onClick={() => setIsEdit(!isEdit)}
            sx={{ width: '120px' }}
          >
            Edit
          </BasicButton>
        ) : (
          <div className="flex gap-2">
            <BasicButton
              variant="outlined"
              color="error"
              sx={{ width: '120px' }}
              onClick={() => setIsEdit(!isEdit)}
            >
              Cancel
            </BasicButton>
            <BasicButton
              sx={{ width: '120px' }}
              onClick={() => console.log('belum dihandle')}
            >
              Save
            </BasicButton>
          </div>
        )}
      </div>
      <Template title={'Informasi QR'}>
        <GridDetailQRIS title="Status QRIS">
          <div
            className={cn(
              `w-[100px] font-semibold text-center border rounded-md bg-[${bg.warning}] border-[#EECEB0] text-[#734011]`,
              statusColors(statusQR)
            )}
          >
            {statusText[statusQR]}
          </div>
        </GridDetailQRIS>
        <JenisQR
          types={{ val: qrType, set: setQRType }}
          disabled={!isEdit}
          category={{ val: qrCategory, set: setQrCategory }}
          tips={{ val: qrTips, set: setQrTips }}
          nominals={{ val: qrNominal, set: setQrNominal }}
          preview={detailQRIS?.mqt_qr_static !== ''}
          preview_url={detailQRIS?.mqt_qr_static}
        />
      </Template>
      <br />
      <Template title="Informasi Usaha">
        {contents.map((c, cIdx) => (
          <CollapseTemp
            collapse={isCollapse[cIdx]}
            title={c.title}
            key={c.title}
            onClick={() => toggleCollapse(cIdx)}
          >
            {c.children}
          </CollapseTemp>
        ))}
      </Template>
    </>
  );
};

export default QRSetting;
