/* eslint-disable import/no-anonymous-default-export */
import update from 'immutability-helper';
import { UtilsAction, IUtilsState, UtilsType } from 'stores/types/utilsTypes';

const initialState = {
  isCheckbox: false,
  isSubmenu: false,
  id: 0,
  content: 'user',
};

export default (
  state: IUtilsState = initialState,
  { type, payload }: UtilsAction
) => {
  switch (type) {
    case UtilsType.SET_CHECKBOX_POLICY:
      return { ...state, isCheckbox: true };
    case UtilsType.SET_ISSUBMENU:
      return { ...state, ...payload, isSubmenu: !state.isSubmenu };
    case UtilsType.CHANGE_DRAWER_CONTENT:
      return update(state, {
        content: { $set: payload },
      });

    default:
      return state;
  }
};
