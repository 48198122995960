import { Outlet, useLocation } from 'react-router-dom';

import Sidebar from '../common/Sidebar';
import Topbar from '../common/Topbar';
import { useEffect, useState } from 'react';
import { AppBar, Drawer } from 'components/muiTheme/muiStyled';
import CssBaseline from '@mui/material/CssBaseline';
import { changeEnv, userLogout } from 'stores/actions/authActions';
import useSandbox from 'hooks/useSandbox';
import { useDispatch } from 'react-redux';
import { isFullVersion } from 'utils/exception';
import { BasicDialog } from 'components/dialog';
import { setDrawerContent } from 'stores/actions/utilsAction';
import RightDrawer from 'components/drawer';
import { ExpiredWarning } from 'components/warning';
import { getExpiredDay } from 'components/warning/ExpiredWarning';
import { colorConfigs } from 'components/configs';

const MainLayout = () => {
  const [isOpen, setisOpen] = useState(true);
  const [isProfile, setisProfile] = useState(false);
  const [mouseOver, setmouseOver] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const dispatch = useDispatch();

  const [openPopup, setOpenPopup] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [daysLeft, setDaysLeft] = useState(1);

  function isSandbox() {
    const param = new URLSearchParams(location.search);
    return param.get('sandbox') === 'false';
  }

  useEffect(() => {
    if (params.get('logout') === 'true') {
      userLogout();
      window.location.replace(isFullVersion ? '/full' : '/');
    } else if (params.get('logout') === 'false') {
      dispatch(changeEnv(isSandbox()));
      window.location.replace(isFullVersion ? '/full' : '/');
    }
    // eslint-disable-next-line
  }, [isSandbox]);

  const handleDrawerOpen = () => {
    setisOpen(!isOpen);
  };

  let { userData } = useSandbox();

  const warning = (days: number) => {
    setShowWarning(true);
    setDaysLeft(Math.floor(days));
    if (days < 1) {
      setOpenPopup(true);
    }
  };

  const { diffDays, expiredLimit } = getExpiredDay(userData, warning);

  useEffect(() => {
    if (showWarning === false) {
      expiredLimit();
    }
    const expired = setInterval(() => {
      expiredLimit();
    }, 60 * 1000);
    return () => {
      clearInterval(expired);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diffDays]);

  const handleDrawer = () => {
    dispatch(setDrawerContent('password'));
    setOpenDrawer(true);
  };

  return (
    <>
      <RightDrawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      />
      <BasicDialog
        type='double'
        open={openPopup}
        icon='warning'
        text='Change Your Expired Password!'
        subtext='Your password has expired. You will not access system until your password is changed.'
        leftbtn={{
          text: 'Cancel',
          color: 'error',
          variant: 'outlined',
          onClick: () => setOpenPopup(false),
        }}
        rightbtn={{
          text: 'Change Now',
          color: 'success',
          onClick: handleDrawer,
        }}
        PaperProps={{ sx: { maxWidth: 450 } }}
      />

      {/* dekstop */}
      <div className=''>
        <div
          className={`flex  mx-2 items-center h-[100%]  ${
            !userData['Env'] ? 'mt-[110px]' : 'mt-[60px]'
          }`}
          style={{
            backgroundColor: colorConfigs.mainBg,
          }}
        >
          <CssBaseline />

          {/* navbar */}
          <AppBar
            position='fixed'
            open={isOpen}
          >
            <Topbar
              {...{
                isOpen,
                isProfile,
                setisOpen,
                setisProfile,
                handleDrawerOpen,
              }}
            />
            <div className='w-full -mb-1'>
              {!userData['Env'] && (
                <div className='bg-[#F6A820]  w-full m-0 px-5 py-3 text-center'>
                  You are in Test Mode and any transactions made are simulated
                  (not real).
                </div>
              )}
            </div>
          </AppBar>

          {/* sidebar */}
          <Drawer
            variant='permanent'
            open={isOpen}
          >
            <Sidebar
              {...{
                isOpen,
                isProfile,
                setisOpen,
                setisProfile,
                mouseOver,
                setmouseOver,
              }}
            />
          </Drawer>

          {/* content  */}
          <div
            className={`${
              isOpen ? 'max-w-[70%] mx-auto xl:px-0' : 'px-14'
            } w-full min-h-[100%] h-[100%] md:px-5  mt-[2pc] gap-10 `}
          >
            <div>
              {showWarning && (
                // warning related expired date
                <div className='py-4 w-full'>
                  <ExpiredWarning
                    diffDays={diffDays}
                    onClick={handleDrawer}
                    dayLeft={{ val: daysLeft, set: setDaysLeft }}
                    show={{ val: showWarning, set: setShowWarning }}
                  />
                </div>
              )}
            </div>

            <Outlet />

            <br />
          </div>
        </div>
      </div>
    </>
  );
};

export default MainLayout;
