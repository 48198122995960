import { AxiosError } from 'axios';

export enum ITicketingType {
  TICKETING_CHANGE_SUCCESS = 'TICKETING_CHANGE_SUCCESS',
  TICKETING_FAILED = 'TICKETING_FAILED',
  TICKETING_PENDING = 'TICKETING_PENDING',

  GET_DONATION_SUCCESS = 'GET_DONATION_SUCCESS',
  SUBMIT_DEACTIVATE_SUCCESS = 'SUBMIT_DEACTIVATE_SUCCESS',
  GET_STATUS_DEACTIVATE = 'GET_STATUS_DEACTIVATE',

  GET_DATA_FAILED = 'GET_DATA_FAILED',
  GET_DATA_PENDING = 'GET_DATA_PENDING',
}
export interface IChangedData {
  name?: string;
  business_type?: number;
  package_type?: number;
  bis_type_code?: string;
  bis_category_code?: string;
  bis_url?: string;
  finance_email?: string;
  finance_phone?: string;
  tech_phone?: string;
  tech_email?: string;
  own_name?: string;
  own_address?: string;
  own_zip?: string;
  own_phone?: string;
  own_citizen?: string;
  ktp_url?: string;
  ktp_name?: string;
  ktp_no?: string;
  npwp_name?: string;
  npwp_number?: string;
  npwp_address?: string;
  npwp_url?: string;
  state?: number;
  info_status?: string;
  bank?: string;
  bank_acc_name?: string;
  bank_acc_no?: string;
  payments?: number[];
}

export interface ChangeMercProfileData {
  merchant_email: string;
  request_by_name: string;
  request_by_role: string;

  before: IChangedData;
  after: IChangedData;
}

interface DetailDonationData {
  mdd_account_name: string;
  mdd_account_no: string;
  mdd_bank_name: string;
  mdd_id: number;
}
export interface ListDonationData {
  md_detail: DetailDonationData[];
  md_name: string;
}
interface DeactivatedData {
  merchant_closed_day?: number;
  merchant_date_request?: string;
  merchant_deactive_id?: number;
  merchant_deactive_reason?: string;
  merchant_deactive_request?: string;
  merchant_deactive_requestby?: number;
  merchant_deactive_rtbalance?: string;
  merchant_deactive_status?: number;
  merchant_deactive_updateby?: number;
  merchant_deactive_updatedate?: string;
  merchant_id?: number;
}

export interface UpdatePreviewData {
  data: string;
  IdCardPhoto: File;
  NpwpPhoto: File;
}

export interface ITicketingState {
  data: ChangeMercProfileData;
  donation: ListDonationData[];
  deactivated: DeactivatedData;
  isLoading: boolean;
  error: AxiosError;
}
export interface ISubmitDeactivateState {
  merchant_id: string;
  mdr_reason: string;
  mdr_rtbalance: string;
  mdr_request_by: number;
  mdr_update_by: number;
  donation_account_id: number;
  request: string;
  account_no: string;
  account_name: string;
  bank: string;
}

interface SetChangeMercProfile {
  type: ITicketingType.TICKETING_CHANGE_SUCCESS;
  payload: ITicketingState;
}
interface SetTicketPending {
  type: ITicketingType.TICKETING_PENDING;
  payload: null;
}
interface SetTicketFailed {
  type: ITicketingType.TICKETING_FAILED;
  payload: { error: AxiosError };
}
interface GetDonationSuccess {
  type: ITicketingType.GET_DONATION_SUCCESS;
  payload: ListDonationData[];
}
interface GetDonationPending {
  type: ITicketingType.GET_DATA_PENDING;
  payload: null;
}
interface GetDonationFailed {
  type: ITicketingType.GET_DATA_FAILED;
  payload: { error: AxiosError };
}
interface SubmitDeactivateSuccess {
  type: ITicketingType.SUBMIT_DEACTIVATE_SUCCESS;
  payload: any;
}
interface GetDeactivateSuccess {
  type: ITicketingType.GET_STATUS_DEACTIVATE;
  payload: DeactivatedData;
}

type IDonate = GetDonationSuccess | GetDonationPending | GetDonationFailed;
export type ITicketingAction =
  | SetChangeMercProfile
  | SetTicketFailed
  | SetTicketPending
  | IDonate
  | SubmitDeactivateSuccess
  | GetDeactivateSuccess;
