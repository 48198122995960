import React, { useEffect } from 'react';
import { Box, Button, Modal, ThemeProvider } from '@mui/material';
import CardComponents from 'components/cardComponents';
import { btnTheme2, ModalSx } from 'components/muiTheme';
import CreateTwoToneIcon from '@mui/icons-material/CreateTwoTone';
import SecurityKey from './securityKey';
import CreatOrderURL from './createOrderURL';
import Messaging from './messaging';
import IpWhitelist from './ipWhitelist';
import { toast, ToastContainer } from 'react-toastify';
import SettingProvider, { SettingContext } from 'context/settingContext';
import { icons } from 'assets';
import useSetting from 'hooks/useSetting';
import { permissionFunction } from 'utils/permission';
import permission from 'constants/permissionRole';
import useSandbox from 'hooks/useSandbox';
import { isFullVersion } from 'utils/exception';
import SetSettleSchedule from './SetSettleSchedule';

const Components = () => {
  const [select, setSelect] = React.useState(1);
  let { isEdit, setisEdit, isModal, setisModal, handleCloseModal } =
    React.useContext(SettingContext);

  let { getApiSettingData, Env, submitEdit } = useSetting();
  let sandbox = useSandbox()?.userData['Env'];

  useEffect(() => {
    const apiEndpoints = [
      'security',
      'url',
      'messaging',
      'ip_whitelist',
      'settlement',
    ];

    apiEndpoints.forEach((endpoint) => {
      getApiSettingData(endpoint, (response) => {
        if (response.code !== 200) {
          toast.error('Terjadi Kesalahan', { autoClose: 4000 });
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getApiSettingData, submitEdit, sandbox]);

  const SectionList = ({ id, text }) => {
    return (
      <div
        className={` pb-3 pt-1 min-w-[200px]  text-[1.2rem] text-center ${
          select === id &&
          'border-b-2 text-[#231F20]  border-[#21AF7D] font-bold'
        } `}
        onClick={() => setSelect(id)}
      >
        {text}
      </div>
    );
  };

  return (
    <div className="w-full">
      <ToastContainer position="top-right" autoClose={4000} pauseOnHover />
      {/* modal */}
      <Modal open={isModal} onClose={handleCloseModal}>
        <Box sx={ModalSx}>
          <div className="flex justify-center text-start flex-col">
            <div className="text-center">
              <div className="flex justify-center align-center">
                <img className="" src={icons.ceklisModal} alt="as" />
              </div>
              <div className="text-md mt-3 mb-2 font-semibold">
                Perubahan Pengaturan Berhasil!
              </div>
              <div className="text-sm mb-5">
                Perubahan pengaturan kamu berhasil tersimpan.
              </div>
              <ThemeProvider theme={btnTheme2}>
                <Button
                  variant="contained"
                  className="w-[100%]"
                  onClick={() => {
                    setisModal(false);
                    setisEdit(false);
                  }}
                >
                  OK
                </Button>
              </ThemeProvider>
            </div>
          </div>
        </Box>
      </Modal>

      <div className="text-xl sm:text-3xl items-center font-bold mb-5 mt-10  cursor-pointer">
        System Setting
      </div>

      <>
        <CardComponents>
          <div className=" mb-[-12px] pl-auto  justify-normal xl:justify-around overflow-auto pt-3 text-[#6C6D71]">
            <div className="flex justify-between w-full">
              <SectionList id={1} text={'Security Key'} />
              <SectionList id={2} text={'Create Order URL'} />
              <SectionList id={3} text={'Messaging'} />
              <SectionList id={4} text={'IP Whitelist'} />
              {!isFullVersion && (
                <SectionList id={5} text={'Settle Schedule'} />
              )}
            </div>
          </div>
        </CardComponents>
        <br />
        <CardComponents>
          <div className="border-b-[1px] mb-3 pb-3 py-5 mt-2 mx-8 font-bold">
            <div className="flex justify-between items-center pb-2 ">
              <div className="text-md lg:text-xl ">
                {select === 1 && 'Security Key'}
                {select === 2 && 'Create Order URL'}
                {select === 3 && 'Messaging'}
                {select === 4 && 'IP Whitelist'}
                {select === 5 && 'Set Settle Schedule'}
              </div>

              <ThemeProvider theme={btnTheme2}>
                <div className={`${isEdit && 'hidden'}`}>
                  <Button
                    variant="contained"
                    className="w-[90px] lg:w-[110px]"
                    onClick={() => setisEdit(true)}
                    startIcon={<CreateTwoToneIcon />}
                    disabled={
                      !permissionFunction(permission[Env].UPDATE_SYSTEMSETTING)
                    }
                  >
                    Edit
                  </Button>
                </div>
              </ThemeProvider>
            </div>
          </div>
          <div className="px-8 ">
            {select === 1 && <SecurityKey />}
            {select === 2 && <CreatOrderURL />}
            {select === 3 && <Messaging />}
            {select === 4 && <IpWhitelist />}
            {select === 5 && <SetSettleSchedule />}
          </div>

          <br />
        </CardComponents>
      </>
    </div>
  );
};

const ApiSetting = () => {
  return (
    <SettingProvider>
      <Components />
    </SettingProvider>
  );
};

export default ApiSetting;
