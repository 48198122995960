import { getLocalItem } from './localStorage';

export const getAuthToken = () => {
  return getLocalItem('token-web-merchant');
};
export const setAuthToken = (token: string): void => {
  return localStorage.setItem('example-token', JSON.stringify(token));
};

export const removeAuthToken = (): void => {
  return localStorage.clear();
};
