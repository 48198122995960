// SectionCard.js
import React from 'react';
import Card from 'components/cardComponents';
import { ISectionCardProps } from './interface';

const SectionCard: React.FC<ISectionCardProps> = ({ title, children }) => (
  <Card>
    <div className="pb-5 pt-2 px-5">
      <div className="text-lg font-semibold mt-2">{title}</div>
      <div className="mt-3">{children}</div>
    </div>
  </Card>
);

export default SectionCard;
