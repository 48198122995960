import React from 'react';
import useReport from 'hooks/useTransaction';
import { useEffect } from 'react';
import { IApiResponse } from 'stores/types/generalTypes';
import DetailTransaction from './detailTransaction';
import Transaction from './TransactionList';
import Alert from 'components/alert';
import TransactionProvider, {
  TransactionContext,
} from 'context/transactionContext';
import useSandbox from 'hooks/useSandbox';

const Transaksi = () => {
  const { getTransactionList, getFilter } = useReport();
  let sandbox = useSandbox()?.userData['Env'];

  let { setIsAlert, params, handleAlert, isAlert, severty, message, isDetail } =
    React.useContext(TransactionContext);

  useEffect(() => {
    getFilter((response: IApiResponse) => {
      if (response.code !== 200) handleAlert('error', 'Failed');
    }); // eslint-disable-next-line
  }, [getFilter]);

  useEffect(() => {
    getTransactionList(params, (response: IApiResponse) => {
      if (response.status !== 200) handleAlert('error', 'Failed');
    }); // eslint-disable-next-line
  }, [getTransactionList, params, sandbox]);

  return (
    <div>
      <Alert
        severty={severty}
        message={message}
        open={isAlert}
        setIsAlert={setIsAlert}
      />
      {isDetail ? <DetailTransaction /> : <Transaction />}
    </div>
  );
};

const Provide = () => {
  return (
    <TransactionProvider>
      <Transaksi />
    </TransactionProvider>
  );
};

export default Provide;
