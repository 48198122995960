import React from 'react';
import { useDropzone } from 'react-dropzone';
import { ClearOutlined, InsertDriveFileOutlined } from '@mui/icons-material';
import { Button, IconButton, ThemeProvider } from '@mui/material';
import { btnOulineTheme } from 'components/muiTheme';
import { Document, Page, pdfjs } from 'react-pdf';
import './custom.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface FileUploadProps {
  files: File | null;
  photo: string | null;
  onFileUpload: (file: File | null) => void;
  onPhotoUpload: (photo: string) => void;
  id?: string;
}

const Component: React.FC<FileUploadProps> = ({
  files,
  onFileUpload,
  onPhotoUpload,
  photo,
  id,
}) => {
  const handleClear = () => {
    onFileUpload(null);
    onPhotoUpload(null);
  };

  const WIDTH = window.innerWidth < 600;

  const handleFileUpload = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    onFileUpload(file);
    if (file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        onPhotoUpload(reader.result as string);
      };
    }
  };

  const accepted = {
    'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
    'application/pdf': ['.pdf'],
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFileUpload,
    accept: accepted,
    multiple: false,
  });

  const renderPreview = () => {
    if (files?.type.startsWith('image/')) {
      return (
        <div className="flex-col gap-2 shadow-lg rounded-xl px-3 max-w-md">
          <img
            src={URL.createObjectURL(files)}
            alt="Preview"
            className="w-full"
          />
          <div
            className={`justify-between bg-white ${files ? 'flex' : 'hidden'}`}
          >
            <div className="font-bold my-auto text-left">{files?.name}</div>
            <IconButton onClick={handleClear} sx={{ px: 0 }}>
              <ClearOutlined />
            </IconButton>
          </div>
        </div>
      );
    } else if (files?.type === 'application/pdf') {
      return (
        <div
          style={{ width: WIDTH ? 270 : 450 }}
          className="shadow-lg rounded-xl"
        >
          <Document file={files}>
            <Page
              width={WIDTH ? 270 : 450}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              pageNumber={1}
            ></Page>
          </Document>
          <div
            className={`justify-between bg-white ${files ? 'flex' : 'hidden'}`}
          >
            <div className="font-bold my-auto text-left">{files?.name}</div>
            <IconButton onClick={handleClear} sx={{ px: 0 }}>
              <ClearOutlined />
            </IconButton>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex flex-col space-y-2 md:space-y-0 md:flex-row gap-2 mx-auto my-10 w-fit">
          <div className="my-auto flex flex-col px-2">
            <p className="text-justify block sm:flex sm:flex-col">
              <b>Unggah dokumen disini atau </b>
              JPG, PNG or PDF (max 2 mb)
            </p>
          </div>

          <ThemeProvider theme={btnOulineTheme}>
            <Button
              id={id}
              variant="outlined"
              color="secondary"
              sx={{ textTransform: 'capitalize' }}
              startIcon={<InsertDriveFileOutlined />}
            >
              Browse
            </Button>
          </ThemeProvider>
        </div>
      );
    }
  };

  return (
    <div {...getRootProps()} className="w-full">
      <input {...getInputProps()} />
      {renderPreview()}
    </div>
  );
};

export default Component;
