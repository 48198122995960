import { AxiosError } from 'axios';
import { IPagination } from './generalTypes';

export enum TransactionType {
  SET_TRANSACTION_LIST_PENDING = 'GET_TRANSACTION_LIST_PENDING',

  SET_TRANSACTION_LIST_ERROR = 'GET_TRANSACTION_LIST_ERROR',
  SET_TRANSACTION_LIST_ERROR_GET = 'SET_TRANSACTION_LIST_ERROR_GET',

  GET_TRANSACTION_LIST_SUCCESS = 'GET_TRANSACTION_LIST_SUCCESS',
  GET_FILTER_LIST = 'GET_FILTER_LIST',
  GET_DETAIL = 'GET_DETAIL',
}

export interface ITransactionListState {
  TransactionListReport: ITransactionListReport;
}

export interface ITransactionListReport {
  isLoading: boolean;
  error: AxiosError;
  pagination: IPagination;
  summary: ISummaryFront;
  filter_params: any;
  amounts: IAmountsTransactionListReport;
  transactions: IDataTransactionListReport[];
  dataFilter: any;
  detail: IDetail;
}

interface ISummaryFront {
  total_submerchant: number;
  total_freq_transaction: number;
  total_mdr: number;
  total_transaction_amount: number;
}

interface IDetail {
  summary: ISummary;
  custommer: ICustommer;
  payment: IPayment;
  items: IItems[];
  items_v2: any;
  timeline: ITimeline[];
  fees: object;
}

interface ITimeline {
  code: string;
  date: string;
  type: string;
}

interface ISummary {
  status: string;
  order_id: number;
  trx_no: string;
  reference_no: string;
  trx_date: string;
}

interface ICustommer {
  name: string;
  phone: string;
  email: string;
}
interface IPayment {
  method: string;
  channel: string;
  va_number: string;
  expired_date: string;
}

interface IItems {
  brand: string;
  category: string;
  id: string;
  merchantname: string;
  name: string;
  price: number;
  quantity: number;
}

export interface IAmountsTransactionListReport {
  total: number;
  settled: number;
  withdrawn: number;
  on_process: number;
  on_hold: number;
  refunded: number;
  failed: number;
  settled_total_record: number;
  withdrawn_total_record: number;
  on_process_total_record: number;
  on_hold_total_record: number;
  refunded_total_record: number;
  failed_total_record: number;
}

interface IDataTransactionListReport {
  date: string;
  trx_no: string;
  channel_name: string;
  settlement_date: string;
  settlement_due: string;
  customer_email: string;
  amount: number;
  va_no: string;
  reference_no: string;
  status: string;
  state: string;
}

export interface IFilterTlReport {
  channels: [];
  states: [];
  statuses: [];
}

export interface IBodyDataCreate {
  order_number: String;
  customer_phone: String;
  customer_name: String;
  customer_email: String;
  reason_to_refund: String;
}

export interface IStates {
  1: string; // "ALL_STATES",
  2: string; // "WITHDRAWABLE",
  3: string; // "ON_HOLD",
  4: string; // "WITHDRAWN",
  5: string; // "REFUNDED",
  6: string; // "ON_PROCESS"
}

export interface IStatuses {
  1: string; // "ALL_STATUS",
  2: string; // "OPEN",
  3: string; // "SUCCEED",
  4: string; // "FAILED"
}

interface GetTransactionList_Report {
  type: TransactionType.GET_TRANSACTION_LIST_SUCCESS;
  payload: {
    filter_params: any;
    pagination: IPagination;
    summary: ISummaryFront;
    amounts: IAmountsTransactionListReport;
    transactions: IDataTransactionListReport[];
  };
}

interface GetFilterTL_report {
  type: TransactionType.GET_FILTER_LIST;
  payload: {
    dataFilter: IFilterTlReport;
  };
}
interface GetDetail {
  type: TransactionType.GET_DETAIL;
  payload: {
    detail: IDetail;
  };
}

interface SetPendingTransactionList_Report {
  type: TransactionType.SET_TRANSACTION_LIST_PENDING;
  payload?: any;
}

interface SetErrorTransactionList_Report {
  type: TransactionType.SET_TRANSACTION_LIST_ERROR;
  payload: {
    error: AxiosError;
  };
}

interface SetErrorGetTransactionList_Report {
  type: TransactionType.SET_TRANSACTION_LIST_ERROR_GET;
  payload: {
    error: AxiosError;
  };
}

export type TransactionAction =
  | GetTransactionList_Report
  | SetPendingTransactionList_Report
  | SetErrorTransactionList_Report
  | GetFilterTL_report
  | SetErrorGetTransactionList_Report
  | GetDetail;
