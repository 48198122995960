import React, { useState } from 'react';
import {
  createTheme,
  IconButton,
  InputAdornment,
  OutlinedInput,
  ThemeProvider,
} from '@mui/material';
import { icons } from 'assets';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import HelperText from '../../helperText';
import { Props } from '.';

const TextProps = createTheme({ typography: { fontFamily: 'Manrope' } });

const Component: React.FC<Props> = (props) => {
  const [show, setShow] = useState(false);
  const iconVariant = () => {
    if (props.tooltip === 'true') {
      if (props.tootiptype === '!') {
        return icons.Info2;
      }
    }
  };

  const xClick = () => {
    setShow((v) => !v);
  };
  const PassAddorment = () => {
    if (props.ispass === 'true') {
      const onMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
      };
      return (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={xClick}
            onMouseDown={onMouseDown}
            edge="end"
          >
            {show ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      );
    }
  };
  return (
    <ThemeProvider theme={TextProps}>
      <div className="flex flex-col py-2 gap-2">
        <div className="flex gap-2">
          <p className="my-auto">{props.text}</p>
          {props.tooltip === 'true' && (
            <IconButton size="small">
              <img src={iconVariant()} alt="q" className="w-6" />
            </IconButton>
          )}
        </div>
        <OutlinedInput
          disabled={props.disabled}
          fullWidth
          sx={{ bgcolor: props.disabled && '#E9ECEF' }}
          type={props.ispass ? (show ? 'text' : 'password') : props.type}
          endAdornment={<PassAddorment />}
          {...props}
        />
        {props.error && <HelperText error={props.errortext} />}
      </div>
    </ThemeProvider>
  );
};
export default Component;
