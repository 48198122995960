import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, ThemeProvider } from '@mui/material';

import { icons } from 'assets';
import { ModalSx, btnTheme2 } from 'components/muiTheme';

const ModalWdReq = ({ open, handleClose, isLoading, handlWdReqAction }) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={ModalSx}>
          <div className="flex justify-center text-center flex-col">
            <div className="text-center flex justify-center">
              <img src={icons.seru} className="w-[80px]" alt="seru" />
            </div>
            <div className="font-bold text-[17px] mt-6">
              Yakin ingin melanjutkan Withdrawal?
            </div>
            <div className="text-[13px] mt-3">
              Kamu sedang melakukan Withdrawal. Pastikan keputusan sudah sesuai.
            </div>
            <div className="flex mt-8 justify-center">
              <ThemeProvider theme={btnTheme2}>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    width: '200px',
                    marginRight: 3,
                    fontSize: '12px',
                  }}
                  onClick={handleClose}
                >
                  Tidak, Batalkan
                </Button>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  loading={isLoading}
                  sx={{ width: '200px', fontSize: '12px' }}
                  onClick={handlWdReqAction}
                >
                  Ya, Lanjutkan
                </LoadingButton>
              </ThemeProvider>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ModalWdReq;
