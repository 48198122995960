import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import React from 'react';
import SettingWrapper from '../components/settingWrapper';

type Props = {};

const PaymentLink = (props: Props) => {
  return (
    <div>
      <SettingWrapper
        titleCard="Payment Link Settings"
        titleBUtton="Save Changes"
        disabled>
        <div>
          Payment Link is a way to give a link to pay. You can copy the link to
          your blog, facebook, instagram or send to your customer email. It also
          can be used for sending a one time bill to your customer.
        </div>

        <div className="flex mt-5 items-center">
          <div className="mr-5">Do you want to enable Payment Link?</div>
          <FormControl disabled>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group">
              <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="No" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </div>
        <br />
      </SettingWrapper>
    </div>
  );
};

export default PaymentLink;
