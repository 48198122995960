import ButtonWithIcon from '../ButtonWithIcon';
import { btnTheme } from 'components/configs/theme';
import { icons } from 'assets';
import { useState } from 'react';
import { BasicPopover } from 'components/Popover';
import { MenuItem } from '@mui/material';

const Component = ({
  haandleClick,
  isLoading,
  handleDownload,
}: {
  haandleClick?: any;
  handleDownload?: any;
  isLoading?: boolean;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'popover' : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (haandleClick) {
      haandleClick();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  return (
    <>
      <ButtonWithIcon
        isLoading={isLoading}
        width='full'
        btnTheme={btnTheme}
        startIcon={icons.exportIcon}
        onClick={(e) => handleOpen(e)}
        variant='outlined'
        color='primary'
      >
        Export
      </ButtonWithIcon>
      <BasicPopover
        width={110}
        open={open}
        anchorEl={anchorEl}
        id={id}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleDownload('csv');
            handleClose();
          }}
          value={1}
        >
          <div className='font-bold text-center  w-full text-[#21AF7D]'>
            csv
          </div>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleDownload('excel');
            handleClose();
          }}
          value={1}
        >
          <div className='font-bold text-center w-full text-[#21AF7D]'>
            Excel
          </div>
        </MenuItem>
      </BasicPopover>
    </>
  );
};

export default Component;
