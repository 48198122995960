/* eslint-disable import/no-anonymous-default-export */

import {
  TransactionAction,
  ITransactionListState,
  TransactionType,
} from 'stores/types/transactionTypes';
import { PaginationState } from './generalReducer';

const initialState = {
  TransactionListReport: {
    isLoading: false,
    error: null,
    pagination: PaginationState,
    amounts: {
      total: 0,
      settled: 0,
      withdrawn: 0,
      on_process: 0,
      on_hold: 0,
      refunded: 0,
      failed: 0,
      settled_total_record: 0,
      withdrawn_total_record: 0,
      on_process_total_record: 0,
      on_hold_total_record: 0,
      refunded_total_record: 0,
      failed_total_record: 0,
    },
    transactions: [],
    dataFilter: {
      channels: [],
      states: [],
      statuses: [],
    },
    detail: {
      summary: {
        status: '',
        order_id: null,
        trx_no: '',
        reference_no: '',
        trx_date: '',
      },
      custommer: {
        name: '',
        phone: '',
        email: '',
      },
      payment: {
        method: '',
        channel: '',
        va_number: '',
        expired_date: '',
      },
      items: [],
      items_v2: [],
      timeline: [],
      fees: {},
    },
    filter_params: {},
    summary: {
      total_submerchant: 0,
      total_freq_transaction: 0,
      total_mdr: 0,
      total_transaction_amount: 0,
    },
  },
};

export default (
  state: ITransactionListState = initialState,
  { type, payload }: TransactionAction
) => {
  switch (type) {
    case TransactionType.SET_TRANSACTION_LIST_PENDING:
      return {
        ...state,
        TransactionListReport: {
          ...state['TransactionListReport'],
          isLoading: true,
        },
      };
    case TransactionType.GET_TRANSACTION_LIST_SUCCESS:
      return {
        ...state,
        TransactionListReport: {
          ...state['TransactionListReport'],
          ...payload,
          isLoading: false,
        },
      };
    case TransactionType.GET_FILTER_LIST:
      return {
        ...state,
        TransactionListReport: {
          ...state['TransactionListReport'],
          ...payload,
          isLoading: false,
        },
      };
    case TransactionType.GET_DETAIL:
      return {
        ...state,
        TransactionListReport: {
          ...state['TransactionListReport'],
          ...payload,
          isLoading: false,
        },
      };
    case TransactionType.SET_TRANSACTION_LIST_ERROR_GET:
      return {
        ...state,
        TransactionListReport: {
          ...initialState.TransactionListReport,
          ...payload,
          isLoading: false,
        },
      };
    case TransactionType.SET_TRANSACTION_LIST_ERROR:
      return {
        ...state,
        TransactionListReport: {
          ...state.TransactionListReport,
          ...payload,
          isLoading: false,
        },
      };

    default:
      return state;
  }
};
