import React, { useEffect } from 'react';
import WidrawalReq from './WithdrawalReq';
import DetailWd from './DetailWd';
import AddIcon from '@mui/icons-material/Add';
import { ThemeProvider } from '@mui/material';
import { btnTheme2 } from 'components/muiTheme';
import LoadingButton from '@mui/lab/LoadingButton';

import TableWdList from './table/tableWd';
import useWithdrawal from 'hooks/useWithdrawal';
import { IApiResponse } from 'stores/types/generalTypes';
import Alert from 'components/alert';
import WithdrawalProvider, {
  WithdrawalContext,
} from 'context/withdrawalContext';
import useSandbox from 'hooks/useSandbox';
import { permissionFunction } from 'utils/permission';
import permission from 'constants/permissionRole';
import useExport from 'hooks/useExport';
import { tableNameWdList } from 'pages/laporan/Settlement/table/dataList';
import AdjustButton from 'components/Button/AdjustButton';
import SortByButton from 'components/Button/SortByButton';
import ExportButton from 'components/Button/ExportButton';
import Card from 'components/cardComponents';

const Components = () => {
  let {
    uiNow,
    isAlert,
    setIsAlert,
    severty,
    message,
    handleAlert,
    params,
    setUiNow,
    header,
  } = React.useContext(WithdrawalContext);

  const { exportWithdrawelList } = useExport();

  const { Env, withdrawal, getWdList, getWdSettled } = useWithdrawal();

  const sandbox = useSandbox()?.userData['Env'];

  const [disabled, setDisabled] = React.useState(true);

  const handleDownloadExcel = () => {
    exportWithdrawelList(params, handleAlert, tableNameWdList);
  };

  const handleGetWdSt = () => {
    getWdSettled(null, (response: any) => {
      if (response.code === 200) {
        response.data.user_can_withdraw === true && setDisabled(false);
      } else {
        handleAlert('error', 'error');
      }
    }); // eslint-disable-next-line
  };

  const handleGetWdList = () => {
    getWdList(params, (response: IApiResponse) => {
      if (response.code !== 200) handleAlert('error', 'error');
    }); // eslint-disable-next-line
  };

  useEffect(() => {
    if (isAlert === true) {
      handleGetWdList();
      handleGetWdSt();
    } // eslint-disable-next-line
  }, [isAlert]);

  useEffect(() => {
    handleGetWdList();
    // eslint-disable-next-line
  }, [getWdList, params, sandbox]);

  useEffect(() => {
    handleGetWdSt();
    // eslint-disable-next-line
  }, [getWdSettled, sandbox]);

  return (
    <div>
      <Alert
        severty={severty}
        message={message}
        open={isAlert}
        setIsAlert={setIsAlert}
      />

      {uiNow === '' && (
        <>
          <div className="text-2xl sm:text-3xl  items-center font-bold mb-5 mt-[5rem] lg:mt-[1rem] cursor-pointer">
            Withdrawal
          </div>
          <Card>
            <div className="p-5 flex justify-between items-center border-b-2 mb-3">
              <div className="text-[14px] lg:text-2xl px-3 py-3 font-bold">
                Withdrawal List{' '}
              </div>
              <ThemeProvider theme={btnTheme2}>
                <LoadingButton
                  variant="contained"
                  disabled={
                    permissionFunction(permission[Env].SETTLED_WITHDRAWAL) ===
                    false
                      ? true
                      : disabled
                  }
                  sx={{ height: 40 }}
                  color="primary"
                  loadingPosition="start"
                  loading={withdrawal.isLoadingReq}
                  startIcon={<AddIcon />}
                  onClick={() => setUiNow('wdRequest')}
                >
                  Request
                </LoadingButton>
              </ThemeProvider>
            </div>

            <div className="flex justify-between items-center p-3 mb-3 px-5">
              <div className="flex space-x-2">
                <AdjustButton header={tableNameWdList} list={header} />
                <SortByButton />
              </div>
              <ExportButton haandleClick={handleDownloadExcel} />
            </div>
            <TableWdList />
          </Card>
        </>
      )}
      {uiNow === 'wdRequest' && <WidrawalReq />}
      {uiNow === 'wdDetail' && <DetailWd />}
    </div>
  );
};

const Withdrawal = () => {
  return (
    <WithdrawalProvider>
      <Components />
    </WithdrawalProvider>
  );
};
export default Withdrawal;
