import type { FC } from 'react';
import PaymentMethodCard from './cardPaymentMethod';
import React from 'react';

export interface ContainerProps {
  data: any;
  movePaymentMethod: any;
  moveChannel: any;
  findMethod: any;
  findChannel: any;
  editView: any;
  pcDrag: any;
  setpcDrag: any;
  handleSelectChannel;
  handleSelectAllChannel;
  handleChangeFee;
  handleChangeType;
}

export const Container: FC<ContainerProps> = ({
  data,
  movePaymentMethod,
  moveChannel,
  findMethod,
  findChannel,
  editView,
  pcDrag,
  setpcDrag,
  handleSelectChannel,
  handleSelectAllChannel,
  handleChangeFee,
  handleChangeType,
}) => {
  const renderedMethods = React.useMemo(() => {
    return data.map(
      (payment, index) =>
        payment?.chanels && (
          <PaymentMethodCard
            key={payment.id} // Pastikan ada key unik untuk setiap elemen
            method={payment}
            index={index}
            movePaymentMethod={movePaymentMethod}
            moveChannel={moveChannel}
            findMethod={findMethod}
            findChannel={findChannel}
            editView={editView}
            {...{
              pcDrag,
              setpcDrag,
              handleSelectChannel,
              handleSelectAllChannel,
              handleChangeFee,
              handleChangeType,
            }}
          />
        )
    );
    // eslint-disable-next-line
  }, [data, movePaymentMethod, moveChannel]);

  return <div className="gap-3 grid">{renderedMethods}</div>;
};
