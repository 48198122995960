import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';

import { StyledTableCellWd, StyledTableRowWd } from 'components/muiTheme';
import { tableNameWdSettled } from './dataList';
import useWithdrawal from 'hooks/useWithdrawal';
import { NumericFormat } from 'react-number-format';
import { Skeleton } from '@mui/material';

const TableWdReq = ({ show }) => {
  const { withdrawal } = useWithdrawal();
  let { data } = withdrawal.wd_settled_transactions;

  return (
    <div>
      <TableContainer
        className="TableContainer"
        sx={{
          minWidth: 'full',
          height: show ? '400px ' : 'auto',
          maxHeight: show ? '' : '16rem',
          overflowY: show ? '' : 'hidden',
        }}
      >
        <Table
          stickyHeader
          sx={{ width: 'full', minWidth: 1200, height: 'auto' }}
        >
          <TableHead>
            <StyledTableRowWd>
              {tableNameWdSettled.map((e, i) => {
                return <StyledTableCellWd>{e.tableName}</StyledTableCellWd>;
              })}
            </StyledTableRowWd>
          </TableHead>

          <TableBody>
            {data !== null &&
              data !== undefined &&
              data.length >= 1 &&
              data.map((row, index) => {
                let handleValue = (e: any) => {
                  if (e.name === 'No')
                    return <div className="pl-1">{index + 1}</div>;

                  if (
                    e.name !== 'reference_no' &&
                    e.name !== 'trx_no' &&
                    e.name !== 'channel'
                  )
                    return (
                      <>
                        Rp.
                        <NumericFormat
                          value={row[e.name]}
                          displayType={'text'}
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          prefix={'  '}
                        />
                      </>
                    );

                  if (row[e.name] === ' ' || row[e.name] === undefined)
                    return '-';

                  return row[e.name];
                };

                return (
                  <StyledTableRowWd>
                    {tableNameWdSettled.map((e, i) => {
                      return (
                        <StyledTableCellWd key={i}>
                          {withdrawal.isLoading === true ? (
                            <Skeleton
                              variant="text"
                              sx={{ fontSize: '1rem' }}
                            />
                          ) : (
                            handleValue(e)
                          )}
                        </StyledTableCellWd>
                      );
                    })}
                  </StyledTableRowWd>
                );
              })}
          </TableBody>
        </Table>
        {(data === null || data === undefined || data.length <= 0) && (
          <div className="w-full h-20 items-center bg-[#F8F8F8] text-[#979797] flex justify-center">
            {withdrawal.isLoading === true ? 'Loading' : 'No Data'}
          </div>
        )}
      </TableContainer>
    </div>
  );
};

export default TableWdReq;
