import { NumericFormat } from 'react-number-format';

export const WrapDetailRefundItem = ({ name, children }) => {
  return (
    <>
      <div className="mb-4 font-semibold">{name}</div>
      <div>{children}</div>
    </>
  );
};

const DetailRefundItem = ({ name, value }) => {
  const handleValue = () => {
    if (name.includes('Amount')) {
      return (
        <>
          Rp.
          <NumericFormat
            value={value.toString()}
            displayType={'text'}
            thousandSeparator={'.'}
            decimalSeparator={','}
            prefix={'  '}
          />
        </>
      );
    }
    return value;
  };

  const handleClassName = () => {
    if (value === 'On-Process') {
      return 'text-[#F8C045]';
    }
    if (value === 'Success - Paid') {
      return 'text-[#18AD65]';
    }
    if (value === 'Failed') {
      return 'text-[#F04545]';
    }
    return '';
  };

  return (
    <div className="flex">
      <div className="flex text-md w-[20%] my-1 mr-3 justify-between">
        <div className="flex">
          {name}
          {name === 'Reason to Refund' && (
            <div className="text-[#EB5050]"> *</div>
          )}
        </div>
        <div>:</div>
      </div>
      <div className={`my-1 ${handleClassName()}`}>{handleValue()}</div>
    </div>
  );
};

export default DetailRefundItem;
