import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { KeyboardArrowRight } from '@mui/icons-material';
import { Button } from '@mui/material';
import { img, icons } from 'assets';
import { RootState } from 'stores';
import { LangProp } from '../interface';
import { setDrawerContent } from 'stores/actions/utilsAction';
import { getProfile, userLogout } from 'stores/actions/authActions';
import { permissionFunction } from 'utils/permission';
import permission from 'constants/permissionRole';
import { isFullVersion } from 'utils/exception';
import { removeCookie } from 'utils/cookie';
import { useTranslation } from 'react-i18next';

const AccMenu = ({ text, onClick }) => {
  return (
    <div
      className="flex justify-between font-medium cursor-pointer"
      onClick={onClick}
    >
      <div className="">{text}</div>
      <KeyboardArrowRight />
    </div>
  );
};

const LangFlag: React.FC<LangProp> = (props) => {
  const { active, flag, lang, onClick } = props;
  return (
    <button
      onClick={onClick}
      className={`${
        active ? 'bg-white' : 'bg-none'
      } rounded-full py-2 flex justify-center gap-2 font-semibold`}
    >
      <img src={flag} alt="flag" className="rounded-full" />
      {lang}
    </button>
  );
};

const Component: React.FC = () => {
  const navigate = useNavigate();
  const { profile } = useSelector((state: RootState) => state.auth);
  const [lang, setLang] = useState('INA');
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    permissionFunction(permission.GET_PROFILE) && dispatch(getProfile());
  }, [dispatch]);

  const handleOut = () => {
    userLogout();
    navigate(`${isFullVersion ? '/full/' : '/'}login`);
    removeCookie('tips-qr');
  };
  const handleLang = (lang: string) => {
    setLang(lang);
    i18n.changeLanguage(lang);
  };

  const toMerchant = () => {
    dispatch(setDrawerContent('profile'));
  };
  const toPassword = () => {
    dispatch(setDrawerContent('password'));
  };

  return (
    <div className="flex flex-col">
      <div className="bg-[#D3EDE4] h-[70px] w-full" />
      <img
        src={profile?.ProfileImage === '' ? img.profile : profile?.ProfileImage}
        alt="profile"
        className="rounded-full h-24 w-24 bg-white border-solid border-2 mx-auto border-[#D3EDE4] -mt-10 object-contain"
      />
      <div className="flex flex-col text-center py-4 space-y-1">
        <p className="font-extrabold">{profile?.Username}</p>
        <p className="font-medium text-[#21AF7D]">{profile?.Fullname}</p>
        <p className="text-sm">{profile?.RoleName}</p>
      </div>
      <hr />
      <div className="py-4 px-6 space-y-3">
        <p className="font-bold">Account</p>
        <AccMenu text="User Profile" onClick={toMerchant} />
        <AccMenu text="Change Password" onClick={toPassword} />
      </div>
      <hr />
      <div className="py-4 px-6 space-y-3">
        <p className="font-bold">Language Setting</p>
        <div className="bg-[#F3F3F3] grid grid-cols-2 rounded-full p-1">
          <LangFlag
            flag={icons.Ind}
            onClick={() => handleLang('INA')}
            active={lang === 'INA'}
            lang="INA"
          />
          <LangFlag
            flag={icons.Eng}
            onClick={() => handleLang('ENG')}
            active={lang !== 'INA'}
            lang="ENG"
          />
        </div>
      </div>
      {t('kembali')}
      <div className="px-6 bottom-6 absolute w-full">
        <Button variant="outlined" color="error" fullWidth onClick={handleOut}>
          Sign Out
        </Button>
      </div>
    </div>
  );
};

export default Component;
