/* eslint-disable import/no-anonymous-default-export */

import {
  RefundAction,
  IRefundState,
  RefundType,
} from 'stores/types/refundTypes';
import { PaginationState2 } from './generalReducer';

const initialState = {
  isLoading: false,
  isLoadingDetail: false,
  error: null,
  pagination: PaginationState2,
  refundList: [],
  detailRefund: {
    ref_no: '',
    created: '',
    payment: '',
    payer_email: '',
    amount: 0,
    status: 0,
    payer_name: '',
    payer_phone: '',
  },
};

export default (
  state: IRefundState = initialState,
  { type, payload }: RefundAction
) => {
  switch (type) {
    case RefundType.SET_REFUND_LIST_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case RefundType.SET_REFUND_CREATE_SUCCES:
      return {
        ...state,
        isLoading: false,
        isLoadingDetail: false,
      };
    case RefundType.SET_REFUND_DETAIL_PENDING:
      return {
        ...state,
        RefundList: {
          ...state['RefundList'],
          isLoadingDetail: true,
        },
      };

    case RefundType.GET_REFUND_LIST_SUCCESS:
      return {
        ...state,
        ...payload,
        isLoading: false,
      };
    case RefundType.GET_REFUND_DETAIL:
      return {
        ...state,
        ...payload,
        isLoading: false,
        isLoadingDetail: false,
      };
    case RefundType.SET_REFUND_LIST_ERROR:
      return {
        ...state,
        ...payload,
        isLoading: false,
        isLoadingDetail: false,
      };
    case RefundType.SET_GETREFUND_LIST_ERROR:
      return {
        ...initialState,
        ...payload,
        isLoading: false,
        isLoadingDetail: false,
      };

    default:
      return state;
  }
};
