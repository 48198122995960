import endpoints from 'api/endpoints';
import axios from 'axios';
import provider, { IProvider } from 'provider';
import MutationTypes from 'provider/methods';
import { toast } from 'react-toastify';
import {
  ISubmitDeactivateState,
  ITicketingType,
} from 'stores/types/ticketingTypes';
import { errToast, handleError } from './errorAction';

export const submitTicketChanged = (
  data: string,
  ktp: File,
  npwp: File,
  message?: (a: string) => void,
  callback?: (v: boolean) => void
) => {
  return async (dispatch) => {
    dispatch({ type: ITicketingType.TICKETING_PENDING });
    try {
      const formData = new FormData();
      formData.append('data', data);
      if (ktp && ktp !== null) formData.append('IdCardPhoto', ktp);
      if (npwp && npwp !== null) formData.append('NpwpPhoto', npwp);
      const objProvider: IProvider = {
        method: MutationTypes.POST,
        path: endpoints.ticketing.edit,
        data: formData,
      };
      const response = await provider(objProvider);
      if (!response) toast.error('Network Error');
      if (response.data?.code === 200) {
        dispatch({
          type: ITicketingType.TICKETING_CHANGE_SUCCESS,
          payload: response.data,
        });
        callback(true);
      }
    } catch (e) {
      let msg = handleError(e, true);
      dispatch({
        type: ITicketingType.TICKETING_FAILED,
        payload: msg,
      });
      message(msg);
    }
  };
};
// export const submitTicketChanged =
//   (
//     data: string,
//     ktp: File | null,
//     npwp: File | null,
//     errors?: (a) => void,
//     setPopup?: (v: boolean) => void
//   ) =>
//   async (dispatch) => {
//     dispatch({ type: ITicketingType.TICKETING_PENDING });

//     const submitForm = new FormData();
//     submitForm.append('data', data);
//     submitForm.append('IdCardPhoto', ktp);
//     submitForm.append('NpwpPhoto', npwp);

//     const response = await apiPost(
//       endpoints.ticketing.edit,
//       submitForm,
//       true,
//       postConfig.standard
//     );

//     if (response.data?.code === 200) {
//       dispatch({
//         type: ITicketingType.TICKETING_CHANGE_SUCCESS,
//         payload: response.data,
//       });
//       setPopup(true);
//     } else if (response.data.data) {
//       errors(response.data.data);
//       dispatch({
//         type: ITicketingType.TICKETING_FAILED,
//         payload: JSON.stringify(response.data.data),
//       });
//       toast.error(JSON.stringify(response.data.data), { autoClose: 4000 });
//     } else {
//       errors(response.data);
//       dispatch({
//         type: ITicketingType.TICKETING_FAILED,
//         payload: JSON.stringify(response.data),
//       });
//       toast.error(JSON.stringify(response.data), { autoClose: 4000 });
//     }
//   };

export const GetDonation = () => async (dispatch) => {
  dispatch({ type: ITicketingType.GET_DATA_PENDING });
  try {
    const objProvider: IProvider = {
      method: MutationTypes.GET,
      path: endpoints.ticketing.donation,
    };
    const response = await provider(objProvider);
    // const response = await apiGet(endpoints.ticketing.donation, true);
    if (!response) toast.error('Failed to get Donation List');
    if (response.data?.code === 200) {
      dispatch({
        type: ITicketingType.GET_DONATION_SUCCESS,
        payload: response.data.data,
      });
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      errToast(error);
      dispatch({
        type: ITicketingType.GET_DATA_FAILED,
        payload: error.response.data,
      });
    }
  }
};

export const submitDeactivate =
  (data: ISubmitDeactivateState, success?: (val: boolean) => void) =>
  async (dispatch) => {
    dispatch({ type: ITicketingType.TICKETING_PENDING });
    try {
      const objProvider: IProvider = {
        method: MutationTypes.POST,
        path: endpoints.ticketing.deactivate.close,
        data: data,
      };
      const response = await provider(objProvider);
      if (!response) toast.error('NETWORK ERROR!');
      else if (response.data?.code === 200) {
        toast.success(response.data?.status, { autoClose: 4000 });
        success(true);
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        errToast(e);
        dispatch({
          type: ITicketingType.TICKETING_FAILED,
          payload: e.response.data,
        });
      }
    }
  };

export const cancelDeactivate =
  (Id: string, success?: (v: boolean) => void) => async (dispatch) => {
    dispatch({ type: ITicketingType.TICKETING_PENDING });
    try {
      const objProvider: IProvider = {
        method: MutationTypes.POST,
        path: endpoints.ticketing.deactivate.cancel,
        data: { merchant_id: Id },
      };
      const response = await provider(objProvider);
      if (!response) toast.error('NETWORK ERROR!');
      if (response.data?.code === 200) {
        toast.success(response.data?.status, { autoClose: 4000 });
        success(true);
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        errToast(e);
        dispatch({
          type: ITicketingType.TICKETING_FAILED,
          payload: e.response.data,
        });
      }
    }
  };

export const getDeactivateData = (id: string) => async (dispatch) => {
  dispatch({ type: ITicketingType.GET_DATA_PENDING });
  try {
    const objProvider: IProvider = {
      method: MutationTypes.GET,
      path: `${endpoints.ticketing.deactivate.current}?merchant_id=${id}`,
    };
    const response = await provider(objProvider);
    if (!response) toast.error('NETWORK ERROR!');
    if (response.data.code === 200) {
      dispatch({
        type: ITicketingType.GET_STATUS_DEACTIVATE,
        payload: response.data.data,
      });
    }
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response.status !== 400) errToast(e);
      dispatch({
        type: ITicketingType.GET_DATA_FAILED,
        payload: e.response.data,
      });
    }
  }
};
