import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { createSSO } from 'stores/actions/authActions';
import { encryptProps } from 'stores/types/authTypes';
import { setCookie } from 'utils/cookie';
import { isFullVersion } from 'utils/exception';
import { getLocalItem } from 'utils/localStorage';

const useRedirectOut = () => {
  const dispatch = useDispatch();
  const token = getLocalItem('token-web-merchant');
  const params: encryptProps = { version: isFullVersion ? 'full' : 'qoinhub' };

  const handleRedirect = useCallback(
    (userData, callback?: (name?: string, msg?: string) => void) => {
      if (userData?.PackageType !== (0 || null)) {
        if (token !== undefined) {
          const data = userData;
          delete data.Token;
          setCookie('ud', JSON.stringify(data));
          Object.assign(params, { sandbox: !userData?.Env });
          dispatch(createSSO(token, params));
        } else callback();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return { token, handleRedirect };
};

export default useRedirectOut;
