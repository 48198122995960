import React from 'react';
import { Grid, GridProps } from '@mui/material';

type contents = { margin?: string; lg?: number; xl?: number };
type Props = {
  title: string;
  front?: contents & { unbold?: boolean };
  back?: contents;
} & GridProps;

const Component: React.FC<Props> = (props) => {
  return (
    <Grid container spacing={2} mb={2} {...props}>
      <Grid
        item
        xs={12}
        lg={props.front?.lg ?? 3}
        xl={props.front?.xl ?? 3}
        my={props.front?.margin ?? 'auto'}
      >
        <span className={`text-base ${props.front?.unbold && 'font-semibold'}`}>
          {props.title}
        </span>
      </Grid>
      <Grid
        item
        xs={12}
        lg={props.back?.lg ?? 6}
        xl={props.back?.xl ?? 5}
        my={props.back?.margin ?? 'auto'}
      >
        {props.children}
      </Grid>
    </Grid>
  );
};

export const SingleDetailGrid: React.FC<Omit<Props, 'title'>> = (props) => {
  const url = window.location.href.split('/').pop();
  const isForm = url === 'onboarding' ? 12 : 9;
  return (
    <Grid container {...props}>
      <Grid item xs={12} xl={isForm}>
        {props.children}
      </Grid>
    </Grid>
  );
};

export default Component;
