/* eslint-disable import/no-anonymous-default-export */

import {
  BalanceType,
  IBalanceState,
  IBalanceActions,
} from 'stores/types/balanceTypes';

const initialState = {
  balance: null,
  isLoading: false,
  error: null,
};

export default (
  state: IBalanceState = initialState,
  { type, payload }: IBalanceActions
) => {
  switch (type) {
    case BalanceType.SET_BALANCE_PENDING:
      return { ...state, ...payload, error: null, isLoading: true };
    case BalanceType.GET_BALANCE_SUCCESS:
      return { ...state, ...payload, isLoading: false };
    case BalanceType.SET_BALANCE_ERROR:
      return initialState;

    default:
      return state;
  }
};
