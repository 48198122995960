import React from 'react';
import { DetailGrid } from 'components/grid/DetailGrid';
import { GridProps } from '@mui/material';

type newFormType = { title: string; children: React.ReactNode } & GridProps;
export const GridDetailQRIS: React.FC<newFormType> = (props) => {
  return (
    <DetailGrid
      title={props.title}
      front={{ lg: 5, xl: 4 }}
      back={{ lg: 7, xl: 5 }}
      {...props}
    >
      {props.children}
    </DetailGrid>
  );
};

type Props = { children: React.ReactNode; noTitle: boolean; title?: string };
const SectionQRIS: React.FC<Props> = (props) => {
  return (
    <>
      {!props.noTitle ? (
        <p className="font-bold text-xl lg:text-[22px] my-3">{props.title}</p>
      ) : null}
      {props.children}
    </>
  );
};

export default SectionQRIS;
