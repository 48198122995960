import React from 'react';
import { Navigate } from 'react-router-dom';
import { IProtectRouteProps } from './interface';
import { isFullVersion } from 'utils/exception';
import { getCookie } from 'utils/cookie';

const Component: React.FC<IProtectRouteProps> = ({
  children,
  isAllowed,
  redirectPath = `${isFullVersion ? '/full/' : '/'}login`,
}) => {
  const token = getCookie('web-merchant');
  if (!isAllowed || token === undefined) {
    localStorage.clear();
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

export default Component;
