import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'stores';
import { Drawer } from '@mui/material';
import ChangePassword from './changePassword';
import MerchantProfile from './merchantProfile';
import UserSetting from './userSetting';
import { DrawerProp } from './interface';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { setDrawerContent } from 'stores/actions/utilsAction';

type DrawerBackProps = {
  title: string;
};

export const DrawerBack: React.FC<DrawerBackProps> = ({ title }) => {
  const dispatch = useDispatch();
  const backTo = () => {
    dispatch(setDrawerContent('user'));
  };

  return (
    <div className="flex">
      <KeyboardArrowLeft className="cursor-pointer" onClick={backTo} />
      <p className="font-bold pl-2">{title}</p>
    </div>
  );
};

const Component: React.FC<DrawerProp> = (props) => {
  const { open, onClose } = props;
  const { content } = useSelector((state: RootState) => state.utils);

  function switchDrawer(view: string) {
    switch (view) {
      case 'user':
        return <UserSetting />;
      case 'profile':
        return <MerchantProfile />;
      case 'password':
        return <ChangePassword />;
    }
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{ zIndex: '2500' }}>
      <div className="px-44" />
      {switchDrawer(content)}
    </Drawer>
  );
};

export default Component;
