import { AxiosError } from 'axios';

export enum SettingType {
  GET_SECURITY_PENDING = 'GET_SECURITY_PENDING',
  GET_URL_PENDING = 'GET_URL_PENDING',
  GET_MESSAGING_PENDING = 'GET_MESSAGING_PENDING',
  GET_IPSETTING_PENDING = 'GET_IPSETTING_PENDING',
  GET_SETTLEMENT_PENDING = 'GET_SETTLEMENT_PENDING',
  GENERETE_SECRET_PENDING = 'GENERETE_SECRET_PENDING',
  GENERETE_KEY_PENDING = 'GENERETE_KEY_PENDING',

  EDIT_SYSTEM_SETTING_PENDING = 'EDIT_SYSTEM_SETTING_PENDING',
  EDIT_SYSTEM_SETTING = 'EDIT_SYSTEM_SETTING',

  GET_DATA_SECURITY = 'GET_DATA_SECURITY',
  GET_DATA_URL = 'GET_DATA_URL',
  GET_DATA_MESSAGING = 'GET_DATA_MESSAGING',
  GET_DATA_IPSETTING = 'GET_DATA_IPSETTING',
  GET_DATA_SETTLEMENT = 'GET_DATA_SETTLEMENT',

  GENERETE_SECRET = 'GENERETE_SECRET',
  GENERETE_KEY = 'GENERETE_SECRET',

  GET_PAYMENT = 'GET_PAYMENT',
  GET_DATAMERCHANT = 'GET_DATAMERCHANT',
  SET_QOINVUEW_PENDING = 'SET_QOINVUEW_PENDING',

  SETTING_ERROR = 'SETTING_ERROR',
  SETTING_ERROR_GET = 'SETTING_ERROR_GET',
}

export interface ISettingState {
  systemSetting: ISystemSetting;
  qoinView: IQoinView;
  isLoading: ILoading;
  error: AxiosError;
}

interface IQoinView {
  payment: IPayment[];
  chanels: IChanels2[][];
  display_name: string;
  language: string;
  theme_pict: string;
}

interface IPayment {
  id: number;
  name: string;
  picture: {
    url: string;
  };
  page_id?: number;
  chanels: IChanels[];
}

export interface IChanels {
  id: number;
  name: string;
  picture: {
    url: string;
  };
  page_id: number;
  ie: number;
  status: number;
}

export interface IChanels2 {
  id: number;
  page_id: number;
  ie: number;
  status: number;
}

interface ILoading {
  securityKey: boolean;
  createOrderURL: boolean;
  messaging: boolean;
  iPWhitelist: boolean;
  settlement: boolean;
  generatKey: boolean;
  generatSecret: boolean;
  isLoading: boolean;
}

interface ISystemSetting {
  securityKey: ISecurityKey;
  createOrderURL: ICreateOrderURL;
  messaging: IMessaging;
  iPWhitelist: IIpWhoteList;
  settlement: ISettlement;
}

interface ISecurityKey {
  code: string;
  api_key: string;
  api_secret: string;
  api_public: string;
}

interface ICreateOrderURL {
  redirect_finish: string;
  callback_notif: string;
  deeplink_redirect: string;
}

interface IMessaging {
  finance_email: string;
  tech_email: string;
  email_to_customer: number;
  email_to_merchant: number;
  is_enable_email: number;
  email_support: string;
  is_enable_sms: number;
  is_enable_wa: number;
  is_enable_web_notif: number;
  is_enable_mobile: number;
  cs_phone: string;
}

interface IIpWhoteList {
  ip_public_dev: string;
  ip_public_staging: string;
  ip_public_prod: string;
  ip_private: string;
}

interface ISettlement {
  settlement_date: string;
  settlement_day: string;
  settlement_method: number;
  settlement_month: string;
  settlement_spec: string;
  settlement_time: string;
  settlement_type: string;
}

interface GetSecurityKey {
  type: SettingType.GET_DATA_SECURITY;
  payload: {
    securityKey: ISecurityKey;
  };
}

interface GetPayment {
  type: SettingType.GET_PAYMENT;
  payload: {
    payment: IPayment;
  };
}

interface GetMerchantData {
  type: SettingType.GET_DATAMERCHANT;
  payload: {
    display_name: IQoinView['display_name'];
    language: IQoinView['language'];
    theme_pict: IQoinView['theme_pict'];
  };
}

interface setQoinViewLoading {
  type: SettingType.SET_QOINVUEW_PENDING;
  payload?: any;
}

interface SetLoadingGetSecurityKey {
  type: SettingType.GET_SECURITY_PENDING;
  payload?: any;
}

interface GetCreateOrderURL {
  type: SettingType.GET_DATA_URL;
  payload: {
    createOrderURL: ICreateOrderURL;
  };
}
interface GetSettingSettlement {
  type: SettingType.GET_DATA_SETTLEMENT;
  payload: {
    settlement: ISettlement;
  };
}
interface setLoadingSettingSettlement {
  type: SettingType.GET_SETTLEMENT_PENDING;
  payload?: any;
}

interface SetLoadingGetCreateOrderURL {
  type: SettingType.GET_URL_PENDING;
  payload?: any;
}

interface GetMessaging {
  type: SettingType.GET_DATA_MESSAGING;
  payload: {
    messaging: IMessaging;
  };
}

interface SetLoadingGetMessaging {
  type: SettingType.GET_MESSAGING_PENDING;
  payload?: any;
}

interface GetIPWhitelist {
  type: SettingType.GET_DATA_IPSETTING;
  payload: {
    iPWhitelist: IIpWhoteList;
  };
}

interface SetLoadingGetIPWhitelist {
  type: SettingType.GET_IPSETTING_PENDING;
  payload?: any;
}

interface GenerateSecret {
  type: SettingType.GENERETE_SECRET;
  payload: {
    api_secret: ISecurityKey['api_secret'];
  };
}

interface SetLoadingGenerateSecret {
  type: SettingType.GENERETE_SECRET_PENDING;
  payload?: any;
}

interface GenerateKey {
  type: SettingType.GENERETE_KEY;
  payload: {
    api_key: ISecurityKey['api_key'];
  };
}

interface SetLoadingGenerateKey {
  type: SettingType.GENERETE_KEY_PENDING;
  payload?: any;
}

interface SetLoadingSubmitEditSystemSetting {
  type: SettingType.EDIT_SYSTEM_SETTING_PENDING;
  payload?: any;
}

interface SubmitEditSystemSetting {
  type: SettingType.EDIT_SYSTEM_SETTING;
  payload?: any;
}

interface SetErrorAction {
  type: SettingType.SETTING_ERROR;
  payload: {
    error: AxiosError;
  };
}

interface SetErrorGetAction {
  type: SettingType.SETTING_ERROR_GET;
  payload: {
    error: AxiosError;
  };
}

export type SettingActions =
  | GetSecurityKey
  | SetErrorGetAction
  | SubmitEditSystemSetting
  | GetSettingSettlement
  | setLoadingSettingSettlement
  | GenerateSecret
  | GenerateKey
  | SetLoadingSubmitEditSystemSetting
  | SetLoadingGenerateSecret
  | SetLoadingGenerateKey
  | SetLoadingGetSecurityKey
  | GetCreateOrderURL
  | SetLoadingGetCreateOrderURL
  | GetMessaging
  | SetLoadingGetMessaging
  | GetIPWhitelist
  | SetLoadingGetIPWhitelist
  | setQoinViewLoading
  | GetPayment
  | GetMerchantData
  | SetErrorAction;
