import { ThunkAction } from 'redux-thunk';
import { RootState } from 'stores';
import { IApiResponse } from 'stores/types/generalTypes';
import { IUtilsState, UtilsAction, UtilsType } from 'stores/types/utilsTypes';
import { errorGlobal } from './errorAction';
import provider, { IProvider } from 'provider';
import MethodProvider from 'provider/methods';
import { IError } from 'stores/types/errorTypes';
import endpoints from 'api/endpoints';
import axios from 'axios';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import config from 'constants/config';
import { setCookie } from 'utils/cookie';
import CryptoJS from 'crypto-js';
import { ClientJS } from 'clientjs';

export const setCheckboxPolicy = (): UtilsAction => ({
  type: UtilsType.SET_CHECKBOX_POLICY,
});

export const setIsSubmenu = (id: IUtilsState['id']): UtilsAction => ({
  type: UtilsType.SET_ISSUBMENU,
  payload: {
    id,
  },
});

export const setDrawerContent = (content: string) => async (dispatch) => {
  await dispatch({
    type: UtilsType.CHANGE_DRAWER_CONTENT,
    payload: content,
  });
};

export const getData = (
  params?: any,
  path?: any,
  callback?: (data: IApiResponse) => void
): ThunkAction<void, RootState, null, IError> => {
  return async (dispatch, getState) => {
    let sandbox = getState()?.auth?.userData['Env'];
    try {
      const objProvider: IProvider = {
        sandbox: sandbox,
        method: MethodProvider.GET,
        path: path,
        queryParams: params,
      };
      const response = await provider(objProvider);
      if (response?.data?.code === 200 || response?.status === 200) {
        callback(response?.data);
      }
    } catch (e) {
      dispatch(errorGlobal(e));
      callback(e);
    }
  };
};

export const getCaptcha = async () => {
  const captchaConfig = { height: 50, width: 180 };
  localStorage.clear();
  try {
    const objProvider: IProvider = {
      method: MethodProvider.POST,
      path: endpoints.captcha,
      data: captchaConfig,
    };
    const response = await provider(objProvider);
    if (response.data?.code === 202) return response.data.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      errorGlobal(err);
      toast.error(err.response.data?.['message']);
    } else console.log(err);
  }
};

// use Referer Control in extensions chrome and custom referer to http://qoinhub.id
export const generateXSRFToken = async (Sandbox: boolean) => {
  const url = !Sandbox
    ? config.API_URL_SANDBOX + 'v2/tok'
    : config.API_URL + 'v2/tok';
  let response = await axios.post(url);
  setCookie('xsrf-token', response?.data?.data?._tok);
};

export const handleGetIdmKey = (keyname: string, data: any, exps?: any) => {
  // to make sure if the data is a formdata
  let formData = {};
  if (data instanceof FormData) {
    data.forEach((val, key) => {
      // if the data is a File, normally will be format object, and in case its undefined will be null
      if (typeof val !== 'object' && val !== 'null') {
        formData[key] = val;
      }
    });

    // sorting the keys (in case while appending didn't sorted)
    let sortedKey = Object.keys(formData).sort();
    let sorted = sortedKey?.reduce((accumulator, key) => {
      accumulator[key] = formData[key];
      return accumulator;
    }, {});

    // re assign data as formdata after removal of file
    data = sorted;
  }

  const dataString = JSON.stringify(data);
  const dataHash = CryptoJS.MD5(dataString).toString();
  const savedIdmKey = Cookies.get(`idmKey-${keyname}`);
  const idmKey = dataHash;

  if (savedIdmKey) {
    return savedIdmKey;
  } else {
    const now = new Date();
    const expires = exps
      ? new Date(now.getTime() + Number(`${exps}000`))
      : new Date(now.getTime() + 12000); // 12 detik = 12000 milidetik

    Cookies.set(`idmKey-${keyname}`, idmKey.toString(), {
      expires,
    });

    return idmKey;
  }
};

export const getBrowserId = () => {
  const client = new ClientJS();
  const fingerprint = client.getFingerprint();

  return fingerprint;
};
