import { ThemeProvider } from '@emotion/react';
import { Checkbox } from '@mui/material';
import { PaginationTheme } from 'components/muiTheme';

const MessageDestination = ({
  isData,
  handleChecked,
  isEdit,
  isenable,
  isInvalid,
  handleChangeDataInput,
}) => {
  return (
    <>
      <div className="block lg:flex text-[#231F20] font-medium w-[80%] mb-3 items-start">
        <div className="w-full mb-3 lg:mb-0 lg:w-[48%]">
          Message Destination
        </div>
        <div className="w-[100%]">
          <ThemeProvider theme={PaginationTheme}>
            <div className="flex mb-4">
              <Checkbox
                color="primary"
                name="is_enable_mobile"
                checked={Boolean(Number(isData.is_enable_sms))}
                // disabled={
                //   isEdit === false ? !isEdit : !isenable?.is_enable_mobile
                // }
                disabled={true} // service blum ada
                onChange={handleChecked}
              />
              <div>
                <div className="font-semibold">Mobile SMS</div>
                <div className="text-[12px]">
                  Your merchant will receive message about every transaction via
                  SMS
                </div>
              </div>
            </div>
            <div className="flex mb-4">
              <Checkbox
                color="primary"
                name="is_enable_wa"
                checked={Boolean(Number(isData.is_enable_wa))}
                disabled={
                  isEdit === false ? !isEdit : !isenable?.is_enable_mobile
                }
                onChange={handleChecked}
              />
              <div>
                <div className="font-semibold">Whatsapp</div>
                <div className="text-[12px]">
                  Your merchant will receive message about every transaction via
                  Whatsapp
                </div>
              </div>
            </div>
          </ThemeProvider>
        </div>
      </div>
      <div
        className={`block lg:flex text-[#231F20] font-medium ${
          isEdit && isInvalid['cs_phone']
            ? 'w-full lg:w-[94%]'
            : 'w-full lg:w-[79%]'
        } mb-3 items-center`}
      >
        <div className="w-full mb-2 lg:mb-0 lg:w-[38%]">Phone Number</div>
        <input
          placeholder="Input phone number"
          defaultValue={isData.cs_phone}
          name="cs_phone"
          disabled={isEdit === false ? !isEdit : !isenable?.is_enable_mobile}
          onChange={handleChangeDataInput}
          value={isData['cs_phone']}
          className={`border-[1px] ${
            isenable?.is_enable_mobile && isEdit && isInvalid['cs_phone']
              ? 'border-[red] outline-[red]'
              : 'border-[#AFAFAF]'
          } w-full lg:w-[75%]  rounded-md py-2 text-start pl-3 px-51`}
        />
        {isEdit && isInvalid['cs_phone'] && (
          <div className="ml-5 w-[18%] text-[red]">
            {isenable?.is_enable_mobile && 'invalid value'}
          </div>
        )}
      </div>
    </>
  );
};

export default MessageDestination;
