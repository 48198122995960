import { ThemeProvider } from '@mui/material';
import CardComponents from 'components/cardComponents';
import { btnTheme2 } from 'components/muiTheme';
import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';

const SettingWrapper = ({
  children,
  titleBUtton,
  titleCard,
  color,
  disabled,
}: {
  children: any;
  titleBUtton: any;
  titleCard: any;
  color?: any;
  disabled?: any;
}) => {
  return (
    <div>
      <div className="text-2xl font-bold py-4">Settings</div>
      <CardComponents>
        <div className="py-4 px-8">
          <div className="border-b-[1px] text-lg mb-3 pb-3 mt-2 font-bold">
            {titleCard}
          </div>
          {children}
        </div>
      </CardComponents>

      <ThemeProvider theme={btnTheme2}>
        <LoadingButton
          variant="contained"
          color={color ? color : 'primary'}
          className="float-right"
          loading={false}
          sx={{ width: '180px', fontSize: '12px', p: 1.3, my: '20px' }}
          onClick={undefined}
          disabled={disabled}>
          {titleBUtton}
        </LoadingButton>
      </ThemeProvider>

      <br />
    </div>
  );
};

export default SettingWrapper;
