import { AxiosError } from 'axios';
import { ActivityData } from 'hooks/useActivityBrowser';

// Action Types
export enum RegisterType {
  SET_DATA_REGISTER = 'SET_DATA_REGISTER',
  SET_FORGOT_EMAIL = 'SET_FORGOT_EMAIL',
  SET_REGISTER_VALIDATE = 'SET_REGISTER_VALIDATE',
}

// IState Example
export interface IRegisterState {
  data: RegisterData;
  isLoading: boolean;
  error: AxiosError;
}

// IAction Example
interface GetRegisterAction {
  type: RegisterType.SET_DATA_REGISTER;
  payload: RegisterData;
}

interface GetForgotEmail {
  type: RegisterType.SET_FORGOT_EMAIL;
  payload: ForgotData;
}
interface GetValidationEmail {
  type: RegisterType.SET_REGISTER_VALIDATE;
  payload: string;
}

// IParams Example
export type RegisterData = {
  ClientId?: string;
  Fullname?: string;
  email: string;
  Status?: string;
  Username?: string;
  website?: string;
  instagram?: string;
  phone?: string;
  PackageType?: string;
  // businessBrand?: string; // a.k.a Fullname
  // name?: string; // a.k.a Username
  // email: string;
  // website?: string; // currently unused
  // instagram?: string; // currently unused
  // phone?: string;
};

export interface ForgotData {
  email: string;
}

export interface ResendMailData extends ActivityData {
  email: string;
}

// IResponse Example
export interface IRegisterResponse {
  code: number;
  message: string;
  data?: RegisterData;
}

export type RegisterAction =
  | GetRegisterAction
  | GetForgotEmail
  | GetValidationEmail;
