import SectionCard from 'components/cardComponents/sectionCard';
import { IOrderSummaryProps } from './interface';
import { Skeleton } from '@mui/material';

const getStatusColor = (status: string): string => {
  switch (status) {
    case 'Success':
      return '#18AD65';
    case 'On-Process':
      return '#F8C045';
    case 'Failed':
      return '#F04545';
    default:
      return '';
  }
};

const handleStatus = (data: string): string => {
  let text = data.toLocaleLowerCase();
  switch (true) {
    case text.includes('succeed'):
      return 'Success';
    case text.includes('open'):
      return 'On-Process';
    case text.includes('failed'):
      return 'Failed';
    default:
      return '';
  }
};

const FieldOrderSummary = ({ name, value, isLoading }) => {
  return (
    <div className="flex md:w-[40rem] text-[12px] lg:text-sm mt-1">
      <div className="w-[50%] lg:w-[12rem] font-bold">{name}</div>
      <div className={`w-[45%] lg:w-[55%]`}>
        {isLoading === true ? (
          <Skeleton variant="text" sx={{ width: 100, fontSize: '1rem' }} />
        ) : (
          <div
            className={`w-1/2 text-xs ${
              getStatusColor(value) ? 'font-extrabold' : ''
            }`}
            style={{ color: getStatusColor(value) }}
          >
            {value || '-'}
          </div>
        )}
      </div>
    </div>
  );
};

const OrderSummary: React.FC<IOrderSummaryProps> = ({ summary, isLoading }) => {
  return (
    <SectionCard title={'Order Summary'}>
      <FieldOrderSummary
        name={'Transaction Status'}
        value={handleStatus(summary.status)}
        {...{ isLoading }}
      />
      <FieldOrderSummary
        name={'Order ID'}
        value={summary.order_id}
        {...{ isLoading }}
      />
      <FieldOrderSummary
        name={'Transaction Number'}
        value={summary.trx_no}
        {...{ isLoading }}
      />
      <FieldOrderSummary
        name={'Reference Number'}
        value={summary.reference_no}
        {...{ isLoading }}
      />
      <FieldOrderSummary
        name={'Transaction Date'}
        value={summary.trx_date}
        {...{ isLoading }}
      />
    </SectionCard>
  );
};

export default OrderSummary;
