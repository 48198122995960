/* eslint-disable import/no-anonymous-default-export */
import update from 'immutability-helper';
import {
  ITicketingAction,
  ITicketingState,
  ITicketingType,
} from './../types/ticketingTypes';

const initialState = {
  data: {
    merchant_email: '',
    request_by_name: '',
    request_by_role: '',
    before: {},
    after: {},
  },
  donation: [],
  deactivated: {},
  isLoading: false,
  error: null,
};

export default (
  state: ITicketingState = initialState,
  { type, payload }: ITicketingAction
) => {
  switch (type) {
    case ITicketingType.TICKETING_PENDING:
      return update(state, { isLoading: { $set: true } });
    case ITicketingType.TICKETING_FAILED:
      return update(state, {
        isLoading: { $set: false },
        error: { $set: payload.error },
      });
    case ITicketingType.TICKETING_CHANGE_SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        data: { $set: payload.data },
      });

    case ITicketingType.GET_DONATION_SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        donation: { $set: payload },
      });
    case ITicketingType.GET_STATUS_DEACTIVATE:
      return update(state, {
        isLoading: { $set: false },
        deactivated: { $set: payload },
      });

    case ITicketingType.GET_DATA_PENDING:
      return update(state, { isLoading: { $set: true } });
    case ITicketingType.GET_DATA_FAILED:
      return update(state, {
        isLoading: { $set: false },
        error: { $set: payload.error },
      });
    default:
      return state;
  }
};
