// Save data to localStorage
export function setLocalItem(key: string, value: any): void {
  localStorage.setItem(key, JSON.stringify(value));
}

// Retrieve data from localStorage
export function getLocalItem(key: string): any {
  const item = localStorage.getItem(key);
  if (item !== null) {
    return JSON.parse(item);
  }
  return null;
}

// Remove data from localStorage
export function removeLocalItem(key: string): void {
  localStorage.removeItem(key);
}
