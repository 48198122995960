import React from 'react';
import { TextField } from '@mui/material';
import { Add } from '@mui/icons-material';
import { onlyNumbers } from 'utils/caseFormatter';
import { BasicButton } from 'components/Button';
import SectionQRIS, { GridDetailQRIS } from '../template';
import { SingleDetailGrid } from 'components/grid/DetailGrid/Component';
import { emailRegex, phoneRegex } from 'components/configs/regex';

export type terminal_content = {
  name: string;
  telp: string;
  email: string;
  term_id: string;
};
const terminal: Record<keyof terminal_content, string> = {
  name: 'Nama',
  telp: 'No. Handphone',
  email: 'Email',
  term_id: 'Terminal ID',
};

type Props = {
  terminals: {
    val: terminal_content[];
    set: (val: React.SetStateAction<terminal_content[]>) => void;
  };
  disabled?: boolean;
  more?: boolean;
};
const DaftarTerminal: React.FC<Props> = ({ terminals, disabled, more }) => {
  const url = window.location.href.split('/').pop();
  // handle creation of terminal
  const addTerminal = () => {
    terminals.set([
      ...terminals?.val,
      { name: '', telp: '', email: '', term_id: '' },
    ]);
  };

  // error & validation process
  const [errors, setErrors] = React.useState<terminal_content[]>([]);
  const validationField = (id: keyof terminal_content, val: string) => {
    const email = id === 'email' && !emailRegex.test(val);
    const telp = id === 'telp' && !phoneRegex.test(val);
    const cond: Record<keyof terminal_content, number> = {
      email: 50,
      name: 100,
      telp: 20,
      term_id: 6,
    };

    if (val.length === 0) return 'Data tidak boleh kosong';
    if (val.length > cond[id]) return `${terminal[id]} terlalu panjang`;
    if (telp || email) return `Format ${terminal[id]} tidak sesuai`;
  };

  const xChanges = (
    idx: number,
    field: keyof terminal_content,
    value: string
  ) => {
    const updated = [...terminals?.val]; // initiate default value
    updated[idx][field] = value; // add the new value to the array
    terminals.set(updated); // update the state
    const error = validationField(field, value); // get the error value
    setErrors((prev) => ({ ...prev, [field]: error })); // update error
  };

  return (
    <SectionQRIS
      noTitle={url !== 'onboarding' && url !== 'register-qris'}
      title="Daftar Terminal"
    >
      {terminals?.val?.length !== 0
        ? terminals?.val?.map((terms, tIdx) => (
            <div key={tIdx} className="border p-4 pb-0 my-4">
              {Object.keys(terminal)?.map((t: keyof typeof terminal) => (
                <GridDetailQRIS key={t} title={terminal[t]}>
                  <TextField
                    size="small"
                    fullWidth
                    value={terms[t]}
                    placeholder={`Masukkan ${terminal[t]}`}
                    onChange={(e) => xChanges(tIdx, t, e.target.value)}
                    onInput={t === 'telp' ? onlyNumbers : null}
                    disabled={disabled}
                    className={`${disabled ? 'bg-[#F0F0F0]' : ''}`}
                  />
                  {errors[t] ? (
                    <b className="text-red-500">{errors[t]}</b>
                  ) : null}
                </GridDetailQRIS>
              ))}
            </div>
          ))
        : null}
      {/* {disabled || url === 'onboarding' || url === 'register-qris' ? null : ( */}
      {/* hanya muncul jika disabled={false} atau more={true} */}
      {disabled || !more ? null : (
        <SingleDetailGrid>
          <BasicButton
            variant="outlined"
            sx={{ border: 'dashed 1.5px', mb: 2 }}
            className="w-full"
            startIcon={<Add />}
            onClick={addTerminal}
          >
            Add more terminal
          </BasicButton>
        </SingleDetailGrid>
      )}
    </SectionQRIS>
  );
};

export default DaftarTerminal;
