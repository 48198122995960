import { useContext } from 'react';
import useReport from 'hooks/useTransaction';
import { TransactionContext } from 'context/transactionContext';
import SelectCheckbox from 'components/filter/selectCheckbox';
import DateTime from 'components/filter/dateTime';
// import SelectCustom from 'components/filter/select';
import Search from 'components/filter/search';

const FilterPopover = ({ id }) => {
  const { filter_params } = useReport();
  let { options } = filter_params?.status;

  const {
    handleStatus,
    // isTransaction,
    filterSearch,
    isCheckedstatuses,
    setDateRange,
    startDate,
    endDate,
    handleChangeSearchFilter,
  } = useContext(TransactionContext);

  const filterComponent = (() => {
    switch (id) {
      case 'created':
        return <DateTime {...{ setDateRange, startDate, endDate }} />;
      case 'no':
        return (
          <Search
            fieldName='transaction_no'
            label='Search Transaction No.'
            handleChangeV2={handleChangeSearchFilter}
            value={filterSearch.transaction_no}
            type='string'
          />
        );
      case 'rrn':
        return (
          <Search
            fieldName='rrn'
            label='Search RRN No.'
            handleChangeV2={handleChangeSearchFilter}
            value={filterSearch.rrn}
            type='string'
          />
        );
      case 'ref_no':
        return (
          <Search
            fieldName='reference_no'
            label='Search Reference No.'
            handleChangeV2={handleChangeSearchFilter}
            value={filterSearch.reference_no}
            type='string'
          />
        );
      case 'issuer_name':
        return (
          <Search
            fieldName='issuer'
            label='Search Issuer.'
            handleChangeV2={handleChangeSearchFilter}
            value={filterSearch.issuer}
            type='string'
          />
        );
      case 'amount':
        return (
          <Search
            fieldName='trx_amount'
            label='Search Trx Amount.'
            handleChangeV2={handleChangeSearchFilter}
            value={filterSearch.trx_amount}
            type='number'
          />
        );
      case 'mdr_fee':
        return (
          <Search
            fieldName='mdr'
            label='Search MDR'
            handleChangeV2={handleChangeSearchFilter}
            value={filterSearch.mdr}
            type='number'
          />
        );
      case 'merchant_name':
        return (
          <Search
            fieldName='merchant_name'
            label='Search merchant Name.'
            handleChangeV2={handleChangeSearchFilter}
            value={filterSearch.merchant_name}
            type='string'
          />
        );
      // case 'channel_name':
      //   return (
      //     <SelectCustom data={channels} {...{ isChannel, setIsChannel }} />
      //   );
      // case 'settlement_due':
      //   return (
      //     <DateTime
      //       setDateRange={setDueDateRange}
      //       startDate={start_settle_due_date}
      //       endDate={end_settle_due_date}
      //     />
      //   );
      // case 'customer_email':
      //   return (
      //     <Search
      //       label="Search Customer Email"
      //       handleChange={setIsEmail}
      //       value={isEmail}
      //     />
      //   );
      case 'status_text':
        return (
          <SelectCheckbox
            data={options}
            value={isCheckedstatuses}
            handleChange={handleStatus}
            from='trans status'
          />
        );
      // case 'state':
      //   return (
      //     <SelectCheckbox
      //       data={states}
      //       value={isCheckedState}
      //       handleChange={handleState}
      //       from="state status"
      //     />
      //   );
      default:
        return null;
    }
  })();

  return <div className='p-2'>{filterComponent}</div>;
};

export default FilterPopover;
