import {
  ClickAwayListener,
  Fade,
  List,
  Paper,
  Popper,
  PopperPlacementType,
  Stack,
  Typography,
  Zoom,
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';

import appRoutes from 'routes/appRoutes';
import appRoutesFull from 'routes/appRoutesFull';
import SidebarItem from './SidebarItem';
import SidebarItemCollapse from './SidebarItemCollapse';
import Alert from 'components/alert';

import { IoEyeSharp } from 'react-icons/io5';
import { BsEyeSlash } from 'react-icons/bs';
import { icons } from 'assets';
import { Skeleton } from '@mui/material';

import { useEffect, useState } from 'react';

import useBalance from 'hooks/useBalance';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import { RootState } from 'stores';
// import { getLocalItem } from 'utils/localStorage';
// import jwtDecode from 'jwt-decode';
import useSandbox from 'hooks/useSandbox';
import { IOSSwitch } from 'components/muiTheme/muiStyled';
import { isFullVersion } from 'utils/exception';
import useRedirectOut from 'hooks/useRedirectOut';
import { permissionFunction } from 'utils/permission';
import permission from 'constants/permissionRole';

const Sidebar = ({ isOpen, setisOpen, mouseOver, setmouseOver }) => {
  const [isSaldo, setisSaldo] = useState(true);
  const [open, setOpen] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [severty, setSeverty] = useState('success');
  const [message, setMessage] = useState('');
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [placement, setPlacement] = useState<PopperPlacementType>();
  let { balance, getBalance, Env } = useBalance();
  // // eslint-disable-next-line
  // const [packageType, setPackageType] = useState(0);

  const userData = useSelector((state: RootState) => state.auth.userData);

  let { handleSwitch, profile } = useSandbox();
  let { handleRedirect } = useRedirectOut();
  let sandbox = useSandbox()?.userData['Env'];

  let RouterSide = !isFullVersion ? appRoutes : appRoutesFull;

  const handleClick =
    (newPlacement: PopperPlacementType) =>
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };

  const handleAlert = (name: string, msg: string) => {
    setIsAlert(true);
    setSeverty(name);
    setMessage(msg);
  };

  useEffect(() => {
    getBalance({ owner_type: 'mrc' }, (response: any) => {
      if (response?.code !== 200) {
        // handleAlert('error', 'get Balance Failed');
      }
    });
  }, [getBalance, sandbox]);

  // useEffect(() => {
  //   const jwt = getLocalItem('token-web-merchant');
  //   setPackageType(jwtDecode(jwt)['PackageType']);
  // }, [userData]);

  const handleMove = () => {
    handleRedirect(userData, handleAlert);
  };

  const handeleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleSwitch(!event.target.checked);
  };

  return (
    <div
      className={`relative h-screen  ${
        userData?.Env === false
          ? 'bg-[#EAEAEA] text-[#006A45]'
          : 'bg-[#131313] text-[#FFFFFF]'
      } `}
      onMouseEnter={() => !isOpen && setisOpen(true)}
      onMouseLeave={() => !isOpen && setmouseOver(false)}
    >
      <Alert
        severty={severty}
        message={message}
        open={isAlert}
        setIsAlert={setIsAlert}
      />
      <Popper
        open={open}
        anchorEl={anchorEl}
        style={{ zIndex: 10000 }}
        placement={placement}
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={() => {
              if (open) setOpen(!open);
            }}
          >
            <Zoom in={open}>
              <Fade
                {...TransitionProps}
                timeout={10}
                className={`${
                  userData.PackageType === 3 && 'cursor-pointer'
                } mt-1 ml-[10px]`}
                style={{
                  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
                }}
              >
                <Paper>
                  <Typography sx={{ p: 2, fontWeight: '500' }}>
                    <button
                      onClick={handleMove}
                      className={` ${
                        userData.PackageType !== 3
                          ? 'text-gray-400'
                          : 'cursor-pointer'
                      } `}
                      disabled={userData.PackageType !== 3}
                    >
                      Open merchant balance
                    </button>
                  </Typography>
                </Paper>
              </Fade>
            </Zoom>
          </ClickAwayListener>
        )}
      </Popper>

      <Stack
        sx={{ width: '100%' }}
        direction="row"
        className={`${isOpen ? 'my-4 flex justify-center' : 'mt-2'}`}
      >
        <img
          width={170}
          src={
            !sandbox
              ? isOpen
                ? icons.plus
                : icons.plusmini
              : isOpen
              ? icons.plus2
              : icons.plusMini2
          }
          alt="logo"
        />
      </Stack>
      <Stack
        sx={{ width: '88%' }}
        direction="row"
        className={`mb-5 ${isOpen ? 'ml-4 mt-2 mb-2' : 'mt-2 ml-1'}`}
      >
        <div
          style={{
            backgroundColor:
              userData?.Env === false ? '#F5F5F5' : 'rgba(255, 255, 255, 0.1)',
          }}
          className={`w-full bg-['rgba(255, 255, 255, 0.1)'] text-xs text-center  rounded-lg  ${
            isOpen
              ? ' grid grid-cols-6 h-[90px] gap-4 px-1 py-4 '
              : 'h-16 flex flex-col justify-between py-2'
          }`}
        >
          <div
            className={`col-start-1 col-end-3 px-3 flex w-14 justify-between text-[16px] items-center ${
              !isOpen && 'pl-1.5 pt-1'
            }`}
          >
            {isFullVersion ? 'Saldo' : 'Earnings'}
            {isOpen && (
              <div
                className="cursor-pointer ml-3"
                onClick={() => setisSaldo(!isSaldo)}
              >
                {isSaldo ? <BsEyeSlash /> : <IoEyeSharp />}
              </div>
            )}
          </div>
          {isOpen && (
            <div
              className={`col-end-9 col-span-2 ${
                isFullVersion && 'cursor-pointer'
              } `}
              onClick={handleClick('right')}
            >
              {isFullVersion && <img src={icons.titik3} alt="menu" />}
            </div>
          )}

          {isOpen ? (
            <div className="pl-2 text-[16px] ml-2 font-bold">
              {isSaldo ? (
                balance.isLoading ? (
                  <div className="w-[200px]">
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: '100rem', width: '100%' }}
                    />
                  </div>
                ) : (
                  <>
                    {permissionFunction(
                      isFullVersion
                        ? permission[Env].GET_SALDO_BALANCE
                        : permission[Env].GET_SALDO_EARNING
                    ) ? (
                      <NumericFormat
                        value={balance?.balance || 0}
                        displayType={'text'}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        prefix={'Rp. '}
                      />
                    ) : (
                      <div className="text-gray-400">
                        No Permission to Get{' '}
                        {isFullVersion ? 'Saldo' : 'Earnings'}
                      </div>
                    )}
                  </>
                )
              ) : (
                '**********'
              )}
            </div>
          ) : (
            <div>*******</div>
          )}
        </div>
      </Stack>

      <div className="max-h-[60%] overflow-scroll">
        <List disablePadding>
          {RouterSide.map((route, index) =>
            route.sidebarProps ? (
              route.child ? (
                <SidebarItemCollapse
                  item={route}
                  key={index}
                  {...{
                    isOpen,
                    setisOpen,
                    mouseOver,
                  }}
                />
              ) : (
                <SidebarItem
                  item={route}
                  key={index}
                  {...{ isOpen, setisOpen, mouseOver }}
                />
              )
            ) : null
          )}
        </List>
      </div>

      <div
        className={`flex items-center justify-between  ${
          sandbox ? 'bg-[#131313]' : 'bg-[#EAEAEA] '
        }  absolute bottom-1 pl-5  w-[100%]`}
      >
        {isOpen && (
          <div className="text-lg ">
            Switch to {userData?.Env ? 'Sandbox' : 'Production'}
          </div>
        )}
        <div className={`${!isOpen && '-ml-2'}`}>
          <FormControlLabel
            control={
              <IOSSwitch
                sx={{ m: 1 }}
                defaultChecked={!userData?.Env}
                disabled={
                  profile.MerchantStatus !== 'approved for production' &&
                  profile.MerchantStatus !== 'in review ticketing'
                    ? true
                    : false
                }
                onChange={handeleSwitch}
              />
            }
            label=""
          />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
