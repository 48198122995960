type SVGIcon = {
  color: string;
};
const EditPen: React.FC<SVGIcon> = ({ color }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4923 1.83241C16.2723 1.83241 16.0544 1.87574 15.8512 1.95993C15.6479 2.04412 15.4632 2.16752 15.3077 2.32309L3.11054 14.5202L2.22209 17.7778L5.47973 16.8894L17.6769 4.69228C17.8324 4.53671 17.9558 4.35203 18.04 4.14878C18.1242 3.94553 18.1675 3.72768 18.1675 3.50768C18.1675 3.28768 18.1242 3.06983 18.04 2.86658C17.9558 2.66333 17.8324 2.47865 17.6769 2.32309C17.5213 2.16752 17.3366 2.04412 17.1334 1.95993C16.9301 1.87574 16.7123 1.83241 16.4923 1.83241ZM15.1499 0.26695C15.5755 0.0906691 16.0316 -6.10352e-05 16.4923 -6.10352e-05C16.9529 -6.10352e-05 17.409 0.0906692 17.8346 0.26695C18.2602 0.44323 18.6469 0.701608 18.9726 1.02733C19.2983 1.35306 19.5567 1.73975 19.733 2.16533C19.9093 2.59091 20 3.04704 20 3.50768C20 3.96832 19.9093 4.42445 19.733 4.85003C19.5567 5.27561 19.2983 5.6623 18.9726 5.98803L6.60343 18.3572C6.49068 18.47 6.35046 18.5513 6.19663 18.5933L1.15733 19.9676C0.840122 20.0541 0.500875 19.9641 0.26838 19.7316C0.0358855 19.4991 -0.0542064 19.1598 0.0323059 18.8426L1.40666 13.8033C1.44861 13.6495 1.52999 13.5093 1.64273 13.3965L14.0119 1.02733C14.3376 0.701608 14.7243 0.44323 15.1499 0.26695Z"
      fill={color}
    />
  </svg>
);
export default EditPen;
