import React from 'react';

interface ISearch {
  label: string;
  handleChange?: (value: string) => void;
  value: string;
  type?: 'string' | 'number';
  handleChangeV2?: (field: string, value: string) => void;
  fieldName?: string;
}

const Search = ({
  label,
  handleChange,
  value,
  type,
  handleChangeV2,
  fieldName,
}: ISearch) => {
  const validateInput = (input: string) => {
    const alphanumericRegex = /^[a-zA-Z0-9\s@.]*$/;
    return alphanumericRegex.test(input);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const field = e.target.id;

    if (handleChangeV2) {
      if (type === 'string' && validateInput(inputValue)) {
        handleChangeV2(field, inputValue);
      } else if (type === 'number') {
        if (!isNaN(Number(inputValue))) {
          handleChangeV2(field, inputValue === '' ? '' : inputValue);
        }
      }
    } else {
      validateInput(inputValue) && handleChange && handleChange(inputValue);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Mencegah perilaku default saat tombol Enter ditekan
    if (e.key === 'Enter') {
      e.preventDefault(); // Mencegah form submit
    }
  };

  return (
    <form className='p-1 px-2'>
      <input
        id={fieldName}
        className='px-3 w-full py-2 border-[1px] rounded-md outline-none border-[#94A3B8]'
        type='search'
        placeholder={label}
        name='search'
        value={value}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown} // Tambahkan event untuk menangani penekanan tombol
      />
    </form>
  );
};

export default Search;
