import { AxiosError } from 'axios';
import endpoints from 'api/endpoints';
import {
  BizUpdate,
  MerchantPreviewData,
  NewMerchantPreviewData,
  OnboardingAction,
  OnboardingType,
  QRISFormData,
  RekeningUpdate,
  new_pay,
  qrisDetailData,
} from 'stores/types/onboardingTypes';
import provider, { IProvider } from 'provider';
import MutationTypes from 'provider/methods';
import { handleError } from './errorAction';
import { isFullVersion } from 'utils/exception';
import { removeCookie } from 'utils/cookie';

const routePath = isFullVersion ? '/full/' : '/';
type res_status = { code: number; status: string };
type AP<T> = Awaited<Promise<T>>; // just to shorten Awaited Promise
export const pilihTipeUsaha = (tipeUsaha: string) => async (dispatch) => {
  await dispatch({
    type: OnboardingType.TIPE_USAHA,
    payload: tipeUsaha,
  });
};

export const pilihTipeProduk = (tipeProduk: string) => async (dispatch) => {
  await dispatch({
    type: OnboardingType.TIPE_PRODUK,
    payload: tipeProduk,
  });
};

export const setStep = (step: number) => async (dispatch) => {
  await dispatch({
    type: OnboardingType.SET_STEP,
    payload: step,
  });
  window.scrollTo(0, 0);
};

export const setMetodePembayaran = (metode) => async (dispatch) => {
  await dispatch({
    type: OnboardingType.SET_METODE_PEMBAYARAN,
    payload: metode,
  });
};

export const setSelectedPaymentChannel =
  (selectedChannel) => async (dispatch) => {
    await dispatch({
      type: OnboardingType.SET_PAYMENT_CHANNEL_SELECTED,
      payload: selectedChannel,
    });
  };

export const setPaymentMethodSelected =
  (selectedMethod) => async (dispatch) => {
    await dispatch({
      type: OnboardingType.SET_PAYMENT_METHOD_SELECTED,
      payload: selectedMethod,
    });
  };

export const getPaymentMethod = () => async (dispatch) => {
  dispatch({ type: OnboardingType.GET_METODE_PEMBAYARAN_PENDING });
  try {
    const objProvider: IProvider = {
      method: MutationTypes.GET,
      path: endpoints.production.paymentMethod,
    };
    const response = await provider(objProvider);
    if (!response) new Error('Unable to get payment methods');
    if (response.data?.code === 200) {
      dispatch({
        type: OnboardingType.GET_METODE_PEMBAYARAN_SUCCESS,
        payload: response.data.data,
      });
    }
  } catch (e) {
    handleError(e, true);
  }
};

export const getBizType = () => async (dispatch) => {
  dispatch({ type: OnboardingType.GET_BIZ_TYPE_PENDING });
  try {
    const objProvider: IProvider = {
      method: MutationTypes.GET,
      path: endpoints.production.bizTypeCategory,
    };
    const response = await provider(objProvider);
    if (!response) new Error('Unable to get type & category business');
    if (response.data.code === 200) {
      dispatch({
        type: OnboardingType.GET_BIZ_TYPE_SUCCESS,
        payload: response.data.data,
      });
    }
  } catch (e) {
    let message = handleError(e, true);
    dispatch({
      type: OnboardingType.GET_BIZ_TYPE_ERROR,
      payload: message,
    });
  }
};

export const getListBank = () => async (dispatch) => {
  dispatch({ type: OnboardingType.GET_LIST_BANK_PENDING });
  try {
    const objProvider: IProvider = {
      method: MutationTypes.GET,
      path: endpoints.getListBank,
    };
    const response = await provider(objProvider);
    if (!response) new Error('Unable to get List Bank');
    if (response.status === 200) {
      dispatch({
        type: OnboardingType.GET_LIST_BANK_SUCCESS,
        payload: response.data.data,
      });
    }
  } catch (e) {
    let message = handleError(e, true);
    dispatch({
      type: OnboardingType.GET_LIST_BANK_ERROR,
      payload: message,
    });
  }
};

export const setInformasiBisnis = (data) => async (dispatch) => {
  await dispatch({
    type: OnboardingType.SET_INFORMASI_BISNIS,
    payload: data,
  });
};

export const setIsDeveloper = (IsDeveloper: boolean) => async (dispatch) => {
  await dispatch({
    type: OnboardingType.SET_IS_DEV,
    payload: IsDeveloper,
  });
};

export const setInformasiBisnisFotoKTP = (data) => async (dispatch) => {
  await dispatch({
    type: OnboardingType.SET_INFORMASI_FOTO_KTP,
    payload: data,
  });
};

export const setUploadFileKTP = (data) => async (dispatch) => {
  await dispatch({
    type: OnboardingType.UPLOAD_KTP,
    payload: data,
  });
};

export const setUploadFileNPWP = (data) => async (dispatch) => {
  await dispatch({
    type: OnboardingType.UPLOAD_NPWP,
    payload: data,
  });
};

export const setInformasiBisnisFotoNPWP = (data) => async (dispatch) => {
  await dispatch({
    type: OnboardingType.SET_INFORMASI_FOTO_NPWP,
    payload: data,
  });
};

export const setDetailBank = (data) => async (dispatch) => {
  await dispatch({
    type: OnboardingType.SET_DETAIL_BANK,
    payload: data,
  });
};

export const submitOnboarding = (data: FormData, payout?: boolean) => {
  return async (dispatch) => {
    dispatch({ type: OnboardingType.SUBMIT_ONBOARD_LOADING, payload: true });
    try {
      const objProvider: IProvider = {
        method: MutationTypes.POST,
        path: endpoints.production.onboarding,
        data: data,
      };
      type data_status = res_status & {
        data?: { ticket_id: string };
      };
      const response = await provider(objProvider);
      if (!response) throw new Error('Network Error');
      if (response.data) {
        const data: AP<data_status> = response.data;
        if (data.code === 200) {
          await getOnboardProcessTicket(data.data.ticket_id, payout, dispatch);
        } else {
          dispatch({
            type: OnboardingType.SUBMIT_ONBOARD_LOADING,
            payload: false,
          });
          throw new Error(data.status);
        }
      }
    } catch (e) {
      handleError(e, true);
      dispatch({ type: OnboardingType.SUBMIT_ONBOARD_LOADING, payload: false });
    }
  };
};

export const getOnboarding = () => {
  // changed to get merchant preview
  return async (dispatch) => {
    dispatch({ type: OnboardingType.MERCHANT_PREVIEW_PENDING });
    try {
      const objProvider: IProvider = {
        method: MutationTypes.GET,
        path: endpoints.production.onboarding + 'pg',
      };
      const response = await provider(objProvider);
      if (!response) new Error('Unable to get onboarding data');
      if (response.data?.code === 200) {
        dispatch({
          type: OnboardingType.MERCHANT_PREVIEW_SUCCESS,
          payload: response.data.data,
        });
      }
    } catch (e) {
      let message = handleError(e, true);
      console.log(message);
    }
  };
};

export const getMerchantPreview = () => {
  return async (dispatch) => {
    const { MERCHANT_PREVIEW_ERROR, MERCHANT_PREVIEW_SUCCESS } = OnboardingType;
    dispatch({ type: OnboardingType.MERCHANT_PREVIEW_PENDING });
    try {
      const objProvider: IProvider = {
        method: MutationTypes.GET,
        path: endpoints.production.merchantPreview,
      };
      const response = await provider(objProvider);
      if (!response) throw new Error('Network Error');
      if (response.data.code === 200) {
        const data: AP<MerchantPreviewData> = response.data.data;

        /* I tried to reconstruct format of payments to only array of number, similar to when I submit onboarding here */
        const payment = response.data?.data?.payments;
        const payObj = payment?.payment_methods?.flatMap((x) => x);
        const mcd = payment?.merchant_code;

        if (payObj) {
          let isQRIS = payObj.find((x) => x.name === 'QRIS');

          // check if there is the previous merchant that already have QRIS selected, to be filtered new format
          let filtered = payObj;
          if (isQRIS) filtered = payObj.filter((x) => x.name !== 'QRIS');
          const list_pay: AP<new_pay[]> = filtered.flatMap(
            (x) => x?.payment_channels
          );

          const pays = list_pay.map((x) => x.id?.toString());
          if (isQRIS) pays.push('QR'); // added QRIS if the QRIS selected

          // reassign the payment
          if (pays) Object.assign(data, { payments: pays });
        } else Object.assign(data, { payments: [] }); // handle empty payment
        Object.assign(data, { merchant_code: mcd });
        dispatch({ type: MERCHANT_PREVIEW_SUCCESS, payload: data });
      } else throw new Error(response);
    } catch (e) {
      let msg = handleError(e, true);
      dispatch({ type: MERCHANT_PREVIEW_ERROR, payload: msg });
    }
  };
};

export const getNewMerchantPreview = () => {
  return async (dispatch) => {
    const { MERCHANT_PREVIEW_ERROR, NEW_PREVIEW_SUCCESS } = OnboardingType;
    dispatch({ type: OnboardingType.MERCHANT_PREVIEW_PENDING });
    try {
      const objProvider: IProvider = {
        method: MutationTypes.GET,
        path: endpoints.production.merchantPreview,
      };
      const response = await provider(objProvider);
      if (!response) throw new Error('Network Error');
      if (response.data.code === 200) {
        const data: AP<NewMerchantPreviewData> = response.data?.data;
        /* I tried to reconstruct format of payments to only array of number, similar to when I submit onboarding here */
        const pay = response.data?.data?.payments;
        const payObj = pay?.payments?.flatMap((x) => x);
        const etc = pay?.merchant_code;

        if (payObj) {
          Object.assign(data, { payments: payObj, merchant_code: etc });
        }
        // if (payObj) Object.assign(data, { payments: pay, merchant_code: etc });
        dispatch({ type: NEW_PREVIEW_SUCCESS, payload: data });
      }
    } catch (e) {
      let message = handleError(e, true);
      dispatch({ type: MERCHANT_PREVIEW_ERROR, payload: message });
    }
  };
};
export const ErrorGetOnboarding = (error: AxiosError): OnboardingAction => ({
  type: OnboardingType.MERCHANT_PREVIEW_ERROR,
  payload: { error },
});

export const setformQRIS = (payload: QRISFormData) => {
  return async (dispatch) =>
    await dispatch({ type: OnboardingType.SET_QRIS_FORM, payload: payload });
};

export const getOnboardProcessTicket = async (
  ticket: string,
  payout?: boolean,
  callback?: any
) => {
  try {
    const objProvider: IProvider = {
      method: MutationTypes.GET,
      path: endpoints.production.onboarding + `/${ticket}`,
    };
    const response = await provider(objProvider);
    if (!response) throw new Error('Network Error');
    if (response.data) {
      let data: Awaited<Promise<res_status>> = response.data;
      if (data) {
        // if success redirected to specified page
        if (data.code === 200) {
          const url = !payout
            ? `${routePath}berhasil-bergabung`
            : `${routePath}berhasil-bergabung/payout`;
          window.location.replace(url);
          removeCookie('tips-qr');
        }
        // if its on-process, try to hit again with some interval timeout
        if (data.code === 202) {
          setTimeout(
            () => getOnboardProcessTicket(ticket, payout, callback),
            3000
          );
        } else throw new Error(data.status);
      }
    }
  } catch (e) {
    handleError(e);
    // console.log('error: ', msg);
    callback({ type: OnboardingType.SUBMIT_ONBOARD_LOADING, payload: false });
  }
};

export const getDetailQRIS = () => {
  return async (dispatch) => {
    try {
      const objProvider: IProvider = {
        method: MutationTypes.GET,
        path: endpoints.merchantQris,
      };
      const response = await provider(objProvider);
      if (!response) throw new Error('Network Error');
      if (response.data) {
        type res = AP<res_status & { data?: qrisDetailData }>;
        const result: res = response.data;
        if (result.code === 200) {
          // if success, save the data to redux
          dispatch({
            type: OnboardingType.GET_DETAIL_QRIS,
            payload: result.data,
          });
          // and make the status qris true
          dispatch({ type: OnboardingType.CHECK_STATUS_QRIS, payload: true });
        } else throw new Error(result.status);
      }
    } catch (e) {
      handleError(e);
      // if (msg !== undefined) console.log('error: ', msg);
      dispatch({ type: OnboardingType.CHECK_STATUS_QRIS, payload: false });
    }
  };
};

export const submitRegisterQRIS = (data: FormData) => {
  return async (dispatch) => {
    try {
      const objProvider: IProvider = {
        path: endpoints.merchantQris,
        method: MutationTypes.POST,
        data: data,
      };
      const response = await provider(objProvider);
      if (!response) throw new Error('Network Error');
      if (response.data) {
        type res = AP<res_status & { data?: { ticket_id: string } }>;
        const data: res = response.data;
        if (data.code === 200) {
          // if success, hit func to verify the ticket
          await getMqTicket(data.data.ticket_id, dispatch);
          // console.log('reg-qris', data);
        }
      } else throw new Error('Something went wrong');
    } catch (e) {
      let msg = handleError(e);
      console.log('error: ', msg);
    }
  };
};

export const getMqTicket = async (ticket: string, callback?: any) => {
  try {
    const objProvider: IProvider = {
      method: MutationTypes.GET,
      path: endpoints.merchantQris + `/async/${ticket}`,
    };
    const response = await provider(objProvider);
    if (!response) throw new Error('Network Error');
    if (response.data) {
      type res = AP<res_status & { data?: qrisDetailData }>;
      const result: res = response.data;
      if (result.code === 200) {
        // if the verification success, save the data to redux
        callback({
          type: OnboardingType.GET_DETAIL_QRIS,
          payload: result.data,
        });
        // console.log('get-mq', result.data);
        // setTimeout(()=>{
        //   window.location.replace('/setting/Merchant-Preview');
        // },3000)
      }
      // if on-process try again, until success or failed
      if (result.code === 202) {
        setTimeout(() => getMqTicket(ticket, callback), 3000);
      } else throw new Error(result.status);
    } else throw new Error('Something went wrong');
  } catch (e) {
    handleError(e);
    // if (msg !== undefined) console.log('error: ', msg);
  }
};

export const setUpdateInfoBiz =
  (data: Partial<BizUpdate>) => async (dispatch) => {
    await dispatch({
      type: OnboardingType.SET_INFORMASI_BISNIS_UPDATE,
      payload: data,
    });
  };
export const setUpdateInfoRek = (data: RekeningUpdate) => async (dispatch) => {
  await dispatch({ type: OnboardingType.SET_REKENING_BANK, payload: data });
};
