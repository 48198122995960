import SectionCard from "components/cardComponents/sectionCard";
import { ICustomerDetail } from "./interface";
import LoadingWrapper from "components/loadingWrapper";

const CustomerDetail: React.FC<ICustomerDetail> = ({
  custommer,
  isLoading,
}) => {
  const customerDetails = [
    { name: "Name", value: custommer.name },
    { name: "Phone", value: custommer.phone },
    { name: "Email", value: custommer.email },
  ];

  return (
    <SectionCard title="Customer Detail">
      {customerDetails.map(({ name, value }) => (
        <LoadingWrapper isLoading={isLoading} fontSize="1.5rem">
          <div key={name} className="flex w-full text-sm mt-1">
            <div className="w-1/4 lg:w-1/5 font-bold">{name}</div>
            <div>{value || "-"}</div>
          </div>
        </LoadingWrapper>
      ))}
    </SectionCard>
  );
};

export default CustomerDetail;
