import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'stores';
import {
  ActionGetDetail,
  ActionGetFilterTL_Report,
  ActionGetTransactionList_Report,
} from 'stores/actions/transactionActions';
import { permissionFunction } from 'utils/permission';
import permission from 'constants/permissionRole';

const useTransaction = () => {
  const dispatch = useDispatch();
  const TransactionListState = useSelector(
    (state: RootState) => state.transactionList.TransactionListReport
  );
  const authState = useSelector((state: RootState) => state.auth.userData);
  const Env = authState['Env'] ? 'PRODUCTION' : 'SANDBOX';

  const handleGetTransactionList = useCallback(
    (params?: any, callback?: (data: any) => void) => {
      permissionFunction(permission[Env].LIST_TRANSACTION) &&
        dispatch(ActionGetTransactionList_Report(params, callback));
    },
    [Env, dispatch]
  );

  const handleGetfilter = useCallback(
    (callback?: (data: any) => void) => {
      permissionFunction(permission[Env].LIST_TRANSACTION) &&
        dispatch(ActionGetFilterTL_Report(callback));
    }, // eslint-disable-next-line
    [dispatch]
  );

  const handleGetDetail = useCallback(
    (id: string, callback?: (data: any) => void) => {
      permissionFunction(permission[Env].GET_TRANSACTION) &&
        dispatch(ActionGetDetail(id, callback));
    },
    [Env, dispatch]
  );

  return {
    TransactionList: TransactionListState,
    dataFilter: TransactionListState.dataFilter,
    filter_params: TransactionListState.filter_params,
    getTransactionList: handleGetTransactionList,
    getFilter: handleGetfilter,
    getDetail: handleGetDetail,
  };
};

export default useTransaction;
