import { Popover } from '@mui/material';
import { BasicButton } from 'components/Button';
import React from 'react';

interface IContainerFilter {
  handleClose: any;
  anchorEl: any;
  children: any;
  top?: string;
  handleReset: any;
  handleSubmit: any;
}

const ContainerFilter: React.FC<IContainerFilter> = ({
  handleClose,
  anchorEl,
  children,
  top,
  handleReset,
  handleSubmit,
}) => {
  const open = Boolean(anchorEl);
  const id = open ? 'popover' : undefined;

  return (
    <>
      <Popover
        sx={{ top: top || '25px' }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div>
          {children}

          <div className="flex justify-end py-2 my-2  px-3 -mt-2 items-center gap-3">
            <div
              className="mr-4 text-[#F04545] font-bold cursor-pointer"
              onClick={() => {
                handleClose();
                handleReset();
              }}
            >
              reset
            </div>
            <BasicButton
              variant="contained"
              color="primary"
              onClick={() => {
                handleClose();
                handleSubmit();
              }}
              style={{ width: 100 }}
            >
              Apply
            </BasicButton>
          </div>
        </div>
      </Popover>
    </>
  );
};

export default ContainerFilter;
