import { AxiosError } from 'axios';
import { IPagination } from './generalTypes';

// Action Types
export enum WithdrawalType {
  SET_WD_PENDING = 'SET_WD_PENDING',
  SET_WD_REQ_PENDING = 'SET_WD_REQ_PENDING',
  SET_WD_ERROR = 'SET_WD_ERROR',

  GET_WD_LIST = 'GET_WD_LIST',
  GET_WD_DETAIL = 'GET_WD_DETAIL',
  GET_WD_SETTLED = 'GET_WD_SETTLED',

  SET_WD_REQUEST = 'SET_WD_REQUEST',
}

// transaction state
export interface IWithdrawalState {
  withdrawal: IWithdrawal;
}

// start withdrawal interface
export interface IWithdrawal {
  isLoading: boolean;
  isLoadingReq: boolean;
  withdrawal_list: IWithdrawal_list;
  withdrawal_detail: IWithdrawal_detail;
  wd_settled_transactions: IWithdrawalSettled;
  error: AxiosError;
}

export interface IWithdrawal_list {
  data: IDataWithdrawal_list[];
  pagination: IPagination;
}

interface IDataWithdrawal_list {
  trx_id: string;
  date: string;
  trx_no: string;
  receipt_no: string;
  total_trx_record: number;
  amount_initial: number;
  amount_trx: number;
  fee_settled: number;
  fee_third_party: number;
  amount_withdrawn: number;
  status: string;
}

export interface IWithdrawal_detail {
  id: string;
  data: IDataWithdrawal_detail[];
  pagination: IPagination;
  withdrawl_information: IWithdrawal_information;
  summary: ISummary;
  settlement_summary: IsettlementSummary;
  settlement_summary_list: ITransferSummary[];
  transfer_summary: ITransferSummary;
  settlement_information: IsettlementInformation;
  transfer_summary_v2: ItransferSummaryV2[];
}

interface ItransferSummaryV2 {
  id: number;
  transfer_status: number;
  transfer_status_text: string;
  bank_name: string;
  account_number: string;
  account_name: string;
  transfer_date: string;
  amount_settlement: number;
  detail: {
    fee_bank_transfer: string;
    service_fee: string;
    amount_transfer: string;
  };
}

interface IsettlementSummary {
  total_amount_initial: number;
  total_amount_trx: number;
  total_fee_admin: number;
  total_fee_mdr: number;
  total_fee_settled: number;
  amount_settlement: number;
}

interface ITransferSummary {
  account_name: string;
  account_number: string;
  bank_name: string;
  bank_transfer_fee: number;
  service_fee: number;
  transfer_date: string;
  transfer_number: string;
  transfer_status: string;
  amount_settlement: number;
  amount_transfer: number;
}

interface IsettlementInformation {
  settlement_date: string;
  settlement_no: string;
  settlement_status: string;
  total_records: number;
}

interface IDataWithdrawal_detail {
  trx_no: string;
  reference_no: string;
  amount_initial: number;
  fee_settled: number;
  fee_third_party: number;
  amount_trx: number;
  channel: string;
}

interface IWithdrawal_information {
  status: string;
  date: string;
  trx_no: string;
  receipt_no: string;
  // next: number,
  total_records: number;
}

interface ISummary {
  amount_initial: number;
  amount_trx: number;
  fee_settled: number;
  fee_third_party: number;
  withdrawn: number;
}

export interface IWithdrawalSettled {
  data: IDataWithdrawalSettled[];
  summary: ISummarySettled;
}

interface IDataWithdrawalSettled {
  trx_no: string;
  reference_no: string;
  initial_amount: number;
  fee_settled: number;
  fee_third_party: number;
  amount: number;
}

interface ISummarySettled {
  total_records: number;
  total_trx_amount: number;
  total_fee_third_party: number;
  total_fee_settled: number;
  total_fee: number;
  total_payable_amount: number;
}
// end withdrawal interface

interface Setwdpending {
  type: WithdrawalType.SET_WD_PENDING;
  payload?: any;
}

interface SetwdpendingReq {
  type: WithdrawalType.SET_WD_REQ_PENDING;
  payload?: any;
}

interface SetWdError {
  type: WithdrawalType.SET_WD_ERROR;
  payload: {
    error: AxiosError;
  };
}

interface GetWdList {
  type: WithdrawalType.GET_WD_LIST;
  payload: IWithdrawal_list;
}

interface GetWdDetail {
  type: WithdrawalType.GET_WD_DETAIL;
  payload: IWithdrawal_detail;
}

interface GetWdSettled {
  type: WithdrawalType.GET_WD_SETTLED;
  payload: IWithdrawalSettled;
}

interface SetWdReq {
  type: WithdrawalType.SET_WD_REQUEST;
  payload?: any;
}

// IResponse Example
export interface IExampleResponse {
  code: number;
  message: string;
  data?: Object;
}

export type WithdrawalAction =
  | GetWdList
  | GetWdDetail
  | GetWdSettled
  | Setwdpending
  | SetWdReq
  | SetWdError
  | SetwdpendingReq;
