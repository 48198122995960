import moment from 'moment';
import { IUserData } from 'stores/types/authTypes';

export { default } from './Component';
export type Prop = {
  diffDays?: number;
  onClick?: () => void;
  dayLeft?: { val: number; set: (v: number) => void };
  show?: { val: boolean; set: (v: boolean) => void };
};

export const getExpiredDay = (
  userData: IUserData,
  warning: (v: number) => void
) => {
  //   const expiredDate = '2023-07-12';
  const expiredDate = userData?.PassExpiredDate;
  const firstExp = userData?.FirstAlertPasswordExpiryDay;
  const today = moment();
  const expiry = moment(expiredDate);
  today.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  expiry.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const diffDays = expiry.diff(today, 'days');

  function expiredLimit() {
    if (diffDays === 0) {
      warning(0);
    } else if (diffDays <= firstExp) {
      warning(diffDays);
    }
  }

  return { diffDays, expiredLimit };
};
