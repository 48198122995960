/* eslint-disable import/no-anonymous-default-export */

import {
  SettingActions,
  ISettingState,
  SettingType,
} from 'stores/types/settingTypes';

const initialState = {
  systemSetting: {
    securityKey: {
      code: '',
      api_key: '',
      api_secret: '',
      api_public: '',
    },
    createOrderURL: {
      redirect_finish: '',
      callback_notif: '',
      deeplink_redirect: '',
    },
    messaging: {
      finance_email: '',
      tech_email: '',
      email_to_customer: null,
      email_to_merchant: null,
      is_enable_email: null,
      email_support: '',
      is_enable_sms: null,
      is_enable_wa: null,
      is_enable_web_notif: null,
      cs_phone: '',
      is_enable_mobile: null,
    },
    iPWhitelist: {
      ip_public_dev: '',
      ip_public_staging: '',
      ip_public_prod: '',
      ip_private: '',
    },
    settlement: {
      settlement_date: '',
      settlement_day: '',
      settlement_method: 0,
      settlement_month: '',
      settlement_spec: '',
      settlement_time: '',
      settlement_type: '',
    },
  },
  qoinView: {
    payment: [],
    chanels: [],
    display_name: '',
    language: '',
    theme_pict: '',
  },
  isLoading: {
    securityKey: false,
    createOrderURL: false,
    messaging: false,
    iPWhitelist: false,
    settlement: false,
    generatKey: false,
    generatSecret: false,
    isLoading: false,
  },

  error: null,
};

export default (
  state: ISettingState = initialState,
  { type, payload }: SettingActions
) => {
  switch (type) {
    case SettingType.SET_QOINVUEW_PENDING:
      return {
        ...state,
        ...payload,
        error: null,
        isLoading: {
          ...state['isLoading'],
          isLoading: true,
        },
      };
    case SettingType.GET_DATAMERCHANT:
      return {
        ...state,
        qoinView: {
          ...state.qoinView,
          ...payload,
        },
        isLoading: {
          ...state['isLoading'],
          isLoading: false,
        },
      };
    case SettingType.GET_PAYMENT:
      return {
        ...state,
        qoinView: {
          ...state.qoinView,
          ...payload,
        },
        isLoading: {
          ...state['isLoading'],
          isLoading: true,
        },
      };
    case SettingType.EDIT_SYSTEM_SETTING_PENDING:
      return {
        ...state,
        ...payload,
        error: null,
        isLoading: {
          ...state['isLoading'],
          isLoading: true,
        },
      };
    case SettingType.EDIT_SYSTEM_SETTING:
      return {
        ...state,
        ...payload,
        error: null,
        isLoading: {
          ...state['isLoading'],
          isLoading: false,
        },
      };
    case SettingType.GET_SECURITY_PENDING:
      return {
        ...state,
        ...payload,
        error: null,
        isLoading: {
          ...state['isLoading'],
          securityKey: true,
        },
      };
    case SettingType.GET_URL_PENDING:
      return {
        ...state,
        ...payload,
        error: null,
        isLoading: {
          ...state['isLoading'],
          createOrderURL: true,
        },
      };
    case SettingType.GET_MESSAGING_PENDING:
      return {
        ...state,
        ...payload,
        error: null,
        isLoading: {
          ...state['isLoading'],
          messaging: true,
        },
      };
    case SettingType.GET_IPSETTING_PENDING:
      return {
        ...state,
        ...payload,
        error: null,
        isLoading: {
          ...state['isLoading'],
          iPWhitelist: true,
        },
      };
    case SettingType.GENERETE_SECRET_PENDING:
      return {
        ...state,
        ...payload,
        error: null,
        isLoading: {
          ...state['isLoading'],
          generatSecret: true,
        },
      };
    case SettingType.GENERETE_KEY_PENDING:
      return {
        ...state,
        ...payload,
        error: null,
        isLoading: {
          ...state['isLoading'],
          generatKey: true,
        },
      };
    case SettingType.GET_DATA_SECURITY:
      return {
        ...state,
        systemSetting: {
          ...state['systemSetting'],
          ...payload,
        },
        isLoading: {
          ...state['isLoading'],
          securityKey: false,
        },
      };
    case SettingType.GET_DATA_URL:
      return {
        ...state,
        systemSetting: {
          ...state['systemSetting'],
          ...payload,
        },
        isLoading: {
          ...state['isLoading'],
          createOrderURL: false,
        },
      };
    case SettingType.GET_DATA_MESSAGING:
      return {
        ...state,
        systemSetting: {
          ...state['systemSetting'],
          ...payload,
        },
        isLoading: {
          ...state['isLoading'],
          messaging: false,
        },
      };
    case SettingType.GET_DATA_IPSETTING:
      return {
        ...state,
        systemSetting: {
          ...state['systemSetting'],
          ...payload,
        },
        isLoading: {
          ...state['isLoading'],
          iPWhitelist: false,
        },
      };
    case SettingType.GENERETE_KEY:
      return {
        ...state,
        systemSetting: {
          ...state['systemSetting'],
          securityKey: {
            ...state['systemSetting'].securityKey,
            ...payload,
          },
        },
        isLoading: {
          ...state['isLoading'],
          generatSecret: false,
          generatKey: false,
        },
      };
    case SettingType.GENERETE_SECRET:
      return {
        ...state,
        systemSetting: {
          ...state['systemSetting'],
          securityKey: {
            ...state['systemSetting'].securityKey,
            ...payload,
          },
        },
        isLoading: {
          ...state['isLoading'],
          generatSecret: false,
          generatKey: false,
        },
      };

    case SettingType.GET_DATA_SETTLEMENT:
      return {
        ...state,
        systemSetting: {
          ...state['systemSetting'],
          ...payload,
        },
        isLoading: {
          ...state['isLoading'],
          settlement: false,
        },
      };
    case SettingType.GET_SETTLEMENT_PENDING:
      return {
        ...state,
        ...payload,
        error: null,
        isLoading: {
          ...state['isLoading'],
          settlement: true,
        },
      };

    case SettingType.SETTING_ERROR_GET:
      return {
        ...initialState,
        ...payload,
        isLoading: {
          securityKey: false,
          createOrderURL: false,
          messaging: false,
          iPWhitelist: false,
          settlement: false,
        },
      };

    case SettingType.SETTING_ERROR:
      return {
        ...state,
        ...payload,
        isLoading: {
          securityKey: false,
          createOrderURL: false,
          messaging: false,
          iPWhitelist: false,
          settlement: false,
        },
      };

    default:
      return state;
  }
};
