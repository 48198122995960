import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'stores';
import {
  GetAmountFrequency,
  GetDataDashboardv2,
  GetTop10Product,
  GetTopPaymentMethod,
  GetTotalIncome,
  GetTotalTransaction,
  GetTransctionFrequency,
} from 'stores/actions/dashboardAction';

const useDashboard = () => {
  const dispatch = useDispatch();
  const dashboardeState = useSelector((state: RootState) => state.dasboard);
  const authState = useSelector((state: RootState) => state.auth);
  const Env = authState.userData.Env ? 'PRODUCTION' : 'SANDBOX';

  const getData = (params) => {
    dispatch(GetTotalTransaction({ params }));
    dispatch(GetTotalIncome({ params }));
    dispatch(GetTransctionFrequency({ params }));
    dispatch(GetAmountFrequency({ params }));
    dispatch(GetTop10Product({ params }));
    dispatch(GetTopPaymentMethod({ params }));
  };

  const getDatav2 = (params) => {
    dispatch(GetDataDashboardv2({ params }));
  };

  return {
    dashboardeState,
    getData,
    Env,
    getDatav2,
  };
};

export default useDashboard;
