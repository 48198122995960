import Dropzone from 'react-dropzone';
import Camera from 'assets/icons/photo-camera.png';
import { handleImageUpload } from '../Component';
import { IPUp } from '../interface';

const Component: React.FC<IPUp> = (props) => {
  const { id, setTheFile, setThePhoto, tempPhoto, theFile, thePhoto } = props;
  return (
    <Dropzone
      onDrop={(files) => handleImageUpload(files[0], setTheFile, setThePhoto)}>
      {({ getRootProps, getInputProps }) => (
        <div className="mx-auto dropzone relative" {...getRootProps()}>
          <img
            src={!theFile ? tempPhoto : thePhoto}
            alt="profile"
            className="rounded-full object-cover h-24 w-24 border-solid border-2 border-[#D3EDE4]"
          />
          <img
            src={Camera}
            alt="cam"
            className="absolute bottom-0 right-0 cursor-pointer"
          />
          <input
            type="file"
            id={id}
            onChange={(e) =>
              handleImageUpload(e.target.files[0], setTheFile, setThePhoto)
            }
            accept="image/png, image/jpeg"
            {...getInputProps()}
          />
        </div>
      )}
    </Dropzone>
  );
};

export default Component;
