import React, { useContext, useState } from 'react';
import TableTransactionList from 'pages/laporan/transaction/table/TableTransactionList';
import HeaderTransactionList from './headerTransactionList';
import TitleFeature from 'components/cardComponents/titleFeature';
import Card from 'components/cardComponents';
import AdjustButton from 'components/Button/AdjustButton';
import ExportButton from 'components/Button/ExportButton';
import { TransactionContext } from 'context/transactionContext';
import useExport from 'hooks/useExport';
import { tableNameList } from '../table/dataList';
import { toast } from 'react-toastify';

const Transaction: React.FC = () => {
  let { params, header } = useContext(TransactionContext);
  const [isLoadingExport, setisLoadingExport] = useState(false);
  const { exportTransactionList } = useExport();

  const handleDownload = (type: 'excel' | 'csv') => {
    setisLoadingExport(true);
    toast.info('loading', { autoClose: 4000 });
    exportTransactionList(params, tableNameList, setisLoadingExport, type);
  };

  return (
    <div className="text-[#222222]">
      <TitleFeature text="Transaction" onClick={undefined} />

      <Card>
        <div className="px-2">
          <HeaderTransactionList />

          <div className="flex justify-between items-center p-3 mb-3 px-5">
            <div className="md:flex md:space-x-2 grid gap-3">
              <AdjustButton header={tableNameList} list={header} />
            </div>
            <ExportButton
              isLoading={isLoadingExport}
              handleDownload={handleDownload}
            />
          </div>
        </div>

        <TableTransactionList />
      </Card>
    </div>
  );
};

export default Transaction;
