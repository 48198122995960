import Cookies from 'js-cookie';

export const setCookie = (key: string, value: string, hour = 6) => {
  const date = new Date();
  date.setTime(date.getTime() + hour * 60 * 60 * 1000);
  Cookies.set(key, value, { expires: date });
};

export const removeCookie = (key: string) => {
  Cookies.remove(key);
};

export const getCookie = (key: string) => {
  return Cookies.get(key);
};
