import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'stores';
import { TextField } from '@mui/material';
import { img } from 'assets';
import { ProfileInput } from '../interface';
import EditIcon from 'assets/icons/edit-pen.svg';
import CircleUpload from 'components/upload/circle';
import { DrawerBack } from '../Component';
import { NumericFormat } from 'react-number-format';
import {
  editUserProfile,
  getProfile,
  setPhotoProfile,
} from 'stores/actions/authActions';
import { PopupDialog } from 'components/dialog';
import { InfoOutlined } from '@mui/icons-material';
import { permissionFunction } from 'utils/permission';
import permission from 'constants/permissionRole';
import useActivityBrowser, { ActivityData } from 'hooks/useActivityBrowser';
import { BasicButton } from 'components/Button';

const TemplateInput: React.FC<ProfileInput> = (props) => {
  const { title, value, disable, error, fixed, onChange } = props;
  return (
    <div className="flex flex-col space-y-2">
      <p className="text-black font-medium">{title}</p>
      <TextField
        error={error}
        value={value}
        sx={fixed ? { backgroundColor: '#E9ECEF' } : {}}
        disabled={disable}
        onChange={onChange}
      />
    </div>
  );
};

const Component: React.FC = () => {
  const { Username, Email, Phone, RoleName, ProfileImage } = useSelector(
    (state: RootState) => state.auth.profile
  );
  const dispatch = useDispatch();
  const [allowEdit, setAllowEdit] = useState(false);
  const [unlock, setUnlock] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profileFile, setProfileFile] = useState(null);
  const [editName, setEditName] = useState(Username);
  const [editPhone, setEditPhone] = useState(Phone);
  const [popup, setPopup] = useState(false);

  const AuditData: ActivityData = useActivityBrowser();

  const handleEdit = () => {
    if (permissionFunction(permission.EDIT_PROFILE)) {
      setAllowEdit(true);
      setUnlock(true);
    }
  };
  const doneEdit = () => {
    const data = {
      name: editName,
      phone: editPhone,
      browserId: AuditData.browserId,
      ipAddress: AuditData.ipAddress,
      latitude: AuditData.latitude,
      longitude: AuditData.longitude,
    };
    permissionFunction(permission.EDIT_PROFILE) &&
      dispatch(editUserProfile(data, setPopup));
    if (profileFile !== null && profileFile?.size < 40960) {
      setPhotoProfile(profileFile, AuditData, setPopup);
      dispatch(getProfile());
    }
    setAllowEdit(false);
    setUnlock(false);
  };

  const closePopup = () => {
    setProfilePhoto(null);
    setProfileFile(null);
    setPopup(false);
  };

  return (
    <div className="flex flex-col w-full">
      <div className="flex py-5 px-6 justify-between">
        <DrawerBack title="User Profile" />
        {!allowEdit && (
          <img
            src={EditIcon}
            alt="edit"
            className="cursor-pointer"
            onClick={handleEdit}
          />
        )}
      </div>
      {unlock ? (
        <CircleUpload
          id="profile"
          setTheFile={setProfileFile}
          setThePhoto={setProfilePhoto}
          theFile={profileFile}
          thePhoto={profilePhoto}
          tempPhoto={ProfileImage !== '' ? ProfileImage : img.profile}
        />
      ) : (
        <div className="mx-auto">
          <img
            src={
              ProfileImage !== '' && profilePhoto === null
                ? ProfileImage
                : profilePhoto !== null
                ? profilePhoto
                : img.profile
            }
            alt="profile"
            className="rounded-full object-contain h-24 w-24 border-solid border-2 border-[#D3EDE4]"
          />
        </div>
      )}

      {profileFile?.size >= 40960 && (
        <div className="flex mx-auto pt-4 pb-8 font-medium">
          <InfoOutlined
            fontSize="small"
            className="my-auto mr-2"
            color="error"
          />
          <div className="text-center text-red-600 text-sm my-auto">
            The maximum image size is 40kb
          </div>
        </div>
      )}
      <div className="px-6 space-y-4">
        <TemplateInput
          title="User Name"
          disable={!unlock}
          value={!allowEdit ? Username : editName}
          onChange={(e) => setEditName(e.target.value)}
        />
        <TemplateInput title="Email" fixed={allowEdit} disable value={Email} />
        <NumericFormat
          title="Phone Number"
          disable={!unlock}
          value={!allowEdit ? Phone : editPhone}
          onChange={(e) => setEditPhone(e.target.value)}
          customInput={TemplateInput}
          allowLeadingZeros
          allowNegative={false}
        />
        <TemplateInput
          title="User Role"
          fixed={allowEdit}
          disable
          value={RoleName}
        />
      </div>

      {popup && (
        <PopupDialog
          open={popup}
          text="Edit Profil Berhasil!"
          subtext="Perubahan data profil berhasil dilakukan."
          onClick={closePopup}
        />
      )}

      {allowEdit && (
        <div className="px-6 absolute bottom-6 w-full">
          <BasicButton
            fullWidth
            onClick={doneEdit}
            disabled={editName === '' || editPhone === ''}
          >
            Save Changes
          </BasicButton>
        </div>
      )}
    </div>
  );
};

export default Component;
