import { useContext } from 'react';
import SelectCheckbox from 'components/filter/selectCheckbox';
import Search from 'components/filter/search';
import { RefundContext } from 'context/refundContext';

const FilterPopover = ({ id }) => {
  const {
    setRefundNo,
    RefundNo,
    isTransactionNo,
    setIsTransactionNo,
    handleStatus,
    isCheckedstatuses,
  } = useContext(RefundContext);

  let dataStatus = [
    {
      code: '1',
      value: 1,
    },
    {
      code: '1',
      value: 1,
    },
    {
      code: '2',
      value: 2,
    },
  ];

  const filterComponent = (() => {
    switch (id) {
      case 'no':
        return (
          <Search
            label="Search Refund Trans No."
            handleChange={setRefundNo}
            value={RefundNo}
          />
        );
      case 'trx_number':
        return (
          <Search
            label="Search Transaction No."
            handleChange={setIsTransactionNo}
            value={isTransactionNo}
          />
        );

      case 'status':
        return (
          <SelectCheckbox
            data={dataStatus}
            value={isCheckedstatuses}
            handleChange={handleStatus}
          />
        );

      default:
        return null;
    }
  })();

  return <div className="p-2">{filterComponent}</div>;
};

export default FilterPopover;
