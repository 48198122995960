// name: name variable, tableName: nameTableUi/nama yang tampil di user, icon: filterIcon
const addTableName = (
  name: string,
  tableName: string,
  filter: boolean,
  typeValue?: string,
  width?: string
) => ({
  name,
  tableName,
  filter,
  typeValue,
  width,
});

export const tableNameListRefund = [
  addTableName('no', 'Refund Trans No.', true, '', '170px'),
  addTableName('refund_date', 'Refund Date', false, '', '120px'),
  addTableName('trx_number', 'Transaction No.', true, '', '160px'),
  addTableName('references_id', 'Reference No.', false, ''),
  addTableName('payment', 'Channel', false, '', '70px'),
  addTableName('amount', 'Refund Amount', false, 'RupiahFormat'),
  addTableName('reason', 'Remark', false, ''),
  addTableName('status', 'Status', true, 'status'),
];
