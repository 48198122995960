import App from 'App';
import { Provider } from 'react-redux';
import { store, persistor } from './stores';
import { PersistGate } from 'redux-persist/integration/react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { createRoot } from 'react-dom/client';
import { FontDefaultTheme } from 'components/muiTheme';
import 'assets';
import { I18nextProvider } from 'react-i18next';
import i18n from 'configs/i18n';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={FontDefaultTheme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </PersistGate>
    </I18nextProvider>
  </Provider>
);
