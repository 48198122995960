import { tableName } from './dataList';

import BasicTable from 'components/table';
import Pagination from 'components/pagination';

const TableDashboard = ({ data, paginationData, params, setparams }) => {
  const handleChangSize = (e) => {
    setparams({
      ...params,
      page: 1,
      size: Number(e.target.value),
    });
  };

  const handleChangePage = (e, value) => {
    setparams({
      ...params,
      page: Number(value),
    });
  };

  return (
    <div>
      <BasicTable
        FilterComponent={undefined}
        dataList={data}
        tableNameList={tableName}
        countPage={params?.size}
        page={params.page}
        isLoading={false}
      />
      <Pagination
        value={params?.size}
        page={params?.page}
        data={paginationData}
        handleChange={handleChangSize}
        handlePage={handleChangePage}
      />
    </div>
  );
};

export default TableDashboard;
