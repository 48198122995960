import React, { useState } from 'react';
import { ClickAwayListener, Popover } from '@mui/material';
import QuestionMark from 'assets/icons/question-mark-circle.svg';
import { Info } from '@mui/icons-material';

const TextContent = {
  title: 'Password Character Combination, must be consisted:',
  subcontent: [
    'minimum length password : 8 karakter',
    'kombinasi karakter password : huruf besar, huruf kecil, spesial karakter, angka',
  ],
};

const InsideContent = () => {
  return (
    <>
      <div className="flex">
        <Info sx={{ color: '#18AD65', mr: '.5rem' }} />
        <div className="text-[#18AD65] font-bold">{TextContent.title} </div>
      </div>
      <ol className="list-disc pl-6 py-2">
        {TextContent.subcontent.map((x: string, i: number) => (
          <li key={i}>{x}</li>
        ))}
      </ol>
    </>
  );
};

const Wide = () => {
  return (
    <div className="bg-white rounded-lg min-w-[32rem] w-80 z-30 px-3 py-2 flex my-3 flex-col absolute border">
      <InsideContent />
    </div>
  );
};

const DrawerMode = ({ open, anchor, handleClose, id }) => {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchor}
      onClose={handleClose}
      sx={{ zIndex: 4000 }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}>
      <div className="w-64 p-2">
        <InsideContent />
      </div>
    </Popover>
  );
};

type Props = {
  mode?: 'wide' | 'drawer';
};

const Component: React.FC<Props> = ({ mode }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const openEl = Boolean(anchorEl);
  const id = openEl ? 'simple-popover' : undefined;
  function setMode(modes: string) {
    switch (modes) {
      case 'wide':
        return <Wide />;
      case 'drawer':
        return (
          <DrawerMode
            id={id}
            open={open}
            anchor={anchorEl}
            handleClose={handleClose}
          />
        );
    }
  }
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        <button type="button" onClick={handleClick}>
          <img src={QuestionMark} alt="info" className="ml-1" />
        </button>
        <div>{open && setMode(mode)}</div>
      </div>
    </ClickAwayListener>
  );
};

export default Component;
