import { AxiosError } from 'axios';

export enum BalanceType {
  SET_BALANCE_PENDING = 'SET_BALANCE_PENDING',
  GET_BALANCE_SUCCESS = 'GET_BALANCE_SUCCESS',
  SET_BALANCE_ERROR = 'SET_BALANCE_ERROR',
}

export interface IBalanceState {
  isLoading: boolean;
  balance?: number;
  error?: AxiosError;
}

interface IBalancePending {
  type: BalanceType.SET_BALANCE_PENDING;
  payload?: any;
}

interface IBalanceSuccess {
  type: BalanceType.GET_BALANCE_SUCCESS;
  payload: {
    balance: number;
  };
}

interface IBalanceError {
  type: BalanceType.SET_BALANCE_ERROR;
  payload: {
    error: AxiosError;
  };
}

export type IBalanceActions = IBalancePending | IBalanceSuccess | IBalanceError;
