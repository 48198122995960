import { icons } from "assets";

export const topicCategories = {
  transaction: [
    "refund",
    "withdrawal",
    "transaction",
    "settlement",
    "top up balance",
    "transfer payout",
  ],
  waitingapproval: ["need approval", "need approval transfer"],
  approval: [
    "approve onboarding",
    "approve sandbox",
    "approve production",
    "approved transfer",
  ],
  reject: [
    "reject onboarding",
    "reject sandbox",
    "reject production",
    "reject transfer",
  ],
  info: ["maintenance", "info", "reminder", "alert"],
};

export const ListIcon = {
  transaction: icons.Transaction,
  approve: icons.Approve,
  reject: icons.Reject,
  info: icons.Info,
};
