import React, { useState } from 'react';
import {
  Box,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  TextField,
} from '@mui/material';
import { Option } from '../interface';
import { BasicButton } from 'components/Button';
import { CustomCheckbox } from 'components/Input';

type Props = SelectProps & {
  options: Option[];
  selection: { val: string[]; set: (v: string[]) => void };
};
const Component: React.FC<Props> = (props) => {
  const { disabled = false, options, selection, multiple } = props;
  const [newOptionValue, setNewOptionValue] = useState('');
  const [canAddNewOption, setCanAddNewOption] = useState(true);
  const [listOption, setListOption] = useState<Option[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof selection.val>) => {
    const {
      target: { value },
    } = event;
    selection?.set(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleNewOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    const inputVal = event.target.value;
    if (/^[a-zA-Z\s]*$/.test(inputVal) && inputVal.length <= 30) {
      setNewOptionValue(inputVal);
    }
  };

  const handleAddOption = () => {
    if (newOptionValue.trim() !== '') {
      setNewOptionValue('');
      setCanAddNewOption(false);
      selection?.set([...selection?.val, newOptionValue]);

      options.splice(0, 0, { value: newOptionValue, label: newOptionValue });
      setListOption(options);
    }
  };

  const checking = listOption.length === 0 ? options : listOption;

  return (
    <Select
      disabled={disabled}
      fullWidth
      size="small"
      onChange={handleChange}
      required
      multiple={multiple}
      value={selection?.val}
      renderValue={
        multiple
          ? (selected: string[]) => selected.join(', ')
          : (selected: string[]) => selected[0]
      }
      sx={{ fontSize: '14px' }}
      {...props}
    >
      {checking.map((list) => (
        <MenuItem key={list.value} value={list.value} sx={{ p: 0 }}>
          {multiple && (
            <CustomCheckbox
              id={'check' + list.label}
              checked={selection?.val.indexOf(list.value) > -1}
            />
          )}
          <ListItemText
            id={list.label}
            primary={list.label}
            sx={{ fontSize: 14, p: 1 }}
          />
        </MenuItem>
      ))}
      {canAddNewOption && (
        <Box display={'flex'} mx={1}>
          <TextField
            id="lainnya"
            label="Lainnya..."
            value={newOptionValue}
            onChange={handleNewOptionChange}
            onKeyDown={(e) => e.stopPropagation()}
            size="small"
            fullWidth
          />
          <BasicButton
            id="ok-btn"
            variant="contained"
            color="success"
            sx={{ ml: 1 }}
            onClick={handleAddOption}
          >
            OK
          </BasicButton>
        </Box>
      )}
    </Select>
  );
};
export default Component;
