import { combineReducers } from 'redux';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import appStateReducer from './appState.Reducer';
import authReducer from './authReducers';
import balanceReducer from './balanceReducer';
import exampleReducer from './exampleReducer';
import refundReducer from './refundReducer';
import TransactionListReducer from './transactionReducer';
import utilsReducer from './utilsReducer';
import registerReducer from './registerReducer';
import onboardingReducer from './onboardingReducers';
import withdrawalReducer from './withdrawalReducer';
import notificationReducers from './notification.Reducers';
import settingReducer from './settingReducer';
import ticketingReducer from './ticketingReducer';
import dashboardReducer from './dashboardReducer';

export const rootReducer = combineReducers({
  example: exampleReducer,
  utils: utilsReducer,
  appState: appStateReducer,
  balance: balanceReducer,
  withdrawal: withdrawalReducer,
  notificationState: notificationReducers,
  auth: persistReducer(
    {
      key: 'auth',
      storage,
      blacklist: ['error'],
      whitelist: ['userData'],
    },
    authReducer
  ),
  register: registerReducer,
  onboarding: onboardingReducer,
  transactionList: TransactionListReducer,
  refund: refundReducer,
  setting: settingReducer,
  ticketing: ticketingReducer,
  dasboard: dashboardReducer,
});
