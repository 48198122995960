import { SelectChangeEvent } from '@mui/material';
import useBalance from 'hooks/useBalance';
import useWithdrawal from 'hooks/useWithdrawal';
import React from 'react';
import { IApiResponse } from 'stores/types/generalTypes';
import { Dayjs } from 'dayjs';
import { tableNameWdList } from 'pages/transaksi/withdrawal/table/dataList';
import {
  tableNameWdDetail,
  tableNameWdList as tableNameWdListST,
} from 'pages/laporan/Settlement/table/dataList';
import moment from 'moment';

export const WithdrawalContext = React.createContext(null);

const WithdrawalProvider = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [idPoper, setidPoper] = React.useState(null);
  const [countPage, setcountPage] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [countPageDetail, setcountPageDetail] = React.useState(10);
  const [pageDetail, setPageDetail] = React.useState(1);
  const [uiNow, setUiNow] = React.useState('');
  const [isAlert, setIsAlert] = React.useState(false);
  const [severty, setSeverty] = React.useState('success');
  const [message, setMessage] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [ReceiptNo, setReceiptNo] = React.useState('');
  const [WithdrawalNo, setWithdrawalNo] = React.useState(null);
  const [header, setHeader] = React.useState(
    tableNameWdListST.map((item) => item.tableName)
  );
  const [headerDetail, setHeaderDetail] = React.useState(
    tableNameWdDetail.map((item) => item.tableName)
  );
  const [WithdrawalDate, setWithdrawalDate] = React.useState<Dayjs | null>(
    null
  );
  const [idDetail, setidDetail] = React.useState(null);
  const [params, setParams] = React.useState({
    page: 1,
    size: 10,
    trans_no: '',
    receipt_no: '',
    start_date: null,
    end_date: null,
  });
  const [paramsDetail, setParamsDetails] = React.useState({
    page: 1,
    size: 10,
  });

  const [dateRange, setDateRange] = React.useState([null, null]);
  const [startDate, endDate] = dateRange;

  const { setWdPReq, getWdDetail } = useWithdrawal();
  const { getBalance } = useBalance();

  const ChangePaginationWdList = (event: SelectChangeEvent) => {
    let count = Number(event.target.value);
    setPage(1);
    setcountPage(count);
    setParams({
      ...params,
      page: 1,
      size: count,
    });
  };

  const ChangePageWdList = (event: SelectChangeEvent, value: any) => {
    setPage(value);
    setParams({
      ...params,
      page: value,
      size: Number(countPage),
    });
  };

  const ChangePaginationWdDetail = (event: SelectChangeEvent) => {
    setPageDetail(1);
    let count = Number(event.target.value);
    setcountPageDetail(count);
    setParamsDetails({
      ...paramsDetail,
      page: 1,
      size: count,
    });
  };

  const ChangePageWdDetail = (event: SelectChangeEvent, value: any) => {
    setPageDetail(value);
    setParamsDetails({
      ...paramsDetail,
      page: value,
      size: Number(countPageDetail),
    });
  };

  const handleAction = (name: string, id: string) => {
    setidDetail(id);
    handleChangeUi(name);
    getWdDetail(null, id, (response: IApiResponse) => {
      if (response.code !== 200) {
        handleChangeUi('');
        handleAlert('error', 'Error');
      }
    });
  };

  const handleChangeUi = (name: string) => {
    setUiNow(name);
  };

  const handleAlert = (name: string, msg: string) => {
    setIsAlert(true);
    setSeverty(name);
    setMessage(msg);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>, id: number) => {
    setAnchorEl(event.currentTarget);
    setidPoper(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlWdReqAction = () => {
    setOpen(false);

    setWdPReq(null, (response: IApiResponse) => {
      handleChangeUi('');
      if (response?.code === 200) {
        getBalance({ owner_type: 'mrc' }, (response: any) => {
          if (response?.statusCode !== 200) {
            handleAlert('error', 'get Balance Failed');
          } else {
            handleAlert('success', 'Succes');
          }
        });
      } else {
        handleAlert('error', 'Error');
      }
    });
  };

  const handleReset = () => {
    setWithdrawalDate(null);
    setReceiptNo('');
    setWithdrawalNo('');
    setParams({
      ...params,
      page: 1,
      size: 10,
      trans_no: '',
      receipt_no: '',
      start_date: null,
      end_date: null,
    });
    setDateRange([null, null]);

    handleClose();
  };

  const handleSubmit = () => {
    setParams({
      ...params,
      trans_no: WithdrawalNo,
      receipt_no: ReceiptNo,
      start_date: startDate ? moment(startDate).format('yy-MM-DD') : '',
      end_date: endDate ? moment(endDate).format('yy-MM-DD') : '',
    });

    handleClose();
  };

  const [isCheckedTableNameList, setisCheckedTableNameList] = React.useState(
    new Array(tableNameWdList.length).fill(true)
  );

  const handleCheckedTableNameList = (position: number) => {
    const updatedCheckedstatuses = isCheckedTableNameList.map((item, index) =>
      index === position ? !item : item
    );
    setisCheckedTableNameList(updatedCheckedstatuses);
  };

  const handleResetTableNameList = () => {
    setisCheckedTableNameList(new Array(tableNameWdList.length).fill(true)); // eslint-disable-next-line
  };

  const [isCheckedTableNameListST, setisCheckedTableNameListST] =
    React.useState(new Array(tableNameWdListST.length).fill(true));

  const handleCheckedTableNameListST = (position: number) => {
    const updatedCheckedstatuses = isCheckedTableNameListST.map((item, index) =>
      index === position ? !item : item
    );
    setisCheckedTableNameListST(updatedCheckedstatuses);
  };

  const handleResetTableNameListST = () => {
    setisCheckedTableNameListST(new Array(tableNameWdListST.length).fill(true)); // eslint-disable-next-line
  };

  let handleProses = (status) => {
    let Process = () => {
      return (
        <div className="bg-[#FFF9F2] text-[#FDBE2C] border border-[#FDBE2C] w-[80px] text-center rounded-md">
          Process
        </div>
      );
    };
    let Success = () => {
      return (
        <div className="bg-[#E9F7F2] text-[#21AF7D] border border-[#BAE6D7] w-[80px] text-center rounded-md">
          Success
        </div>
      );
    };
    let Failed = () => {
      return (
        <div className="bg-[#FFF4F2] text-[#CB3A31] border border-[#EEB4B0] w-[80px] text-center rounded-md">
          Failed
        </div>
      );
    };

    if (status.toLowerCase().includes('process')) return <Process />;
    if (status.toLowerCase().includes('success')) return <Success />;
    if (status.toLowerCase().includes('failed')) return <Failed />;
  };

  let handleProses2 = (code, text) => {
    let Process = () => {
      return (
        <div className="bg-[#FFF9F2] text-[#FDBE2C] border border-[#FDBE2C] w-[80px] text-center rounded-md">
          {text}
        </div>
      );
    };
    let Success = () => {
      return (
        <div className="bg-[#E9F7F2] text-[#21AF7D] border border-[#BAE6D7] w-[25%] text-center rounded-md">
          {text}
        </div>
      );
    };
    let Failed = () => {
      return (
        <div className="bg-[#FFF4F2] text-[#CB3A31] border border-[#EEB4B0] w-[80px] text-center rounded-md">
          {text}
        </div>
      );
    };

    if (code === 0) return <Process />;
    if (code === 1) return <Success />;
    if (code === 2) return <Failed />;
  };

  let valueContext = {
    anchorEl,
    idPoper,
    handleReset,
    WithdrawalDate,
    setWithdrawalDate,
    countPage,
    setcountPage,
    ReceiptNo,
    setReceiptNo,
    WithdrawalNo,
    setWithdrawalNo,
    page,
    setPage,
    countPageDetail,
    setcountPageDetail,
    pageDetail,
    setPageDetail,
    setUiNow,
    uiNow,
    isAlert,
    setIsAlert,
    severty,
    setSeverty,
    message,
    setMessage,
    idDetail,
    open,
    paramsDetail,
    setOpen,
    show,
    setShow,
    params,
    setParams,
    ChangePaginationWdList,
    ChangePageWdList,
    ChangePaginationWdDetail,
    ChangePageWdDetail,
    handleAction,
    handleChangeUi,
    handleAlert,
    handleMenu,
    handleClose,
    handlWdReqAction,
    handleSubmit,
    setDateRange,
    startDate,
    endDate,
    handleProses,
    handleProses2,

    isCheckedTableNameList,
    handleCheckedTableNameList,
    handleResetTableNameList,

    isCheckedTableNameListST,
    handleCheckedTableNameListST,
    handleResetTableNameListST,

    header: {
      val: header,
      set: setHeader,
    },
    headerDetail: {
      val: headerDetail,
      set: setHeaderDetail,
    },
  };
  return (
    <WithdrawalContext.Provider value={valueContext}>
      {props.children}
    </WithdrawalContext.Provider>
  );
};

export default WithdrawalProvider;
