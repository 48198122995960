import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'stores';
import { GridImage, GridLine, GridSelect } from './Components';
import { IBizInfo } from './interface';
import useCustomFormik from 'hooks/useCustomFormik';
import { InformasiBisnisPemilikSchema } from 'components/configs/validationSchema';
import { info_biz_values } from 'stores/types/onboardingTypes';
import useOnboardData from 'hooks/useOnboardData';

export const InfoBisnis: React.FC<IBizInfo> = ({ data, error }) => {
  const {
    informasiBisnis,
    tipeUsaha,
    QRIS,
    merchantPreview: MP,
  } = useSelector((state: RootState) => state.onboarding);
  const { userData, profile } = useSelector((state: RootState) => state.auth);
  const { bizData } = useOnboardData();

  // declare formik related here
  const initVal: info_biz_values = {
    ...informasiBisnis,
    NpwpNumber: informasiBisnis.NpwpNumber || QRIS?.NoNPWP,
  };
  const formik = useCustomFormik(initVal, InformasiBisnisPemilikSchema);
  const { values, errors } = formik;

  const [check, setCheck] = useState(false);
  const [bizName, setBizName] = useState(
    profile.Fullname !== '' ? profile.Fullname : userData.Fullname
  );
  const [tipeBiz, setTipeBiz] = useState(informasiBisnis.BizTypeCode);
  const [catBiz, setCatBiz] = useState(informasiBisnis.BizCategoryCode);
  const [ownNation, setOwnNation] = useState(
    MP.data?.own_citizen || informasiBisnis.OwnerNationality
  );
  const [ktpFile, setKtpFile] = useState(
    QRIS?.FileKTP ?? informasiBisnis.IdCardPhotoFile
  );
  const [ktpPhoto, setKtpPhoto] = useState(
    QRIS?.PhotoKTP ?? informasiBisnis.IdCardPhoto
  );
  const [npwpFile, setNpwpFile] = useState(informasiBisnis.NpwpPhotoFile);
  const [npwpPhoto, setNpwpPhoto] = useState(informasiBisnis.NpwpPhoto);
  const [ktpUrl, setKtpUrl] = useState('');
  const [npwpUrl, setNPwpUrl] = useState('');

  const isExist = bizData?.filter((b) => b?.categories !== undefined);
  const listBiz = isExist?.map(({ code: value, name: label }) => ({
    value,
    label,
  }));
  const pkgExist = MP.data && MP.data?.package_type !== null;

  useEffect(() => {
    if (profile?.Phone && profile?.Username) {
      formik.setValues({
        ...values,
        OwnerPhone: profile.Phone,
        OwnerName: profile.Username,
      });
    }

    if (pkgExist) {
      setTipeBiz(MP.data?.bis_type_code);
      setCatBiz(MP.data?.bis_category_code);
      formik.setValues({
        BizUrl: MP.data?.bis_url,
        BizTypeCode: MP.data?.bis_type_code,
        BizCategoryCode: MP.data?.bis_category_code,
        PicFinanceEmail: MP.data?.finance_email,
        PicFinancePhone: MP.data?.finance_phone,
        PicTechEmail: MP.data?.tech_email,
        PicTechPhone: MP.data?.tech_phone,
        OwnerName: MP.data?.own_name,
        OwnerNationality: MP.data?.own_citizen,
        OwnerAddress: MP.data?.own_address,
        OwnerZip: MP.data?.own_zip,
        OwnerPhone: MP.data?.own_phone,
        IdCardName: MP.data?.ktp_name,
        IdCardNumber: MP.data?.ktp_no,
        NpwpName: MP.data?.npwp_name,
        NpwpNumber: MP.data?.npwp_number,
        NpwpAddress: MP.data?.npwp_address,
      });
      setKtpUrl(MP.data?.ktp_url);
      setNPwpUrl(MP.data?.npwp_url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [MP]);

  const getCategory = () => {
    if (tipeBiz !== '') {
      const filter = isExist?.filter((types) => types?.code === tipeBiz);
      if (filter[0]?.categories[0]?.type === tipeBiz) {
        const listOption = filter[0]?.categories?.map(
          ({ code: value, name: label }) => ({
            value,
            label,
          })
        );
        return listOption;
      }
    }
  };

  const sampleNation = [
    { value: 'WNI', label: 'WNI' },
    { value: 'WNA', label: 'WNA' },
  ];

  useEffect(() => {
    if (error?.val) {
      if (tipeBiz === '') setCheck(true);
      if (catBiz === '') setCheck(true);
      if (ktpFile === null) setCheck(true);
      if (npwpFile === null) setCheck(true);
    } else setCheck(false);
  }, [catBiz, error?.val, tipeBiz, ktpFile, npwpFile]);

  // compiled data to sent outside of this component, such as onboarding & merc preview
  type compiled_props = Record<keyof info_biz_values, string>;
  type extra_value = {
    BizName: string;
    IdCardPhoto: string;
    IdCardPhotoFile: File;
    NpwpPhoto: string;
    NpwpPhotoFile: File;
  };

  useEffect(() => {
    const compiled: compiled_props & extra_value = {
      ...values,
      BizTypeCode: tipeBiz,
      BizCategoryCode: catBiz,
      OwnerNationality: ownNation,
      BizName: bizName,
      IdCardPhoto: ktpPhoto,
      IdCardPhotoFile: ktpFile,
      NpwpPhoto: npwpPhoto,
      NpwpPhotoFile: npwpFile,
      PicFinancePhone: values.PicFinancePhone,
    };
    data?.set(compiled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bizName, catBiz, data, ktpFile, npwpFile, ownNation, tipeBiz, values]);

  // error condition & warning here
  const sizeLimit = 2 * (1024 * 1024);
  const notCompany = tipeUsaha === '1';
  const messageImage = (value: File) => {
    if (check && value === null) return 'Data tidak boleh kosong';
    if (value !== null && value?.size >= sizeLimit) {
      return 'Ups! File terlalu besar, silakan perkecil ukuran file Anda dan coba lagi.';
    }
  };
  const errImg = (val: File) =>
    (check && val === null) || (val !== null && val?.size >= sizeLimit);
  const errCond = (val: keyof typeof initVal): boolean => {
    if (errors[val] === undefined) return false;
    return check || (values[val] !== '' && errors[val] !== undefined);
  };
  const errMsg = (val: keyof typeof initVal): string => {
    if (values[val]?.length === 0) return 'Data tidak boleh kosong';
    if (errors[val] !== undefined) return errors[val];
  };

  return (
    <>
      <div className="space-y-2 bg-white p-5">
        <div className="font-bold text-xl">Informasi Bisnis</div>
        <GridLine
          fieldTitle="Nama Usaha"
          value={bizName}
          onChange={(e) => setBizName(e.target.value)}
          disabled
        />
        <GridSelect
          fieldTitle="Tipe Entitas Bisnis"
          options={listBiz}
          selected={{ set: setTipeBiz, val: tipeBiz }}
          placeholder="Pilih tipe entitas bisnis"
          error={check && tipeBiz === ''}
          message="Data tidak boleh kosong"
        />
        <GridSelect
          fieldTitle="Kategori Bisnis"
          options={getCategory()}
          selected={{ set: setCatBiz, val: catBiz }}
          placeholder="Pilih kategori bisnis"
          disable={tipeBiz === ''}
          error={check && catBiz === ''}
          message="Data tidak boleh kosong"
        />
        <GridLine
          fieldTitle="URL Bisnis"
          id="BizUrl"
          value={values.BizUrl}
          onChange={formik.handleChange}
          placeholder="Masukkan url bisnis"
          error={(check && values.BizUrl === '') || errCond('BizUrl')}
          message={errMsg('BizUrl')}
        />
        <GridLine
          fieldTitle="E-mail PIC Finance"
          id="PicFinanceEmail"
          value={values?.PicFinanceEmail}
          onChange={formik.handleChange}
          placeholder="Masukkan e-mail PIC finance"
          error={errCond('PicFinanceEmail')}
          message={errMsg('PicFinanceEmail')}
        />
        <GridLine
          fieldTitle="No. HP PIC Finance"
          id="PicFinancePhone"
          mode="phone"
          value={values?.PicFinancePhone}
          onChange={formik.handleChange}
          placeholder="Masukkan no. hp PIC finance"
          error={errCond('PicFinancePhone')}
          message={errMsg('PicFinancePhone')}
        />
        <GridLine
          fieldTitle="E-mail PIC Teknis"
          id="PicTechEmail"
          value={values?.PicTechEmail}
          onChange={formik.handleChange}
          placeholder="Masukkan e-mail PIC teknis"
          error={errCond('PicTechEmail')}
          message={errMsg('PicTechEmail')}
        />
        <GridLine
          fieldTitle="No. HP PIC Teknis"
          id="PicTechPhone"
          mode="phone"
          value={values?.PicTechPhone}
          onChange={formik.handleChange}
          placeholder="Masukkan no. hp PIC teknis"
          error={errCond('PicTechPhone')}
          message={errMsg('PicTechPhone')}
        />
      </div>

      <div className="space-y-2 bg-white p-5">
        <div className="font-bold text-xl">Informasi Pemilik</div>
        <GridLine
          fieldTitle="Nama Pemilik"
          id="OwnerName"
          value={values?.OwnerName}
          onChange={formik.handleChange}
          placeholder="Masukkan nama pemilik"
          error={errCond('OwnerName')}
          message={errMsg('OwnerName')}
          disabled
        />
        <GridSelect
          fieldTitle="Kewarganegaraan"
          options={sampleNation}
          selected={{ val: ownNation, set: setOwnNation }}
          placeholder="Pilih kewarganegaraan"
          error={check && ownNation === ''}
          message="Data tidak boleh kosong"
        />
        <GridLine
          fieldTitle="Alamat Pemilik"
          id="OwnerAddress"
          value={values?.OwnerAddress}
          onChange={formik.handleChange}
          placeholder="Masukkan alamat pemilik"
          error={errCond('OwnerAddress')}
          message={errMsg('OwnerAddress')}
        />
        <GridLine
          fieldTitle="Kode POS"
          mode="zip"
          id="OwnerZip"
          value={values?.OwnerZip}
          onChange={formik.handleChange}
          placeholder="Masukkan kode POS"
          error={errCond('OwnerZip')}
          message={errMsg('OwnerZip')}
        />
        <GridLine
          fieldTitle="No.HP Pemilik"
          mode="phone"
          id="OwnerPhone"
          value={values?.OwnerPhone}
          onChange={formik.handleChange}
          placeholder="Masukkan no.hp pemilik"
          error={errCond('OwnerPhone')}
          message={errMsg('OwnerPhone')}
          disabled
        />
      </div>
      <div className="space-y-2 bg-white p-5">
        <div className="font-bold text-xl">Dokumen</div>
        <GridImage
          id="id-ktp"
          fieldTitle={`KTP ${notCompany ? 'Pemilik Usaha' : 'Direktur Usaha'}`}
          setTheFile={setKtpFile}
          setThePhoto={setKtpPhoto}
          theFile={ktpFile}
          thePhoto={pkgExist ? ktpUrl : ktpPhoto}
          error={errImg(ktpFile)}
          message={messageImage(ktpFile)}
        />
        <GridLine
          fieldTitle="Nama Sesuai KTP"
          id="IdCardName"
          value={values?.IdCardName}
          onChange={formik.handleChange}
          placeholder="Masukkan nama sesuai KTP"
          error={errCond('IdCardName')}
          message={errMsg('IdCardName')}
        />
        <GridLine
          fieldTitle="Nomor KTP"
          mode="number"
          id="IdCardNumber"
          value={values?.IdCardNumber}
          onChange={formik.handleChange}
          placeholder="Masukkan nomor KTP"
          error={errCond('IdCardNumber')}
          message={errMsg('IdCardNumber')}
        />

        <GridImage
          id="id-npwp"
          fieldTitle={`NPWP ${notCompany ? 'Pemilik Usaha' : 'Perusahaan'}`}
          setTheFile={setNpwpFile}
          setThePhoto={setNpwpPhoto}
          theFile={npwpFile}
          thePhoto={pkgExist ? npwpUrl : npwpPhoto}
          error={errImg(npwpFile)}
          message={messageImage(npwpFile)}
        />
        <GridLine
          fieldTitle="Nama Sesuai NPWP"
          id="NpwpName"
          value={values?.NpwpName}
          onChange={formik.handleChange}
          placeholder="Masukkan nama sesuai NPWP"
          error={errCond('NpwpName')}
          message={errMsg('NpwpName')}
        />
        <GridLine
          fieldTitle="Nomor NPWP"
          mode="number"
          id="NpwpNumber"
          value={values?.NpwpNumber}
          onChange={formik.handleChange}
          placeholder="Masukkan nomor NPWP"
          error={errCond('NpwpNumber')}
          message={errMsg('NpwpNumber')}
        />
        <GridLine
          fieldTitle="Alamat Sesuai NPWP"
          id="NpwpAddress"
          value={values?.NpwpAddress}
          onChange={formik.handleChange}
          placeholder="Masukkan alamat sesuai NPWP"
          error={errCond('NpwpAddress')}
          message={errMsg('NpwpAddress')}
        />
      </div>
    </>
  );
};

export default InfoBisnis;
