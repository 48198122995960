import React from 'react';
import { SettingContext } from 'context/settingContext';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import useSetting from 'hooks/useSetting';

const Priview = ({ data }) => {
  let { merchantName, backGroundColor, bgImage, logoImage } =
    React.useContext(SettingContext);
  let { SettingState } = useSetting();

  const containerStyles: any = {
    backgroundColor: backGroundColor || '#191717',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  if (bgImage !== '') {
    containerStyles.backgroundImage = `url(${bgImage})`;
  }

  console.log(bgImage, logoImage, SettingState?.qoinView?.theme_pict, 'p');

  return (
    <div className="w-full max-w-[330px] bg-white h-full">
      <div
        className=" h-[100px]  flex mb-14 relative py-3 px-5"
        style={containerStyles}
      >
        <div className="">
          {logoImage ? (
            <div>
              <img width={25} height={25} src={logoImage} alt="" />
            </div>
          ) : SettingState?.qoinView?.theme_pict !== '' &&
            SettingState?.qoinView?.theme_pict !== undefined ? (
            <img
              width={30}
              height={30}
              src={SettingState?.qoinView?.theme_pict}
              alt="logo"
            />
          ) : (
            'LOGO'
          )}
        </div>
        <div className="absolute top-14 w-[87%]">
          <div className=" rounded-md bg-white h-auto p-3 drop-shadow-lg">
            <div className="font-bold border-b-2 pb-1 truncate ">
              {merchantName ? merchantName : 'Merchant Name'}
            </div>
            <div className="font-extrabold mt-1">Rp. 0</div>
            <div className="text-[10px] mt-1">Order ID 00000000</div>
          </div>
        </div>
      </div>

      <div className="mt-1 px-5 py-3">
        <div className="font-bold mt-1">Choose payment method</div>
      </div>
      <div className="overflow-scroll h-[300px]">
        {data?.map((e, i) => {
          const channelsWithStatus1 =
            e?.chanels?.length > 0 &&
            e.chanels.filter(
              (channel) =>
                Number(channel.status) === 1 &&
                Number(channel.pc_integration_type !== 1)
            );
          if (channelsWithStatus1?.length === 0) {
            return null;
          }

          return (
            e?.chanels && (
              <div key={i}>
                <div className="mt-1 px-5">
                  <div className="font-bold mt-1">{e.name}</div>
                  <div className="flex justify-between border-b-2">
                    <div className="flex ">
                      {channelsWithStatus1
                        ?.slice(0, 3)
                        .map((channel, index) => (
                          <div key={index} className="flex ">
                            <div className="w-[50px] min-h-[30px] px-2 border-2 m-2 flex items-center">
                              <img src={channel.picture.url} alt={e.name} />
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className="flex items-center">
                      {channelsWithStatus1?.length > 3 && (
                        <div style={{ padding: 3 }}>
                          + {channelsWithStatus1?.length - 3}
                        </div>
                      )}
                      <ChevronRightIcon />
                    </div>
                  </div>
                </div>
              </div>
            )
          );
        })}

        <br />
      </div>
    </div>
  );
};

export default Priview;
