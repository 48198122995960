import React from 'react';
import RefundCheck from './RefundCheck';
import RefundList from './RefundList';
import RefundProvider, { RefundContext } from 'context/refundContext';
import { useEffect } from 'react';
import useRefund from 'hooks/useRefund';
import { IApiResponse2 } from 'stores/types/generalTypes';
import Alert from 'components/alert';
import useSandbox from 'hooks/useSandbox';
import TitleFeature from 'components/cardComponents/titleFeature';

const Components = () => {
  const { getRefundList } = useRefund();
  let { params, handleAlert, severty, message, isAlert, setIsAlert, isDetail } =
    React.useContext(RefundContext);

  let sandbox = useSandbox()?.userData['Env'];

  useEffect(() => {
    getRefundList(params, (response: IApiResponse2) => {
      if (response.status_code !== 200) handleAlert('error', 'Failed');
    }); // eslint-disable-next-line
  }, [getRefundList, params, sandbox]);

  return (
    <>
      <Alert
        severty={severty}
        message={message}
        open={isAlert}
        setIsAlert={setIsAlert}
      />
      <TitleFeature
        text="Refund"
        className="mb-5 mt-[5rem] lg:mt-[1rem]"
        onClick={undefined}
      />
      {isDetail ? <RefundCheck /> : <RefundList />}
    </>
  );
};

const Refund = () => {
  return (
    <RefundProvider>
      <Components />
    </RefundProvider>
  );
};

export default Refund;
