import React, { useEffect } from 'react';
import DetailWd from './DetailSettlement';
import TableWdList from './table/tableSettlement';
import useWithdrawal from 'hooks/useWithdrawal';
import { IApiResponse } from 'stores/types/generalTypes';
import Alert from 'components/alert';
import WithdrawalProvider, {
  WithdrawalContext,
} from 'context/withdrawalContext';
import useSandbox from 'hooks/useSandbox';
import { tableNameWdList } from './table/dataList';
import useExport from 'hooks/useExport';
import Card from 'components/cardComponents';
import AdjustButton from 'components/Button/AdjustButton';
import SortByButton from 'components/Button/SortByButton';
import ExportButton from 'components/Button/ExportButton';

const Components = () => {
  let {
    uiNow,
    isAlert,
    setIsAlert,
    severty,
    message,
    header,
    handleAlert,
    params,
  } = React.useContext(WithdrawalContext);

  const { getWdList } = useWithdrawal();
  const sandbox = useSandbox()?.userData['Env'];

  useEffect(() => {
    getWdList({ ...params, v: 'bi' }, (response: IApiResponse) => {
      if (response.code !== 200) handleAlert('error', 'error');
    }); // eslint-disable-next-line
  }, [getWdList, params, sandbox]);

  const { exportWithdrawelList } = useExport();

  const handleDownloadExcel = () => {
    exportWithdrawelList(params, handleAlert, tableNameWdList);
  };

  return (
    <div>
      <Alert
        severty={severty}
        message={message}
        open={isAlert}
        setIsAlert={setIsAlert}
      />

      {uiNow === '' && (
        <>
          <div className="text-2xl sm:text-3xl  items-center font-bold mb-5 mt-[5rem] lg:mt-[1rem] cursor-pointer">
            Settlement
          </div>
          <Card>
            <div className="flex p-5 w-full justify-between items-centerborder-b-4]">
              <div className="text-[14px] lg:text-2xl px-3 py-3 font-bold">
                Settlement List
              </div>
            </div>

            <div className="flex justify-between items-center p-3 mb-3 px-5">
              <div className="md:flex md:space-x-2 grid gap-3">
                <AdjustButton header={tableNameWdList} list={header} />
                <SortByButton />
              </div>
              <ExportButton haandleClick={handleDownloadExcel} />
            </div>

            <TableWdList />
          </Card>
        </>
      )}
      {uiNow === 'wdDetail' && <DetailWd />}
    </div>
  );
};

const Settlement = () => {
  return (
    <WithdrawalProvider>
      <Components />
    </WithdrawalProvider>
  );
};
export default Settlement;
