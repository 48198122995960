// owner_type

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'stores';
import { getSaldoActions } from 'stores/actions/balanceActions';
import { permissionFunction } from 'utils/permission';
import permission from 'constants/permissionRole';
import { isFullVersion } from 'utils/exception';

const useBalance = () => {
  const dispatch = useDispatch();
  const balance = useSelector((state: RootState) => state.balance);
  const authState = useSelector((state: RootState) => state.auth.userData);
  const Env = authState['Env'] ? 'PRODUCTION' : 'SANDBOX';

  const handleBalance = useCallback(
    (params: any, callback?: (data: any) => void) => {
      permissionFunction(
        isFullVersion
          ? permission[Env].GET_SALDO_BALANCE
          : permission[Env].GET_SALDO_EARNING
      ) && dispatch(getSaldoActions(params, callback));
    },
    [Env, dispatch]
  );

  return {
    Env,
    balance,
    getBalance: handleBalance,
  };
};

export default useBalance;
