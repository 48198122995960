import React from 'react';
import { SelectChangeEvent } from '@mui/material';

import useRefund from 'hooks/useRefund';
import { IApiResponse2 } from 'stores/types/generalTypes';
import { IBodyDataCreate } from 'stores/types/refundTypes';
import { tableNameListRefund } from 'pages/transaksi/refund/table/datalist';

export const RefundContext = React.createContext(null);

const RefundProvider = (props) => {
  const { refundList, getRefundDetail, createRefund } = useRefund();
  let data = refundList.refundList;

  const [openModal, setOpenModal] = React.useState(false);
  const [isDetail, setisDetail] = React.useState(false);
  const [isFailedReq, setFailedReq] = React.useState(false);
  const [countPage, setcountPage] = React.useState('10');
  const [page, setPage] = React.useState(1);
  const [isAlert, setIsAlert] = React.useState(false);
  const [severty, setSeverty] = React.useState('success');
  const [message, setMessage] = React.useState('');
  const [transactionNo, setTransactionNo] = React.useState('');
  const [RefundNo, setRefundNo] = React.useState('');
  const [isTransactionNo, setIsTransactionNo] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [idPoper, setidPoper] = React.useState(null);
  const [reason, setReason] = React.useState('');

  const [params, setParams] = React.useState({
    page: 1,
    limit: 10,
    status: '',
    trx_number: '',
    no: '',
  });

  const [statuschecked, setStatuschecked] = React.useState(null);

  const [isCheckedstatuses, setisCheckedstatuses] = React.useState([
    false,
    false,
    false,
  ]);

  const [isCheckedTableNameList, setisCheckedTableNameList] = React.useState(
    new Array(tableNameListRefund.length).fill(true)
  );

  const handleCheckedTableNameList = (position: number) => {
    const updatedCheckedstatuses = isCheckedTableNameList.map((item, index) =>
      index === position ? !item : item
    );
    setisCheckedTableNameList(updatedCheckedstatuses);
  };

  const handleResetTableNameList = () => {
    setisCheckedTableNameList(new Array(tableNameListRefund.length).fill(true)); // eslint-disable-next-line
  };

  const handleOpenModal = () => {
    setOpenModal(true);
    setFailedReq(false);
  };

  const handleCloseModal = () => setOpenModal(false);

  const handleChangeCountPage = (event: SelectChangeEvent) => {
    if (data.length >= 10) setcountPage(event.target.value);

    setPage(1);
    setParams({
      ...params,
      page: 1,
      limit: Number(event.target.value),
    });
  };

  const ChangePage = (event: SelectChangeEvent, value: any) => {
    setPage(value);
    setParams({
      ...params,
      page: value,
      limit: Number(countPage),
    });
  };

  const handleAlert = (name: string, msg: string) => {
    setIsAlert(true);
    setSeverty(name);
    setMessage(msg);
  };

  const handleOpenDetail = () => {
    getRefundDetail(transactionNo, (response: IApiResponse2) => {
      if (response.status_code !== 200) {
        setFailedReq(true);
      } else {
        setisDetail(!isDetail);
        setOpenModal(false);
      }
    });
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>, id: number) => {
    setAnchorEl(event.currentTarget);
    setidPoper(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReset = () => {
    setRefundNo('');
    setIsTransactionNo('');
    setStatuschecked(null);
    setisCheckedstatuses([false, false, false]);

    setParams({
      page: 1,
      limit: 10,
      status: '',
      trx_number: '',
      no: '',
    });

    setAnchorEl(null);
  };

  const handleCheckedsstatuses = (position: number) => {
    const updatedCheckedstatuses = isCheckedstatuses.map((item, index) =>
      index === position ? !item : false
    );
    setisCheckedstatuses(updatedCheckedstatuses);
  };

  const handleStatus = (i: any, data: any) => {
    i === 1 && setStatuschecked(1);
    i === 2 && setStatuschecked(2);

    handleCheckedsstatuses(i);
  };

  const handleSubmit = () => {
    setPage(1);
    setParams({
      ...params,
      page: 1,
      status: statuschecked,
      trx_number: isTransactionNo,
      no: RefundNo,
    });

    handleClose();
  };

  const handleSubmitRefund = (bodyData: IBodyDataCreate) => {
    createRefund(bodyData, (response: IApiResponse2) => {
      handleCloseModal();
      setTransactionNo('');
      setReason('');
      if (response.status_code === 202 || response.status_code === 200) {
        setisDetail(false);
        setOpenModal(false);
        handleAlert('success', 'Succes');
      } else {
        handleAlert('error', 'Failed');
        setOpenModal(false);
        setisDetail(false);
      }
    });
  };

  let valueContext = {
    params,
    setParams,
    ChangePage,
    isAlert,
    severty,
    message,
    transactionNo,
    setTransactionNo,
    setidPoper,
    idPoper,
    setAnchorEl,
    anchorEl,
    isDetail,
    setisDetail,
    countPage,
    page,
    setIsAlert,
    isFailedReq,
    setFailedReq,
    openModal,
    handleOpenModal,
    handleCloseModal,
    RefundNo,
    setRefundNo,
    isTransactionNo,
    setIsTransactionNo,
    handleReset,
    handleSubmit,
    handleMenu,
    handleStatus,
    handleClose,
    statuschecked,
    handleAlert,
    isCheckedstatuses,
    handleChangeCountPage,
    handleOpenDetail,
    setReason,
    reason,
    handleSubmitRefund,

    isCheckedTableNameList,
    handleCheckedTableNameList,
    handleResetTableNameList,
  };

  return (
    <RefundContext.Provider value={valueContext}>
      {props.children}
    </RefundContext.Provider>
  );
};

export default RefundProvider;
