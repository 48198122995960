import { useContext } from 'react';

import { tableNameListRefund } from './datalist';
import useRefund from 'hooks/useRefund';
import { RefundContext } from 'context/refundContext';

import Pagination from 'components/pagination';
import BassicTable from 'components/table';
import Filter from './filter';

const TableRefund = (props: any) => {
  const { refundList } = useRefund();
  let { pagination, isLoading } = refundList;

  let { header } = props;

  let data = refundList.refundList;

  let {
    countPage,
    handleChangeCountPage,
    ChangePage,
    page,
    handleSubmit,
    handleReset,
  } = useContext(RefundContext);

  return (
    <>
      <BassicTable
        FilterComponent={Filter}
        headerList={header.val}
        tableNameList={tableNameListRefund}
        dataList={data}
        isLoading={isLoading}
        handleSubmitFilter={handleSubmit}
        handleResetFilter={handleReset}
        {...{ countPage, page }}
      />

      <Pagination
        value={countPage}
        data={pagination}
        page={page}
        handleChange={handleChangeCountPage}
        handlePage={ChangePage}
      />
    </>
  );
};

export default TableRefund;
