// name: name variable, tableName: nameTableUi/nama yang tampil di user, icon: filterIcon
const addTableName = (
  name: string,
  tableName: string,
  filter: boolean,
  typeValue?: string,
  width?: string,
  formatText?: string
) => ({
  name,
  tableName,
  filter,
  typeValue,
  width,
  formatText,
});

// 0: name variable, 1: nameTableUi/nama yang tampil di user, 2: filterIcon
export const tableNameWdList = [
  addTableName('settlement_date', 'Settlement Date', true, '', '180px'),
  addTableName('trx_no', 'Settlement No', true, '', '145px'),
  addTableName('receipt_no', 'Receipt No', true, '', '140px', 'text-center'),
  addTableName(
    'total_trx_record',
    'Total Records',
    false,
    '',
    '110px',
    'text-center'
  ),
  addTableName(
    'settlement_amount',
    'Settlement Amount',
    false,
    'RupiahFormat',
    '160px'
  ),
  addTableName(
    'transfer_amount',
    'Transfer Amount',
    false,
    'RupiahFormat',
    '160px'
  ),
  addTableName('status', 'Status', false, 'status', '12 0px'),
  addTableName('trx_no', 'Action', false, 'ActionDetail', '50px'),
];

export const tableNameWdDetail = [
  addTableName('trx_no', 'Transaction No', false, '', '120px'),
  addTableName('reference_no', 'Reference No', false, '', '110px'),
  addTableName(
    'initial_amount',
    'Initial Amount',
    false,
    'RupiahFormat',
    '110px'
  ),
  addTableName('fee_settled', 'Settled Fee', false),
  addTableName('fee_third_party', '3rd Party Fee', false),
  addTableName('amount', 'Transaction Amount', false, 'RupiahFormat', '170px'),
  addTableName('channel', 'Payment Channel', false, '', '150px'),
];

export const tableNameWdSettled = [
  addTableName('No', 'No', false),
  addTableName('trx_no', 'Transaction No', false),
  addTableName('reference_no', 'Reference No', false),
  addTableName('initial_amount', 'Initial Amount', false),
  addTableName('fee_settled', 'Settled Fee', false),
  addTableName('fee_third_party', '3rd Party Fee', false),
  addTableName('amount', 'Trx Amount', false),
  addTableName('channel', 'Payment Channel', false),
];
