import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

const WIDTH = window.innerWidth < 600;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PreviewProps {
  file: string | File;
}
type ImageProp = { src: string };
const IMAGE: React.FC<ImageProp> = ({ src }) => {
  return (
    <img
      src={src}
      alt="preview"
      width={WIDTH ? 270 : 400}
      className="max-h-64"
    />
  );
};

const Component: React.FC<PreviewProps> = ({ file }) => {
  // eslint-disable-next-line
  const [numPages, setNumPages] = useState<number | null>(null);

  const getFileType = (file: File | string) => {
    if (typeof file === 'string') {
      if (file?.startsWith('https')) {
        const encoded_filename = file.replace(/^.*\/([^/]+?)\?.*$/, '$1');
        const plain_filename = decodeURI(encoded_filename);
        const fileExt = plain_filename.split('.').pop();
        return fileExt;
      }
    } else {
      if (file?.type.startsWith('image/')) {
        return 'image';
      } else if (file?.type === 'application/pdf') {
        return 'pdf';
      } else {
        return null;
      }
    }
  };

  const renderPreview = (fileType: string | null) => {
    if (fileType === 'pdf') {
      return (
        <Document
          file={file}
          onLoadSuccess={({ numPages }) => setNumPages(numPages)}
        >
          <Page
            width={WIDTH ? 270 : 340}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            pageNumber={1}
          />
        </Document>
      );
    } else {
      return <IMAGE src={file as string} />;
    }
  };

  const fileType = getFileType(file);

  return <>{fileType && renderPreview(fileType)}</>;
};
export default Component;
