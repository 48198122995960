const PERMISSION = {
  GET_SALDO_BALANCE: 'Web:Sandbox:Balance:Get',
  SANDBOX: {
    GET_SALDO_BALANCE: 'Web:Sandbox:Balance:Get',
    GET_SALDO_EARNING: 'Web:Sandbox:MerchantEarning:Get',

    GET_REFUND: 'Web:Sandbox:Refund:Get',
    LIST_REFUND: 'Web:Sandbox:Refund:List',
    CREATE_REFUND: 'Web:Sandbox:Refund:Create',

    GET_TRANSACTION: 'Web:Sandbox:Transaction:Get',
    LIST_TRANSACTION: 'Web:Sandbox:Transaction:List',

    GET_WITHDRAWAL: 'Web:Sandbox:Withdrawal:Get',
    LIST_WITHDARWAL: 'Web:Sandbox:Withdrawal:List',
    SETTLED_WITHDRAWAL: 'Web:Sandbox:Withdrawal:Settle',
    REQ_WITHDRAWAL: 'Web:Sandbox:Withdrawal:Req',

    GET_SYSTEMSETTING: 'Web:Sandbox:SystemSetting:Get',
    UPDATE_SYSTEMSETTING: 'Web:Sandbox:SystemSetting:Update',
    CREATE_SYSTEMSETTING: 'Web:Sandbox:SystemSetting:Create',

    GET_QOINVIEW: 'Web:Sandbox:QoinView:Get',
    UPDATE_QOINVIEW: 'Web:Sandbox:QoinView:Update',
  },

  PRODUCTION: {
    GET_SALDO_BALANCE: 'Web:Production:Balance:Get',
    GET_SALDO_EARNING: 'Web:Production:MerchantEarning:Get',

    GET_REFUND: 'Web:Production:Refund:Get',
    LIST_REFUND: 'Web:Production:Refund:List',
    CREATE_REFUND: 'Web:Production:Refund:Create',

    GET_TRANSACTION: 'Web:Production:Transaction:Get',
    LIST_TRANSACTION: 'Web:Production:Transaction:List',

    GET_WITHDRAWAL: 'Web:Production:Withdrawal:Get',
    LIST_WITHDARWAL: 'Web:Production:Withdrawal:List',
    SETTLED_WITHDRAWAL: 'Web:Production:Withdrawal:Settle',
    REQ_WITHDRAWAL: 'Web:Production:Withdrawal:Req',

    GET_SYSTEMSETTING: 'Web:Production:SystemSetting:Get',
    UPDATE_SYSTEMSETTING: 'Web:Production:SystemSetting:Update',
    CREATE_SYSTEMSETTING: 'Web:Production:SystemSetting:Create',

    GET_QOINVIEW: 'Web:Production:QoinView:Get',
    UPDATE_QOINVIEW: 'Web:Production:QoinView:Update',
  },

  GET_MESSAGE: 'Web:Messaging:Get',
  LIST_MESSAGE: 'Web:Messaging:List',
  UPDATE_MESSAGE: 'Web:Messaging:Update',
  DELETE_MESSAGE: 'Web:Messaging:Delete',

  EDIT_PASSWORD: 'Web:Password:Edit',
  DELETE_PASSWORD: 'Web:Profile:Delete',
  EDIT_PROFILE: 'Web:Profile:Edit',
  GET_PROFILE: 'Web:Profile:Get',
  USER_LIST: 'Web:Merchant:User:List',
  ROLE_LIST: 'Web:Merchant:RolePermission:List',

  CREATE_AGENCY: 'Web:Merchant:MerchantAgency:Create',
  UPDATE_AGENCY: 'Web:Merchant:MerchantAgency:Update',
  GET_AGENCY: 'Web:Merchant:MerchantAgency:Get',
  LIST_AGENCY: 'Web:Merchant:MerchantAgency:List',
};

export default PERMISSION;

// refund, wd, trx
