import React, { useEffect, useState } from 'react';
import useSetting from 'hooks/useSetting';
import { toast } from 'react-toastify';
import { SettingContext } from 'context/settingContext';
import { ThemeProvider } from '@emotion/react';
import { btnTheme2 } from 'components/muiTheme';
import RefreshIcon from '@mui/icons-material/Refresh';
import LoadingButton from '@mui/lab/LoadingButton';
import useSandbox from 'hooks/useSandbox';
import LabelInfo from './components/labelInfo';
import WrapEditSubmit from './components/WrapEditSubmit';

const SecurityKey = () => {
  const [isInfo, setisInfo] = useState(true);
  let { SettingState, generate, submitEdit, getApiSettingData } = useSetting();
  let { isLoading, systemSetting } = SettingState;
  let { securityKey } = systemSetting;
  let { generatKey, generatSecret } = isLoading;
  let { api_key, api_public, api_secret } = securityKey;

  let { isEdit, setisEdit, setisModal } = React.useContext(SettingContext);

  const [api_publicState, setApi_public] = React.useState(api_public);
  const [api_keyState, setApi_keyState] = React.useState(api_key);
  const [api_secretState, setApi_secret] = React.useState(api_secret);

  const [defaultFormInput, setDefaultFormInput] = React.useState({
    api_publicState,
    api_keyState,
    api_secretState,
  });
  let sandbox = useSandbox()?.userData['Env'];

  const handleGenerate = (id: number) => {
    let url = id === 2 ? 'key' : 'secret';
    generate(url, (response: any) => {
      if (response.code !== 200) {
        toast.error('Terjadi Kesalahan', { autoClose: 4000 });
      } else {
        id === 2
          ? setApi_keyState(response.data)
          : setApi_secret(response.data);
      }
    });
  };

  useEffect(() => {
    setisEdit(false);
  }, [setisEdit]);

  useEffect(() => {
    setisEdit(false);
    getApiSettingData('security', (response) => {
      if (response.code !== 200) {
        toast.error('Terjadi Kesalahan', { autoClose: 4000 });
      } else {
        setApi_public(response.data.api_public);
        setApi_keyState(response.data.api_key);
        setApi_secret(response.data.api_secret);
        setDefaultFormInput({
          api_publicState: response.data.api_public,
          api_keyState: response.data.api_key,
          api_secretState: response.data.api_secret,
        });
      }
    });
  }, [getApiSettingData, sandbox, setisEdit]);

  const handleEdit = () => {
    let data = {
      api_key,
      api_public: api_publicState,
      api_secret,
    };
    submitEdit('security', data, (response: any) => {
      if (response.code !== 200) {
        toast.error('Terjadi Kesalahan', { autoClose: 4000 });
      } else {
        getApiSettingData('security', (response: any) => {
          if (response.code !== 200)
            toast.error('Terjadi Kesalahan', { autoClose: 4000 });
        });
        setisModal(true);
      }
    });
  };

  const handleChange = (e: any) => {
    setApi_public(e.target.value);
  };

  const handleCancel = () => {
    setisEdit(false);
    setApi_public(defaultFormInput.api_publicState);
    setApi_keyState(defaultFormInput.api_keyState);
    setApi_secret(defaultFormInput.api_secretState);
  };

  const InputField = ({ name, defaultValue, id }) => {
    return (
      <div className="block lg:flex h-[5rem] lg:h-[3rem]  gap-5 mb-5 ">
        <div className="w-[20rem] items-center flex font-medium text-[1.2rem] h-11">
          {name}
        </div>
        <div className="w-full lg:w-[38rem] h-[3.2rem] ...">
          <input
            placeholder="Input value"
            value={defaultValue}
            disabled={true}
            className={`
                   w-full border-[1px] border-[#AFAFAF] h-full rounded-md py-2 text-start pl-3 pr-3`}
          />
        </div>
        <div className={`flex items-center shrink-0 h-11 w-[7rem]`}>
          <ThemeProvider theme={btnTheme2}>
            <div className={`${(id === 1 || !isEdit) && 'hidden'} w-[20%]`}>
              <LoadingButton
                variant="contained"
                loading={id === 2 ? generatKey : generatSecret}
                className="w-[120px] px-3"
                onClick={() => handleGenerate(id)}
                startIcon={<RefreshIcon />}
              >
                Generate
              </LoadingButton>
            </div>
          </ThemeProvider>
        </div>
      </div>
    );
  };

  return (
    <div>
      <LabelInfo
        {...{ isInfo, setisInfo }}
        text="  You will need to know your Merchant Code and Server Key to
            communicate with Qoin. Please use the Development server while you
            are still in development."
      />

      <div className="gap-1 max-w-[1000px]">
        <div>
          <InputField
            id={1}
            name="Merchant Code"
            defaultValue={securityKey.code}
          />
          <InputField
            id={2}
            name="API Key / Client ID"
            defaultValue={api_keyState}
          />
          <InputField
            id={3}
            name="API Secret / Client Secret"
            defaultValue={api_secretState}
          />
        </div>

        <div className="block lg:flex gap-5 mb-5 ">
          <div className="w-[20rem] items-center flex font-medium text-[1.2rem] h-11">
            Public Key
          </div>
          <div className="w-full lg:w-[38rem] h-full ...">
            <textarea
              placeholder="Input public key"
              defaultValue={securityKey.api_public}
              disabled={!isEdit}
              onChange={handleChange}
              value={api_publicState}
              className={`border-[1px] w-full h-[200px] border-[#AFAFAF] rounded-md  pr-[20px] text-start p-2 `}
            />
            <WrapEditSubmit
              {...{ handleCancel, isEdit, handleEdit }}
              w="100%"
            />
          </div>
          <div className={`flex items-center shrink-0 h-11 w-[7rem]`}></div>
        </div>
      </div>
    </div>
  );
};

export default SecurityKey;
