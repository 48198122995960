import { useState, useEffect } from 'react';
import { ClientJS } from 'clientjs';
import { noUndef } from 'utils/exception';

export interface ActivityData {
  latitude: number | null;
  longitude: number | null;
  ipAddress: string | null;
  browserId: number | null;
}

export function addAuditrail(data: FormData, auditrail: ActivityData) {
  const { browserId, ipAddress, latitude, longitude } = auditrail;
  if (browserId) data.append('BrowserId', browserId?.toString());
  if (ipAddress) data.append('IP', ipAddress);
  if (latitude && longitude) {
    data.append('Lat', noUndef(latitude));
    data.append('Long', noUndef(longitude));
  }
}

function useActivityBrowser(): ActivityData {
  const [latitude, setLatitude] = useState<number | null>(null);
  const [longitude, setLongitude] = useState<number | null>(null);
  const [ipAddress, setIpAddress] = useState<string | null>('');
  const [browserId, setBrowserId] = useState<number | null>(null);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
    });

    fetch('https://api.ipify.org')
      .then((response) => response.text())
      .then((ip) => setIpAddress(ip))
      .catch((err) => err);

    const client = new ClientJS();
    const fingerprint = client.getFingerprint();

    setBrowserId(fingerprint);
  }, []);

  return { latitude, longitude, ipAddress, browserId };
}

export default useActivityBrowser;
