import { ThemeProvider } from '@emotion/react';
import {
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from '@mui/material';
import { PaginationTheme } from 'components/muiTheme';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import { ItemTypes } from './ItemTypes';
import { useEffect, useMemo, useState } from 'react';
import { getEmptyImage } from 'react-dnd-html5-backend';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

interface ICardPaymentChannel {
  priview?: boolean;
  chanel?;
  index?;
  pmId?;
  moveChannel?;
  dragindId?;
  findChannel?;
  editView?;
  pcDrag?;
  setpcDrag?: any;
  handleSelectChannel?;
  handleChangeFee?;
  handleChangeType?;
}
const CardPaymentChannel: React.FC<ICardPaymentChannel> = ({
  chanel,
  index,
  pmId,
  moveChannel,
  dragindId,
  findChannel,
  priview,
  editView,
  pcDrag,
  handleSelectChannel,
  handleChangeFee,
  handleChangeType,
}) => {
  const [hide, sethide] = useState(false);

  const [{ isDragging }, ref, preview] = useDrag({
    type: ItemTypes.CHANNEL,
    item: { pmId, index, type: ItemTypes.CHANNEL, pcPage_id: chanel.page_id },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const hoverCallback = useMemo(() => {
    return (
      draggedItem: { index: number; pmId: number },
      monitor: DropTargetMonitor
    ) => {
      if (
        draggedItem.index !== index &&
        draggedItem.pmId === pmId &&
        monitor.isOver()
      ) {
        !isDragging && dragindId.set(index);
        moveChannel(draggedItem.pmId, draggedItem.index, index);
        draggedItem.index = index;
      }
    }; // eslint-disable-next-line
  }, []);

  useEffect(() => {
    !priview && findChannel(pmId, chanel.id);
    // eslint-disable-next-line
  }, [isDragging]);

  const [, drop] = useDrop({
    accept: ItemTypes.CHANNEL,
    hover: hoverCallback,
    drop: (item) => console.log(item, 'ini'),
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    sethide(false);
  }, [pcDrag]);

  let isDragPc = pcDrag === chanel.page_id;

  return (
    <div key={index} ref={(node) => editView && ref(drop(node))}>
      <div
        className={`${
          isDragPc
            ? 'p-0 bg-[#F0F0F0] h-[43px]'
            : ' border-[1.5px] bg-[#FFFFFF]'
        }  ${!priview && 'pb-5 lg:pb-0 mb-3 '} w-full rounded-md  px-3 `}
      >
        <div
          className="flex justify-between items-center"
          onClick={() => !isDragPc && sethide(!hide)}
        >
          <ThemeProvider theme={PaginationTheme}>
            {editView || (priview && !isDragPc) ? (
              <div className="flex items-center ">
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      name="status"
                      sx={isDragPc && { display: 'none' }}
                      checked={Boolean(Number(chanel['status']))}
                      onChange={(e) => {
                        !chanel['status'] && sethide(true);
                        handleSelectChannel(
                          pmId,
                          chanel.page_id,
                          e.target.checked
                        );
                      }}
                    />
                  }
                  label={undefined}
                />
                <div
                  className={`${
                    isDragPc && 'hidden'
                  }  text-start -ml-5 text-[16px] cursor-pointer font-bold`}
                >
                  <div>{chanel?.name}</div>
                </div>
              </div>
            ) : (
              <div className="items-center  text-[16px] font-bold p-2">
                <div>
                  <div>{chanel?.name}</div>
                </div>
              </div>
            )}
          </ThemeProvider>
          {editView && !isDragPc && (
            <DragIndicatorIcon sx={{ color: '#9E9E9E', cursor: 'grab' }} />
          )}
        </div>

        <Collapse
          in={editView ? hide : chanel['status']}
          timeout="auto"
          unmountOnExit
        >
          <div className="grid gap-4  my-2">
            <FormControl sx={{ m: 1, width: '100%' }}>
              <div className="mb-3">Pilih Tipe Payment :</div>
              <Select
                value={Number(chanel['pc_integration_type'])}
                sx={{
                  '&:hover': editView && {
                    color: '#18AD65',
                  },
                  color: '#94A3B8',
                  '&.Mui-MenuItem': {
                    // this is to refer to the prop provided by M-UI
                    backgroundColor: 'red', // updated backgroundColor
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#18AD65',
                    color: '#18AD65 !important',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': editView && {
                    borderColor: '#18AD65',
                    color: '#18AD65',
                  },
                  '.MuiSvgIcon-root ': {
                    fill: '#21AF7D !important',
                  },
                  width: '90%',
                  height: 40,
                }}
                MenuProps={{
                  sx: {
                    '&& .Mui-selected': {
                      backgroundColor: '#18AD65',
                      color: 'white',
                      display: 'none',
                    },
                  },
                }}
                onChange={(e) =>
                  handleChangeType(pmId, chanel['page_id'], e.target.value)
                }
                disabled={!editView}
              >
                <MenuItem value={1}>Host to Host</MenuItem>
                <MenuItem value={2}>Webview</MenuItem>
                <MenuItem value={3}>Host to Host & Webview</MenuItem>
              </Select>
            </FormControl>
            <ThemeProvider theme={PaginationTheme}>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="ie"
                value={chanel['ie']}
                className="grid gap-5   "
                onChange={(e) => {
                  handleChangeFee(pmId, chanel['page_id'], e.target.value);
                }}
              >
                <FormControlLabel
                  value="1"
                  disabled={!editView}
                  control={
                    <Radio
                      sx={{
                        color: '#21AF7D',
                        '&.Mui-checked': {
                          color: '#21AF7D',
                        },
                        marginTop: '-45px',
                      }}
                    />
                  }
                  label={
                    <div className=" items-center">
                      <div className=" w-full justify-between items-center">
                        <strong>Include Fee</strong>
                      </div>
                      <div>
                        Setiap transaksi yang berhasil, biaya transaksi akan
                        ditanggung oleh Merchant.
                      </div>
                    </div>
                  }
                />
                <FormControlLabel
                  value="2"
                  disabled={!editView}
                  control={
                    <Radio
                      sx={{
                        color: '#21AF7D',
                        height: 10,
                        '&.Mui-checked': {
                          color: '#21AF7D',
                        },
                        marginTop: '-45px',
                      }}
                    />
                  }
                  label={
                    <div className=" items-center">
                      <div className=" w-full justify-between items-center">
                        <strong>Exclude Fee</strong>
                      </div>
                      <div>
                        Setiap transaksi yang berhasil, biaya transaksi tidak
                        akan ditanggung oleh Merchant.
                      </div>
                    </div>
                  }
                />
              </RadioGroup>
            </ThemeProvider>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default CardPaymentChannel;
