import React from 'react';
import { TableCell } from '@mui/material';
import { icons } from 'assets';

interface IHeaderRowContent {
  handleMenu: any;
  dataList: any;
  headerList?: any;
  disableNo: boolean;
  listParams?: any;
}

const HeaderRowContent: React.FC<IHeaderRowContent> = ({
  handleMenu,
  dataList,
  headerList,
  disableNo,
  listParams,
}) => {
  const headerTemplate = (item, idx: number) => {
    return (
      <TableCell
        key={idx}
        className={`bg-[#eeeeee] font-bold`}
        sx={{
          display: `${
            headerList
              ? headerList?.includes(item?.tableName)
                ? 'table-cell'
                : 'none'
              : 'table-cell'
          }`,
        }}
      >
        <div
          className={`flex w-full`}
          style={{ width: item?.width || '130px', fontWeight: 'bold' }}
        >
          {item?.tableName && (
            <div
              className={`${
                (item.tableName === 'Action' ||
                  item.typeValue === 'icon' ||
                  item.typeValue === 'checkbox') &&
                'text-center mx-auto'
              }  `}
            >
              {item.tableName}
            </div>
          )}
          {item?.filter && (
            <div className='relative  flex items-center'>
              <img
                onClick={(e) => {
                  handleMenu(e, item.name, item);
                }}
                className='cursor-pointer ml-2'
                src={icons.filter}
                alt='icon'
              />
              {listParams && listParams[item?.filterName] && (
                <div className='bg-[#EE4E2B] w-[6px] h-[6px] left-[22px] top-[2px] rounded-full absolute'></div>
              )}
            </div>
          )}
        </div>
      </TableCell>
    );
  };

  return (
    <>
      {!disableNo && (
        <TableCell
          align='justify'
          className='bg-[#eeeeee] '
        >
          <div className='font-bold ml-[8px] w-[13px]'>No.</div>
        </TableCell>
      )}
      {dataList.map((item, idx) => headerTemplate(item, idx))}
    </>
  );
};

export default HeaderRowContent;
