import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'stores';
import { changeEnv } from 'stores/actions/authActions';

const useSandbox = () => {
  const dispatch = useDispatch();
  const { userData, profile } = useSelector((state: RootState) => state.auth);

  const handleSwitch = (Env: boolean) => {
    dispatch(changeEnv(Env));
  };

  return {
    userData,
    profile,
    handleSwitch,
  };
};

export default useSandbox;
