const InputField = ({
  isEdit,
  isInvalid,
  nameInput,
  name,
  defaultvalue,
  value,
  disabled,
  handleChange,
}) => {
  return (
    <div
      className={`block lg:flex mt-5 text-[#231F20] font-medium ${
        isEdit && isInvalid[nameInput]
          ? 'w-full lg:w-[94%]'
          : 'w-full lg:w-[79%] '
      } mb-3 items-center`}
    >
      <div className="w-full lg:w-[38%] mb-3 lg:mb-0">{name}</div>
      <input
        placeholder="Input IP here"
        name={nameInput}
        defaultValue={defaultvalue}
        onChange={handleChange}
        value={value}
        disabled={disabled}
        className={`border-[1px] w-full lg:w-[75%]  ${
          isEdit && isInvalid[nameInput] ? 'border-[red] outline-[red]' : ''
        } rounded-md py-2 text-start pl-3 px-51`}
      />
      {isEdit && isInvalid[nameInput] && (
        <div className="ml-0 lg:ml-5 mt-2 lg:mt-0 w-full lg:w-[18%] text-[red]">
          invalid value
        </div>
      )}
    </div>
  );
};

export default InputField;
