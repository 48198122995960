import React from "react";
import PropTypes from "prop-types";
import Skeleton from "@mui/material/Skeleton";

interface Props {
  isLoading: boolean;
  children: React.ReactNode;
  fontSize?: string;
}

const LoadingWrapper: React.FC<Props> = ({ isLoading, children, fontSize }) => {
  return (
    <>
      {isLoading === true ? (
        <Skeleton variant="text" sx={{ fontSize }} />
      ) : (
        <>{children}</>
      )}
    </>
  );
};

LoadingWrapper.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  fontSize: PropTypes.string,
};

LoadingWrapper.defaultProps = {
  fontSize: "1rem",
};

export default LoadingWrapper;
