import { AxiosError } from 'axios';
import provider, { IProvider } from 'provider';
import MethodProvider from 'provider/methods';
import { ThunkAction } from 'redux-thunk';
import { RootState } from 'stores';
import { IError } from 'stores/types/errorTypes';
import { IApiResponse } from 'stores/types/generalTypes';
import {
  WithdrawalAction,
  WithdrawalType,
  IWithdrawal_list,
  IWithdrawal_detail,
  IWithdrawalSettled,
} from 'stores/types/withdrawalTypes';
import { errorGlobal } from './errorAction';
import endpoints from 'api/endpoints';
import { generateXSRFToken, getBrowserId } from './utilsAction';
import { getCookie } from 'utils/cookie';

export const WdPending = (): WithdrawalAction => ({
  type: WithdrawalType.SET_WD_PENDING,
});
export const WdPendingReq = (): WithdrawalAction => ({
  type: WithdrawalType.SET_WD_REQ_PENDING,
});

export const WdError = (error: AxiosError): WithdrawalAction => ({
  type: WithdrawalType.SET_WD_ERROR,
  payload: {
    error,
  },
});

export const GetWdList = (data: IWithdrawal_list): WithdrawalAction => ({
  type: WithdrawalType.GET_WD_LIST,
  payload: data,
});

export const GetWdDetail = (data: IWithdrawal_detail): WithdrawalAction => ({
  type: WithdrawalType.GET_WD_DETAIL,
  payload: data,
});

export const GetWdSettled = (data: IWithdrawalSettled): WithdrawalAction => ({
  type: WithdrawalType.GET_WD_SETTLED,
  payload: data,
});

export const SetWdReq = (): WithdrawalAction => ({
  type: WithdrawalType.SET_WD_REQUEST,
});

export const GetWdListAction = (
  params?: any,
  callback?: (data: IApiResponse) => void
): ThunkAction<void, RootState, null, WithdrawalAction | IError> => {
  return async (dispatch, getState) => {
    dispatch(WdPending());

    let sandbox = getState()?.auth?.userData['Env'];

    try {
      const objProvider: IProvider = {
        sandbox: sandbox,
        method: MethodProvider.GET,
        path: endpoints.withdrawalList,
        queryParams: params,
      };
      const response = await provider(objProvider);

      let data = {
        data: response?.data?.data?.withdrawal_lists,
        pagination: response?.data?.data?.pagination,
      };

      dispatch(GetWdList(data));
      callback(response?.data);
    } catch (e) {
      dispatch(errorGlobal(e));
      dispatch(WdError(e));
      callback(e);
    }
  };
};

export const GetWdDetailAction = (
  params?: any,
  id?: string,
  callback?: (data: IApiResponse) => void
): ThunkAction<void, RootState, null, WithdrawalAction | IError> => {
  return async (dispatch, getState) => {
    dispatch(WdPending());

    let sandbox = getState()?.auth?.userData['Env'];

    try {
      const objProvider: IProvider = {
        sandbox: sandbox,
        method: MethodProvider.GET,
        path: `${endpoints.withdrawalDetail}/${id}`,
        queryParams: params,
      };
      const response = await provider(objProvider);

      let data = {
        id,
        data: response?.data?.data?.transactions,
        pagination: response?.data?.data?.pagination,
        withdrawl_information: response?.data?.data?.withdraw_information,
        summary: response?.data?.data?.summary,
        transfer_summary: response?.data?.data?.transfer_summary,
        settlement_summary_list: response?.data?.data?.transactions,
        settlement_summary: response?.data?.data?.settlement_summary,
        settlement_information: response?.data?.data?.settlement_information,
        transfer_summary_v2: response?.data?.data?.transfer_summary_v2 || [],
      };

      dispatch(GetWdDetail(data));
      console.log(data, 'hi');

      callback(response?.data);
    } catch (e) {
      callback(e);
      dispatch(errorGlobal(e));
      dispatch(WdError(e));
    }
  };
};

export const GetWdSettledActions = (
  params?: any,
  callback?: (data: IApiResponse) => void
): ThunkAction<void, RootState, null, WithdrawalAction | IError> => {
  return async (dispatch, getState) => {
    dispatch(WdPendingReq());

    let sandbox = getState()?.auth?.userData['Env'];

    try {
      const objProvider: IProvider = {
        sandbox: sandbox,
        method: MethodProvider.GET,
        path: endpoints.withdrawalSettled,
      };
      const response = await provider(objProvider);

      let data = {
        data: response?.data?.data?.settled_transactions,
        summary: response?.data?.data?.summary,
      };

      dispatch(GetWdSettled(data));
      callback(response?.data);
    } catch (e) {
      dispatch(errorGlobal(e));
      callback(e);
    }
  };
};
export const SetWdReqAction = (
  params?: any,
  callback?: (data: IApiResponse) => void
): ThunkAction<void, RootState, null, WithdrawalAction | IError> => {
  return async (dispatch, getState) => {
    dispatch(WdPending());
    let Session = '12';

    let sandbox = getState()?.auth?.userData['Env'];
    let broserId = getBrowserId();
    await generateXSRFToken(sandbox);

    try {
      const objProvider: IProvider = {
        sandbox: sandbox,
        method: MethodProvider.POST,
        path: endpoints.withdrawalOrder,
        data: { broserId },
        headers: {
          Session,
          'X-Xsrf-Token': getCookie('xsrf-token'),
        },
      };
      const response = await provider(objProvider);

      dispatch(SetWdReq());
      callback(response?.data);
    } catch (e) {
      dispatch(errorGlobal(e));
      callback(e);
    }
  };
};
